// OverView_Unit_progress_GetNotesCategory


import { Get } from "../../estateTool/dashboard/utils/Axios";
import { endpoint } from "../EndPoint";
import { GetHeader } from "../Header";

export const GetUnitProgressNotesCategory = (data) => {
    // rpid = rpid === "" || rpid === null ? 0 : rpid;
  return new Promise((resolve, reject) => {
    Get(endpoint.OverView_Unit_progress_GetNotesCategory, GetHeader(), data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
