import { useEffect, useState, useRef } from "react";
import "./dashboard.css";
import "../dashboard/overview/overviews.css";
import "../dashboard/components/Dropdowns/AcitivityDropdown/ActivityDropdown.css";
import gearIcon from "../dashboard/Icons/settings-customization-icon.svg";
import DenishFlag from "../dashboard/Icons/danish-flag.svg";
import EnglishFlag from "../dashboard/Icons/english-flag.svg";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TabMenu from "./economy/TabMenu";
import { Grommet, CheckBox } from "grommet";

import { useNavigate, useSearchParams } from "react-router-dom";

import { SetSessionItem } from "./utils/SessionStorage";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";

import LazyLoad from "react-lazy-load";
import { GetAllPropertiesByRegionUnitType } from "../../API/AllPropertiesByRegionUnitType/AllPropertiesByRegionUnitType";
import ActivityDropdown from "./components/Dropdowns/AcitivityDropdown/ActivityDropdown";
import { GetAllRegions } from "../../API/AllPropertiesAllRegions/AllPropertiesAllRegions";

import { Trans, useTranslation } from "react-i18next";
import Star from "./components/Star/Star";
import { GetAllStarProperties } from "../../API/Common/CommonStarProperties";

import "../dashboard/economy/economy.css";

import calendar from "../dashboard/Icons/calendar.svg";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addYears, endOfYear, startOfYear } from "date-fns";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  format,
} from "date-fns";
import { ArrowUpDown } from "./components/ArrowUpDown/ArrowUpDown";
import SearchIcon from "../dashboard/Icons/search.svg";

const theme = {
  fontFamily: "Cerebri Sans",
  checkBox: {
    border: {
      width: "1px",
      color: "#F4F5F5",
      radius: "4px",
    },

    check: {
      extend: ({ checked }) => `
        ${checked && `background-color: #619130;`}
        ${checked && `stroke: white`}`,
    },

    color: "white",
    extend: {
      marginRight: "8px",
    },

    icon: {
      size: "16px",
    },
    size: "16px",
  },
};

function Dashboard() {
  //i18Next
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  // for session id
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchParams.get("uid") || isNaN(Number(searchParams.get("uid")))) {
      navigate("/error404");
    } else {
      SetSessionItem("uid", searchParams.get("uid"));
    }
  }, [searchParams]);

  //API Consumption for Search Property
  const [searchInput, setSearchInput] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };
  if (searchInput.length >= 3) {
  }

  const [selectLanguage, setSelectLanguage] = useState("English");
  const handleLanguageChange = (e) => {
    if (e.target.value == "English") {
      changeLanguage("en");
      setSelectLanguage("English");
    } else {
      changeLanguage("de");
      setSelectLanguage("Danish");
    }
  };

  // Api consumption for All properties
  const [myProperty, setMyProperty] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [errorProperty, setErrorProperty] = useState([
    { error: false, status: "", message: "" },
  ]);

  const GetAllPropertiesByRegionByUnitType = (RegionID, Type, searchText) => {
    try {
      GetAllPropertiesByRegionUnitType(RegionID, Type, searchInput)
        .then((data) => {
          if (data != null && data != undefined) {
            const categoryList = data.data.map(
              ({ E_ID, PropertyName, Address, UnitType, IsStarred }) => {
                return {
                  id: E_ID.toString(),
                  name: PropertyName,
                  Address: Address,
                  UnitType: UnitType,
                  isStar: IsStarred,
                };
              }
            );
            setMyProperty(categoryList);
            setSelectedProperty(categoryList);
          } else {
            setMyProperty([null]);
          }
        })
        .catch((error) => {
          setErrorProperty({ error: error.message, status: error.status });
          setMyProperty([null]);
          setSelectedProperty([null]);
        });
    } catch (e) {
      setMyProperty([null]);
      setSelectedProperty([null]);
      setErrorProperty({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  //Checkbox logic for All properties
  const [allCheckedProperty, setAllCheckedProperty] = useState(false);
  const [optionCheckedProperty, setOptionCheckedProperty] = useState([]);

  const OnClickedProperty = (e, isCheckAll) => {
    const { id, checked } = e.currentTarget;

    if (isCheckAll) {
      setSelectedProperty(myProperty);
      if (checked) {
        setOptionCheckedProperty(myProperty.map((list) => list.id));
        setAllCheckedProperty(true);
      } else {
        setOptionCheckedProperty([0]);
        setAllCheckedProperty(false);
      }
    } else {
      if (checked) {
        const checkValue = [...optionCheckedProperty, id];
        setOptionCheckedProperty(checkValue);

        if (checkValue.length == myProperty.length) {
          setAllCheckedProperty(true);
          setSelectedProperty(myProperty);
        } else {
          let selectProperty = myProperty.filter((item) =>
            checkValue.includes(item.id)
          );
          setSelectedProperty(selectProperty);
        }
      } else {
        const checkValue = optionCheckedProperty.filter((item) => item !== id);
        if (checkValue.length != 0) {
          setOptionCheckedProperty(checkValue);
          setAllCheckedProperty(false);
          let selectProperty = myProperty.filter((item) =>
            checkValue.includes(item.id)
          );
          setSelectedProperty(selectProperty);
        } else {
          setOptionCheckedProperty([]);
          setAllCheckedProperty(false);
          setSelectedProperty(myProperty);
        }
      }
    }
  };

  const DeselectAll = () => {
    setOptionCheckedProperty([]);
    setAllCheckedProperty(false);
  };
  // API Consumption for All Regions
  const [myDataRegions, setMyDataRegions] = useState([]);
  const [errorRegions, setErrorRegions] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetAllByRegions();
  }, []);

  const GetAllByRegions = () => {
    try {
      GetAllRegions()
        .then((data) => {
          if (data != null && data != undefined) {
            const categoryList = data.data.map(({ RegionId, RegionName }) => {
              return {
                id: RegionId.toString(),
                name: RegionName,
              };
            });
            setMyDataRegions(categoryList);
          } else {
            setMyDataRegions([null]);
          }
        })
        .catch((error) => {
          setErrorRegions({ error: error.message, status: error.status });
          setMyDataRegions([null]);
        });
    } catch (e) {
      setMyDataRegions([null]);
      setErrorRegions({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const ActivitiesRegions = myDataRegions;

  //Checkbox logic for All Regions
  const [allCheckedRegions, setAllCheckedRegions] = useState(false);
  const [optionCheckedRegions, setOptionCheckedRegions] = useState([]);

  const OnClickedRegions = (e, isCheckAll) => {
    const { id, checked } = e.currentTarget;

    if (isCheckAll) {
      if (checked) {
        setOptionCheckedRegions(ActivitiesRegions.map((list) => list.id));
        setAllCheckedRegions(true);
      } else {
        setOptionCheckedRegions([]);
        setAllCheckedRegions(false);
      }
      GetAllPropertiesByRegionByUnitType(0);
    } else {
      if (checked) {
        const checkValue = [...optionCheckedRegions, id];
        setOptionCheckedRegions(checkValue);

        if (checkValue.length == ActivitiesRegions.length) {
          setAllCheckedRegions(true);
          GetAllPropertiesByRegionByUnitType(0);
        } else {
        }
      } else {
        const checkValue = optionCheckedRegions.filter((item) => item !== id);
        if (checkValue.length != 0) {
          setOptionCheckedRegions(checkValue);

          setAllCheckedRegions(false);
        } else {
          setOptionCheckedRegions([]);
          setAllCheckedRegions(false);
          GetAllPropertiesByRegionByUnitType(0);
        }
      }
    }
  };

  //Checkbox logic for Unit Type
  const Activities = [
    { name: `${t("sale")}`, id: "1" },
    { name: `${t("leasing")}`, id: "2" },
  ];
  const [allChecked, setAllChecked] = useState(false);
  const [optionChecked, setOptionChecked] = useState([]);

  const OnClickUnit = (e, isCheckAll) => {
    const { id, checked } = e.currentTarget;
    if (isCheckAll) {
      if (checked) {
        setOptionChecked(Activities.map((list) => list.id));
        setAllChecked(true);
      } else {
        setOptionChecked([]);
        setAllChecked(false);
      }
      GetAllPropertiesByRegionByUnitType(0);
    } else {
      if (checked) {
        const checkValue = [...optionChecked, id];
        setOptionChecked(checkValue);

        if (checkValue.length == Activities.length) {
          setAllChecked(true);
          GetAllPropertiesByRegionByUnitType(0);
        } else {
        }
      } else {
        const checkValue = optionChecked.filter((item) => item !== id);
        if (checkValue.length != 0) {
          setOptionChecked(checkValue);

          setAllChecked(false);
        } else {
          setOptionChecked([]);
          setAllChecked(false);
          GetAllPropertiesByRegionByUnitType(0);
        }
      }
    }
  };

  let FilterRegions =
    optionCheckedRegions === null ||
    optionCheckedRegions === undefined ||
    optionCheckedRegions.length === 0
      ? 0
      : optionCheckedRegions;
  let FilterType =
    optionChecked === null ||
    optionChecked === undefined ||
    optionChecked.length === 0
      ? 0
      : optionChecked;

  useEffect(() => {
    GetAllPropertiesByRegionByUnitType(FilterRegions, FilterType, searchInput);
  }, [FilterRegions, FilterType, searchInput]);

  const StarredProperties = ({ name, address, isStarred, eid }) => {
    return (
      <>
        <div className="starred-container">
          <div className="starred-item1">
            <Star
              // eid={eid}
              isStar={isStarred}
            />
          </div>
          <div>
            <span className="light fs-13 fw-300">{name}</span> <br />
            <span className="starred-item3 light fs-12 fw-300">{address}</span>
          </div>
        </div>
      </>
    );
  };
  //API consumption for get Star properties
  const [starProperty, setStarProperty] = useState([]);
  const [errorstarProperty, setErrorstarProperty] = useState([
    { error: false, status: "", message: "" },
  ]);

  const AllStarProperty = () => {
    try {
      GetAllStarProperties()
        .then((data) => {
          if (data != null && data != undefined) {
            setStarProperty(data.data);
          } else {
            setStarProperty([null]);
          }
        })
        .catch((error) => {
          setErrorstarProperty({ error: error.message, status: error.status });
          setStarProperty([null]);
        });
    } catch (e) {
      setStarProperty([null]);
      setErrorstarProperty({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };
  useEffect(() => {
    AllStarProperty();
  }, []);

  // DateRange;

  const [date, setDate] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const ResetFilter = () => {
    setDate([
      {
        startDate: startOfMonth(new Date()),
        endDate: endOfDay(new Date()),
        key: "selection",
      },
    ]);
  };

  let refStartDate = useRef(`${format(date[0].startDate, "yyyy-MM-dd")}`);
  let refEndDate = useRef(`${format(date[0].endDate, "yyyy-MM-dd")}`);

  const [filterDate, setFilterDate] = useState(
    `${refStartDate.current}/${refEndDate.current}`
  );

  const DateFilter = () => {
    refStartDate.current = `${format(date[0].startDate, "yyyy-MM-dd")}`;
    refEndDate.current = `${format(date[0].endDate, "yyyy-MM-dd")}`;

    setFilterDate(refStartDate.current + "/" + refEndDate.current);
    setanchorCalendar(null);
  };

  const definedTimes = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),

    startOfLastThreeMonths: startOfMonth(addMonths(new Date(), -3)),
    endOfLastThreeMonths: endOfMonth(addMonths(new Date(), -1)),
    startOfLastYear: startOfYear(addYears(new Date(), -1)),
    endOfLastYear: endOfYear(addYears(new Date(), -1)),
    // startOfLastSevenDays: startOfDay(addDays(new Date(), -1)),
    // endOfLastSevenDays: startOfDay(addDays(new Date(), -7)),
    startOfLastSevenDays: startOfDay(addDays(new Date(), -7)),
    endOfLastSevenDays: startOfDay(addDays(new Date(), -1)),
    // startOfLastThirtyDays: startOfDay(addDays(new Date(), -1)),
    // endOfLastThirtyDays: startOfDay(addDays(new Date(), -30)),
    startOfLastThirtyDays: startOfDay(addDays(new Date(), -30)),
    endOfLastThirtyDays: startOfDay(addDays(new Date(), -1)),
    startOfAllTime: new Date(2001, 0, 1),
  };
  const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  };

  function createStaticRanges(ranges) {
    return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
  }
  const staticRanges = createStaticRanges([
    {
      label: `${t("today")}`,
      range: () => ({
        startDate: definedTimes.startOfToday,
        endDate: definedTimes.endOfToday,
      }),
    },
    {
      label: `${t("last-7-days")}`,
      range: () => ({
        startDate: definedTimes.startOfLastSevenDays,
        endDate: definedTimes.endOfLastSevenDays,
      }),
    },
    {
      label: `${t("last-30-days")}`,
      range: () => ({
        startDate: definedTimes.startOfLastThirtyDays,
        endDate: definedTimes.endOfLastThirtyDays,
      }),
    },
    {
      label: `${t("last-month")}`,
      range: () => ({
        startDate: definedTimes.startOfLastMonth,
        endDate: definedTimes.endOfLastMonth,
      }),
    },
    {
      label: `${t("this-month")}`,
      range: () => ({
        startDate: definedTimes.startOfMonth,
        endDate: definedTimes.endOfToday,
      }),
    },
    {
      label: `${t("last-3-months")}`,
      range: () => ({
        startDate: definedTimes.startOfLastThreeMonths,
        endDate: definedTimes.endOfLastThreeMonths,
      }),
    },
    {
      label: `${t("last-year")}`,
      range: () => ({
        startDate: definedTimes.startOfLastYear,
        endDate: definedTimes.endOfLastYear,
      }),
    },
    {
      label: `${t("all-time")}`,
      range: () => ({
        startDate: definedTimes.startOfAllTime,
        endDate: definedTimes.endOfToday,
      }),
    },
  ]);

  const [anchorEl, setAnchorEl] = useState(false);
  const [customizationAnchorEl, setCustomizationAnchorEl] = useState(false);

  let refSid = useRef(optionCheckedProperty);
  const [sid, setSid] = useState(0);

  const ApplyProperties = (e) => {
    refSid.current = optionCheckedProperty;
    setSid(optionCheckedProperty);
    setAnchorEl(false);
  };

  const ResetProperties = () => {
    setAllCheckedRegions(false);
    setOptionCheckedRegions([]);
    
    setOptionCheckedProperty([]);
    setAllCheckedProperty(false);
    GetAllPropertiesByRegionByUnitType(0, 0, "");
    
    
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCustomizationClick = (event) => {
    setCustomizationAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCustomizationClose = () => {
    setCustomizationAnchorEl(null);
  };

  const [anchorCalendar, setanchorCalendar] = useState(false);
  const handleClickCalendar = (event) => {
    setanchorCalendar(event.currentTarget);
  };
  const handleCloseCalendar = () => {
    setanchorCalendar(null);
  };

  const Country = [
    {
      value: "English",
      label: (
        <>
          <div className="color-section">
            <div>
              <div>
                <img
                  style={{
                    minWidth: "1.4em",
                    height: "18px",
                    minHeight: "1em",
                    display: "inline-block",
                    borderRadius: "4px",
                    fontSize: "13px",
                  }}
                  height={12}
                  src={EnglishFlag}
                  alt="flag-icon"
                />
              </div>
            </div>
            {/* <span
              style={{
                backgroundColor: "#b23977",
                // backgroundImage : `url()`
                minWidth: "1.4em",
                height: "18px",
                minHeight: "1em",
                display: "inline-block",
                borderRadius: "4px",
                fontSize: "13px",
              }}
            ></span> */}
            <div className="light fs-14">{t("english")}</div>
          </div>
        </>
      ),
    },
    {
      value: "Danish",
      label: (
        <div className="color-section">
          {/* <span
            style={{
              backgroundColor: "#000000",
              minWidth: "1.4em",
              height: "18px",
              minHeight: "1em",
              display: "inline-block",
              borderRadius: "4px",
              fontSize: "13px",
            }}
          ></span> */}
          <div>
            <img
              style={{
                minWidth: "1.4em",
                height: "18px",
                minHeight: "1em",
                display: "inline-block",
                borderRadius: "4px",
                fontSize: "13px",
              }}
              height={12}
              src={DenishFlag}
              alt="flag-icon"
            />
          </div>

          <div className="light fs-14">{t("danish")}</div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div style={{ padding: "30px", backgroundColor: "#F9FAFA" }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span className="medium fs-14">{t("customization")}</span>
          <Button
            id="customization-button"
            aria-controls={
              customizationAnchorEl ? "customization-menu" : undefined
            }
            aria-haspopup="true"
            aria-expanded={customizationAnchorEl ? "true" : undefined}
            onClick={handleCustomizationClick}
            sx={{
              "&:hover": { backgroundColor: "transparent" },
              padding: "0px",
            }}
          >
            <Box className="gear-icon-section">
              <img
                style={{ position: "relative", top: "8px", cursor: "pointer" }}
                height={19}
                src={gearIcon}
                alt="Gear Icon"
              />
            </Box>
          </Button>
          <Menu
            id="customization-menu"
            customizationAnchorEl={customizationAnchorEl}
            open={customizationAnchorEl}
            onClose={handleCustomizationClose}
            MenuListProps={{
              "aria-labelledby": "customization-button",
            }}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.02)",
            }}
          >
            <MenuItem
              sx={{
                "&:hover": { backgroundColor: "transparent" },
                borderRadius: "8px",
                padding: "0px",
                overflow: "hidden",
                border: "1px solid #F4F5F5",
              }}
            >
              <div className="customization-section">
                <div className="customization-subsection">
                  <div className="medium fs-14">{t("language")}</div>
                  <div style={{ width: "100%" }}>
                    <FormControl variant="outlined" sx={{ width: "100%" }}>
                      <InputLabel id="test-select-label">
                        {t("language")}
                      </InputLabel>
                      <Select
                        sx={{
                          width: "100%",
                          height: 48,
                          backgroundColor: "#ffff",
                          border: "1px solid #ffff",
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "white",
                            },
                          },
                        }}
                        variant="outlined"
                        onChange={handleLanguageChange}
                        labelId="test-select-label"
                        label={t("language")}
                        value={selectLanguage}
                      >
                        {Country?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </MenuItem>
          </Menu>
        </Box>

        <div
          className="bold  primary-color"
          style={{ fontSize: "32px", marginBottom: "16px" }}
        >
          {t("dashboard-header")}
        </div>
        <div style={{ lineHeight: "22px" }} className="light fs-14 fw-300">
          <Trans i18nKey={"dashboard-header-description"}></Trans>
        </div>
        <hr
          style={{
            margin: "27.5px 0",
            width: "280px",
            border: " 1px solid #F4F5F5",
          }}
        />
        <div style={{ margin: "25px 0", gap: "16px" }} className="display-flex">
          <div>
            <Button
              id="basic-button"
              aria-controls={anchorEl ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={anchorEl ? "true" : undefined}
              onClick={handleClick}
              sx={{
                "&:hover": { backgroundColor: "transparent" },
                padding: "0px",
              }}
            >
              <div className="all-properties-dropdown-dashboard">
                <span className="light fs-14 fw-300 mt-3px">
                  {t("selected-properties")} ({optionCheckedProperty.length})
                </span>

                <ArrowUpDown component={anchorEl} />
              </div>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={anchorEl}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                marginTop: "8px",
                backgroundColor: "rgba(0, 0, 0, 0.02)",
                "& .MuiPaper-root": {
                  borderRadius: "8px",
                },
              }}
            >
              <MenuItem
                sx={{
                  "&:hover": { backgroundColor: "transparent" },
                  borderRadius: "8px",
                  padding: "0px",
                  overflow: "hidden",
                  border: "1px solid #F4F5F5",
                }}
              >
                <div className="all-properties">
                  <div className="all-properties-items">
                    <div className="search-property">
                      <TextField
                        className="light"
                        id="outlined-basic"
                        onChange={handleChange}
                        value={searchInput}
                        placeholder={t("search-property")}
                        label={t("search-property")}
                        variant="outlined"
                        sx={{
                          width: "175px",
                          height: "44px",
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "8px",
                            border: "1px solid #F4F5F5",
                          },
                          endAdornment: (
                            <IconButton>
                              <img
                                height={12}
                                className="question-mark"
                                src={SearchIcon}
                                alt="Question mark"
                              />
                            </IconButton>
                          ),
                        }}
                      />
                    </div>
                    <UnitType
                      allChecked={allChecked}
                      optionChecked={optionChecked}
                      OnClickUnit={OnClickUnit}
                      Activities={Activities}
                    />
                    <AllRegions
                      allCheckedRegions={allCheckedRegions}
                      optionCheckedRegions={optionCheckedRegions}
                      OnClickedRegions={OnClickedRegions}
                      ActivitiesRegions={ActivitiesRegions}
                    />
                  </div>

                  <hr className="horizontal" />

                  <div className="child-container">
                    <div style={{ marginTop: "20px" }}>
                      <div>
                        <span className="starred-properties bold fs-14 ">
                          {t("starred-properties")}
                        </span>
                      </div>
                      <div
                        className="scrollable-container-dashboard"
                        style={{ paddingRight: "20px" }}
                      >
                        {starProperty?.map((item, i) => (
                          <StarredProperties
                            key={i}
                            eid={item.E_ID}
                            isStarred={item.IsStarred}
                            name={item.PropertyName}
                            address={item.Address}
                          />
                        ))}
                      </div>
                    </div>

                    <div className="vertical">
                      <Grommet theme={theme}>
                        <div
                          style={{ marginTop: "14px" }}
                          className="light fs-14"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="parent">
                              <CheckBox
                                id="0"
                                checked={allCheckedProperty}
                                onChange={(e) => OnClickedProperty(e, true)}
                                label={t("all-properties")}
                              />
                            </div>
                            <div
                              onClick={DeselectAll}
                              className="light-italic fs-12"
                              style={{
                                margin: "8px 0 0 134px",
                                color: "#888",
                              }}
                            >
                              {t("deselect-all")}
                            </div>
                          </div>
                          <div className="scrollable-container-dashboard">
                            {myProperty?.map(
                              ({ id, name, Address, UnitType, isStar }) => (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        lineHeight: "22px",
                                        marginBottom: "10px",
                                        marginLeft: "30px",
                                      }}
                                    >
                                      <div>
                                        <CheckBox
                                          id={id}
                                          key={id}
                                          checked={optionCheckedProperty?.includes(
                                            id
                                          )}
                                          onChange={(e) =>
                                            OnClickedProperty(e, false)
                                          }
                                        />
                                      </div>
                                      <Star
                                        AllStarProperty={AllStarProperty}
                                        eid={id}
                                        isStar={isStar}
                                      />
                                      &nbsp; &nbsp;&nbsp;
                                      <div>
                                        <div className="starred-item2 light fw-300 fs-14">
                                          {name}
                                        </div>
                                        <div className="starred-item3 light fs-12 ">
                                          {Address}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="status-box bold fs-12 fw-700">
                                      <div className="status-box-text">
                                        {UnitType}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            )}
                          </div>
                        </div>
                      </Grommet>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          padding: "19px 5px",
                          height: "54px",
                          gap: "15px",
                          marginLeft: "158px",
                        }}
                      >
                        <div
                          onClick={ResetProperties}
                          className="medium fs-13 no-btn-section uppercase"
                        >
                          {t("reset")}
                        </div>
                        <div
                          onClick={() => ApplyProperties()}
                          className="medium fw-600 fs-13 yes-btn-section uppercase"
                        >
                          {t("apply")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MenuItem>
            </Menu>
          </div>

          <Button
            id="basic-button-calendar"
            aria-controls={anchorCalendar ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={anchorCalendar ? "true" : undefined}
            onClick={handleClickCalendar}
            sx={{
              "&:hover": { backgroundColor: "transparent" },
              padding: "0px",
            }}
          >
            <div className="date-picker-filter">
              <div className="date-filter-styling">
                <div className="light fs-14 ">
                  <div className="light fs-14 ">
                    {`${format(date[0].startDate, "dd. MMM. yyyy")} - ${format(
                      date[0].endDate,
                      "dd. MMM. yyyy"
                    )}`}
                  </div>
                </div>
                <div>
                  <img
                    className="img-cal"
                    height={15}
                    src={calendar}
                    alt="calendar"
                  />
                </div>
              </div>
            </div>
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorCalendar}
            open={anchorCalendar}
            onClose={handleCloseCalendar}
            MenuListProps={{
              "aria-labelledby": "basic-button-calendar",
            }}
            sx={{ marginTop: "8px", backgroundColor: "rgba(0, 0, 0, 0.02)" }}
          >
            <MenuItem
              sx={{
                "&:hover": { backgroundColor: "transparent" },
                borderRadius: "8px",
                padding: "0px",
                overflow: "hidden",
                border: "1px solid #F4F5F5",
              }}
            >
              <div className="date-picker-modal-popup">
                <div className="date-picker-modal-popup-position">
                  <div className="dateRangeHeading medium fs-14 justify-content-center">
                    <span>{t("shortcuts")}</span>
                  </div>
                  <DateRangePicker
                    onChange={(item) => setDate([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    weekStartsOn={1}
                    ranges={date}
                    direction="horizontal"
                    preventSnapRefocus={true}
                    calendarFocus="backwards"
                    staticRanges={staticRanges}
                    rangeColors={["#619130"]}
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      padding: "19px 25px",
                      height: "54px",
                      gap: "15px",
                      backgroundColor: "#f9fafa",
                    }}
                  >
                    <div
                      onClick={() => ResetFilter()}
                      className="regular  fs-14 no-btn-section uppercase"
                    >
                      {t("reset")}
                    </div>
                    <div
                      onClick={() => DateFilter()}
                      className="regular fs-14 yes-btn-section uppercase"
                    >
                      {t("apply")}
                    </div>
                  </div>
                </div>
              </div>
            </MenuItem>
          </Menu>
        </div>

        <div
          style={{
            margin: "26px 0",
            backgroundColor: "#F9FAFA",
          }}
        >
          <LazyLoad>
            <TabMenu
              datefilter={filterDate}
              sid={
                sid === null || sid === undefined || sid.length === 0 ? 0 : sid
              }
              ActivitiesProperty={selectedProperty}
            />
          </LazyLoad>
        </div>
      </div>
    </>
  );
}

export default Dashboard;

const UnitType = ({ allChecked, optionChecked, OnClickUnit, Activities }) => {
  //i18Next
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div className="allactivities">
        <div>
          <Button
            id="basic-button"
            aria-controls={anchorEl ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? "true" : undefined}
            onClick={handleClick}
            sx={{
              "&:hover": { backgroundColor: "transparent" },
              padding: "0px",
              color: "black",
            }}
          >
            <div className="all-regions-dropdown-dropdown">
              <span className="light fs-14">{t("all-unit-types")}</span>

              <ArrowUpDown component={anchorEl} />
            </div>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={anchorEl}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.02)",
              "& .MuiPaper-root": {
                borderRadius: "8px",
                width: "fit-content",
                marginTop: "8px",
              },
            }}
          >
            <MenuItem
              sx={{
                "&:hover": { backgroundColor: "transparent" },
                borderRadius: "8px",
                padding: "0px",
                overflow: "hidden",
                border: "1px solid #F4F5F5",
              }}
            >
              <div
                style={{ zIndex: "3" }}
                className="all-unit-dropdown-container"
              >
                <ActivityDropdown
                  parentId="0"
                  allChecked={allChecked}
                  optionChecked={optionChecked}
                  OnClicked={OnClickUnit}
                  Activities={Activities}
                  Label={t("all-types-of-unit")}
                />
              </div>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </>
  );
};

const AllRegions = ({
  allCheckedRegions,
  optionCheckedRegions,
  OnClickedRegions,
  ActivitiesRegions,
}) => {
  //i18N
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <div className="allactivities">
        <div>
          <Button
            id="basic-button"
            aria-controls={anchorEl ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? "true" : undefined}
            onClick={handleClick}
            sx={{
              "&:hover": { backgroundColor: "transparent" },
              padding: "0px",
              color: "black",
            }}
          >
            <div className="all-regions-dropdown-dropdown">
              <span className="light fs-14">
                {t("all-regions")} ({optionCheckedRegions.length})
              </span>
              <ArrowUpDown component={anchorEl} />
            </div>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={anchorEl}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.02)",
              "& .MuiPaper-root": {
                borderRadius: "8px",
                width: "fit-content",
                // left: "555px !important",
                transformOrigin: "right top !important",
                marginTop: "8px",
              },
            }}
          >
            <MenuItem
              sx={{
                "&:hover": { backgroundColor: "transparent" },
                borderRadius: "8px",
                padding: "0px",
                overflow: "hidden",
                border: "1px solid #F4F5F5",
              }}
            >
              <div className="all-regions-dropdown-container">
                <ActivityDropdown
                  parentId="0"
                  allChecked={allCheckedRegions}
                  optionChecked={optionCheckedRegions}
                  OnClicked={OnClickedRegions}
                  Activities={ActivitiesRegions}
                  Label={t("all-regions")}
                />
              </div>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </>
  );
};
