import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import "./estateTool/dashboard/i18Next/i18n";
import createTheme from "./theme";

import useTheme from "./hooks/useTheme";
// import createEmotionCache from "./utils/createEmotionCache";
import Page404 from "./pages/auth/Page404";
import Dashboard from "./estateTool/dashboard/Dashboard";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";

// const clientSideEmotionCache = createEmotionCache();

function App() {
  // const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    // <CacheProvider value={emotionCache}>
    //   <HelmetProvider>
    //     <Helmet titleTemplate="%s | Mira" defaultTitle="Estate-Tool" />
    //     <Provider store={store}>
    //       <LocalizationProvider dateAdapter={AdapterDateFns}>
    //         <MuiThemeProvider theme={createTheme(theme)}>
    //           <AuthProvider>{content}</AuthProvider>
    //         </MuiThemeProvider>
    //       </LocalizationProvider>
    //     </Provider>
    //   </HelmetProvider>
    // </CacheProvider>
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MuiThemeProvider theme={createTheme(theme)}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Dashboard />}></Route>
              <Route exact path="error404" element={<Page404 />}></Route>
            </Routes>
          </BrowserRouter>
        </MuiThemeProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
