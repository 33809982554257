import React from "react";
import sortUp from "../../Icons/sort-up.svg";
import sortDown from "../../Icons/sort-down.svg";

export const Sorting = ({ Ascending, Descending }) => {
  return (
    <>
      <div style={{ display: "inline-block", margin: "7px " }}>
        <div className="cursor" style={{ lineHeight: "11px" }}>
          <img onClick={Ascending} height={6} src={sortUp} />
        </div>
        <div
          className="cursor"
          style={{ lineHeight: "0px", marginLeft: "0.5px" }}
        >
          <img onClick={Descending} height={6} src={sortDown} />
        </div>
      </div>
    </>
  );
};
