import React, { useState } from "react";
import Tooltip from "../components/Tooltip/Tooltip";
import { t } from "i18next";

import DownloadWidget from "../components/DownloadWidget/DownloadWidget";

import { StatusChangeChart } from "./StatusChangeChart";
import { CustomTableCell, CustomTableRow, theme } from "../../../utils/Helper";
import {
  Paper,
  Switch,
  Table,
  TableBody,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@mui/material";
import { Chart } from "react-chartjs-2";
import { useRef } from "react";

const Colors = [
  "#888888",
  "#77C7A1",
  "#F6E651",
  "#BF87D2",
  "#A2C8D0",
  "#F1664B",
];

// styled switch

const Status = ({ text, color }) => {
  return (
    <>
      <span
        className="idleness-statuslabel"
        style={{ background: color }}
      ></span>
      {text}
    </>
  );
};

// Dummy Data

const statusDummyData = [
  {
    status: "not-Listed",
    last_period: "14",
    current_period: "10",
  },
  {
    status: "available",
    last_period: "14",
    current_period: "10",
  },
  {
    status: "reserved",
    last_period: "14",
    current_period: "10",
  },
  {
    status: "Tenancy agreement in process",
    last_period: "14",
    current_period: "10",
  },
  {
    status: "leased",
    last_period: "14",
    current_period: "10",
  },
  {
    status: "terminated",
    last_period: "14",
    current_period: "10",
  },
];

const StatusChange = () => {
  const myref = useRef();
  const [refObj] = useState(myref);
  //Options for CSV data

  return (
    <div
      ref={myref}
      style={{
        borderRadius: "8px",
        height: "content-fit",
        backgroundColor: "#FFF",
        position: "relative",
        margin: "25px 0",
      }}
    >
      <div className="display-flex justify-content-between">
        <div className="margin-heading-card bold fs-14 display-flex availabilityrooms-card-title">
          <div>{t("Status Change")}</div>

          <Tooltip text={t("Status Change")} paragraph="" />
        </div>
        <div>
          <DownloadWidget
            refObj={refObj}
            fileName={"status-change"}
            type={true}
          />
        </div>
      </div>
      <div>
        <Paper className="card-container" style={{ marginBottom: "97px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell
                  width="40%"
                  align="left"
                  sx={{ paddingLeft: "70px" }}
                >
                  {t("status")}
                </CustomTableCell>
                <CustomTableCell width="30%" align="center">
                  {t("Last period")}
                  <div className="light fw-300 fs-14">
                    16. nov. 2022 - 14. dec. 2022
                  </div>
                </CustomTableCell>
                <CustomTableCell width="30%" align="center">
                  {t("Current period")}
                  <div className="light fw-300 fs-14">
                    16. nov. 2022 - 14. dec. 2022
                  </div>
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statusDummyData.slice(0, 9).map((row, i) => (
                <CustomTableRow key={i}>
                  <CustomTableCell
                    width="40%"
                    align="left"
                    sx={{ paddingLeft: "70px" }}
                  >
                    <Status color={Colors[i]} />
                    {t(row.status)}
                  </CustomTableCell>
                  <CustomTableCell width="30%" align="center">
                    {row.last_period}
                  </CustomTableCell>
                  <CustomTableCell width="30%" align="center">
                    {row.current_period}
                  </CustomTableCell>
                </CustomTableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        {/* <div
          className="display-flex justify-content-between"
          style={{ padding: "0 70px 15px 70px" }}
        >
          <div
            className="display-flex align-items-center"
            style={{ gap: "5px" }}
          >
            <ThemeProvider theme={theme}>
              <button
                onClick={handleToggle}
                style={{
                  border: "none",
                  backgroundColor: "#fff",
                  marginRight: "16px",
                  marginTop: "10px",
                }}
              >
                <Switch color="success" />
              </button>
            </ThemeProvider>
            <div className="light fw-300 fs-14">
              {t("Compare with data from last period")}
            </div>
          </div>
          <div
            className="display-flex align-items-center"
            style={{ gap: "20px" }}
          >
            <div className="light fw-300 fs-14" style={{ color: "#888888" }}>
              {t("Use arrows to go back in period")}
            </div>
            <div style={{ cursor: "pointer", display: "flex", gap: "10px" }}>
              <button className="pagination-style" >
                <KeyboardDoubleArrowLeftIcon />
              </button>
              <button className="pagination-style">
                <KeyboardArrowLeftIcon />
              </button>
              <button className="pagination-style">
                <KeyboardArrowRightIcon />
              </button>
              <button className="pagination-style">
                <KeyboardDoubleArrowRightIcon />
              </button>
            </div>
          </div>
        </div> */}
        <div style={{ padding: "0 35px 0px 35px" }}>
          <StatusChangeChart />
        </div>
        <div className="unit-process-footer">
          <div className="footer-section">
            <div
              className="footer-color-section"
              style={{ backgroundColor: "#888888" }}
            ></div>
            <div className="line-height-18 medium fw-500 fs-12">
              {t("not-Listed")}
            </div>
          </div>
          <div className="footer-section">
            <div
              className="footer-color-section"
              style={{ backgroundColor: "#77C7A1" }}
            ></div>
            <div className="line-height-18  medium fw-500 fs-12">
              {t("available")}
            </div>
          </div>
          <div className="footer-section">
            <div
              className="footer-color-section"
              style={{ backgroundColor: "#F6E651" }}
            ></div>
            <div className="line-height-18 medium fw-500 fs-12">
              {t("reserved")}
            </div>
          </div>
          <div className="footer-section">
            <div
              className="footer-color-section"
              style={{ backgroundColor: "#BF87D2" }}
            ></div>
            <div className="medium line-height-18 fw-500 fs-12">
              {t("Tenancy agreement in process")}
            </div>
          </div>
          <div className="footer-section">
            <div
              className="footer-color-section"
              style={{ backgroundColor: "#A2C8D0" }}
            ></div>
            <div className="medium line-height-18 fw-500 fs-12">
              {t("leased")}
            </div>
          </div>
          <div className="footer-section">
            <div
              className="footer-color-section"
              style={{ backgroundColor: "#F1664B" }}
            ></div>
            <div className="medium line-height-18 fw-500 fs-12">
              {t("terminated")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusChange;
