import React, { useEffect, useRef, useState } from "react";

import styled from "@emotion/styled";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Box, Grid, CardContent, Card as MuiCard } from "@mui/material";
import { spacing } from "@mui/system";
import "./customer.css";
import Tooltip from "../components/Tooltip/Tooltip";

import BreakDownTable from "./BreakDownTable";
import { GetCustomersBreakdownRegion } from "../../../API/Customers/CustomersBreakdownRegion";
import { useTranslation } from "react-i18next";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import NoDataScreen from "../components/NoDataScreen";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 217px;
`;

function BreakdownChart({ sid, datefilter }) {
  //i18n
  const { t, i18n } = useTranslation();

  //API Consumption
  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);
  useEffect(() => {
    GetCustomersBreakdownByRegion(sid, datefilter);
  }, [sid, datefilter]);

  const GetCustomersBreakdownByRegion = (sid, datefilter) => {
    try {
      GetCustomersBreakdownRegion(sid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({ error: true, status: "", message: "Failed to retrieve data" });
    }
  };

  // For Total registrations
  let Total = 0;
  myData.forEach(function (elem) {
    Total += Number(elem.Quantity);
  });
  const data = {
    labels: myData?.map((x) => x.RegionName),

    labelsPer: myData?.map((x) => x.Percentage),
    datasets: [
      {
        data: myData?.map((x) => x.Percentage),
        backgroundColor: [
          "#A2C8D0",
          "#77C7A1",
          "#F6E651",
          "#A2C8D0",
          "#F1664B",
        ],
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: function (value, index, values) {
          if (value > 0) {
            value = value.toString();
            value = value.split(/(?=(?:...)*$)/);
            value = value.join(",");
            return value + "%";
          } else {
            value = "";
            return value;
          }
        },
        display: true,
        color: "white",
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItem, data) {
            let title = tooltipItem[0].raw;
            // console.log(tooltipItem ,"title");
            // console.log(tooltipItem[0].raw
            //   ,"title");
            return title + "%";
          },
          label: function (tooltipItem) {
            let label = tooltipItem.label;
            // console.log(label ,"lable");
            return label;
          },
        },
        backgroundColor: "#FFFFFF",
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
        padding: 10,
        cornerRadius: 8,
        borderColor: "#F4F5F5",
        borderWidth: 1,
      },
      legend: {
        display: false,
      },
      plugins: [ChartDataLabels],
    },
  };

  const myref = useRef();
  const [refObj] = useState(myref);

  return (
    <>
      <div
        ref={myref}
        style={{
          backgroundColor: "#FFF",
          borderRadius: "10px",
          width: "100%",
          // padding: "0 20px 25px 0",
          margin: "0px 0 30px 0",
          minHeight: "500px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <div className="margin-heading-card" style={{ display: "flex" }}>
            <div className="bold fw-700 fs-14">
              {t("breakdown-of-customers-by-region")}
            </div>

            <Tooltip
              text={t("breakdown-of-customers-by-region")}
              paragraph=""
            />
          </div>
          <div>
            <DownloadWidget
              refObj={refObj}
              fileName={"customers-breakdown-by-region"}
              type={true}
            />
          </div>
        </div>
        {Total != 0 ? (
          <Grid container spacing={6}>
            <Grid item xs={12} md={4.2}>
              <CardContent>
                <Spacer mb={6} />

                <ChartWrapper>
                  <Pie
                    data={data}
                    plugins={[ChartDataLabels]}
                    options={options}
                  />
                </ChartWrapper>
                <Box className="total-registrations">
                  <div className="light fw-300 fs-12">
                    {t("total-registrations")}
                  </div>

                  <div className="bold fw-700 fs-16">{Total}</div>
                </Box>
              </CardContent>
            </Grid>
            <Grid item xs={12} md={7.8}>
              <div style={{ paddingRight: "44px" }}>
                <BreakDownTable sid={sid} datefilter={datefilter} />
              </div>
            </Grid>
          </Grid>
        ) : (
          <NoDataScreen height={"500px"} />
        )}
      </div>
    </>
  );
}

export default BreakdownChart;
