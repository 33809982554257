import React, { useEffect, useState } from "react";
import Tooltip from "../components/Tooltip/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import styled from "@emotion/styled";
import "./overviews.css";
import TableRow from "@mui/material/TableRow";
import { Badge, Dialog, IconButton, Paper, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { GetUnitProgress } from "../../../API/OverView/UnitProgress";
import { GetUnitProgressDetailBid } from "../../../API/OverView/UnitProgressDetailBid";
import comments from "../Icons/comment.svg";
import addcomment from "../Icons/add-comment.svg";
import TableChreronGrey from "../Icons/table-chevron-grey.svg";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { Sorting } from "../components/Sorting/Sorting";
import Comments from "./Comments";
import moment from "moment";
import { GetOverviewUnitProgressTenants } from "../../../API/OverView/OverviewUnitProgressTenants";
import NoDataScreen from "../components/NoDataScreen";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchIcon from "../../dashboard/Icons/search.svg";

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    ${"" /* background: ${(props) => props.theme.palette.common.white}; */}
    ${"" /* color: ${(props) => props.theme.palette.common.black}; */}
    border-bottom: none;
    font-size: 13px;
    font-family : "Cerebri sans-medium";
    font-weight: 500;
    line-height : 20px
    white-space: nowrap;
    color: #000000;
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family : "Cerebri sans-light";
    font-size: 13px;
    font-weight: 300;
    line-height : 30px
    white-space: nowrap;
    padding:  12px;


    :first-of-type{
      border-radius : 8px 0 0 8px ;
    }
    :last-child{
      border-radius :  0 8px 8px 0  ;
    }


  }
  // &.${tableCellClasses.body} 
  // :first-of-type {
  //   // border-radius : 8px 0 0 8px;
  //   background-color : black;



  // }

`;
const CustomInnerTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    ${"" /* color: ${(props) => props.theme.palette.common.black}; */}
    border-bottom: none;
    font-size: 13px;
    font-family : "Cerebri sans-medium";
    font-weight: 500;
    line-height : 20px
    white-space: nowrap;
    color: #000000;
    padding: 5px 10px;
    line-height : 30px
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family : "Cerebri sans-light";
    font-size: 13px;
    font-weight: 300;
    white-space: nowrap;
    padding:  8px;
    height : 44px
    line-height : 30px
    margin : 5px;
    white-space: nowrap;
  }
`;
const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
    overflow: hidden;
    border-radius: 8px;
  }
`;
const RCLS = ({ color, text }) => {
  return (
    <>
      <div
        className="semi-bold fs-10 column-subsection"
        style={{ backgroundColor: color }}
      >
        {text}
      </div>
    </>
  );
};
const Tenants = ({ cid }) => {
  // API Consumption for  Tenants

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetAvailiabilityByRooms(cid);
  }, [cid]);

  const GetAvailiabilityByRooms = () => {
    try {
      GetOverviewUnitProgressTenants(cid)
        .then((data) => {
          if (data != null && data !== undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const [openTenants, setOpenTenants] = React.useState(false);

  const handleOpenTenants = () => {
    setOpenTenants(true);
  };
  const handleTenantsClose = () => {
    setOpenTenants(false);
  };

  return (
    <>
      {myData.length > 0 && (
        <span>
          (
          <span
            onClick={setOpenTenants}
            style={{ color: "#619130" }}
            className="cursor light fs-13 "
          >
            show more
          </span>
          )
        </span>
      )}

      <Dialog
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
          },
        }}
        open={openTenants}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              overflow: "hidden",
              width: "533px",
              height: "max-content",
              border: "1px solid #F4F5F5",
              boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.05)",
            },
          },
        }}
        onClose={handleTenantsClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            padding: "16px 22px 16px 18px",
          }}
        >
          <div className="bold fw-500 fs-14"> Tenants</div>
          <Table>
            <TableHead>
              <CustomTableRow>
                <CustomTableCell align="left" width="50%">
                  Name
                </CustomTableCell>
                <CustomTableCell
                  align="left "
                  width="20%"
                  sx={{ paddingLeft: "11px" }}
                >
                  Zip Code
                </CustomTableCell>
                <CustomTableCell
                  align="left"
                  width="30%"
                  sx={{ paddingLeft: "13px" }}
                >
                  Date of Birth
                </CustomTableCell>
              </CustomTableRow>
            </TableHead>
            <TableBody>
              {myData.map((item, i) => {
                return (
                  <CustomTableRow key={i}>
                    <CustomTableCell align="left" width="50%">
                      {item.Name}
                    </CustomTableCell>
                    <CustomTableCell
                      align="left"
                      width="20%"
                      sx={{ paddingLeft: "20px" }}
                    >
                      {item.ZipCode}
                    </CustomTableCell>
                    <CustomTableCell align="left" width="30%">
                      {new Date(item.DateOfBirth).toLocaleDateString("en-En", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </CustomTableCell>
                  </CustomTableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Dialog>
    </>
  );
};

const ExpandableTableRow = ({
  children,
  expandComponent,
  id,
  open,
  close,
  isexpanded,
  reduceComponent,
}) => {
  const CustomTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #f9fafa;
    }
  `;

  return (
    <>
      <CustomTableRow sx={{ marginTop: "10px" }}>{children}</CustomTableRow>
      {open ? <TableRow>{expandComponent}</TableRow> : null}
    </>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  TableRow: {
    cursor: "pointer",
    borderLeft: "8px solid #9a031e",
    marginTop: "8px",
  },
  tableCell: {
    marginTop: "8px",
  },
});
// bid={row.B_ID} eid={row.E_ID} pid={row.P_ID} mfid={row.MF_ID} cid={row.C_ID} rpid={row.SYS_RP_ID}
const ChildTable = ({ bid }) => {
  const classes = useStyles();

  const [openComment, setOpenComment] = React.useState(false);
  const [rpId, setRpid] = useState("");
  const [eId, setEid] = useState("");
  const [pId, setPid] = useState("");
  const [mfId, setMfid] = useState("");
  const [cId, setCId] = useState("");

  const handleCommentClick = (rpid, eid, pid, mfid, cid) => {
    setOpenComment(true);
    setRpid(rpid);
    setEid(eid);
    setPid(pid);
    setMfid(mfid);
    setCId(cid);
  };
  const handleCommentClose = () => {
    setOpenComment(false);
  };

  // Unit ProgressDetailBy Bid

  const [unitprogressdetail, setUnitprogressdetail] = useState([]);
  const [unitprogressdetailError, setUnitprogressdetailError] = useState([
    { error: false, status: "", message: "" },
  ]);

  const GetOverviewByUnitProgressDetailByBid = () => {
    try {
      GetUnitProgressDetailBid(bid)
        .then((data) => {
          if (data != null && data !== undefined) {
            setUnitprogressdetail(data.data);
          } else {
            setUnitprogressdetail([null]);
          }
        })
        .catch((error) => {
          setUnitprogressdetailError({
            error: error.message,
            status: error.status,
          });
          setUnitprogressdetail([null]);
        });
    } catch (e) {
      setUnitprogressdetail([null]);
      setUnitprogressdetailError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  useEffect(() => {
    GetOverviewByUnitProgressDetailByBid();
  }, []);

  return (
    <>
      <Paper className={classes.root} style={{ "padding-top": "0px" }}>
        <Table
          className={classes.table}
          aria-label="simple table"
          sx={{
            minWidth: 650,
            borderCollapse: "separate",
            borderSpacing: "0px 8px",
            paddingLeft: "11px",
            height: "44px",
            // position:'relative',
            // bottom:'32px',

            borderLeft: "1.5px solid #F4F5F5",
            "padding-top": "0px",
            "margin-top": "-12px",
            marginBottom: "32px",
            marginLeft: "12px",
          }}
        >
          <TableHead>
            <TableRow sx={{ position: "relative", top: "13px" }}>
              <CustomInnerTableCell
                align="left"
                width="20%"
                sx={{
                  paddingLeft: "81px !important",
                  "padding-top": "0px !important",
                  "padding-bottom": "0px !important",
                  "line-height": "0px !important ",
                }}
              >
                {/* <div style={{"padding-top":'7px'}}> */}
                Date
                {/* </div>   */}
              </CustomInnerTableCell>
              <CustomInnerTableCell
                width="5%"
                sx={{
                  padding: "0px",
                  lineHeight: "0px",
                  "padding-top": "0px !important",
                  "padding-bottom": "0px !important",
                  "line-height": "0px !important",
                }}
              ></CustomInnerTableCell>
              <CustomInnerTableCell
                align="left"
                width="47%"
                sx={{
                  padding: "0px",
                  lineHeight: "0px",
                  "padding-top": "0px !important",
                  "padding-bottom": "0px !important",
                  "line-height": "0px !important",
                }}
              >
                Activity
              </CustomInnerTableCell>
              <CustomInnerTableCell
                align="left"
                width="15%"
                sx={{
                  paddingLeft: "19px !important",
                  padding: "0px",
                  lineHeight: "0px",
                  "padding-top": "0px !important",
                  "padding-bottom": "0px !important",
                  "line-height": "0px !important",
                }}
              >
                Created by
              </CustomInnerTableCell>
              <CustomInnerTableCell
                align="center"
                width="13%"
                sx={{
                  "padding-top": "0px !important",
                  "padding-bottom": "0px !important",
                  "line-height": "0px !important",
                }}
              >
                Comments
              </CustomInnerTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {unitprogressdetail.map((row, id) => (
              <>
                <TableRow
                  sx={{
                    " background-color": "#f4f5f5",
                    "z-index": "0",
                    position: "relative",
                    top: "30px",
                  }}
                  key={id}
                >
                  <CustomInnerTableCell
                    style={{ position: "relative" }}
                    align="center"
                    width="15%"
                  >
                    <hr
                      style={{
                        border: "1.5px solid #F4F5F5",
                        position: "absolute",
                        right: "100px",
                        width: "14px",
                        top: "24px",
                        left: "-12.1px",
                        borderBottomWidth: "0px",
                      }}
                    ></hr>
                    {moment(row.Created.time).format("ll")}
                  </CustomInnerTableCell>

                  <CustomInnerTableCell width="5%">
                    <div
                      className="light fw-300 fs-13 "
                      style={{
                        backgroundColor:
                          row.ActivityType === 27
                            ? "rgba(119, 199, 161, 0.35)"
                            : row.ActivityType === 29
                            ? "rgba(246, 230, 81, 0.35)"
                            : row.ActivityType === 994
                            ? "rgba(162, 200, 208, 0.35)"
                            : row.ActivityType === 993
                            ? "rgba(241, 102, 75, 0.35)"
                            : "none",
                        height: "20px",
                        position: "relative",
                        width: "20px",
                        borderRadius: "900px",
                        right: "29px",
                      }}
                    >
                      <div className="semi-bold fw-600 fs-10  value">
                        {row.SequenceNo}
                      </div>
                    </div>
                  </CustomInnerTableCell>

                  <CustomInnerTableCell align="left" width="47%">
                    <span className="light fw-300 fs-13 ">{row.Activity}</span>{" "}
                    <Tenants cid={row.C_ID} />
                  </CustomInnerTableCell>

                  <CustomInnerTableCell
                    align="left"
                    width="15%"
                    sx={{ paddingLeft: "19px !important" }}
                  >
                    {row.CreatedBy}
                  </CustomInnerTableCell>
                  <CustomInnerTableCell align="center" width="13%">
                    <div
                      onClick={() =>
                        handleCommentClick(
                          row.SYS_RP_ID,
                          row.E_ID,
                          row.P_ID,
                          row.MF_ID,
                          row.C_ID
                        )
                      }
                    >
                      {row.Comments == "" ? (
                        <img
                          height={25}
                          onClick={() =>
                            handleCommentClick(
                              row.SYS_RP_ID,
                              row.E_ID,
                              row.P_ID,
                              row.MF_ID,
                              row.C_ID
                            )
                          }
                          // rpid, eid, pid, mfid, cid
                          style={{ position: "relative", top: "2px" }}
                          className=" cursor addcomment"
                          src={addcomment}
                          alt="addcomment"
                        />
                      ) : (
                        <Badge
                          overlap="circular"
                          badgeContent={row.Comments}
                          sx={{
                            "& .MuiBadge-badge": {
                              fontWidth: 700,
                              lineHeight: "20px",
                              fontSize: 9.5,
                              height: "19px",
                              width: " 12px",
                              borderRadius: "100%",
                              backgroundColor: "#619130",
                              color: "#fff",
                              zIndex: 0,
                            },
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                        >
                          <img
                            height={25}
                            className="cursor comments"
                            src={comments}
                            alt="comments"
                          />
                        </Badge>
                      )}
                    </div>
                  </CustomInnerTableCell>
                </TableRow>
              </>
              // </div>
            ))}
          </TableBody>
          {/* </div> */}
        </Table>
        <Dialog
          BackdropProps={{
            style: { backgroundColor: "rgba(0, 0, 0, 0.08)" },
          }}
          open={openComment}
          maxWidth="lg"
          fullWidth
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                overflow: "hidden",
              },
            },
          }}
          onClose={handleCommentClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Comments
            handleCommentClose={handleCommentClose}
            rpId={rpId}
            bid={bid}
            eId={eId}
            pId={pId}
            mfId={mfId}
            cId={cId}
          />
        </Dialog>
      </Paper>
    </>
  );
};

const TableRowSection = ({ myData }) => {
  const [open, setOpen] = useState("");

  const handleRotate = (id) => {
    setOpen((prev) => (prev == id ? "" : id));
  };

  return (
    <>
      <TableBody>
        {myData.map((row, id) => (
          <ExpandableTableRow
            key={id}
            id={id}
            open={open === row.B_ID}
            expandComponent={
              <CustomTableCell colSpan="10" sx={{ paddingRight: "0" }}>
                <ChildTable bid={row.B_ID} />
              </CustomTableCell>
            }
          >
            <CustomTableCell
              width="5%"
              onClick={() => handleRotate(row.B_ID)}
              style={{ padding: "0 22px 0 15px" }}
            >
              <div
                className="cursor"
                style={{ position: "relative", left: "6px", top: "2px" }}
              >
                <img
                  height={15}
                  style={{
                    transform:
                      open === row.B_ID ? "rotate(90deg)" : "rotate(0)",
                    transition: "all 0.2s linear",
                  }}
                  className="addcomment"
                  src={TableChreronGrey}
                  alt="addcomment"
                />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left" width="20%">
              <div className="table-first-row-section">
                <div
                  style={{ backgroundColor: "rgba(119, 199, 161, 0.35)" }}
                  className="semi-bold fw-600 fs-10 column-body-section"
                >
                  {row.R}
                </div>
                <div
                  style={{ backgroundColor: "rgba(246, 230, 81, 0.35)" }}
                  className="semi-bold fw-600 fs-10 column-body-section"
                >
                  {row.C}
                </div>
                <div
                  style={{ backgroundColor: "rgba(162, 200, 208, 0.35)" }}
                  className="semi-bold fw-600 fs-10 column-body-section"
                >
                  {row.L}
                </div>
                <div
                  style={{ backgroundColor: "rgba(241, 102, 75, 0.35)" }}
                  className="semi-bold fw-600 fs-10 column-body-section"
                >
                  {row.S}
                </div>
              </div>
            </CustomTableCell>
            <CustomTableCell
              align="left"
              width="12%"
              sx={{ whiteSpace: "nowrap" }}
            >
              {row.Address}
            </CustomTableCell>
            <CustomTableCell
              align="right"
              width="10%"
              style={{ paddingRight: "37px" }}
            >
              {row.UnitNo}
            </CustomTableCell>
            {/* <CustomTableCell></CustomTableCell> */}
            <CustomTableCell
              align="left"
              width="16%"
              style={{ paddingleft: "18px" }}
            >
              {row.Property}
            </CustomTableCell>

            <CustomTableCell
              align="right"
              width="10%"
              sx={{ paddingRight: "15px !important" }}
            >
              {row.Room}
            </CustomTableCell>

            <CustomTableCell
              align="right"
              width="7%"
              sx={{ paddingRight: "15px !important" }}
            >
              {row.Sqmtr} m&sup2;
            </CustomTableCell>
            <CustomTableCell
              align="right"
              width="15%"
              sx={{ paddingRight: "40px !important" }}
            >
              {new Intl.NumberFormat("en-DE").format(row.YearlyRent)}
              &nbsp;DKK
            </CustomTableCell>
          </ExpandableTableRow>
        ))}
      </TableBody>
    </>
  );
};
const UnitProgressOverTime = ({ sid, datefilter }) => {
  //i18Next
  const { t, i18n } = useTranslation();

  //Options for CSV data
  const options = {
    keys: [
      { field: "R", title: "R" },
      { field: "C", title: "C" },
      { field: "L", title: "L" },
      { field: "S", title: "S" },
      { field: "Address" },
      { field: "UnitNo", title: "UnitNo" },
      { field: "Property", title: "Property" },
      { field: "Room", title: "Room" },
      { field: "Sqmtr", title: "Sqmtr" },
      { field: "YearlyRent", title: "YearlyRent" },
      { field: "Created", title: "Created" },
    ],
  };

  // API Consumption UnitProgress

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetOverviewByUnitProgress(sid, datefilter);
  }, [sid, datefilter]);

  const GetOverviewByUnitProgress = (sid, datefilter) => {
    try {
      GetUnitProgress(sid, datefilter)
        .then((data) => {
          if (data != null && data !== undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };


  const Ascending = (id) => {
    let myArr = [...myData];
    if (id === "R") {
      myArr.sort((a, b) => a.R - b.R);
      setMyData(myArr);
    } else if (id === "C") {
      myArr.sort((a, b) => a.C - b.C);
      setMyData(myArr);
    } else if (id === "L") {
      myArr.sort((a, b) => a.L - b.L);
      setMyData(myArr);
    } else if (id === "S") {
      myArr.sort((a, b) => a.S - b.S);
      setMyData(myArr);
    } else if (id === "Address") {
      myArr.sort((a, b) => a.Address.localeCompare(b.Address));
      setMyData(myArr);
    } else if (id === "UnitNo") {
      myArr.sort((a, b) => a.UnitNo.localeCompare(b.UnitNo));
      setMyData(myArr);
    } else if (id === "Property") {
      myArr.sort((a, b) => a.Property.localeCompare(b.Property));
      setMyData(myArr);
    } else if (id === "Room") {
      myArr.sort((a, b) => a.Room - b.Room);
      setMyData(myArr);
    } else if (id === "Sqmtr") {
      myArr.sort((a, b) => a.Sqmtr - b.Sqmtr);
      setMyData(myArr);
    } else if (id === "YearlyRent") {
      myArr.sort((a, b) => a.YearlyRent - b.YearlyRent);
      setMyData(myArr);
    }
  };
  const Descending = (id) => {
    let myArr = [...myData];
    if (id === "R") {
      myArr.sort((a, b) => b.R - a.R);
      setMyData(myArr);
    } else if (id === "C") {
      myArr.sort((a, b) => b.C - a.C);
      setMyData(myArr);
    } else if (id === "L") {
      myArr.sort((a, b) => b.L - a.L);
      setMyData(myArr);
    } else if (id === "S") {
      myArr.sort((a, b) => b.S - a.S);
      setMyData(myArr);
    } else if (id === "Address") {
      myArr.sort((a, b) => b.Address.localeCompare(a.Address));
      setMyData(myArr);
    } else if (id === "UnitNo") {
      myArr.sort((a, b) => b.UnitNo - a.UnitNo);
      setMyData(myArr);
    } else if (id === "Property") {
      myArr.sort((a, b) => b.Property.localeCompare(a.Property));
      setMyData(myArr);
    } else if (id === "Room") {
      myArr.sort((a, b) => b.Room - a.Room);
      setMyData(myArr);
    } else if (id === "Sqmtr") {
      myArr.sort((a, b) => b.Sqmtr - a.Sqmtr);
      setMyData(myArr);
    } else if (id === "YearlyRent") {
      myArr.sort((a, b) => b.YearlyRent - a.YearlyRent);
      setMyData(myArr);
    }
  };

  //Handle search logic
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  
  const handleSearch = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = myData?.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput?.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(myData);
    }
  };
  useEffect(() => {
    handleSearch(searchInput);
  }, [searchInput,myData]);

  const [count, setCount] = useState(10);
  const [listState, setListState] = useState([]);

  useEffect(() => {
    setListState(filteredResults.slice(0, count));
  }, [filteredResults]);

  const fetchMoreData = async () => {
    setTimeout(async () => {
      let newArr = listState.concat(filteredResults.slice(count, count + 10));
      setListState(newArr);
      setCount(count + 10);
    }, 100);
  };

  return (
    <div className="unit-process-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          zIndex: 3,
          position: "relative",
          paddingRight: "5px",
        }}
      >
        <div className="margin-heading-card" style={{ display: "flex" }}>
          <div className="bold fw-700 fs-14">
            {t("unit-progress-over-time")}
          </div>

          <Tooltip text={t("unit-progress-over-time")} paragraph="" />
        </div>
        <div style={{ display: "flex" }}>
          <div className="search-property" style={{ marginTop: "16px" }}>
            <TextField
              className="light"
              id="outlined-basic"
              onChange={(e) => handleSearch(e?.target?.value)}
              value={searchInput}
              placeholder="Search unit"
              label="Search unit"
              variant="outlined"
              sx={{
                width: "175px",
                height: "44px",
              }}
              InputProps={{
                style: {
                  borderRadius: "8px",
                  border: "1px solid #F4F5F5",
                },
                endAdornment: (
                  <IconButton>
                    <img
                      height={12}
                      className="question-mark"
                      src={SearchIcon}
                      alt="Question mark"
                    />
                  </IconButton>
                ),
              }}
            />
          </div>
          <DownloadWidget
            options={options}
            data={myData}
            fileName={"unit-progress-over-time"}
            type={false}
          />
        </div>
      </div>

      {myData.length != 0 ? (
        <>
          <div
            id="scrollableUnitProgressDiv"
            className="scrollable-container"
            style={{ height: "462px", padding: " 0 21px 0 25px" }}
          >
            <InfiniteScroll
              dataLength={listState.length}
              next={fetchMoreData}
              hasMore={true}
              scrollableTarget="scrollableUnitProgressDiv"
            >
              <Table>
                <TableHead>
                  <TableRow className="sticky" sx={{ backgroundColor: "#fff" }}>
                    <CustomTableCell width="5%"></CustomTableCell>
                    <TableCell
                      align="left"
                      width="20%"
                      sx={{
                        border: "none",
                        fontFamily: "Cerebri Sans",
                        fontWeight: "600",
                        color: "#00000",
                      }}
                    >
                      <RCLS color="rgba(119, 199, 161, 0.35)" text="R" />
                      <Sorting
                        Ascending={() => Ascending("R")}
                        Descending={() => Descending("R")}
                      />
                      <RCLS color="rgba(246, 230, 81, 0.35)" text="C" />
                      <Sorting
                        Ascending={() => Ascending("C")}
                        Descending={() => Descending("C")}
                      />
                      <RCLS color="rgba(162, 200, 208, 0.35)" text="L" />
                      <Sorting
                        Ascending={() => Ascending("L")}
                        Descending={() => Descending("L")}
                      />
                      <RCLS color="rgba(241, 102, 75, 0.35)" text="S" />
                      <Sorting
                        Ascending={() => Ascending("S")}
                        Descending={() => Descending("S")}
                      />
                    </TableCell>
                    <CustomTableCell
                      className="column-subsection"
                      align="left"
                      width="12%"
                    >
                      {t("address")}
                      <Sorting
                        Ascending={() => Ascending("Address")}
                        Descending={() => Descending("Address")}
                      />
                    </CustomTableCell>
                    <CustomTableCell align="right" width="10%">
                      {t("unit-no")}.
                      <Sorting
                        Ascending={() => Ascending("UnitNo")}
                        Descending={() => Descending("UnitNo")}
                      />
                    </CustomTableCell>
                    {/* <CustomTableCell></CustomTableCell> */}

                    <CustomTableCell align="left" width="16%">
                      {t("property")}
                      <Sorting
                        Ascending={() => Ascending("Property")}
                        Descending={() => Descending("Property")}
                      />
                    </CustomTableCell>
                    <CustomTableCell align="right" width="10%">
                      {t("room")}
                      <Sorting
                        Ascending={() => Ascending("Room")}
                        Descending={() => Descending("Room")}
                      />
                    </CustomTableCell>

                    <CustomTableCell align="right" width="7%">
                      m&sup2;
                      <Sorting
                        Ascending={() => Ascending("Sqmtr")}
                        Descending={() => Descending("Sqmtr")}
                      />
                    </CustomTableCell>

                    <CustomTableCell
                      align="right"
                      width="15%"
                      sx={{ paddingRight: "28px" }}
                    >
                      {t("yearly-rent")}
                      <Sorting
                        Ascending={() => Ascending("YearlyRent")}
                        Descending={() => Descending("YearlyRent")}
                      />
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableRowSection myData={listState} />
              </Table>
            </InfiniteScroll>
          </div>

          <div className="unit-process-footer">
            <div className="footer-section">
              <div
                className="footer-color-section"
                style={{ backgroundColor: "#77C7A1" }}
              ></div>
              <div className="line-height-18 medium fw-500 fs-12">
                {t("reservations")}
              </div>
            </div>
            <div className="footer-section">
              <div
                className="footer-color-section"
                style={{ backgroundColor: "#F6E651" }}
              ></div>
              <div className="line-height-18  medium fw-500 fs-12">
                {t("contract-sent-for-signature")}
              </div>
            </div>
            <div className="footer-section">
              <div
                className="footer-color-section"
                style={{ backgroundColor: "#A2C8D0" }}
              ></div>
              <div className="line-height-18 medium fw-500 fs-12">
                {t("leased")}
              </div>
            </div>
            <div className="footer-section">
              <div
                className="footer-color-section"
                style={{ backgroundColor: "#F1664B" }}
              ></div>
              <div className="medium line-height-18 fw-500 fs-12">
                {t("sold")}
              </div>
            </div>
          </div>
        </>
      ) : (
        <NoDataScreen />
      )}
    </div>
  );
};

export default UnitProgressOverTime;
