import React from "react";
import SigningsTable from "./SigningsTable";

const Signings = ({ sid, datefilter }) => {
  return (
    <>
      <div className="signings-main-content">
        <SigningsTable sid={sid} datefilter={datefilter} />
      </div>
    </>
  );
};

export default Signings;
