import { Get } from "../../estateTool/dashboard/utils/Axios";
import { endpoint } from "../EndPoint";
import { GetHeader } from "../Header";

export const GetEconomyIdlenessShow = (eid, AssetID, datefilter) => {
  eid = eid === "" || eid === null || eid === undefined ? 0 : eid;
  //   AssetID =
  //     AssetID === "" || AssetID === null || AssetID === undefined ? 0 : AssetID;

  datefilter =
    datefilter === "" || datefilter === null || datefilter === undefined
      ? "2020-01-01/2023-01-31"
      : datefilter;
  return new Promise((resolve, reject) => {
    Get(
      endpoint.Economy_Idleness_Show,
      GetHeader(),
      eid + "/" + AssetID + "/" + datefilter
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
