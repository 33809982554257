import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Bar } from "react-chartjs-2";

import { CardContent, Card as MuiCard, Typography } from "@mui/material";
import { spacing } from "@mui/system";

import { GetCustomersTransactionAge } from "../../../API/Customers/CustomersTransactionAge";
import { useTranslation } from "react-i18next";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);
const ChartWrapper = styled.div`
  height: 425px;
  width: 100%;
  position: relative;
  bottom: 5px;
`;

const SwitchToggleChart = ({ sid , datefilter}) => {

  //i18n
  const { t, i18n } = useTranslation();

  // API Consumption

  const [myData, setMyData] = useState({ data: [] });
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  console.log("objectmyData",myData)

  useEffect(() => {
    GetCustomersTransactionByAge(sid , datefilter);
  }, [sid , datefilter]);

  const GetCustomersTransactionByAge = (sid , datefilter) => {
    try {
      GetCustomersTransactionAge(sid , datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const data = {
    labels: myData?.data?.Data?.map((x) => x.Age),
    datasets: [
      {
        label: "Tenants",
        backgroundColor: "#619130",
        borderColor: ["#fff"],

        // borderColor: theme.palette.secondary.main,
        // hoverBackgroundColor: '#619130' ,
        // hoverBorderColor: theme.palette.secondary.main,
        data: myData?.data?.Data?.map((x) => x.Tenants),

        barPercentage: 0.75,
        categoryPercentage: 0.5,
        borderWidth: 1,
        maxBarThickness: 30,
        barThickness: 30,
      },
      {
        label: "Registrations",
        backgroundColor: "#D00200",
        borderColor: ["#fff"],

        // hoverBackgroundColor: theme.palette.grey[300],
        // hoverBorderColor: theme.palette.grey[300],
        data: myData?.data?.Data?.map((x) => x.Registrations),
        barPercentage: 0.75,
        categoryPercentage: 0.5,
        borderWidth: 1,
        maxBarThickness: 30,
        barThickness: 30,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          borderDash: [5, 10],
          color: "#F4F5F5",
        },
        stacked: false,
        ticks: {
          stepSize: 1,
        },
      },

      x: {
        stacked: false,
        grid: {
          color: "transparent",
        },
      },
    },
  };

  return (
    <div
      style={{
        marginBottom: "15px",
        backgroundColor: "#ffff",
        borderRadius: "8px",
      }}
    >
      <CardContent>
        <Spacer mb={6} />

        <ChartWrapper>
          <Bar data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </div>
  );
};

export default SwitchToggleChart;
