import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function DropDown({ data, label, value, onChange, width }) {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const divStyle = {
    color: "rgb(144,144,144)",
    border: "#f4f5f5",

    fontSize: "14px",
    fontFamily: "Cerebri sans-light",
  };

  const MenuProps = {
    PaperProps: {
      style: {
        boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
        borderColor: "1px solid red",
      },
    },
  };
  return (
    <Box sx={{ width: width }}>
      <FormControl fullWidth>
        <InputLabel style={divStyle} id="demo-simple-select-label">
          {label}
        </InputLabel>
        <Select
          sx={{
            borderRadius: "8px",
            backgroundColor: "#fff",
            borderColor: "#f4f5f5",

            ".MuiOutlinedInput-notchedOutline": { borderColor: "#f4f5f5" },

            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#888888",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#f4f5f5",
            },
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          IconComponent={ExpandMoreIcon}
          small
          onChange={onChange}
          MenuProps={MenuProps}
        >
          {data?.map((option, i) => (
            <MenuItem key={i} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
