import React from "react";
import { useEffect, useState } from "react";

import Reserved from "./Reserved";
import "./economy.css";

import Popup from "reactjs-popup";
import styled from "@emotion/styled";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";

import Tooltip from "../components/Tooltip/Tooltip";
import { GetEconomyIdleness } from "../../../API/EconomyIdleness/EconomyIdleness";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";
import NoDataScreen from "../components/NoDataScreen";

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: Cerebri sans-medium;
    font-weight: 500;
    font-size: 13px;
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family: Cerebri sans-light;
    font-weight: 300;
    font-size: 13px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
  }
`;

// Data
let id = 0;
function createData(status, quantity, percentage, amount) {
  id += 1;
  return { id, status, quantity, percentage, amount };
}

const StatusLabel = ({ text, color }) => {
  return (
    <>
      <span
        className="idleness-statuslabel"
        style={{ background: color }}
      ></span>
      {text}
    </>
  );
};

function Idleness({ sid, datefilter }) {
  //i18Next
  const { t, i18n } = useTranslation();

  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetEconomyByIdleness(sid, datefilter);
  }, [sid, datefilter]);

  const GetEconomyByIdleness = (sid, datefilter) => {
    try {
      GetEconomyIdleness(sid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };
  const Colors = [
    "#77C7A1",
    "#77C7A1",
    "#A2C8D0",
    "#A2C8D0",
    "#F6E651",
    "#F1664B",
    "#F1664B",
    "#BF87D2",
    "#BF87D2",
  ];

  //Options for CSV data
  const options = {
    keys: [
      { field: "Status", title: "Status" },
      { field: "Quantity", title: "Quantity" },
      { field: "Percentage", title: "Percentage %" },
      { field: "TotalAmount", title: "Total Amount in DKK" },
    ],
  };

  return (
    <>
      <div
        style={{
          borderRadius: "8px",
          height: "content-fit",
          backgroundColor: "#FFF",
          position: "relative",
          width :'100%'
        }}
      >
        {myData?.filter((data)=>data.Status === "Total")[0]?.Quantity != "0" ? (
          <Paper className="card-container">
            <Table>
              <TableHead>
                <TableRow>
                  <CustomTableCell sx={{ padding: "16px 16px 16px 30px" }}>
                    {t("status")}
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {t("quantity")}
                  </CustomTableCell>
                  <CustomTableCell align="left"></CustomTableCell>
                  <CustomTableCell align="right">
                    {t("percentage")}
                  </CustomTableCell>
                  <CustomTableCell
                    align="right"
                    sx={{ padding: "16px 50px 16px 16px" }}
                  >
                    {t("Yearly rent")}
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myData?.slice(0, -1)?.map((row, i) => (
                  <CustomTableRow key={i}>
                    <CustomTableCell
                      sx={{ padding: "16px 16px 16px 30px" }}
                      component="th"
                      scope="row"
                      width="30%"
                    >
                      <StatusLabel color={Colors[i]} />
                      {row.Status}
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      {row.Quantity}
                    </CustomTableCell>
                    <CustomTableCell
                      sx={{ padding: "16px 50px 16px 0px" }}
                      align="left"
                    >
                      {row.AssetID < 2 || row.Quantity == "0" ? (
                        ""
                      ) : (
                        <>
                          (
                          <span style={{ color: "#619130", cursor: "pointer" }}>
                            <Popup
                              lockScroll={true}
                              overlayStyle={{
                                backgroundColor: "rgba(0,0,0,0.4)",
                              }}
                              contentStyle={{ width: "1400px" }}
                              modal
                              trigger={<span>show</span>}
                            >
                              {(close) => (
                                <Reserved
                                  sid={sid}
                                  datefilter={datefilter}
                                  close={close}
                                  AssetID={row.AssetID}
                                  Status={row.Status}
                                />
                              )}
                            </Popup>
                          </span>
                          )
                        </>
                      )}
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      {row.Percentage}%
                    </CustomTableCell>
                    <CustomTableCell
                      align="right"
                      sx={{ padding: "16px 50px 16px 16px" }}
                    >
                      {new Intl.NumberFormat("en-DE").format(row.TotalAmount)}
                      &nbsp;DKK
                    </CustomTableCell>
                  </CustomTableRow>
                ))}
              </TableBody>

              <TableHead>
                <TableRow>
                  <CustomTableCell sx={{ padding: "16px 16px 16px 30px" }}>
                    {myData?.filter((data)=>data.Status === "Total")[0]?.Status}
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {myData?.filter((data)=>data.Status === "Total")[0]?.Quantity}
                  </CustomTableCell>
                  <CustomTableCell align="left"></CustomTableCell>

                  <CustomTableCell align="right">
                    {myData?.filter((data)=>data.Status === "Total")[0]?.Percentage}
                  </CustomTableCell>
                  <CustomTableCell
                    align="right"
                    sx={{ padding: "16px 50px 16px 16px" }}
                  >
                    {new Intl.NumberFormat("en-DE").format(
                      myData?.filter((data)=>data.Status === "Total")[0]?.TotalAmount
                    )}
                    &nbsp;DKK
                  </CustomTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Paper>
        ) : (
          <NoDataScreen />
        )}
        {/* </Card> */}
      </div>
    </>
  );
}

export default Idleness;
