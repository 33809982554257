import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PostSigningSendReminder } from "../../../API/SigningsTable/SigningSendReminder";

const RemindPopup = ({
  handleClose,
  stepNumber,
  zid,
  name,
  actor,
  orderId,
}) => {
  const [iValue, setIValue] = useState();

  //i18n
  const { t, i18n } = useTranslation();

  //API consumption
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);
  const SigninSendReminder = (data) => {
    try {
      PostSigningSendReminder(data)
        .then((response) => {})
        .catch((error) => {
          setError({ error: error.message, status: error.status });
        });
    } catch (error) {
      setError({ error: true, status: "", message: "Failed to retrieve data" });
    }
  };

  const handleSendReminder = () => {
    SigninSendReminder({
      orderId: orderId,
      step: stepNumber,
      zid: zid,
    });
    handleClose();
  };

  return (
    <>
      <div className="reminder">
        <div className="medium reminder-msg">
          {t("are-you-sure-that-you-will-remind")}?
        </div>
        <br />
        <hr style={{ border: "1px solid #f4f5f4" }} />
        <div>
          <div className="reminder-data-name">
            <div className="name medium fs-14">{name}</div>
            <div className=" name light  fs-14">{actor}</div>
          </div>

          <div className="regular fw-500 fs-14 reminder-btn">
            <div className="btn-btn" onClick={handleClose}>
              <div className="btn-text uppercase">{t("no")}</div>
            </div>
            <div className="btn-btn-green" onClick={handleSendReminder}>
              <div className="btn-text uppercase">{t("yes")}</div>
            </div>
          </div>
        </div>
        {/* ))} */}
      </div>
    </>
  );
};

export default RemindPopup;
