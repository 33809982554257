import React from "react";
import { Grommet, CheckBox } from "grommet";
import "./ActivityDropdown.css";

const theme = {
  fontFamily: "Cerebri Sans",
  checkBox: {
    border: {
      width: "1px",
      color: "#F4F5F5",
      radius: "4px",
    },

    check: {
      extend: ({ checked }) => `
        ${checked && `background-color: #619130;`}
        ${checked && `stroke: white`}`,
    },

    color: "white",
    extend: {
      marginRight: "8px",
    },

    icon: {
      size: "16px",
    },
    size: "16px",
  },
};

const ActivityDropdown = ({
  allChecked,
  optionChecked,
  OnClicked,
  Activities,
  Label,
  parentId,
  flag,
}) => {
  return (
    <>
      <Grommet theme={theme}>
        <div className="light fw-400 fs-14 line-height-22 scrollable-container">
          {!flag && (
            <div className="parent">
              <CheckBox
                id={!flag ? "0" : "1"}
                checked={allChecked}
                onChange={(e) => OnClicked(e, true)}
                label={Label}
              />
            </div>
          )}
          <div className="child">
            {Activities?.map(({ id, name }) => (
              <CheckBox
                id={id}
                key={id}
                checked={optionChecked?.includes(id)}
                onChange={(e) => OnClicked(e, false)}
                label={name}
              />
            ))}
          </div>
        </div>
      </Grommet>
    </>
  );
};

export default ActivityDropdown;

ActivityDropdown.defaultProps = {
  flag: false,
};
