import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import GoogleMapReact from "google-map-react";
import AddIcon from "@mui/icons-material/Add";
import "./economy.css";
import { CardContent, Card as MuiCard, Dialog } from "@mui/material";
import { spacing } from "@mui/system";

import MileStone from "../overview/MileStonePopup/MileStone";
import { useTranslation } from "react-i18next";
import MarkerPopup from "../overview/MileStonePopup/MarkerPopup";
import { GetMilestoneProperties } from "../../../API/Common/CommonGetMilestoneProperties";
import clientMarker from "../Icons/client-color_property-marker.svg";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const GoogleMapReactWrapper = styled.div`
  height: 600px;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
`;

function Map({ sid, datefilter, ActivitiesProperty }) {
  //i18Next
  const { t, i18n } = useTranslation();

  

  const getMapOptions = (maps) => {
    return {
      fullscreenControl: false,
      mapTypeControl: false,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      scrollwheel: true,
      streetViewControl: true,
    };
  };

  // API Consumption GetMilestoneProperties

  const [milestoneProperty, setMilestoneProperty] = useState([]);
  const [errorMilestoneProperty, setErrorMilestoneProperty] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    propertyMilestone();
  }, [datefilter]);

  const options = {
    // center: {
    //   lat: 55.694821,
    //   lng: 12.530963,
    // },
    center: {
      lat: parseFloat(milestoneProperty?.[0]?.Coordinates?.split(",", 1)?.[0]) ||55.694821 ,
      lng: parseFloat(milestoneProperty?.[0]?.Coordinates?.split(",")?.splice(1)?.[0]) || 12.530963,
    },
    zoom: 12,
  };
  const propertyMilestone = () => {
    try {
      GetMilestoneProperties(datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMilestoneProperty(data.data);
          } else {
            setMilestoneProperty([null]);
          }
        })
        .catch((error) => {
          setErrorMilestoneProperty({
            error: error.message,
            status: error.status,
          });
          setMilestoneProperty([null]);
        });
    } catch (e) {
      setMilestoneProperty([null]);
      setErrorMilestoneProperty({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };
  const renderMarkers = (map, maps) => {
    new maps.Marker(
      {
        position: {
          lat: 55.694821,
          lng: 12.530963,
        },
        map,
        title: "marker",
      },
      {
        position: {
          lat: 40.712784,
          lng: -74.005941,
        },
        map,
        title: "marker 2",
      }
    );
  };
  const [openMap, setOpenMap] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openMarkerPopup, setOpenMarkerPopup] = useState(false);
  const [markerData, setMarkerData] = useState([]);
  const [markerEID, setMarkerEID] = useState();

  const handleMarkerPopup = () => {
    setOpenMarkerPopup(!openMarkerPopup);
  };

  // useEffect(() => {
  //   if (!openMap) {
  //     setOpenMarkerPopup(false);
  //   }
  // }, [openMap]);

  const handleMarkerClick = (data) => {
    setMarkerData(data);
    setMarkerEID(data.SYS_E_ID);
  };

  const Marker = (props) => {
    console.log(props, "props")
    return (
      <div
        style={{
          width: "20px",
          height: "20px",
          borderRadius: "100%",
          border: `2px solid ${
            props.data.Target === "Under Target"
              ? "#82FF99"
              : props.data.Target === "On Target"
              ? "#F6E651"
              : "#f1664b"
          }`,
          backgroundColor: `${
            props.data.Target === "Under Target"
              ? "rgba(130, 255, 153, 0.5)"
              : props.data.Target === "On Target"
              ? "rgba(246, 230, 81, 0.5)"
              : "rgba(241, 102, 75, 0.5)"
          }`,
          textAlign: "center",
          padding: "0 5px",
          color: "#3f51b5",
          fontSize: "16",
          fontWeight: "bold",
          padding: "4",
          cursor: "pointer",
        }}
        onClick={() => {
          handleMarkerClick(props.data);
        }}
      >
        {markerEID == props.data.SYS_E_ID && (
          <img
            src={clientMarker}
            alt="marker"
            width={36}
            height={49}
            style={{ position: "absolute", left: "-8px", bottom: "-10px" }}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          borderLeft: "1px solid #F4F5F5",
          borderRight: "1px solid #F4F5F5",
          borderBottom: "1px solid #f4f5f5",
          backgroundColor: "white",
        }}
      >
        {openMap && (
          <CardContent
            style={{
              backgroundColor: "white",
              padding: "30px 35px  0px 35px",
              position: "relative",
            }}
            onClick={() => setOpenMarkerPopup(false)}
          >
            <GoogleMapReactWrapper>
              <div className="btn-section">
                <div
                  className="bold fs-13 fw-500 btn-create-milestone"
                  onClick={handleClickOpen}
                >
                  <span className="mt-3px">
                    &nbsp;
                    <AddIcon />
                  </span>
                  <span className="medium fs-13 cursor uppercase">
                    &nbsp;{t("create-milestone")}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "8px",
                  }}
                >
                  <div className="btn-card">
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        background: "#F1664B",
                        borderRadius: "2px",
                        marginRight: "8px",
                      }}
                    ></div>
                    <span style={{ fontSize: "12px", lineHeight: "2px" }}>
                      {t("over-target")}
                    </span>
                  </div>

                  <div className="btn-card">
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        background: "#F6E651",
                        borderRadius: "2px",
                        marginRight: "8px",
                      }}
                    ></div>
                    <span style={{ fontSize: "12px", lineHeight: "2px" }}>
                      {t("on-target")}
                    </span>
                  </div>
                  <div className="btn-card">
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        background: "#77C7A1",
                        borderRadius: "2px",
                        marginRight: "8px",
                      }}
                    ></div>
                    <span style={{ fontSize: "12px", lineHeight: "2px" }}>
                      {t("under-target")}
                    </span>
                  </div>
                </div>
              </div>
              <Dialog
                BackdropProps={{
                  style: { backgroundColor: "rgba(0, 0, 0, 0.08)" },
                }}
                className="scrollable-container"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      borderRadius: "8px",
                      overflow: "hidden",
                      width: "1221px",
                      display: "flex",
                      flexWrap: "wrap",
                    },
                  },
                }}
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <MileStone
                  handleClose={handleClose}
                  handleClickOpen={handleClickOpen}
                  open={open}
                  sid={sid}
                  datefilter={datefilter}
                  ActivitiesProperty={ActivitiesProperty}
                />
              </Dialog>

              <GoogleMapReact
                options={getMapOptions}
                bootstrapURLKeys={{
                  key: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
                }}
                defaultCenter={options.center}
                defaultZoom={options.zoom}
                // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
              >
                {milestoneProperty?.map((item, i) => (
                  <Marker
                    key={i}
                    lat={item?.Coordinates?.split(",", 1)}
                    lng={item?.Coordinates?.split(",").splice(1)}
                  
                    id={i}
                    data={item}
                    $hover
                  />
                ))}
              </GoogleMapReact>

              <div
                onClick={(e) => {
                  handleMarkerPopup();
                  e.stopPropagation();
                }}
              >
                <MarkerPopup
                  handleMarkerPopup={handleMarkerPopup}
                  openMarkerPopup={openMarkerPopup}
                  markerData={markerData}
                  datefilter={datefilter}
                />
              </div>
            </GoogleMapReactWrapper>
          </CardContent>
        )}

        <div
          className="bold  fs-12 map-show-button uppercase"
          onClick={() => setOpenMap(!openMap)}
        >
          {openMap === false ? `${t("show-map")}` : `${t("hide-map")}`}
        </div>
      </div>
    </>
  );
}

export default Map;
