import React, { useRef, useState, useEffect } from "react";
import "./traffic.css";

import styled from "@emotion/styled";
import { Doughnut } from "react-chartjs-2";

import { CardContent, Grid } from "@mui/material";
import { spacing } from "@mui/system";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GetTrafficOverview } from "../../../API/TrafficOverview/TrafficOverview";
import Tooltip from "../components/Tooltip/Tooltip";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";
import NoDataScreen from "../components/NoDataScreen";

const Spacer = styled.div(spacing);
const ChartWrapper = styled.div`
  height: 201px;
  position: relative;
`;
const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: "Cerebri sans-medium";
    font-size: 13px;
    font-weight: 500;
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family: "Cerebri sans-medium";
    font-size: 13px;
    font-weight: 500;
  }
`;

function TrafficOverview({ sid, datefilter }) {
  //i18Next
  const { t, i18n } = useTranslation();

  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetTrafficByOverview(sid, datefilter);
  }, [sid, datefilter]);

  const GetTrafficByOverview = (sid, datefilter) => {
    try {
      GetTrafficOverview(sid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({ error: true, status: "", message: "Failed to retrieve data" });
    }
  };

  const data = {
    labels: [`${t("Desktop")}`, `${t("Tablet")}`, `${t("Mobile")}`],
    datasets: [
      {
        data: [
          myData?.data?.source[2]?.Percentage,
          myData?.data?.source[1]?.Percentage,
          myData?.data?.source[0]?.Percentage,
        ],
        backgroundColor: ["#77C7A1", "#A2C8D0", "#F6E651"],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutout: "80%",
    borderWidth: 3,
    plugins: {
      tooltip: {
        yAlign: "top",
        backgroundColor: "#FFFFFF",
        borderWidth: 2,
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
      },
      legend: {
        display: false,
      },
    },
  };

  const CustomTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #f9fafa;
    }
  `;

  const StatusLabel = ({ text, color }) => {
    return (
      <div>
        <span
          className="traffic-overview-status-label"
          style={{ background: color }}
        ></span>
        <span className="light fw-300 fs-13">{text}</span>
      </div>
    );
  };
  const StatusLabel2 = ({ text }) => {
    return (
      <div>
        <span className="light fw-300 fs-13">{text}</span>
      </div>
    );
  };

  function createData(status, prcntvalue) {
    return { status, prcntvalue };
  }

  const rows = [
    createData(
      <StatusLabel text={t("Desktop")} color="#77C7A1" />,
      myData?.data?.source[2]?.Percentage + "%"
    ),
    createData(
      <StatusLabel text={t("Tablet")} color="#A2C8D0" />,
      myData?.data?.source[1]?.Percentage + "%"
    ),
    createData(
      <StatusLabel text={t("Mobile")} color="#F6E651" />,
      myData?.data?.source[0]?.Percentage + "%"
    ),
  ];
  const visitors = [
    createData(
      <StatusLabel2 text={t("Recordings")} />,
      myData?.data?.stats[0]?.Recordings
    ),
    createData(
      <StatusLabel2 text={t("Users")} />,
      myData?.data?.stats[0]?.Users
    ),
    createData(
      <StatusLabel2 text={t("Pageviews")} />,
      myData?.data?.stats[0]?.Pageviews
    ),
  ];
  const Benchmarks = [
    createData(
      <StatusLabel2 text={t("Avg. pages per visit")} />,
      myData?.data?.benchmark[0]?.AvgPagePerVisit
    ),
    createData(
      <StatusLabel2 text={t("Avg. visit duration")} />,
      myData?.data?.benchmark[0]?.AvgVisitDuration
    ),
    createData(
      <StatusLabel2 text={t("Avg. friction score")} />,
      myData?.data?.benchmark[0]?.AvgFrictionScore
    ),
  ];

  const myref = useRef();
  const [refObj] = useState(myref);

  const plugins = [
    {
      afterDraw: function (chart) {
        if (chart.data.datasets[0].data.every((item) => item == "0")) {
          let ctx = chart.ctx;
          let width = chart.width;
          let height = chart.height;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "25px Cerebri sans";
          ctx.fillText("No data available", width / 2, height / 2);
          ctx.restore();
        }
      },
    },
  ];
  return (
    <>
      <div
        ref={myref}
        style={{
          borderRadius: "8px",
          height: "content-fit",
          backgroundColor: "#FFF",
          position: "relative",
        }}
        className="traffic-overview-card-header"
      >
        <div className="card-header">
          <div className="margin-heading-card header-text">
            <div>
              <div className="bold fw-700 fs-14">{t("traffic-overview")}</div>
            </div>
            <Tooltip text={t("traffic-overview")} paragraph="" />
          </div>
          <div>
            <DownloadWidget
              refObj={refObj}
              fileName={"traffic-overview"}
              type={true}
            />
          </div>
        </div>

        {data.datasets[0].data.every((item) => item != "0") ? (
          <div
            className="display-flex justify-content-betweeen"
            sx={{ paddingBottom: "30px" }}
          >
            <Grid container item spacing={6}>
              <Grid item xs={12} md={6} lg={3}>
                <CardContent sx={{ padding: 0 }}>
                  <Spacer mb={6} />
                  <ChartWrapper>
                    <DoughnutInner>
                      {data.datasets[0].data.every((item) => item != "0") && (
                        <div
                          style={{
                            width: "30%",
                            margin: "auto",
                            lineHeight: "22px",
                          }}
                          className="light fs-13 fw-300"
                        >
                          {t("users-type-of-device")}
                        </div>
                      )}
                    </DoughnutInner>
                    <Doughnut data={data} options={options} plugins={plugins} />
                  </ChartWrapper>
                </CardContent>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <TableContainer className="tablecontainer-padding-15px">
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <CustomTableCell>{t("source")}</CustomTableCell>
                        <CustomTableCell align="right">%</CustomTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, name) => (
                        <CustomTableRow
                          key={name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <CustomTableCell component="th" scope="row">
                            {row.status}
                          </CustomTableCell>
                          <CustomTableCell align="right">
                            {row.prcntvalue}
                          </CustomTableCell>
                        </CustomTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <TableContainer className="tablecontainer-padding-15px">
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <CustomTableCell>{t("visitor-stats")}</CustomTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {visitors.map((visitors, name) => (
                        <CustomTableRow
                          key={name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <CustomTableCell component="th" scope="row">
                            {visitors.status}
                          </CustomTableCell>
                          <CustomTableCell align="right">
                            {visitors.prcntvalue}
                          </CustomTableCell>
                        </CustomTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TableContainer sx={{ paddingRight: "24px" }}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <CustomTableCell>{t("benchmarks")}</CustomTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Benchmarks.map((row, name) => (
                        <CustomTableRow
                          key={name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <CustomTableCell component="th" scope="row">
                            {row.status}
                          </CustomTableCell>
                          <CustomTableCell align="right">
                            {row.prcntvalue}
                          </CustomTableCell>
                        </CustomTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        ) : (
          <NoDataScreen />
        )}
      </div>
    </>
  );
}

export default TrafficOverview;
