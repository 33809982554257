import React from "react";
import styled from "@emotion/styled";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import "./events.css";
import { useTranslation } from "react-i18next";

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: "Cerebri sans-light";
    font-weight: 300;
    font-size: 13px;
  }
  &.${tableCellClasses.body} {
    font-size: 13px;
    border-bottom: none;
    font-family: "Cerebri sans-light";
    white-space: nowrap;
    font-weight: 300;
    width: 100%;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
  }
`;

// Data
let id = 0;
function createData(participants, value) {
  id += 1;
  return { id, participants, value };
}

function MapTable({ markerIndex, myData }) {
  //i18n
  const { t, i8n } = useTranslation();
  const rows = [
    createData("Age", myData?.[markerIndex]?.Age),
    createData("Avg. age", myData?.[markerIndex]?.AvgAge),
    createData(
      "Owner-occupied residence",
      myData?.[markerIndex]?.ResidenceOwner
    ),
    createData("Rental residence", myData?.[markerIndex]?.ResidenceRenting),
    createData(
      "Other type of residence ",
      myData?.[markerIndex]?.ResidenceOther
    ),
    createData("Married", myData?.[markerIndex]?.Married),
    createData("Single", myData?.[markerIndex]?.Single),
    createData("Divorced", myData?.[markerIndex]?.Divorced),
    createData("Cohabiting", myData?.[markerIndex]?.Cohabiting),
    createData("Male", myData?.[markerIndex]?.Male),
    createData("Female", myData?.[markerIndex]?.Female),
    createData("Other gender", myData?.[markerIndex]?.OtherGender),
  ];
  // const rowsNext = markerIndex - 1;
  // console.log(markerIndex,"markerIndex")
  // console.log(myData?.[markerIndex]?.ZipCode, "ZipCode")
  return (
    <>
      <div className="table-section">
        <Table mb={6}>
          <TableHead>
            <div style={{ marginTop: "30px", marginBottom: "30px" }}>
              <TableRow className="sticky">
                <div className="medium fw-500 fs-14">
                  {myData?.[markerIndex]?.City}
                </div>
                <div className="light fw-300 fs-13">
                  {" "}
                  {myData?.[markerIndex]?.Participants} {t("participants")}
                </div>
              </TableRow>
            </div>
          </TableHead>

          <div className="table-data">
            <TableBody>
              {rows.map((row) => (
                <CustomTableRow key={row.id}>
                  <CustomTableCell component="th" scope="row">
                    {row.participants}
                  </CustomTableCell>
                  <CustomTableCell align="right">{row.value}</CustomTableCell>
                </CustomTableRow>
              ))}
            </TableBody>
          </div>
        </Table>
      </div>
    </>
  );
}

export default MapTable;
