import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DateField from "../components/DateField";
import "./events.css";
import { EventsAddParticipants } from "../../../API/Events/AddParticipants";
import { useEffect, useState } from "react";
import DropDown from "../components/DropDown";
import dayjs from "dayjs";
import { GetEventsPreferredUnits } from "../../../API/Events/EventParticipantPreferedUnit";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { GetCompletedEventsCountries } from "../../../API/Events/EventsCompletedEventCountries";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { REQUIRED_ERR } from "../../../utils/validationConstants";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

// Icons for checkbox dropdown
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const AddParticipant = ({
  PropertyName,
  handleClose,
  GetEventsCompletedByParticipants,
  eid,
  countryData,
  sys_oh_id,
}) => {
  //i18Next
  const { t, i18n } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      street: "",
      houseNo: "",
      floor: "",
      sideDoor: "",
      zipCode: "",
      city: "",
      country: "",
      email: "",
      phone: "",
      dateOfBirth: "",
      currentHousingType: "",
      civilType: "",
      gender: "",
      preferedUnit: "",
    },
  });
  // const ResidenceTypes = [
  //   {
  //     name: "Single-family houses",
  //     id: "Single-family houses",
  //   },
  //   {
  //     name: "Ownership apartments",
  //     id: "Ownership apartments",
  //   },
  //   {
  //     name: "Ideal shares",
  //     id: "Ideal shares",
  //   },
  //   {
  //     name: "Cooperative housing",
  //     id: "Cooperative housing",
  //   },
  //   {
  //     name: "Rental housing",
  //     id: "Rental housing",
  //   },
  // ];
  const ResidenceTypes = [
    {
      name: `${t("owner")}`,
      id: "Owner",
    },
    {
      name: `${t("renting")}`,
      id: "Renting",
    },
    {
      name: `${t("other")}`,
      id: "Other",
    },
  ];

  const CivilStatus = [
    {
      name: `${t("married")}`,
      id: "Married",
    },
    {
      name: `${t("single")}`,
      id: "Single",
    },
    {
      name: `${t("divorced")}`,
      id: "Divorced",
    },
    {
      name: `${t("cohabiting")}`,
      id: "Cohabiting",
    },
  ];

  const Genders = [
    {
      name: `${t("male")}`,
      id: "Male",
    },
    {
      name: `${t("female")}`,
      id: "Female",
    },
    {
      name: `${t("other")}`,
      id: "Other",
    },
  ];
  const HeardAbout = [
    {
      name: `${t("social-media")}`,
      id: "1",
    },
    {
      name: `${t("outdoor-marketing")}`,
      id: "2",
    },
    {
      name: `${t("website")}`,
      id: "3",
    },
    {
      name: `${t("newspaper")}`,
      id: "4",
    },
    {
      name: `${t("instagram")}`,
      id: "5",
    },
    {
      name: `${t("facebook")}`,
      id: "6",
    },
    {
      name: `${t("google-ads")}`,
      id: "7",
    },
    {
      name: `${t("newsletter")}`,
      id: "8",
    },
    {
      name: `${t("avis")}`,
      id: "9",
    },
    {
      name: `${t("youtube")}`,
      id: "10",
    },
  ];

  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [streetName, setstreetName] = useState("");
  const [houseNo, sethouseNo] = useState("");
  const [floor, setfloor] = useState("");
  const [sidedoor, setsidedoor] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");

  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [dateOfBirth, setdateOfBirth] = useState(null);
  const [residenceType, setresidenceType] = useState("");
  const [civilStatus, setcivilStatus] = useState("");
  const [gender, setgender] = useState("");
  const [bid, setbid] = useState("");
  const [heardAbout, setheardAbout] = useState([]);

  // Get request for Preffered Unit
  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);
  useEffect(() => {
    GetEventsByPreferredUnits();
  }, []);

  const GetEventsByPreferredUnits = () => {
    try {
      GetEventsPreferredUnits(eid)
        .then((data) => {
          if (data != null && data !== undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  //Post request for Add Participants
  const [posterror, setPostError] = useState([
    { error: false, status: "", message: "" },
  ]);
  const [postData, setPostData] = useState([]);

  const EventsByAddParticipants = (data) => {
    try {
      EventsAddParticipants(data)
        .then((response) => {
          setPostData(response);
          GetEventsCompletedByParticipants();
        })
        .catch((error) => {
          setPostError({ error: error.message, status: error.status });
        });
    } catch (e) {
      setPostError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  // console.log(heardAbout, "heardAbout");
  const handleAddParticipant = (data) => {
    console.log("data", data);

    // const myData = {
    //   b_id: bid.toString(),
    //   oh_id: sys_oh_id,
    //   e_id: eid,
    //   firstName: firstName,
    //   lastName: lastName,
    //   dateOfBirth: dayjs(dateOfBirth).format("YYYY-MM-DD"),
    //   gender: gender,
    //   civilStatus: civilStatus,
    //   residenceType: residenceType,
    //   email: email,
    //   street: streetName,
    //   house: houseNo,
    //   floor: floor,
    //   sidedoor: sidedoor,
    //   zip: zipcode,
    //   city: city,
    //   country: country,
    //   phone: phone,
    //   source: heardAbout.toString(),
    // };
    // EventsByAddParticipants(myData);
  };
  const handleHeardAbout = (event) => {
    let newArray = [...heardAbout, event.target.value];
    if (heardAbout.includes(event.target.value)) {
      newArray = newArray.filter((item) => item !== event.target.value);
    }
    setheardAbout(newArray);
  };

  return (
    <form onSubmit={handleSubmit(handleAddParticipant)}>
      <div className="edit-future-container scrollable-container">
        <div className="medium fs-14 edit-heading">Information</div>
        <div className="event-fst-row">
          <div>
            <Controller
              control={control}
              {...register("firstName")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="text"
                  label={t("first-name")}
                  className="Editinfo-text-field"
                  // value={firstName}
                  // onChange={(e) => setfirstName(e.target.value)}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("firstName");
                  }}
                  value={value}
                />
              )}
            />
            {errors?.firstName && (
              <p
                style={{
                  color: "red",
                }}
              >
                {REQUIRED_ERR}
              </p>
            )}
          </div>
          <div className="events-last-name">
            <Controller
              control={control}
              {...register("lastName")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="text"
                  label={t("last-name")}
                  className="Editinfo-text-field"
                  // value={lastName}
                  // onChange={(e) => setlastName(e.target.value)}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("lastName");
                  }}
                  value={value}
                />
              )}
            />
            {errors?.lastName && (
              <p
                style={{
                  color: "red",
                }}
              >
                {REQUIRED_ERR}
              </p>
            )}
          </div>
        </div>
        <div className="event-second-row">
          <div>
            <Controller
              control={control}
              {...register("street")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="text"
                  label={t("street-name")}
                  className="Editinfo-text-field"
                  // value={streetName}
                  // onChange={(e) => setstreetName(e.target.value)}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("street ");
                  }}
                  value={value}
                />
              )}
            />
            {errors?.street && (
              <p
                style={{
                  color: "red",
                }}
              >
                {REQUIRED_ERR}
              </p>
            )}
          </div>
          <div className="event-data-sub-section">
            {/* <div className="house-no" > */}

            <Controller
              control={control}
              {...register("houseNo")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="text"
                  label={t("house-no.")}
                  className="event-second-row-section"
                  // value={houseNo}
                  // onChange={(e) => sethouseNo(e.target.value)}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("houseNo");
                  }}
                  value={value}
                />
              )}
            />
            {errors?.houseNo && (
              <p
                style={{
                  color: "red",
                }}
              >
                {REQUIRED_ERR}
              </p>
            )}
          </div>
          <div>
            <Controller
              control={control}
              {...register("floor")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="text"
                  label={t("floor")}
                  className="regular fw-300 fs-14 event-second-row-section"
                  // value={floor}
                  // onChange={(e) => setfloor(e.target.value)}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("floor");
                  }}
                  value={value}
                />
              )}
            />
            {errors?.floor && (
              <p
                style={{
                  color: "red",
                }}
              >
                {REQUIRED_ERR}
              </p>
            )}
          </div>
          <div>
            <Controller
              control={control}
              {...register("sideDoor")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="text"
                  label={t("side/door")}
                  className="event-second-row-section"
                  // value={sidedoor}
                  // onChange={(e) => setsidedoor(e.target.value)}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("sideDoor");
                  }}
                  value={value}
                />
              )}
            />
            {errors?.sideDoor && (
              <p
                style={{
                  color: "red",
                }}
              >
                {REQUIRED_ERR}
              </p>
            )}
          </div>
        </div>
        <div className="event-third-row">
          <div>
            <Controller
              control={control}
              {...register("zipCode")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="text"
                  label={t("zipCode")}
                  className="Editinfo-text-field"
                  // value={zipcode}
                  // onChange={(e) => setzipcode(e.target.value)}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("zipCode");
                  }}
                  value={value}
                />
              )}
            />
            {errors?.zipCode && (
              <p
                style={{
                  color: "red",
                }}
              >
                {REQUIRED_ERR}
              </p>
            )}
          </div>
          <div className="events-last-name">
            <Controller
              control={control}
              {...register("city")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="text"
                  label={t("city")}
                  className="Editinfo-text-field"
                  // value={city}
                  // onChange={(e) => setcity(e.target.value)}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("city");
                  }}
                  value={value}
                />
              )}
            />
            {errors?.city && (
              <p
                style={{
                  color: "red",
                }}
              >
                {REQUIRED_ERR}
              </p>
            )}
          </div>
          <div className="events-last-name">
            <Controller
              name="country"
              {...register("country")}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DropDown
                  width={196}
                  data={countryData}
                  label={t("country")}
                  // onChange={(e) => setcountry(e.target.value)}
                  // value={country}
                  onChange={(e) => {
                    onChange(e);
                    // setEid(e.target.value);
                  }}
                  value={value}
                />
              )}
            />
            {errors?.country && (
              <p
                style={{
                  color: "red",
                }}
              >
                {REQUIRED_ERR}
              </p>
            )}
          </div>
        </div>
        <div className="event-third-row">
          <div>
            <Controller
              control={control}
              {...register("email")}
              // rules={{ required: true }}
              rules={{
                required: "This field is required.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="text"
                  label={t("e-mail")}
                  className="Editinfo-text-field"
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("email");
                  }}
                  value={value}
                />
              )}
            />
            {/* <p
              style={{
                color: "red",
                fontSize : '12px'
              }}
            > */}
            {errors.email && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginTop: "5px",
                  marginLeft: "10px",
                }}
              >
                {errors.email.message}
              </p>
            )}
            {/* </p> */}

            {/* {errors?.email && (
              <p
                style={{
                  color: "red",
                }}
              >
                {REQUIRED_ERR}
              </p>
            )} */}
          </div>
          <div className="events-last-name">
            <Controller
              control={control}
              {...register("phone")}
              // rules={{ required: true }}
              rules={{
                required: "This field is required.",
                pattern: {
                  value: /^[0-9]{10}$/, // Regular expression for a 10-digit phone number
                  message: "Invalid phone number",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="text"
                  label={t("phone")}
                  className="Editinfo-text-field"
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("phone");
                  }}
                  value={value}
                  // value={phone}
                  // onChange={(e) => setphone(e.target.value)}
                />
              )}
            />
            {errors?.phone && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginTop: "5px",
                  marginLeft: "10px",
                }}
              >
                {errors.phone.message}
              </p>
            )}
          </div>
        </div>
        <div className="event-fourth-row">
          <span className=" regular fw-300 fs-14 ">
            <Controller
              control={control}
              {...register("dateOfBirth")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                // <DateField
                //   label={t("date-of-birth")}
                //   value={dateOfBirth}
                //   onChange={(newValue) => setdateOfBirth(newValue)}
                // />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="D.MMM.YYYY"
                    label={t("date-of-birth")}
                    // value={value}
                    // onChange={onChange}
                    onChange={(e) => {
                      onChange(e);
                      // setEid(e.target.value);
                    }}
                    value={value}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              )}
            />
            {errors?.dateOfBirth && (
              <p
                style={{
                  color: "red",
                }}
              >
                {REQUIRED_ERR}
              </p>
            )}
          </span>
          <div>
            <Controller
              name="currentHousingType"
              {...register("currentHousingType")}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DropDown
                  width={196}
                  data={ResidenceTypes}
                  label={t("current-housing-type")}
                  // onChange={(e) => setresidenceType(e.target.value)}
                  // value={residenceType}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                />
              )}
            />
            {errors?.currentHousingType && (
              <p
                style={{
                  color: "red",
                }}
              >
                {REQUIRED_ERR}
              </p>
            )}
          </div>
          <div>
            <Controller
              name="civilType"
              {...register("civilType")}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DropDown
                  width={196}
                  data={CivilStatus}
                  label={t("civil-status")}
                  // onChange={(e) => setcivilStatus(e.target.value)}
                  // value={civilStatus}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                />
              )}
            />
            {errors?.civilType && (
              <p
                style={{
                  color: "red",
                }}
              >
                {REQUIRED_ERR}
              </p>
            )}
          </div>
          <div>
            <Controller
              name="gender"
              {...register("gender")}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DropDown
                  width={196}
                  data={Genders}
                  label={t("gender")}
                  // onChange={(e) => setgender(e.target.value)}
                  // value={gender}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                />
              )}
            />
            {errors?.gender && (
              <p
                style={{
                  color: "red",
                }}
              >
                {REQUIRED_ERR}
              </p>
            )}
          </div>
        </div>

        <div>
          <div className="preferred-unit">
            <div className="medium fs-14" style={{ marginBottom: "26px" }}>
              {t("preferred-unit")}
            </div>
            <div className="preferred-units">
              {PropertyName}&nbsp; ({bid.length})
            </div>
            <div>
              <Controller
                name="preferedUnit"
                {...register("preferedUnit")}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    // onChange={(event, newValue) =>
                    //   setbid(newValue.map((e) => e.SYS_B_ID))
                    // }
                    multiple
                    id="checkboxes-tags-demo"
                    options={myData}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    // value={value}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.Unit}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.Unit}
                      </li>
                    )}
                    style={{ width: 345 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("search")}
                        // placeholder="Favorites"
                      />
                    )}
                  />
                )}
              />
              {errors?.preferedUnit && (
                <p
                  style={{
                    color: "red",
                  }}
                >
                  {REQUIRED_ERR}
                </p>
              )}
            </div>
            <div className="heard-prop-checkbox-section">
              <div className="medium fs-14">
                {t("heard-about-the-property")}
              </div>
              <div className="heard-prop-checkboxes">
                {HeardAbout.map((item, i) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "165px",
                        }}
                        key={i}
                      >
                        <FormControlLabel
                          onChange={handleHeardAbout}
                          label={item.name}
                          value={item.id}
                          control={
                            <Checkbox
                              {...label}
                              sx={{
                                color: "#619130",
                                borderRadius: "8px",
                                "&.Mui-checked": {
                                  color: "#619130",
                                  verticalAlign: "middle",
                                  position: "relative",
                                  bottom: "1px",
                                },
                              }}
                            />
                          }
                        />

                        {/* <div className="light fw-300 fs-14">{item.name}</div> */}
                      </div>
                    </>
                  );
                })}
              </div>
              {/* <hr className="horizonal-line-event"/> */}
            </div>
          </div>
        </div>
      </div>
      <div className="add-to-event">
        <div className="create-event-btn-btn">
          <div
            onClick={handleClose}
            className="medium fs-14 btn-text-no uppercase"
          >
            {t("cancel")}
          </div>
        </div>
        <div className="event-btn-green">
          <button
            className="medium fs-14 btn-text-no uppercase"
            style={{ backgroundColor: "transparent", color: "#fff" }}
            type="submit"
            // onClick={() => {
            //   handleAddParticipant();
            //   handleClose();
            // }}
          >
            {t("add-to-event")}
          </button>
        </div>
      </div>
    </form>
  );
};
