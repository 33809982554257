import { Get } from "../../estateTool/dashboard/utils/Axios";
import { endpoint } from "../EndPoint";
import { GetHeader } from "../Header";

export const GetSigningsTable = (eid, user, status, completed, datefilter) => {
  eid = eid === "" || eid === null || eid === undefined ? 0 : eid;
  user = user === "" || user === null || user === undefined ? 0 : user;
  status =
    status === "" ||
    status === null ||
    status === undefined ||
    status.length === 0
      ? 0
      : status;
  completed =
    completed === "" ||
    completed === null ||
    completed === undefined ||
    completed.length === 0
      ? 0
      : completed;
  datefilter =
    datefilter === "" || datefilter === null || datefilter === undefined
      ? "2020-01-01/2023-01-31"
      : datefilter;
  return new Promise((resolve, reject) => {
    Get(
      endpoint.Signings_Table,
      GetHeader(),
      eid + "/" + user + "/" + completed + "/" + status + "/" + datefilter
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
