import { Get } from "../../estateTool/dashboard/utils/Axios";
import { endpoint } from "../EndPoint";
import { GetHeader } from "../Header";

export const GetPropertiesById = (id) => {
    id = id === "" || id === null || id === undefined ? 0 : id;
  

  return new Promise((resolve, reject) => {
    Get(endpoint.Common_GetPropertiesById, GetHeader(), id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};