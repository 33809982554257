import React, { useRef, useState, useEffect } from "react";
import "./overviews.css";

import { GetOverviewStatusActivity } from "../../../API/OverviewStatusActivity/OverviewStatusActivity";

import usePopup from "../hooks/usePopup";

import ActivityDropdown from "../components/Dropdowns/AcitivityDropdown/ActivityDropdown";
import Tooltip from "../components/Tooltip/Tooltip";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";
import Thumb from "../components/Thumb/Thumb";
import moment from "moment/moment";
import { ArrowUpDown } from "../components/ArrowUpDown/ArrowUpDown";
import NoDataScreen from "../components/NoDataScreen";
import { Button, Menu, MenuItem } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";

function StatusActivity({ sid, datefilter }) {
  //i18Next
  const { t, i18n } = useTranslation();

  const Activities = [
    { name: `${t("available")}`, id: "10" },
    { name: `${t("reserved")}`, id: "50" },
    { name: `${t("sold")}`, id: "80" },
    { name: `${t("leased")}`, id: "90" },
  ];

 

  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  const GetOverviewByStatusActivity = (eid, activity, datefilter) => {
    try {
      GetOverviewStatusActivity(sid, activity, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };
  const [allChecked, setAllChecked] = useState(false);
  const [optionChecked, setOptionChecked] = useState([]);

  const OnClicked = (e, isCheckAll) => {
    const { id, checked } = e.currentTarget;
    if (isCheckAll) {
      if (checked) {
        setOptionChecked(Activities.map((list) => list.id));
        setAllChecked(true);
      } else {
        setOptionChecked([]);
        setAllChecked(false);
      }
      // GetOverviewByStatusActivity(0);
    } else {
      if (checked) {
        const checkValue = [...optionChecked, id];
        setOptionChecked(checkValue);

        if (checkValue.length == Activities.length) {
          setAllChecked(true);
          // GetOverviewByStatusActivity(0);
        } else {
        }
      } else {
        const checkValue = optionChecked.filter((item) => item !== id);
        if (checkValue.length != 0) {
          setOptionChecked(checkValue);
          // GetOverviewByStatusActivity(sid, checkValue.toString());
          setAllChecked(false);
        } else {
          setOptionChecked([]);
          setAllChecked(false);
          // GetOverviewByStatusActivity(0);
        }
      }
    }
  };

  let activityFilter =
    optionChecked === null ||
    optionChecked === undefined ||
    optionChecked.length === 0
      ? 0
      : optionChecked;
  useEffect(() => {
    GetOverviewByStatusActivity(sid, activityFilter, datefilter);
  }, [sid, activityFilter, datefilter]);

  //Options for CSV data
  const options = {
    keys: [
      { field: "Username", title: "Name" },
      { field: "StatusId" },
      { field: "Status" },
      { field: "Navn", title: "Property Name" },
      { field: "Address" },
      { field: "Created", title: "Created At" },
    ],
  };

  const LabelActivity = ({
    color,
    status,
    created,
    name,
    username,
    address,
    isLike,
    sid,
    bid,
  }) => {
    let formattedTime = moment(created).format("ll | HH:mm");
    return (
      <>
        <div className="card-body">
          <div>
            <span>
              <span className="medium fw-500 fs-13 line-height-20">{name}</span>
              <br />

              <span className="light fw-300 fs-13 line-height-20">
                {address}
              </span>
            </span>
            <br />
            <span className="light fs-10 fw-300">
              {username} | {formattedTime}
            </span>
          </div>

          <div className="display-flex align-items-center">
            <div
              className="medium fs-12 fw-500 status-activity-label"
              style={{
                backgroundColor: color,
              }}
            >
              {status}
            </div>
            <Thumb sid={sid} bid={bid} isLike={isLike} />
          </div>
        </div>
      </>
    );
  };

  const [anchorEl, setAnchorEl] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [count, setCount] = useState(10);
  const [listState, setListState] = useState([]);

  useEffect(() => {
    setListState(myData.slice(0, count));
  }, [myData]);

  const fetchMoreData = async () => {
    setTimeout(async () => {
      let newArr = listState.concat(myData.slice(count, count + 10));
      setListState(newArr);
      setCount(count + 10);
    }, 100);
  };

  return (
    <>
      <div className=" statusactivity-card-header">
        <div className="display-flex justify-content-between">
          <div className="statusactivity-card-title">
            <div className="heading bold fs-14 fw-700">
              {t("status-activity")}
            </div>
            <Tooltip text={t("status-activity")} paragraph="" />
          </div>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <div className="allactivities cursor">
              <Button
                id="basic-button"
                aria-controls={anchorEl ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  "&:hover": { backgroundColor: "transparent" },
                  padding: "0px",
                  color: "black",
                }}
              >
                <div className="statusactivity-allactivities-dropdown">
                  <span className="light fw-300 fs-14 line-height-22">
                    {t("all-activities")}
                  </span>

                  <ArrowUpDown component={anchorEl} />
                </div>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={anchorEl}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.02)",
                  "& .MuiPaper-root": {
                    borderRadius: "8px",
                    width: "fit-content",
                    marginTop: "8px",
                  },
                }}
              >
                <MenuItem
                  sx={{
                    "&:hover": { backgroundColor: "transparent" },
                    borderRadius: "8px",
                    padding: "20px",
                    overflow: "hidden",
                    border: "1px solid #F4F5F5",
                  }}
                >
                  <div className="dropdown-container">
                    <ActivityDropdown
                      parentId="0"
                      allChecked={allChecked}
                      optionChecked={optionChecked}
                      OnClicked={OnClicked}
                      Activities={Activities}
                      Label={t("all-activities")}
                    />
                  </div>
                </MenuItem>
              </Menu>
            </div>
            <DownloadWidget
              options={options}
              data={myData}
              fileName={"overview-status-activity"}
              type={false}
            />
          </div>
        </div>
      </div>

      {myData.length != 0 ? (
        <div
          style={{ height: "373px" }}
          id="scrollableStatusDiv"
          className="scrollable-container"
        >
          <InfiniteScroll
            dataLength={listState.length}
            next={fetchMoreData}
            hasMore={true}
            scrollableTarget="scrollableStatusDiv"
          >
            {listState?.map((item, i) => {
              return (
                <>
                  <LabelActivity
                    key={i}
                    color={
                      item.Status == "Solgt"
                        ? "#F1664B"
                        : item.Status == "Reserveret"
                        ? "#F6E651"
                        : item.Status == "Ledig"
                        ? "#77C7A1"
                        : item.Status == "Udlejet"
                        ? "#A2C8D0"
                        : null
                    }
                    status={item.Status.toUpperCase()}
                    created={item.Created}
                    name={item.Navn + " " + item.SYS_ID}
                    username={item.Username}
                    address={item.Address}
                    isLike={item.IsLike}
                    sid={item.SYS_ID}
                    bid={item.B_ID}
                    index={i}
                  />
                </>
              );
            })}
          </InfiniteScroll>
        </div>
      ) : (
        <NoDataScreen height={"377px"} imgHeight={"150px"} />
      )}
    </>
  );
}

export default StatusActivity;
