import React from "react";
import moment from "moment/moment";

export const CustomDateFormat = ({ dateformat }) => {
  return (
    <>
      {new Date(dateformat).toLocaleDateString("en-En", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })}
    </>
  );
};
export const CustomTimeFormat = ({ timeformat }) => {
  moment(timeformat).format("ll | HH:mm");
  return <div></div>;
};
export const CustomCurrencyFormat = ({ currencyformat }) => {
  return <div>{new Intl.NumberFormat("en-DE").format(currencyformat)}</div>;
};
