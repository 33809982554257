import { Box, Grid, Switch } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import questionmark from "../Icons/questionmark.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TransactionAgeTable from "./TransactionAgeTable";
import "./customer.css";
import TransactionAgeChart from "./TransactionAgeChart";
import SwitchToggleChart from "./SwitchToggleChart";
import Tooltip from "../components/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";

const TransactionAge = ({ sid, datefilter }) => {
  //i18n
  const { t, i18n } = useTranslation();

  const [show, setShow] = useState(true);

  const handleToggle = () => {
    setShow(!show);
  };

  const myref = useRef();
  const [refObj] = useState(myref);

  return (
    <div ref={myref} className="breakdown-container">
      <div className="breakdown-sub-container">
        <div className="breakdown-heading">
          <div className="heading-age bold fw-700 fs-14">
            {t("transactions-divided-by-age")}
          </div>
          <Tooltip text={t("transactions-divided-by-age")} paragraph="" />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="data-events">
            <span className="customer-heading light fw-300 fs-14">
              {t("compare-with-data-from-events")}
            </span>
            <button
              onClick={handleToggle}
              style={{
                border: "none",
                backgroundColor: "#fff",
                marginRight: "16px",
                marginTop: "10px",
              }}
            >
              <Switch color="success" />
            </button>
          </div>
          <div>
            <DownloadWidget
              refObj={refObj}
              fileName={"customers-transactions-byage"}
              type={true}
            />
          </div>
        </div>
      </div>
      <Grid className="grid-container" container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <TransactionAgeTable sid={sid} datefilter={datefilter}/>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className="chart-section">
            {show ? (
              <TransactionAgeChart sid={sid} datefilter={datefilter} />
            ) : (
              <SwitchToggleChart sid={sid} datefilter={datefilter} />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default TransactionAge;
