import { useTranslation } from "react-i18next";
import questionmark from "../Icons/questionmark.svg";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  styled,
  tableCellClasses,
  TableHead,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { AddParticipant } from "./AddParticipant";
import { useEffect, useState } from "react";
import { Table, TableRow } from "semantic-ui-react";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TableBody,
  TableCell,
  Chip,
  Typography,
} from "@mui/material";
import { GetEventsCompletedParticipants } from "../../../API/Events/EventsCompletedParticipants";
import { EventsDeleteParticipant } from "../../../API/Events/EventsDeleteParticipant";
import { EditParticipant } from "./EditParticipant";
import { GetEventsParticipantsById } from "../../../API/Events/EventsParticipantsById";
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: "Cerebri sans-medium";
    font-size: 13px;
    font-weight: 500;
    border: none;
    line-height: 20px;
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family: "Cerebri sans-light";
    font-size: 13px;
    border: "none";
    width: 1010px;
    font-weight: 300;
    line-height: 20px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
  }
`;

//Edit & Delete Participant dropdown
const EditInfoEventDropDown = ({
  eid,
  PropertyName,
  handleClose,
  anchorEl,
  open,
  resid,
  ohid,
  GetEventsCompletedByParticipants,
  countryData,
}) => {
  //i18Next
  const { t, i18n } = useTranslation();

  const [EditInfo, setEditInfo] = useState(false);

  const handleEditClickOpen = () => {
    setEditInfo(true);
  };

  const handleEditClose = () => {
    setEditInfo(false);
  };

  const EventsDeleteByParticipant = () => {
    try {
      EventsDeleteParticipant(resid)
        .then((response) => {
          GetEventsCompletedByParticipants(ohid);
        })
        .catch((error) => {
          //   setError({ error: error.message, status: error.status });
        });
    } catch (error) {
      //   setError({ error: true, status: "", message: "Failed to retrieve data" });
    }
  };
  const handleDeleteParticipant = () => {
    handleClose();
    EventsDeleteByParticipant();
  };

  // Get request for ParticipantById
  const [participant, setparticipant] = useState([]);
  const [participanterror, setparticipanterror] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetByEventsParticipantsById();
  }, [resid]);
  const GetByEventsParticipantsById = () => {
    try {
      GetEventsParticipantsById(resid)
        .then((data) => {
          if (data != null && data !== undefined) {
            setparticipant(data.data);
          } else {
            setparticipant([null]);
          }
        })
        .catch((error) => {
          setparticipanterror({ error: error.message, status: error.status });
          setparticipant([null]);
        });
    } catch (e) {
      setparticipant([null]);
      setparticipanterror({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  return (
    <Menu
      id="simple-menu"
      align="center"
      anchorEl={anchorEl}
      keepMounted
      onClose={handleClose}
      open={open}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem onClick={handleEditClickOpen}>
        <div className="info-drop">{t("edit")}</div>
      </MenuItem>

      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              overflow: "hidden",
            },
          },
        }}
        open={EditInfo}
        onClose={handleEditClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="responsive-dialog-title"
      >
        {/* countryData.map((e) => e.Id) */}
        {participant.map((item) => (
          <EditParticipant
            countryData={countryData}
            Participant={item}
            sysid={resid}
            PropertyName={PropertyName}
            eid={eid}
            GetEventsCompletedByParticipants={GetEventsCompletedByParticipants}
            handleClose={handleEditClose}
          />
        ))}
        {/* </>
          );
        })} */}
      </Dialog>

      <MenuItem>
        <div onClick={handleDeleteParticipant} className="info-drop">
          {t("delete")}
        </div>
      </MenuItem>
    </Menu>
  );
};

const EventParticipantList = ({
  eid,
  PropertyName,
  Name,
  Email,
  Mobile,
  AssignedUser,
  resid,
  ohid,
  GetEventsCompletedByParticipants,
  countryData,
}) => {
  //i18Next
  const { t, i18n } = useTranslation();
  
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableBody>
        <CustomTableRow style={{ textAlign: "left" }}>
          <CustomTableCell width="25%" align="left">
            <div
              style={{ textAlign: "left", paddingLeft: "18px" }}
              className="events-name medium fs-13"
            >
              {Name}
            </div>
          </CustomTableCell>

          <CustomTableCell width="35%" align="left" sx={{ paddingLeft: "5px" }}>
            <div className="email-event">
              <div style={{ textAlign: "left" }} className="light fs-13">
                {Email}
              </div>
              <div style={{ textAlign: "left" }} className="light fs-14">
                {Mobile}
              </div>
            </div>
          </CustomTableCell>
          <CustomTableCell width="30%" align="left">
            <div className="participated-value">
              <div className="assign-to light fs-14">{AssignedUser}</div>
            </div>
          </CustomTableCell>

          <CustomTableCell
            width="10%"
            align="right"
            style={{
              position: "relative",
              paddingBottom: "25px",
              paddingTop: "10px",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: "40px",
                top: "9px",
              }}
            >
              {/* <div className="MoreVertIcon-container"> */}
              <span className="MoreVertIcon">
                <div>
                  <div
                    className="vertical-ellipsis
                      "
                    style={{
                      transform: `rotate(${anchorEl ? "90" : "0"}deg)`,
                    }}
                  >
                    <IconButton
                      //   aria-label="more"
                      onClick={handleClick}
                      aria-haspopup="true"
                      aria-controls="long-menu"
                      sx={{
                        ml: 1,
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "transparent",
                        },
                      }}
                    >
                      <span className="moreverticons-span">
                        <MoreVertIcon style={{ fill: "#619130" }} />
                      </span>
                    </IconButton>
                  </div>

                  <div>
                    <EditInfoEventDropDown
                      countryData={countryData}
                      PropertyName={PropertyName}
                      eid={eid}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      open={open}
                      resid={resid}
                      ohid={ohid}
                      GetEventsCompletedByParticipants={
                        GetEventsCompletedByParticipants
                      }
                    />
                  </div>
                </div>
              </span>
              {/* </div> */}
            </div>
          </CustomTableCell>
        </CustomTableRow>
        <br />
      </TableBody>
    </>
  );
};

export const AddEventsInformation = ({
  eid,
  handleClose,
  PropertyName,
  EventDate,
  EventTime,
  Registered,
  Participated,
  Reserved,
  sys_oh_id,
  countryData,
}) => {
  //i18n
  const { t, i18n } = useTranslation();
  const PropertyData = ({ status, number }) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <div>
            <div className="regular fw-300 fs-13">{status}</div>
            <div className="aboutnumberstwo semi-bold">{number}</div>
          </div>
          <div
            style={{
              borderLeft: "1px solid #000",
              height: "25px",
              margin: "20px 60px 0",
            }}
          ></div>
        </div>
      </>
    );
  };

  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetEventsCompletedByParticipants();
  }, [sys_oh_id]);

  const GetEventsCompletedByParticipants = () => {
    try {
      GetEventsCompletedParticipants(sys_oh_id)
        .then((data) => {
          if (data != null && data !== undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  //open modal for Add participant
  const [EditInfo, setEditInfo] = useState(false);

  const handleEditClickOpen = () => {
    setEditInfo(true);
  };

  const handleEditClose = () => {
    setEditInfo(false);
  };
  return (
    <div
      style={{
        backgroundColor: "#fff",
        width: "1018px",
        margin: " 20px auto",
        height: "560px",
        borderRadius: "8px",
      }}
    >
      <div className="new-events-subcontainer">
        <div className="new-events-header">
          <div>
            <div className="bold fs-14">{t("add-information-about-event")}</div>
          </div>
          <div>
            <div className="new-events-que-mrk">
              <img height={12} src={questionmark} alt="questionmark" />
            </div>
          </div>
        </div>
        <div className="new-events-moreovericon" onClick={handleClose}>
          <a>
            <span
              style={{
                position: "relative",
                top: "10px",
                left: "10px",
              }}
            >
              <CloseIcon style={{ fill: "#000000", margin: "1px 1px" }} />
            </span>
          </a>
        </div>
      </div>
      <hr style={{ border: "1px solid #F4F5F5" }} />

      <div
        style={{
          backgroundColor: "#FFF",
          height: "132px",
          boxSizing: "border-box",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "38px 32px",
            paddingLeft: "20px",
          }}
        >
          <div
            className=" regular fs-14 fw-700 "
            style={{ marginRight: "13px" }}
          >
            <span className="medium fs-16">{PropertyName}</span>
            <br />
            <span className="light fs-12">
              {new Date(EventDate).toLocaleDateString("en-En", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}{" "}
              | {EventTime}
            </span>
          </div>

          {/* <div style={{ marginTop: "4px" }}>
            <img height={12} src={questionmark} alt="questionmark" />
          </div> */}
        </div>
        <div style={{ display: "flex", padding: "24px", alignItems: "center" }}>
          <div className="regular fw-300 fs-13">
            <PropertyData status={t("registered")} number={Registered} />
          </div>
          <div className="aboutnumbersone"></div>

          <div className="regular fw-300 fs-13">
            <PropertyData status={t("participated")} number={Participated} />
          </div>
          <div className="aboutnumberstwo"></div>

          <div>
            <div className="fs-13">{t("no-of-units-reserved")}</div>
            <div className="aboutnumbers semi-bold">{Reserved}</div>
          </div>
        </div>

        <div>
          <div>
            {/* <div
                  className="regular fs-18 fw-700"
                  style={{ display: "flex" }}
                >
                  <div
                    className="regular fw-500 fs-11"
                    style={{
                      margin: " 0 0 10px 7px ",
                    }}
                  ></div>
                </div> */}
          </div>

          <div
            onClick={handleEditClickOpen}
            className="regular fw-300 fs-13"
            style={{
              width: "172px",
              height: "44px",
              backgroundColor: "#619130",
              color: "#FFFF",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
              cursor: "pointer",
            }}
          >
            <span>
              &nbsp;
              <AddIcon />
            </span>
            <span className="medium fs-13 uppercase">
              &nbsp;{t("add")} {t("participant")}
            </span>
          </div>

          <Dialog
            BackdropProps={{
              style: { backgroundColor: "rgba(0, 0, 0, 0.08)" },
            }}
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  overflow: "hidden",
                },
              },
            }}
            open={EditInfo}
            onClose={handleEditClose}
            fullWidth
            maxWidth="lg"
            aria-labelledby="responsive-dialog-title"
          >
            <AddParticipant
              countryData={countryData}
              eid={eid}
              PropertyName={PropertyName}
              sys_oh_id={sys_oh_id}
              GetEventsCompletedByParticipants={
                GetEventsCompletedByParticipants
              }
              handleClose={handleEditClose}
            />
          </Dialog>
        </div>
      </div>
      <hr style={{ border: "1px solid #F4F5F5" }} />
      <Table width="100%">
        <TableHead>
          <TableRow>
            {/* <div className="info-even-head"> */}
            <CustomTableCell
              width="30%"
              align="left"
              sx={{ "padding-left": "60px" }}
            >
              <div className="medium fs-13">{t("Name")}</div>
            </CustomTableCell>
            <CustomTableCell width="30%" align="left">
              <div style={{ padding: "0px" }} className="medium fs-13">
                {t("Contact")}
              </div>
            </CustomTableCell>
            <CustomTableCell width="30%" align="left">
              <div className="medium fs-13">{t("Assign to")}</div>
            </CustomTableCell>
            <CustomTableCell width="10%"></CustomTableCell>
            {/* </div> */}
          </TableRow>
        </TableHead>
      </Table>

      <div
        className="scrollable-container"
        style={{
          height: "303px",
          "padding-left": "30px",
          "padding-right": "20px",
        }}
      >
        {myData.map((item) => (
          <EventParticipantList
            countryData={countryData}
            PropertyName={PropertyName}
            eid={eid}
            Name={item.Name}
            Email={item.Email}
            Mobile={item.Mobile}
            AssignedUser={item.AssignedUser}
            resid={item.sys_res_id}
            ohid={item.OH_ID}
            GetEventsCompletedByParticipants={GetEventsCompletedByParticipants}
          />
        ))}
      </div>
    </div>
  );
};
