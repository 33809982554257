import React, { useEffect, useRef } from "react";
import "./Milestonepopup.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Dialog, IconButton, Menu, Switch } from "@mui/material";
import usePopup from "../../hooks/usePopup";
import { MenuItem } from "semantic-ui-react";
import { CustomDateFormat } from "../../components/CustomFormatting";
import { DeleteMilestone } from "../../../../API/OverView/DeleteMilestone";
import AutomaticMileStone from "./AutomaticMileStonePopup";
import { useState } from "react";
import EditAutomaticMilestone from "./EditAutomaticMilestone";
import EditManualMilestonePopup from "./EditManualMileStonePopup";
import { PutSetActiveMilestone } from "../../../../API/OverView/SetActiveMilestone";
import { GetMilestoneDetailsById } from "../../../../API/Common/CommonGetMilestoneDetailsById";
import { useTranslation } from "react-i18next";


export const MilestoneCard = ({
  Name,
  Type,
  Target,
  Status,
  Parameter,
  Color,
  Visibility,
  UseAs,
  CreatedOn,
  CreatedBy,
  IsActive,
  sysID,
  GetByMilestones,
  item,
  Users,
}) => {
  //i18Next
  const { t, i18n } = useTranslation();

  // Edit Milestone popup functions
  const [openEdit, setOpenEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
    setOpenEdit(false);
  };

  const handleEditClickOpen = () => {
    // handleClose();
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const [user, setUser] = useState(null);

  //  const open = Boolean(anchorEl);

  const handleClickUser = (event) => {
    setUser(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setUser(null);
  };
  // API consumption for Delete Milestone
  const DeleteByMilestone = () => {
    try {
      DeleteMilestone(sysID)
        .then((response) => {})
        .catch((error) => {
          //   setError({ error: error.message, status: error.status });
        });
    } catch (error) {
      //   setError({ error: true, status: "", message: "Failed to retrieve data" });
    }
  };
  const handleDeleteMilestone = () => {
    DeleteByMilestone(sysID);
    setTimeout(() => {
      GetByMilestones();
    }, 1000);
  };

  // API consumption for switch

  const [Switchcase, setSwitchcase] = useState(IsActive === 1 ? true : false);

  const [errorUpdateSwitch, setErrorUpdateSwitch] = useState([
    { error: false, status: "", message: "" },
  ]);
  const MilestoneSwitechUpdate = (data) => {
    try {
      PutSetActiveMilestone(data)
        .then((response) => {
          setSwitchcase((prev) => !prev);
        })
        .catch((error) => {
          setErrorUpdateSwitch({
            error: error.message,
            status: error.status,
          });
        });
    } catch (e) {
      setErrorUpdateSwitch({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const handleSwitch = (event) => {
    let data = {
      SYS_ID: sysID,
      IsActive: event.target.checked === true ? 1 : 0,
    };

    MilestoneSwitechUpdate(data);
  };

  // API consumption for get Milestones details by Id
  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetMilestonesDetails();
  }, []);

  const GetMilestonesDetails = () => {
    try {
      GetMilestoneDetailsById(sysID)
        .then((data) => {
          if (data != null && data !== undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };
  return (
    <>
      <div className="created-milestone-container">
        <div className="created-milestone-header">
          <div className="milestone-header-section">
            <div className="regular fw-600 fs-18">{Name}</div>
          </div>
          <div>
            <div
              style={{
                transform: `rotate(${anchorEl ? "90" : "0"}deg)`,
              }}
              className="milestone-vertical-ellipsis"
            >
              <IconButton
                onClick={handleClick}
                aria-haspopup="true"
                aria-controls="long-menu"
                sx={{
                  ml: 1,
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "transparent",
                  },
                }}
              >
                <span>
                  <MoreVertIcon
                    style={{
                      fill: "#619130",
                      position: "relative",
                      bottom: "7px",
                      right: "12px",
                    }}
                  />
                </span>
              </IconButton>
            </div>

            <Menu
              id="simple-menu"
              align="center"
              anchorEl={anchorEl}
              keepMounted
              onClose={handleClose}
              open={open}
              sx={{ borderRadius: "8px" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div
                onClick={() => handleEditClickOpen(sysID)}
                onClose={handleClose}
                className="regular fw-300 fs-14 
                milestone-resolution-popup"
              >
                {t("edit")}
              </div>

              <div
                onClose={handleClose}
                onClick={handleDeleteMilestone}
                className="regular fw-300 fs-14 milestone-resolution-popup"
              >
                {t("delete")}
              </div>
            </Menu>
          </div>
        </div>
        <div className="active-section">
          <div>
            <Switch
              checked={Switchcase}
              value={Switchcase}
              onChange={handleSwitch}
              color="success"
              size="small"
            />
          </div>
          <div className="regular fw-300 fs-12">{t("active")}</div>
        </div>
        <div className="milestone-type">
          <div className="type regular fw-300 fs-14">{t("type")}</div>
          <div className="regular fw-400 fs-14">{Type}</div>
        </div>
        <div className="milestone-target">
          <div className="type regular fw-300 fs-14">{t("target-date")}</div>
          <div className="regular fw-400 fs-14">
            {Target === "Ongoing Target"
              ? "Ongoing Target"
              : // <CustomDateFormat dateformat={Target} />
                item.Target}
          </div>
        </div>
        <div className="milestone-status">
          <div className="type regular fw-300 fs-14">{t("status")}</div>
          <div className="regular fw-400 fs-14">{Status}</div>
        </div>
        <div className="milestone-parameter">
          <div className="type regular fw-300 fs-14">{t("parameter")}</div>
          <div className="regular fw-400 fs-14">{Parameter}</div>
        </div>
        <div className="milestone-color">
          <div className="type regular fw-300 fs-14">{t("color")}</div>
          <div className="color-section">
            <span className="color" style={{ background: Color }}></span>
            <div className="regular fw-400 fs-14">
              {Color === "#B23977"
                ? "Pink"
                : Color === "#000000"
                ? "Black"
                : Color === "#A2C8D0"
                ? "Blue"
                : Color === "#AAC992"
                ? "Green"
                : ""}
            </div>
          </div>
        </div>
        <div className="milestone-visibility">
          <div className="type regular fw-300 fs-14">{t("visibility")}</div>
          <div className="shared-section">
            <div className="regular fw-400 fs-14">{Visibility}&nbsp;</div>

            {Users !== "" && (
              <>
                <IconButton
                  onClick={handleClickUser}
                  aria-haspopup="true"
                  aria-controls="long-menu"
                  sx={{
                    padding: "0",
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "transparent",
                    },
                  }}
                >
                  (
                  <span className="show-more-mile regular fw-300 fs-14">
                    {t("show-more")}
                  </span>
                  )
                </IconButton>
                <Menu
                  id="simple-menu"
                  align="center"
                  anchorEl={user}
                  keepMounted
                  onClose={handleCloseDropdown}
                  open={user}
                  sx={{
                    "& .MuiPaper-root": {
                      padding: "8px 12px",
                      border: "1px solid #f4f5f5",
                      borderRadius: "8px",
                    },
                    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.05)",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div
                    className="regular fs-12"
                    style={{ lineHeight: "25px", float: "left" }}
                  >
                    {t("shared-with")}
                  </div>
                  <MenuItem align="left">
                    {Users.split(",").map((step) => (
                      <div
                        className="light fs-12"
                        style={{
                          width: "max-content",
                          lineHeight: "20px",
                        }}
                      >
                        {step}
                        {"\n"}
                      </div>
                    ))}
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>
        </div>
        <div className="milestone-use-as">
          <div className="type regular fw-300 fs-14">{t("use-as")}</div>
          <div className="regular fw-400 fs-14">{UseAs}</div>
        </div>

        <div className="regular fw-300 fs-12 createdby-date-name">
          {t("created")} <CustomDateFormat dateformat={CreatedOn} /> {t("by")}{" "}
          {CreatedBy}
        </div>
      </div>
      <Dialog
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.08)" },
        }}
        fullWidth
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              overflow: "hidden",
            },
          },
        }}
        maxWidth="lg"
        open={openEdit}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {Type === "Automatic" ? (
          <EditAutomaticMilestone
            handleClose={handleClose}
            GetByMilestones={GetByMilestones}
            milestoneDetailsData={myData}
            item={item}
          />
        ) : (
          <EditManualMilestonePopup
            GetByMilestones={GetByMilestones}
            milestoneDetailsData={myData}
            handleClose={handleClose}
            item={item}
          />
        )}
      </Dialog>
    </>
  );
};
