import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationsDE from "../Languages/de/translation.json";
import translationsEN from "../Languages/en/translation.json";


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: translationsEN,
      },
      de: {
        translations: translationsDE,
      },
    },

    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: '-', // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
