import { Get } from "../../estateTool/dashboard/utils/Axios";
import { endpoint } from "../EndPoint";
import { GetHeader } from "../Header";

export const GetTrafficFunnels = (eid, type, datefilter) => {
  eid = eid === "" || eid === null || eid === undefined ? 0 : eid;
  type = type === "" || type === null || type === undefined ? 0 : type;
  datefilter =
    datefilter === "" || datefilter === null || datefilter === undefined
      ? "2020-01-01/2023-01-31"
      : datefilter;

  return new Promise((resolve, reject) => {
    Get(
      endpoint.Traffic_Get_Funnels,
      GetHeader(),
      eid + "/" + type + "/" + datefilter
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
