import React, { useState } from "react";
import Tooltip from "../components/Tooltip/Tooltip";
import { t } from "i18next";
import styled from "@emotion/styled";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataScreen from "../components/NoDataScreen";

import {
  Card,
  IconButton,
  Menu,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import Popup from "reactjs-popup";
import { GetEconomyUpcomingIdleness } from "../../../API/EconomyIdleness/EconomyIdleness";
import { useEffect } from "react";
import moment from "moment";

// Table Styled Component

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: Cerebri sans-medium;
    font-weight: 500;
    font-size: 13px;
    white-space: nowrap;
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family: Cerebri sans-light;
    font-weight: 300;
    font-size: 13px;
    white-space: nowrap;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
  }
`;

// DummyData

const upcomingData = [
  {
    address: "Fuglebakkevej 88C 2.tv",
    unitno: "8-W210 - 1",
    Property: "Fuglebakkevej 88",
    room: "3",
    sqmtr: "65",
    rent: "1.175",
    yearly: "76.375",
    until: "1",
  },
  {
    address: "Fuglebakkevej 88C 2.tv",
    unitno: "8-W210 - 1",
    Property: "Fuglebakkevej 88",
    room: "3",
    sqmtr: "110",
    rent: "1.195",
    yearly: "76.175",
    until: "29",
  },
  {
    address: "Fuglebakkevej 88C 2.tv",
    unitno: "8-W210 - 1",
    Property: "Fuglebakkevej 88",
    room: "2",
    sqmtr: "99",
    rent: "1.122",
    yearly: "76.445",
    until: "79",
  },
  {
    address: "Fuglebakkevej 88C 2.tv",
    unitno: "8-W210 - 1",
    Property: "Fuglebakkevej 88",
    room: "7",
    sqmtr: "61",
    rent: "1.1112",
    yearly: "76.115",
    until: "100",
  },
  {
    address: "Fuglebakkevej 88C 2.tv",
    unitno: "8-W210 - 1",
    Property: "Fuglebakkevej 88",
    room: "3",
    sqmtr: "65",
    rent: "1.175",
    yearly: "76.475",
    until: "2",
  },
];

const UpcomingIdleness = ({ sid }) => {
  const [user, setUser] = useState(null);
  const [economyUpcomingIdleness, setEconomyUpcomingIdleness] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetUpcomingByIdleness(sid);
  }, [sid]);

  const [count, setCount] = useState(10);
  const [listState, setListState] = useState([]);

  useEffect(() => {
    setListState(economyUpcomingIdleness.slice(0, count));
  }, [economyUpcomingIdleness]);

  const fetchMoreData = async () => {
    setTimeout(async () => {
      let newArr = listState.concat(
        economyUpcomingIdleness.slice(count, count + 10)
      );
      setListState(newArr);
      setCount(count + 10);
    }, 100);
  };

  // Economy upcoming idleness API call
  const GetUpcomingByIdleness = (sid) => {
    try {
      GetEconomyUpcomingIdleness(sid)
        .then((data) => {
          if (data != null && data != undefined) {
            setEconomyUpcomingIdleness(data.data);
          } else {
            setEconomyUpcomingIdleness([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setEconomyUpcomingIdleness([null]);
        });
    } catch (e) {
      setEconomyUpcomingIdleness([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  //  const open = Boolean(anchorEl);
  const handleClickUser = (event) => {
    setUser(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setUser(null);
  };

  return (
    <div
      style={{
        borderRadius: "8px",
        height: "content-fit",
        backgroundColor: "#FFF",
        position: "relative",
        margin: "25px 0",
      }}
      className="analysis-card-header"
    >
      <div className="display-flex justify-content-between">
        <div className="margin-heading-card analysis-card-title">
          <div className="heading bold fs-14 fw-700">
            {t("Upcoming idleness")}
          </div>

          <Tooltip text={t("Upcoming idleness")} paragraph="" />
        </div>
      </div>
      <Paper className="card-container scrollable-container">
        <TableContainer
          sx={{ maxHeight: 445 }}
          className="scrollable-container"
        >
          {economyUpcomingIdleness.length != 0 ? (
            <div
              id="scrollableActionDiv"
              className="scrollable-container"
              style={{ height: "373px" }}
            >
              <InfiniteScroll
                dataLength={listState.length}
                next={fetchMoreData}
                hasMore={true}
                scrollableTarget="scrollableActionDiv"
              ></InfiniteScroll>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ borderSpacing: "0 8px" }}
              >
                <TableHead>
                  <TableRow>
                    <CustomTableCell width="18%">
                      {t("Address")}
                    </CustomTableCell>
                    <CustomTableCell
                      align="right"
                      width="13%"
                      sx={{ paddingRight: "50px" }}
                    >
                      {t("unit-no")}
                    </CustomTableCell>
                    <CustomTableCell width="13%">
                      {t("property")}
                    </CustomTableCell>
                    <CustomTableCell align="center" width="8%">
                      {t("room")}
                    </CustomTableCell>
                    <CustomTableCell align="center" width="7%">
                      {t("Sq. m")}
                    </CustomTableCell>
                    <CustomTableCell align="right" width="9%">
                      {t("Rent per")} sq. m
                    </CustomTableCell>
                    <CustomTableCell align="center" width="15%">
                      {t("Yearly rent")}
                    </CustomTableCell>
                    <CustomTableCell align="center" width="17%">
                      {t("Days until idleness")}
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listState
                    ?.sort((a, b) => a.DaysUntilIdle - b.DaysUntilIdle)
                    ?.map((data, i) => (
                      <CustomTableRow
                        key={i}
                        sx={{
                          backgroundColor:
                            data.DaysUntilIdle < 30 ? "#fdede9 !important" : "",
                          margin: "10px",
                        }}
                      >
                        {console.log(data.DaysUntilIdle, "render")}
                        <CustomTableCell width="18%">
                          {data.Address}
                        </CustomTableCell>
                        <CustomTableCell
                          width="10%"
                          align="right"
                          sx={{ paddingRight: "50px" }}
                        >
                          {data.UnitNo}
                        </CustomTableCell>
                        <CustomTableCell width="13%">
                          {data.Property}
                        </CustomTableCell>
                        <CustomTableCell width="8%" align="center">
                          {data.Rooms}
                        </CustomTableCell>
                        <CustomTableCell width="7%" align="center">
                          {data.Sqm} m<sup>2</sup>
                        </CustomTableCell>
                        <CustomTableCell width="12%" align="right">
                          {data.RentPerSqmYearly} DKK
                        </CustomTableCell>
                        <CustomTableCell width="15%" align="center">
                          {data.RentYearly} DKK
                          {/* {new Intl.NumberFormat("en-DE").format(
                            (data.RentYearly)
                          )} */}
                        </CustomTableCell>
                        <CustomTableCell width="17%" align="center">
                          <span>
                            <div className="light fw-300 fs-13 line-height-20">
                              {data.DaysUntilIdle} days&nbsp;(
                              <span
                                style={{ color: "#619130", cursor: "pointer" }}
                                onClick={handleClickUser}
                                className="light fw-300 fs-12 show-more-link"
                              >
                                {t("show-more")}
                              </span>
                              )
                            </div>

                            <Menu
                              id="basic-menu"
                              anchorEl={user}
                              open={user}
                              onClose={() => setUser(null)}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              sx={{
                                "& .MuiPaper-root": {
                                  borderRadius: "8px",
                                  width: "fit-content",
                                  marginTop: "8px",
                                  marginLeft: "-105px",
                                },
                              }}
                            >
                              <MenuItem
                                sx={{
                                  "&:hover": { backgroundColor: "transparent" },
                                  borderRadius: "8px",
                                  padding: "0px",
                                  overflow: "hidden",
                                  border: "1px solid #F4F5F5",
                                }}
                              >
                                <div className="showmore-card">
                                  <div className="untilidleness-container">
                                    <div>
                                      <span className="medium">
                                        Start of rental
                                      </span>
                                      :
                                      {data.StartOfRentalDate !== ""
                                        ? moment(data.StartOfRentalDate).format(
                                            " MMMM D, YYYY"
                                          )
                                        : ""}
                                    </div>
                                    <div>
                                      <span className="medium">
                                        Termination date
                                      </span>
                                      :
                                      {data.TerminationDate !== ""
                                        ? moment(data.TerminationDate).format(
                                            " MMMM D, YYYY"
                                          )
                                        : ""}
                                    </div>
                                    <div>
                                      <span className="medium">
                                        Move-out date
                                      </span>
                                      :
                                      {data.MoveOutDate !== ""
                                        ? moment(data.MoveOutDate).format(
                                            " MMMM D, YYYY"
                                          )
                                        : ""}
                                    </div>
                                    <div>
                                      <span className="medium">
                                        Idleness date
                                      </span>
                                      :
                                      {data.IdleDate !== ""
                                        ? moment(data.IdleDate).format(
                                            " MMMM D, YYYY"
                                          )
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                              </MenuItem>
                            </Menu>
                          </span>
                        </CustomTableCell>
                      </CustomTableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <NoDataScreen />
          )}
        </TableContainer>
      </Paper>
    </div>
  );
};

export default UpcomingIdleness;
