import React from "react";
import { useState } from "react";
import { PutOverviewUpdateLikeStatus } from "../../../../API/OverviewUpdateLikeStatus/OverviewUpdateLikeStatus";
import Liked from "../../Icons/seen.svg";
import UnLiked from "../../Icons/unseen.svg";

const Thumb = ({ sid, bid, isLike }) => {
  const [like, setLike] = useState(isLike);

  //API Consumption
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);
  const OverviewUpdateLikeStatus = (data) => {
    try {
      PutOverviewUpdateLikeStatus(data)
        .then((response) => {
          console.log(response, "put");
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
        });
    } catch (e) {
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const handleClick = (sid, bid, like) => {
    let isLikeFlag = like === "0" ? "1" : "0";
    OverviewUpdateLikeStatus({
      Status_SYS_ID: sid,
      B_ID: bid,
      IsLike: isLikeFlag,
    });

    setLike(isLikeFlag);
  };

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <img
          height={13}
          src={like == "1" ? Liked : UnLiked}
          alt="thumb"
          onClick={() => handleClick(sid, bid, like)}
        />
      </div>
    </>
  );
};

export default Thumb;
