import { Get } from "../../estateTool/dashboard/utils/Axios";
import { endpoint } from "../EndPoint";
import { GetHeader } from "../Header";

export const GetOverviewNotesCategoryByEID = (eid) => {
  // eid = eid === "" || eid === null ? 0 : eid;
  return new Promise((resolve, reject) => {
    Get(endpoint.Overview_Notes_Category_By_EID, GetHeader())
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
