import React, { useState, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./signings.css";
import Popup from "reactjs-popup";
import SigningOrder from "./SigningOrder";
import { GetSigningsTable } from "../../../API/SigningsTable/SigningsTable";
import CancelOrderPopup from "./CancelOrderPopup";
import EditSigninPopup from "./EditSigninPopup";
import { Button, Dialog, IconButton, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import SigningDetail from "./SigningDetail";
import { GetuserbyProperty } from "../../../API/Common/CommonUserbyProperty";
import ActivityDropdown from "../components/Dropdowns/AcitivityDropdown/ActivityDropdown";
import { Grommet, CheckBox } from "grommet";
import { ArrowUpDown } from "../components/ArrowUpDown/ArrowUpDown";
import { Sorting } from "../components/Sorting/Sorting";
import NoDataScreen from "../components/NoDataScreen";
import InfiniteScroll from "react-infinite-scroll-component";
const theme = {
  fontFamily: "Cerebri Sans",
  checkBox: {
    border: {
      width: "1px",
      color: "#F4F5F5",
      radius: "4px",
    },

    check: {
      extend: ({ checked }) => `
        ${checked && `background-color: #619130;`}
        ${checked && `stroke: white`}`,
    },

    color: "white",
    extend: {
      marginRight: "8px",
    },

    icon: {
      size: "16px",
    },
    size: "16px",
  },
};

const SigningsTable = ({ sid, datefilter }) => {
  //i18n
  const { t, i18n } = useTranslation();

  const CustomTableCell = styled(TableCell)`
    &.${tableCellClasses.head} {
      background: #ffffff;
      color: #000000;
      border-bottom: none;
      font-family: "Cerebri sans-medium";
      font-size: 13px;
    }
    &.${tableCellClasses.body} {
      border-bottom: none;
      font-family: "Cerebri sans-light";
      font-size: 13px;
      font-weight: 300;
    }
  `;

  const CustomTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #f9fafa;
    }
  `;

  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorElCreated, setAnchorElCreated] = useState(false);

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  const GetSigningsByTable = (eid, user, status, completed, datefilter) => {
    try {
      GetSigningsTable(sid, user, completed, status, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({ error: true, status: "", message: "Failed to retrieve data" });
    }
  };

  const MoreOverPopup = ({
    eid,
    zid,
    unitname,
    unitaddress,
    unitno,
    createdby,
    signers,
    status,
    created,
    expires,
    reminder,
    interval,
  }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const handleClose = () => {
      setAnchorEl(null);
    };

    const [openEdit, setOpenEdit] = React.useState(false);
    const [openCancel, setOpenCancel] = React.useState(false);

    const handleCancelClickOpen = () => {
      setOpenCancel(true);
      handleClose();
    };
    const handleEditClickOpen = () => {
      setOpenEdit(true);
      handleClose();
    };

    const handleCancelClose = () => {
      setOpenCancel(false);
    };
    const handleEditClose = () => {
      setOpenEdit(false);
    };

    return (
      <>
        <div>
          <div
            style={{
              marginLeft: "30px",
              backgroundColor: "#ffffff",
              border: "1px solid #f4f5f5",
              borderRadius: "8px",
              height: "44px",
              width: "44px",
              transform: `rotate(${anchorEl ? "90" : "0"}deg)`,
            }}
          >
            <IconButton
              onClick={handleClick}
              aria-haspopup="true"
              aria-controls="long-menu"
              sx={{
                ml: 1,
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
            >
              <span className="moreverticons-span">
                <MoreVertIcon style={{ fill: "#619130" }} />
              </span>
            </IconButton>
          </div>
          <>
            <div className="download-dropdown-signings">
              <Menu
                id="simple-menu"
                align="center"
                anchorEl={anchorEl}
                keepMounted
                onClose={handleClose}
                open={open}
                sx={{
                  marginTop: "10px",
                  backgroundColor: "rgba(0, 0, 0, 0.02)",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <span>
                  <MenuItem
                    onClick={handleEditClickOpen}
                    className="hover-gray"
                  >
                    <div className="light fs-14">{t("edit-signing-order")}</div>
                  </MenuItem>
                </span>
                <Dialog
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        overflowY: "hidden",
                        overflowX: "auto",
                      },
                    },
                  }}
                  open={openEdit}
                  onClose={handleEditClose}
                  maxWidth="lg"
                  aria-labelledby="responsive-dialog-title"
                >
                  <EditSigninPopup
                    eid={eid}
                    zid={zid}
                    unitname={unitname}
                    unitaddress={unitaddress}
                    unitno={unitno}
                    createdby={createdby}
                    signers={signers}
                    status={status}
                    created={created}
                    expires={expires}
                    reminder={reminder}
                    interval={interval}
                    handleEditClose={handleEditClose}
                  />
                </Dialog>

                <hr className="download-hr-signings" />
                <span onClick={handleCancelClickOpen}>
                  <MenuItem className="hover-gray">
                    <div className="light fs-14">
                      {t("cancel-signing-order")}
                    </div>
                  </MenuItem>
                </span>
                <Dialog
                  open={openCancel}
                  onClose={handleCancelClose}
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "444px",
                        overflowX: "hidden",
                      },
                    },
                  }}
                  aria-labelledby="customized-dialog-title"
                  aria-describedby="modal-modal-description"
                >
                  <CancelOrderPopup
                    zid={zid}
                    unitname={unitname}
                    unitaddress={unitaddress}
                    unitno={unitno}
                    createdby={createdby}
                    signers={signers}
                    handleCancelClose={handleCancelClose}
                  />
                </Dialog>
              </Menu>
            </div>
          </>
        </div>
      </>
    );
  };

  // );
  const [open, setOpen] = React.useState(false);
  const [zid, setZid] = useState("");
  const [eid, setEid] = useState("");

  const handleClickOpen = (Zyz_S_Id, E_ID) => {
    setOpen(true);
    setZid(Zyz_S_Id);
    setEid(E_ID);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // API Consumption for User

  const [myUser, setMyUser] = useState([]);
  const [errorUser, setErrorUser] = useState([
    { error: false, status: "", message: "" },
  ]);
  useEffect(() => {
    GetuserbyByProperty();
  }, []);
  const GetuserbyByProperty = () => {
    try {
      GetuserbyProperty(0, 0)
        .then((data) => {
          if (data != null && data != undefined) {
            const categoryList = data.data.map(({ U_ID, Navn }) => {
              return { id: U_ID.toString(), name: Navn };
            });
            setMyUser(categoryList);
          } else {
            setMyUser([null]);
          }
        })
        .catch((error) => {
          setErrorUser({ error: error.message, status: error.status });
          setMyUser([null]);
        });
    } catch (e) {
      setMyUser([null]);
      setErrorUser({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  // Checkbox logic for Created by
  const Activities = myUser;

  const [allChecked, setAllChecked] = useState(false);
  const [optionChecked, setOptionChecked] = useState([]);

  const OnClicked = (e, isCheckAll) => {
    const { id, checked } = e.currentTarget;

    if (isCheckAll) {
      if (checked) {
        setOptionChecked(Activities.map((list) => list.id));
        setAllChecked(true);
      } else {
        setOptionChecked([]);
        setAllChecked(false);
      }
      GetSigningsByTable(sid);
    } else {
      if (checked) {
        const checkValue = [...optionChecked, id];
        setOptionChecked(checkValue);

        if (checkValue.length == Activities.length) {
          setAllChecked(true);
          GetSigningsByTable(sid);
        } else {
          GetSigningsByTable(sid, checkValue.toString());
        }
      } else {
        const checkValue = optionChecked.filter((item) => item !== id);
        if (checkValue.length !== 0) {
          setOptionChecked(checkValue);
          GetSigningsByTable(sid, checkValue.toString());
          setAllChecked(false);
        } else {
          setOptionChecked([]);
          setAllChecked(false);
          GetSigningsByTable(sid);
        }
      }
    }
  };

  //Checkbox logic for Status
  const ActivitiesStatus = [
    // { id: "1", name: "In process", parentId: "1" },
    { name: `${t("group")} 1`, id: "GROUP1", parentId: "1" },
    { name: `${t("group")} 2`, id: "GROUP2", parentId: "1" },
    { name: `${t("group")} 3`, id: "GROUP3", parentId: "1" },
    { name: `${t("group")} 4`, id: "GROUP4", parentId: "1" },
    { name: `${t("group")} 5`, id: "GROUP5", parentId: "1" },
    { name: `${t("group")} 6`, id: "GROUP6", parentId: "1" },
    // { id: "2", name: "Completed", parentId: "0" },
  ];
  const [allCheckedStatus, setAllCheckedStatus] = useState(false);
  const [optionCheckedStatus, setOptionCheckedStatus] = useState([]);

  const OnClickedStatus = (e, isCheckAll) => {
    const { id, checked } = e.currentTarget;

    if (isCheckAll) {
      if (checked) {
        // if (id === "1") {
        //   setCancelledId([]);
        //   setCompleteId([]);
        //   setComplete(false);
        //   setCancelled(false);
        //   setInProcess([1]);
        // }
        setOptionCheckedStatus(ActivitiesStatus.map((list) => list.id));
        setAllCheckedStatus(true);
      } else {
        setOptionCheckedStatus([]);
        setAllCheckedStatus(false);
      }
      GetSigningsByTable(sid);
    } else {
      if (checked) {
        const checkValue = [...optionCheckedStatus, id];
        setOptionCheckedStatus(checkValue);

        if (checkValue.length <= Activities.length) {
          setAllCheckedStatus(true);
          GetSigningsByTable(sid);
        } else {
          GetSigningsByTable(sid, checkValue.toString());
        }
      } else {
        const checkValue = optionCheckedStatus.filter((item) => item !== id);
        if (checkValue.length !== 0) {
          setOptionCheckedStatus(checkValue);
          GetSigningsByTable(sid, checkValue.toString());
          setAllCheckedStatus(false);
        } else {
          setOptionCheckedStatus([]);
          setAllCheckedStatus(false);
        }
      }
    }
  };

  //
  const HeadCells = [
    {
      id: "Status",
      alignment: "left",
      label: `${t("status")}`,
      width: "10%",
    },
    {
      id: "Signers",
      alignment: "left",
      label: `${t("signers")}`,
      width: "15%",
    },
    {
      id: "Created",
      alignment: "center",
      label: `${t("created")}`,
      width: "13%",
    },
    {
      id: "Expires",
      alignment: "center",
      label: `${t("expires")}`,
      width: "10%",
    },
    {
      id: "CreatedBy",
      alignment: "left",
      label: `${t("created-by")}`,
      width: "24%",
    },
    {
      id: "UnitName",
      alignment: "left",
      label: `${t("unit")}`,
    },
  ];

  const Ascending = (id) => {
    let myArr = [...myData];
    if (id === "Status") {
      myArr.sort((a, b) => a.Status.localeCompare(b.Status));
      setMyData(myArr);
    } else if (id === "Signers") {
      myArr.sort((a, b) => a.Signers.localeCompare(b.Signers));
      setMyData(myArr);
    } else if (id === "Created") {
      myArr.sort((a, b) => a.Created.localeCompare(b.Created));
      setMyData(myArr);
    } else if (id === "Expires") {
      myArr.sort((a, b) => a.Expires.localeCompare(b.Expires));
      setMyData(myArr);
    } else if (id === "CreatedBy") {
      myArr.sort((a, b) => a.CreatedBy.localeCompare(b.CreatedBy));
      setMyData(myArr);
    } else if (id === "UnitName") {
      myArr.sort((a, b) => a.UnitName.localeCompare(b.UnitName));
      setMyData(myArr);
    } else if (id === "") {
      myArr.sort((a, b) => a.Listing - b.Listing);
      setMyData(myArr);
    }
  };

  const Descending = (id) => {
    let myArr = [...myData];
    if (id === "Status") {
      myArr.sort((a, b) => b.Status.localeCompare(a.Status));
      setMyData(myArr);
    } else if (id === "Signers") {
      myArr.sort((a, b) => b.Signers.localeCompare(a.Signers));
      setMyData(myArr);
    } else if (id === "Created") {
      myArr.sort((a, b) => b.Created.localeCompare(a.Created));
      console.log(myArr, "myArr");
      setMyData(myArr);
    } else if (id === "Expires") {
      myArr.sort((a, b) => b.Expires.localeCompare(a.Expires));
      setMyData(myArr);
    } else if (id === "CreatedBy") {
      myArr.sort((a, b) => b.CreatedBy.localeCompare(a.CreatedBy));
      setMyData(myArr);
    } else if (id === "UnitName") {
      myArr.sort((a, b) => b.UnitName.localeCompare(a.UnitName));
      setMyData(myArr);
    } else if (id === "") {
      myArr.sort((a, b) => b.Listing - a.Listing);
      setMyData(myArr);
    }
  };

  const [complete, setComplete] = useState(false);
  const [completeId, setCompleteId] = useState([]);

  const onClickCompleted = (e) => {
    const { id, checked } = e.currentTarget;
    if (checked) {
      setComplete(true);
      setCompleteId([2]);
      setCancelled(false);
      setCancelledId([]);
      setInProcess(false);
      setInProcessId([]);
    } else {
      setCompleteId([]);
      setComplete(false);
    }
  };

  const [cancelled, setCancelled] = useState(false);
  const [cancelledId, setCancelledId] = useState([]);
  const onClickCancelled = (e) => {
    const { id, checked } = e.currentTarget;
    if (checked) {
      setCancelled(true);
      setCancelledId([3]);
      setComplete(false);
      setCompleteId([]);
      setInProcess(false);
      setInProcessId([]);
    } else {
      setCancelledId([]);
      setCancelled(false);
    }
  };
  const [inProcess, setInProcess] = useState(false);
  const [inProcessId, setInProcessId] = useState([]);
  const onClickInProcess = (e) => {
    const { id, checked } = e.currentTarget;
    if (checked) {
      setInProcessId([1]);
      setInProcess(true);
      setCancelled(false);
      setCancelledId([]);
      setComplete(false);
      setCompleteId([]);
    } else {
      setInProcessId([]);
      setInProcess(false);
    }
  };

  let CreatedByFilter =
    optionChecked === null ||
    optionChecked === undefined ||
    optionChecked.length === 0
      ? 0
      : optionChecked;

  const StatusFilter = useMemo(() => {
    if (
      cancelledId !== null &&
      cancelledId !== undefined &&
      cancelledId.length !== 0
    ) {
      return cancelledId;
    } else if (
      completeId !== null &&
      completeId !== undefined &&
      completeId.length !== 0
    ) {
      return completeId;
    } else if (
      inProcessId !== null &&
      inProcessId !== undefined &&
      inProcessId.length !== 0
    ) {
      return inProcessId;
    }
    return [];
  }, [cancelledId, completeId, inProcessId]);

  const groupFilter = useMemo(() => {
    if (
      optionCheckedStatus !== null ||
      optionCheckedStatus !== undefined ||
      optionCheckedStatus !== 0
    ) {
      return optionCheckedStatus;
    } else {
      return [];
    }
  }, [optionCheckedStatus]);

  useEffect(() => {
    GetSigningsByTable(
      sid,
      CreatedByFilter,
      groupFilter,
      StatusFilter,
      datefilter
    );
  }, [sid, CreatedByFilter, StatusFilter, datefilter, groupFilter]);

  const handleStatusClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCreatedClick = (event) => {
    setAnchorElCreated(event.currentTarget);
  };

  const [count, setCount] = useState(10);
  const [listState, setListState] = useState([]);

  useEffect(() => {
    setListState(myData.slice(0, count));
  }, [myData]);

  const fetchMoreData = async () => {
    setTimeout(async () => {
      let newArr = listState.concat(myData.slice(count, count + 10));
      setListState(newArr);
      setCount(count + 10);
    }, 100);
  };
  return (
    <>
      <div className="signing-container">
        <div className="all-units-container regular fs-14 fw-300">
          <Button
            id="basic-button"
            aria-controls={anchorEl ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? "true" : undefined}
            onClick={handleClick}
            sx={{
              "&:hover": { backgroundColor: "transparent" },
              padding: "0px",
              color: "black",
            }}
          >
            <div className="all-unit">
              <span className="light fs-14">{t("status")}</span>
              <ArrowUpDown component={anchorEl} />
            </div>
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={anchorEl}
            onClose={handleStatusClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.02)",
              "& .MuiPaper-root": {
                borderRadius: "8px",
                marginTop: "8px",
              },
            }}
          >
            <MenuItem
              sx={{
                "&:hover": { backgroundColor: "transparent" },
                borderRadius: "8px",
                padding: "0px",
                overflow: "hidden",
                // border: "1px solid #F4F5F5",
              }}
            >
              <div style={{ width: "214px", padding: "25px" }}>
                <Grommet theme={theme}>
                  <div className="light fs-14">
                    <CheckBox
                      id="3"
                      label={t("Cancelled")}
                      checked={cancelled}
                      onChange={(e) => onClickCancelled(e, true)}
                    />
                  </div>
                </Grommet>
                <Grommet theme={theme} style={{ marginTop: "12px" }}>
                  <div className="light fs-14">
                    <CheckBox
                      id="1"
                      label={t("In process")}
                      checked={inProcess}
                      onChange={(e) => onClickInProcess(e, true)}
                    />
                  </div>
                </Grommet>
                <div className="display-none-parent">
                  <ActivityDropdown
                    flag={true}
                    optionChecked={optionCheckedStatus}
                    OnClicked={OnClickedStatus}
                    Activities={ActivitiesStatus}
                    Label={t("In process")}
                  />
                </div>
                <Grommet theme={theme}>
                  <div className="light fs-14">
                    <CheckBox
                      id="2"
                      label={t("completed")}
                      checked={complete}
                      onChange={(e) => onClickCompleted(e, true)}
                    />
                  </div>
                </Grommet>
              </div>
            </MenuItem>
          </Menu>
        </div>

        <div className="all-units-container">
          <Button
            id="basic-button"
            aria-controls={anchorElCreated ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={anchorElCreated ? "true" : undefined}
            onClick={handleCreatedClick}
            sx={{
              "&:hover": { backgroundColor: "transparent" },
              padding: "0px",
              color: "black",
            }}
          >
            <div className="all-unit">
              <span className="light fs-14">{t("created-by")}</span>
              <ArrowUpDown component={anchorElCreated} />
            </div>
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorElCreated}
            open={anchorElCreated}
            onClose={() => setAnchorElCreated(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.02)",
              "& .MuiPaper-root": {
                borderRadius: "8px",
                marginTop: "8px",
              },
            }}
          >
            <MenuItem
              sx={{
                "&:hover": { backgroundColor: "transparent" },
                borderRadius: "8px",
                padding: "0px",
                overflow: "hidden",
              }}
            >
              <div className="createdBy-dropdown">
                <ActivityDropdown
                  parentId="0"
                  allChecked={allChecked}
                  optionChecked={optionChecked}
                  OnClicked={OnClicked}
                  Activities={Activities}
                  Label={t("All")}
                />
              </div>
            </MenuItem>
          </Menu>
        </div>
      </div>

      <div className="signin-table-container">
        <div className="signin-table-section">
          {myData?.length != 0 ? (
            <>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {HeadCells.map((item) => (
                      <CustomTableCell
                        key={item.id}
                        align={item.alignment}
                        width={item.width}
                      >
                        {item.label}
                        <Sorting
                          Ascending={() => Ascending(item.id)}
                          Descending={() => Descending(item.id)}
                        />
                      </CustomTableCell>
                    ))}
                    {/* <CustomTableCell align="left"></CustomTableCell> */}
                  </TableRow>
                </TableHead>
              </Table>
              <div
                id="scrollableSigningsDiv"
                className="scrollable-container"
                style={{ height: "500px" }}
              >
                <Table>
                  <TableBody>
                    <InfiniteScroll
                      dataLength={listState.length}
                      next={fetchMoreData}
                      hasMore={true}
                      scrollableTarget="scrollableSigningsDiv"
                    >
                      {listState?.map((row, id) => (
                        <CustomTableRow key={id}>
                          <CustomTableCell
                            align="center"
                            component="th"
                            scope="row"
                          >
                            <div
                              className="medium fs-12 cursor status"
                              style={{
                                backgroundColor:
                                  row.Status.slice(0, 1) === "0"
                                    ? "#FF6868"
                                    : row.Status.slice(0, 1) >
                                      row.Status.slice(2, 3)
                                    ? "#FFE49E"
                                    : row.Status.slice(0, 1) ===
                                      row.Status.slice(2, 3)
                                    ? "#B5E2AF"
                                    : "null",
                              }}
                            >
                              <div
                                onClick={() =>
                                  handleClickOpen(row.Zyz_S_Id, row.E_ID)
                                }
                              >
                                {row.Status}
                              </div>
                            </div>
                          </CustomTableCell>
                          <CustomTableCell align="left" width="15%">
                            {row.Signers}
                            <div>
                              (
                              <span
                                style={{ color: "#619130", cursor: "pointer" }}
                              >
                                <Popup
                                  overlayStyle={{
                                    backgroundColor: "rgba(0,0,0,0.5)",
                                  }}
                                  contentStyle={{ width: "1400px" }}
                                  modal
                                  trigger={<span>{t("show-more")}</span>}
                                >
                                  {(close) => (
                                    <SigningDetail
                                      zid={row.Zyz_S_Id}
                                      eid={row.E_ID}
                                      close={close}
                                    />
                                  )}
                                </Popup>
                              </span>
                              )
                            </div>
                          </CustomTableCell>
                          <CustomTableCell align="center">
                            {new Date(row.Created).toLocaleDateString("en-En", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}
                          </CustomTableCell>
                          <CustomTableCell align="center">
                            {row.Expires == "-"
                              ? "-"
                              : new Date(row.Expires).toLocaleDateString(
                                  "en-En",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )}
                          </CustomTableCell>
                          <CustomTableCell align="left">
                            <span className="fs-14 light">{row.CreatedBy}</span>
                          </CustomTableCell>
                          <CustomTableCell align="left" width="15%">
                            <span className="medium fs-13">{row.UnitName}</span>
                            <br />
                            <span className="light fs-13">
                              {row.UnitAddress}
                            </span>

                            <br />
                            <span className="light fs-13">{row.UnitNo}</span>
                          </CustomTableCell>
                          <CustomTableCell align="center">
                            <MoreOverPopup
                              eid={row.E_ID}
                              zid={row.Zyz_S_Id}
                              unitname={row.UnitName}
                              unitaddress={row.UnitAddress}
                              unitno={row.UnitNo}
                              createdby={row.CreatedBy}
                              signers={row.Signers}
                              status={row.Status}
                              created={row.Created}
                              expires={row.Expires}
                              reminder={row.ReminderDate}
                              interval={row.Interval}
                            />
                          </CustomTableCell>
                        </CustomTableRow>
                      ))}
                    </InfiniteScroll>
                  </TableBody>
                </Table>
              </div>
            </>
          ) : (
            <NoDataScreen />
          )}

          <Dialog
            maxWidth="lg"
            open={open}
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "fit-content",
                  // overflow : 'hidden',
                  // position: 'relative'
                },
              },
            }}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <SigningOrder zid={zid} eid={eid} SigninOrderClose={handleClose} />
          </Dialog>
        </div>
      </div>
    </>
  );
};
export default SigningsTable;
