import { Get } from "../../estateTool/dashboard/utils/Axios";
import { endpoint } from "../EndPoint";
import { GetHeader } from "../Header";

export const GetSigningReminders = (eid, zid) => {
  eid = eid === "" || eid === null ? 0 : eid;
  zid = zid === "" || zid === null ? 0 : zid;

  return new Promise((resolve, reject) => {
    Get(endpoint.Signings_Get_Reminders, GetHeader(), eid + "/" + zid)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};