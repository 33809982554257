import React from "react";
import styled from "@emotion/styled";

import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "../components/Tooltip/Tooltip";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";
import { GetTrafficPopularFilters } from "../../../API/Traffic/TrafficPopularFilters";
import { useState } from "react";
import { useEffect } from "react";
import NoDataScreen from "../components/NoDataScreen";

function PopularFilter({ sid, datefilter }) {
  //i18Next
  const { t, i18n } = useTranslation();

  const CustomTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #f9fafa;
    }
    tr {
      border-radius: "4px";
    }
  `;

  const CustomTableCell = styled(TableCell)`
    &.${tableCellClasses.head} {
      background: #ffffff;
      color: #000000;
      border-bottom: none;
      font-family: "Cerebri sans-medium";
      font-size: 13px;
      font-weight: 500;
    }
    &.${tableCellClasses.body} {
      border-bottom: none;
      font-family: "Cerebri sans-light";
      font-size: 13px;
      font-weight: 300;
    }
  `;

  function createData(filters, used) {
    return { filters, used };
  }

  const rows = [
    createData("Price", 4),
    createData("Square meters", 43),
    createData("Floorplan", 22),
    createData("Orientation", 22),
    createData("Outdoor space", 22),
    createData("Status", 22),
  ];
  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetTrafficByPopularFilters(sid, datefilter);
  }, [sid, datefilter]);

  const GetTrafficByPopularFilters = (sid, datefilter) => {
    try {
      GetTrafficPopularFilters(sid, datefilter)
        .then((data) => {
          if (data != null && data !== undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  //Options for CSV data
  const options = {
    keys: [
      { field: "Filters", title: "Filters" },
      { field: "Used", title: "Used" },
    ],
  };

  return (
    <>
      <div className="paper">
        <div className="card-wrapper">
          <div className="card-header">
            <div className="margin-heading-card header-text">
              <div>
                <div className="bold fw-700 fs-14">{t("popular-filters")}</div>
              </div>
              <Tooltip text={t("popular-filters")} paragraph="" />
            </div>
            <div>
              <DownloadWidget
                options={options}
                data={myData}
                fileName={"traffic-popular-filter"}
                type={false}
              />
            </div>
          </div>

          <div className="card-body scrollable-container-popular-filter">
            {myData?.length != 0 ? (
              <div className="scrollable-container" style={{ height: "380px" }}>
                <TableContainer
                  component={Paper}
                  sx={{ padding: "0 24px 24px" }}
                >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <CustomTableCell>{t("filters")}</CustomTableCell>
                        <CustomTableCell align="right">
                          {t("used")}
                        </CustomTableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {myData.map((row, id) => (
                        <CustomTableRow
                          key={id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <CustomTableCell component="th" scope="row">
                            {row.Filters}
                          </CustomTableCell>
                          <CustomTableCell align="right">
                            {row.Used}
                          </CustomTableCell>
                        </CustomTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : (
              <NoDataScreen height={"380px"} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PopularFilter;
