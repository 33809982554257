import { Get } from "../../estateTool/dashboard/utils/Axios";
import { endpoint } from "../EndPoint";
import { GetHeader } from "../Header";

export const GetUnitProgressDetailBid = (bid) => {
    bid = bid === "" || bid === null ? 0 : bid;
  return new Promise((resolve, reject) => {
    Get(endpoint.Overview_Unit_ProgressDetail_By_Bid, GetHeader(), bid)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
