import React, { useState, useEffect } from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import styled from "@emotion/styled";
import "./customer.css";
import Tooltips from "../components/Tooltip/Tooltip";

import { Card as MuiCard, Switch } from "@mui/material";
import { spacing } from "@mui/system";
import { GetCustomersJourney } from "../../../API/Customers/CustomersJourney";
import { useTranslation } from "react-i18next";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useRef } from "react";
import NoDataScreen from "../components/NoDataScreen";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 300px;
  width: 100%;
  padding: 10px 25px;
  height: 300px;
  width: 100%;
  padding: 10px 25px;
`;

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CustomerJourney = ({ sid, datefilter }) => {
  //i18n
  const { t, i18n } = useTranslation();

  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});

  // API Consumption

  const [myBarData, setMyBarData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetCustomersByJourney(sid, datefilter);
  }, [sid, datefilter]);

  const GetCustomersByJourney = (sid, datefilter) => {
    try {
      GetCustomersJourney(sid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyBarData(data.data);
          } else {
            setMyBarData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyBarData([null]);
        });
    } catch (e) {
      setMyBarData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };
  useEffect(() => {
    setChartData({
      labels: myBarData.map((x) => x.Type),
      datasets: [
        {
          data: myBarData.map((x) => x.Count),
          borderColor: ["#000000"],
          backgroundColor: ["#619130"],
          anchor: "end",
          align: "top",
          barPercentage: 0.99,
          categoryPercentage: 0.999,
          borderWidth: {
            top: 3.5,
            right: 1.5,
            bottom: 0,
            left: 0,
          },
        },
      ],
    });
    setChartOptions({
      maintainAspectRatio: false,

      plugins: {
        tooltip: {
          backgroundColor: "#FFFFFF",
          borderWidth: 2,
          borderDash: [2, 2],
          titleColor: "#000000",
          bodyColor: "#000000",
        },
        datalabels: {
          display: true,
          color: "black",
          anchor: "end",
          align: "top",

          font: {
            weight: "bold",
            size: 16,
            family: "Cerebri Sans",
          },
        },
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          grid: {
            borderDash: [5, 10],
            color: "#F4F5F5",
          },
          stacked: false,
          ticks: {
            stepSize: 50,
          },
        },

        x: {
          stacked: false,
          grid: {
            color: "transparent",
          },
        },
      },
    });
  }, [myBarData]);

  const myref = useRef();
  const [refObj] = useState(myref);

  let Total = 0;
  let total = myBarData?.reduce(function (sum, value) {
    return (Total += Number(value.Count));
  }, 0);

  return (
    <div ref={myref} className="breakdown-container">
      <div className="customer-journey">
        <div className="breakdown-sub-container">
          <div className="margin-heading-card breakdown-heading">
            <div className="bold fw-700 fs-14">{t("customer-journey")}</div>
            <Tooltips text={t("customer-journey")} paragraph="" />
          </div>
          <div className="data-events">
            <span className="signing-heading light fw-300 fs-14">
              {t("compare-with-data")}
            </span>
            <span
            // style={{ marginRight: "15px", }}
            >
              <button
                style={{
                  border: "none",
                  backgroundColor: "#fff",
                  marginRight: "16px",
                  marginTop: "10px",
                }}
              >
                <Switch color="success" />
              </button>
            </span>

            <div>
              <DownloadWidget
                refObj={refObj}
                fileName={"customers-cutomer-journey"}
                type={true}
              />
            </div>
          </div>
        </div>
      </div>

      {Total != 0 ? (
        <ChartWrapper>
          <Bar
            data={chartData}
            plugins={[ChartDataLabels]}
            options={chartOptions}
            height="80px"
          />
        </ChartWrapper>
      ) : (
        <NoDataScreen height="150px" />
      )}
    </div>
  );
};

export default CustomerJourney;
