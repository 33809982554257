import React, { useEffect } from "react";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Typography,
  Card,
  Paper,
  tableCellClasses,
  makeStyles,
  styled,
} from "@mui/material";
import "./customer.css";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "../components/Tooltip/Tooltip";
import { useState } from "react";
import { GetCustomersBreakdownRegionID } from "../../../API/Customers/CustomerRegionZipCode";
import { useTranslation } from "react-i18next";

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: "Cerebri sans-medium";
    font-size: 13px;
    font-weight: 500;
    border: none;
    line-height: 20px;
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family: "Cerebri sans-light";
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
  }
`;

function createData(status, quantity, percentage) {
  return { status, quantity, percentage };
}

const rows = [
  createData("Region Hovedstaden", 33, "22%"),
  createData("Region Sjælland", 33, "22 %"),
  createData("Eclair", 33, "22 %"),
  createData("Cupcake", 33, "22 %"),
  createData("Gingerbread", 33, "22 %"),
];

const BreakDownSubTable = ({ handleClose, sid, RegionID, RegionName, datefilter }) => {

  //i18n
  const { t, i18n } = useTranslation();

  const [zipCode, setZipCode] = useState({ data: [] });
  const [zipCodeError, setZipCodeError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetBreakDownSubTableByRegionId();
  }, [sid, datefilter]);

  const GetBreakDownSubTableByRegionId = () => {
    try {
      GetCustomersBreakdownRegionID(sid, RegionID,datefilter)
        .then((data) => {
          if (data != null && data !== undefined) {
            setZipCode(data);
          } else {
            setZipCode([null]);
          }
        })
        .catch((error) => {
          setZipCodeError({
            error: error.message,
            status: error.status,
          });
          setZipCodeError([null]);
        });
    } catch (e) {
      setZipCodeError([null]);
      setZipCodeError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  return (
    <>
      <div
      // className="scrollbar-dialog scrollable-container"
      >
        <div
          className=" sticky display-flex justify-content-between"
          style={{ backgroundColor: "#fff", height: "64px", zIndex: "2" }}
        >
          <div className="region-name display-flex">
            <div>
              <div className=" bold fs-13">
                {RegionName} ({zipCode.data.length})
              </div>
            </div>
            <Tooltip text={t("visibility")} paragraph="" />
          </div>
          <div className="signin-div-close-button" onClick={handleClose}>
            <a className="close">
              <div style={{ margin: "5px" }}>
                <CloseIcon fontSize="large" />
              </div>
            </a>
          </div>
        </div>
        <hr className="horizonal-line" />
        <div
          className="scrollable-container"
          style={{ height: "430px", padding: " 0 21px 5px 25px" }}
        >
          <Table sx={{ width: "650px", height: "274px" }}>
            <TableHead>
              <TableRow className="sticky" sx={{ backgroundColor: "#fff" }}>
                <CustomTableCell width="40%">{t("status")}</CustomTableCell>
                <CustomTableCell width="30%" align="center">{t("quantity")}</CustomTableCell>
                <CustomTableCell align="center" width="30%">{t("percentage")}</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {zipCode?.data?.map((row, i) => (
                <CustomTableRow key={i}>
                  <CustomTableCell
                    align="left"
                    component="td"
                    scope="row"
                    width="40%"
                  >
                    {row.ZipCodeName}
                  </CustomTableCell>
                  <CustomTableCell
                    align="right"
                    width="30%"
                    sx={{ paddingRight: "77px" }}
                  >
                    {row.Quantity}
                  </CustomTableCell>
                  <CustomTableCell
                    align="right"
                    width="30%"
                    sx={{ paddingRight: "67px" }}
                  >
                    {row.Percentage} %
                  </CustomTableCell>
                </CustomTableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default BreakDownSubTable;
