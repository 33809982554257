import React from "react";
import { useEffect, useState } from "react";

import styled from "@emotion/styled";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";
import Tooltip from "../components/Tooltip/Tooltip";

import { GetCustomerTransactionSqrmtr } from "../../../API/Customers/CustomersTransactionsSqrmtr";
import { useTranslation } from "react-i18next";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import NoDataScreen from "../components/NoDataScreen";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: "Cerebri sans-medium";
    font-size: 13px;
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family: "Cerebri sans-light";
    font-size: 13px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
  }
`;

// Data
let id = 0;
function createData(name, calories, fat, carbs) {
  id += 1;

  return { id, name, calories, fat, carbs };
}

const rows = [
  createData("1-room", 4, "10%", "10.000.000 DKK"),
  createData("2-room", 2, "8%", "10.000.000 DKK"),
  createData("3-room", 8, "7%", "10.000.000 DKK"),
  createData("4-room", 5, "15%", "10.000.000 DKK"),
  createData("5-room", 2, "34%", "10.000.000 DKK"),
  createData("Over 6-room", "0", "0%", "0 DKK"),
];
const styles = {
  rectangle: {
    width: "50px",
    height: "50px",
  },
};

function TransactionSquareMeters({ sid, datefilter }) {
  //i18n
  const { t, i18n } = useTranslation();

  // API Consumption

  const [myData, setMyData] = useState({ data: [] });
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetCustomerTransactionBySqrmtr(sid, datefilter);
  }, [sid, datefilter]);

  const GetCustomerTransactionBySqrmtr = (sid, datefilter) => {
    try {
      GetCustomerTransactionSqrmtr(sid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  //Options for CSV data
  const options = {
    keys: [
      { field: "SqrMtr", title: "Sqr Mtr" },
      { field: "Tenants", title: "Tenants" },
      { field: "Percentage", title: "Percentage" },
      { field: "YearlyRent", title: "Total annual rent" },
    ],
  };

  let Total = 0;
  let total = myData?.data?.reduce(function (sum, value) {
    return (Total += Number(value.Tenants));
  }, 0);
  return (
    <>
      <div className="transaction-container">
        <div className="margin-heading-card" style={{ display: "flex" }}>
          <div className="bold fw-700 fs-14">
            {t("transactions-divided-by-square-meters")}
          </div>
          <Tooltip
            text={t("transactions-divided-by-square-meters")}
            paragraph=""
          />
        </div>
        <div>
          <DownloadWidget
            options={options}
            data={myData.data}
            fileName={"customers-transactions-bySqMtr"}
            type={false}
          />
        </div>
      </div>
      <Card>
        <Paper className="card-container">
          {Total !== 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <CustomTableCell>{t("square-meters")}</CustomTableCell>
                  <CustomTableCell align="right">
                    {t("quantity")}
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {t("percentage")}
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {t("total-annual-rent")}
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myData?.data.map((row, i) => (
                  <CustomTableRow key={(row.id, i)}>
                    <CustomTableCell component="th" scope="row">
                      {row.SqrMtr}
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      {row.Tenants}
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      {row.Percentage}%
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      {new Intl.NumberFormat("en-DE").format(row.YearlyRent)}{" "}
                      DKK
                    </CustomTableCell>
                  </CustomTableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoDataScreen />
          )}
        </Paper>
      </Card>
    </>
  );
}

export default TransactionSquareMeters;
