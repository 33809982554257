import React from "react";
import deltaUp from "../../Icons/delta-up.svg";
import deltaDown from "../../Icons/delta-down.svg";

function DeltaArrow({ component }) {
  return (
    <div>
      <img
        height={8}
        className="arrow-up-down-icon"
        src={component > 0 ? deltaUp : component < 0 ? deltaDown : ""}
        alt=""
      />
    </div>
  );
}

export default DeltaArrow;
