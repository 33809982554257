import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip } from "chart.js";

import { Card as MuiCard } from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { GetEventsHistory } from "../../../API/Events/EventsHistory";
import moment from "moment";
import NoDataScreen from "../components/NoDataScreen";
const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 332px;
  width: 100%;
`;

const EventHistoryStacked = ({ sid, datefilter }) => {
  //i18Next
  const { t, i18n } = useTranslation();

  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetEventsByHistory(sid, datefilter);
  }, [sid, datefilter]);

  const GetEventsByHistory = (sid, datefilter) => {
    try {
      GetEventsHistory(sid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const data = {
    labels: myData.map((x) => moment(x.EventDate).format("ll")),
    datasets: [
      {
        backgroundColor: "#F6E651",
        data: myData.map((x) => x.Reserved),
        stack: 1,
        maxBarThickness: 50,
        barThickness: 50,
        barPercentage: 0.5,
        label: `${t("reserved")}`,
      },
      {
        backgroundColor: "#619130",
        data: myData.map((x) => x.Participated),
        stack: 1,
        maxBarThickness: 50,
        barThickness: 50,
        label: `${t("participated")}`,
      },
    ],
  };

  ChartJS.register(Tooltip);

  const options = {
    maintainAspectRatio: false,

    plugins: {
      tooltip: {
        backgroundColor: "#FFFFFF",
        borderWidth: 2,
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
      },
      legend: {
        display: true,
        position: "bottom",
        align: "center",

        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 20,
          font: { family: "Cerebri Sans-medium", size: "12px" },
        },
      },
    },
    scales: {
      y: {
        grid: {
          borderDash: [5, 10],
          color: "#F4F5F5",
        },
        stacked: false,
        ticks: {
          stepSize: 5,
          color: "#888888",
          font: {
            family: "Cerebri sans-light",
          },
        },
      },

      x: {
        grid: {
          borderDash: [5, 10],
          color: "#FFFF",
        },

        ticks: {
          minRotation: 20,
          color: "#888888",
          font: {
            family: "Cerebri sans-light",
            size: 11,
          },
        },
      },
    },
  };

  return (
    <>
      {myData?.length != 0 ? (
        <div className="history-chart-section">
          <Spacer mb={6} />
          <ChartWrapper>
            <Bar data={data} options={options} />
          </ChartWrapper>
        </div>
      ) : (
        <NoDataScreen />
      )}
    </>
  );
};

export default EventHistoryStacked;
