import { Get } from "../../estateTool/dashboard/utils/Axios";
import { endpoint } from "../EndPoint";
import { GetHeader } from "../Header";

export const GetTrafficFunnelStepUrlList = (eid, type) => {
  eid = eid === "" || eid === null || eid === undefined ? 2 : eid;
  type = type === "" || type === null || type === undefined ? 2 : type;
  return new Promise((resolve, reject) => {
    Get(endpoint.Traffic_GetFunnelStepUrlsList, GetHeader(), eid + "/" + type)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
