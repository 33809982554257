import {
  Paper,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import Reserved from "../../economy/Reserved";
import { GetEconomyIdleness } from "../../../../API/EconomyIdleness/EconomyIdleness";
import { useEffect } from "react";
import Popup from "reactjs-popup";
import { GetPropertiesById } from "../../../../API/Common/CommonGetPropertiesById";
import { Table } from "@material-ui/core";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import photoComingSoon from "../../Icons/photo-coming-soon.jpg";
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: Cerebri sans-medium;
    font-weight: 500;
    font-size: 13px;
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family: Cerebri sans-light;
    font-weight: 300;
    font-size: 13px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
  }
`;

const StatusLabel = ({ text, color }) => {
  return (
    <>
      <span
        className="idleness-statuslabel"
        style={{ background: color }}
      ></span>
      {text}
    </>
  );
};

const MarkerPopup = ({
  handleMarkerPopup,
  openMarkerPopup,
  markerData,
  datefilter,
}) => {
  //i18Next
  const { t, i18n } = useTranslation();

  const Colors = [
    "#77C7A1",
    "#77C7A1",
    "#A2C8D0",
    "#A2C8D0",
    "#F6E651",
    "#F1664B",
    "#F1664B",
    "#BF87D2",
    "#BF87D2",
  ];
  const { SYS_E_ID } = markerData;

  // API Consumption Idleness

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetEconomyByIdleness(SYS_E_ID, datefilter);
  }, [datefilter, markerData, openMarkerPopup]);

  const GetEconomyByIdleness = () => {
    try {
      GetEconomyIdleness(SYS_E_ID, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  // API Consumption Idleness

  const [propertyData, setPropertyData] = useState([]);
  const [errorProperty, setErrorProperty] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    propertiesById(SYS_E_ID);
  }, [markerData]);

  const propertiesById = (SYS_E_ID) => {
    try {
      GetPropertiesById(SYS_E_ID)
        .then((data) => {
          if (data != null && data != undefined) {
            setPropertyData(data.data);
          } else {
            setPropertyData([null]);
          }
        })
        .catch((error) => {
          setErrorProperty({ error: error.message, status: error.status });
          setPropertyData([null]);
        });
    } catch (e) {
      setPropertyData([null]);
      setErrorProperty({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };
  return (
    <>
      {propertyData.length > 0 && (
        <div
          style={{
            display: "flex",
            zIndex: 1,
            position: "relative",
            bottom: "20px",
            justifyContent: "center",
            cursor: "pointer",
            translate: openMarkerPopup ? "0px -428px" : "inherit",
            transition: "all 300ms ease-in-out",
          }}
          onClick={handleMarkerPopup}
        >
          <div
            style={{
              background: "white",
              width: "44px",
              height: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #F4F5F5",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <ArrowDropUpIcon
              style={{
                color: "#619130",
                rotate: openMarkerPopup ? "180deg" : "inherit",
              }}
            />
          </div>
        </div>
      )}
      <div
        className="marker-container"
        style={{
          translate: openMarkerPopup ? "0px -448px" : "inherit",
          transition: "all 300ms ease-in-out",
        }}
      >
        <div className="marker-left-container">
          <img
            height={"129px"}
            width={"191px"}
            style={{ borderRadius: "10px" }}
            src={
              propertyData[0]?.Image ? propertyData[0]?.Image : photoComingSoon
            }
            alt={"property_img"}
          ></img>
          <span className="fs-16 regular bold" style={{ marginTop: "22px" }}>
            {propertyData[0]?.Name}
          </span>
          <span className="fs-13 light">{propertyData[0]?.City}</span>

          <span className="fs-16 regular bold" style={{ marginTop: "15px" }}>
            {propertyData[0]?.BrokerName}
          </span>
          <span className="fs-13 light">{propertyData[0]?.BrokerName}</span>
          <span className="fs-13 light">{propertyData[0]?.BrokerPhone}</span>
          <span className="fs-13 light">{propertyData[0]?.BrokerEmail}</span>

          <span className="fs-16 regular bold" style={{ marginTop: "35px" }}>
            {t("milestone-status")}
          </span>

          <span className="fs-13 light">{markerData?.Target}</span>
          <span className="fs-10 light" style={{ color: "gray" }}>
            {markerData?.Statement}
          </span>
        </div>
        <div className="scrollable-container">
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ position: "sticky", top: 0 }}>
                <CustomTableCell sx={{ padding: "0px 16px 16px 30px" }}>
                  {t("status")}
                </CustomTableCell>
                <CustomTableCell
                  align="right"
                  sx={{ padding: "0px 16px 16px 30px" }}
                >
                  {t("quantity")}
                </CustomTableCell>
                <CustomTableCell
                  align="left"
                  sx={{ padding: "0px 16px 16px 30px" }}
                ></CustomTableCell>
                <CustomTableCell
                  align="right"
                  sx={{ padding: "0px 16px 16px 30px" }}
                >
                  {t("percentage")}
                </CustomTableCell>
                <CustomTableCell
                  align="right"
                  sx={{ padding: "0px 50px 16px 16px" }}
                >
                  {t("total-amount")}
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myData?.slice(0, 9).map((row, i) => (
                <CustomTableRow key={i}>
                  <CustomTableCell
                    sx={{ padding: "16px 16px 16px 30px" }}
                    component="th"
                    scope="row"
                    width="30%"
                  >
                    <StatusLabel color={Colors[i]} />
                    {t(row.Status)}
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {row.Quantity}
                  </CustomTableCell>
                  <CustomTableCell
                    sx={{ padding: "16px 50px 16px 0px" }}
                    align="left"
                  >
                    {row.AssetID < 2 || row.Quantity == "0" ? (
                      ""
                    ) : (
                      <>
                        (
                        <span style={{ color: "#619130", cursor: "pointer" }}>
                          <Popup
                            overlayStyle={{
                              backgroundColor: "rgba(0,0,0,0.4)",
                            }}
                            contentStyle={{ width: "1400px" }}
                            modal
                            trigger={<span>{t("show")}</span>}
                          >
                            {(close) => (
                              <Reserved
                                sid={markerData.SYS_E_ID}
                                datefilter={datefilter}
                                close={close}
                                AssetID={row.AssetID}
                                Status={row.Status}
                              />
                            )}
                          </Popup>
                        </span>
                        )
                      </>
                    )}
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {row.Percentage}%
                  </CustomTableCell>
                  <CustomTableCell
                    align="right"
                    sx={{ padding: "16px 50px 16px 16px" }}
                  >
                    {new Intl.NumberFormat("en-DE").format(row.TotalAmount)}
                    &nbsp;DKK
                  </CustomTableCell>
                </CustomTableRow>
              ))}
            </TableBody>

            <TableHead>
              <TableRow>
                <CustomTableCell sx={{ padding: "16px 16px 16px 30px" }}>
                  {myData[9]?.Status}
                </CustomTableCell>
                <CustomTableCell align="right">
                  {myData[9]?.Quantity}
                </CustomTableCell>
                <CustomTableCell align="left"></CustomTableCell>

                <CustomTableCell align="right">
                  {myData[9]?.Percentage}%
                </CustomTableCell>
                <CustomTableCell
                  align="right"
                  sx={{ padding: "16px 50px 16px 16px" }}
                >
                  {new Intl.NumberFormat("en-DE").format(
                    myData[9]?.TotalAmount
                  )}
                  &nbsp;DKK
                </CustomTableCell>
              </TableRow>
            </TableHead>
          </Table>
        </div>
      </div>
    </>
  );
};

export default MarkerPopup;
