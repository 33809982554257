import React from "react";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import "./economy.css";

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Typography,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";

import axios from "axios";
import { GetEconomyAnalysis } from "../../../API/EconomyAnalysis/EconomyAnalysis";
import { useTranslation } from "react-i18next";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: Cerebri sans-medium;
    font-weight: 500;
    font-size: 13px;
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family: Cerebri sans-light;
    font-weight: 300;
    font-size: 13px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
  }
`;

// Data
let id = 0;
function createData(name, calories, fat, carbs) {
  id += 1;
  return { id, name, calories, fat, carbs };
}

const StatusLabel = ({ text, color }) => {
  return (
    <div className="availabilityrooms-status-label">
      <span
        style={{
          background: color,
        }}
      ></span>
      {text}
    </div>
  );
};

function AnalysisTable({ sid, datefilter }) {
  //i18Next
  const { t, i18n } = useTranslation();

  // API Consumption

  const [myData, setMyData] = useState({ data: [] });
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetEconomyByAnalysis(sid, datefilter);
  }, [sid, datefilter]);

  const GetEconomyByAnalysis = (sid, datefilter) => {
    try {
      GetEconomyAnalysis(sid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const Colors = ["#77C7A1", "#F6E651", "#888888", "#A2C8D0"];
  return (
    <>
      <Paper className="analysis-container">
        <Table mb={6}>
          <TableHead>
            <TableRow>
              <CustomTableCell sx={{ padding: "16px 16px 16px 30px" }}>
                {t("status")}
              </CustomTableCell>
              <CustomTableCell align="right">{t("quantity")}</CustomTableCell>
              <CustomTableCell align="right">{t("percentage")}</CustomTableCell>
              <CustomTableCell
                align="right"
                sx={{ padding: "16px 50px 16px 16px" }}
              >
                {t("total-amount")}
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {myData?.data.slice(0, 4).map((row, i) => (
              <CustomTableRow key={i}>
                <CustomTableCell
                  component="th"
                  scope="row"
                  sx={{ padding: "16px 16px 16px 30px" }}
                >
                  <div className="light" style={{ display: "flex" }}>
                    <StatusLabel color={Colors[i]} />
                    {row.Status}
                  </div>
                </CustomTableCell>
                <CustomTableCell className="light" align="right">
                  {row.Quantity}
                </CustomTableCell>
                <CustomTableCell className="light" align="right">
                  {row.Percentage}%
                </CustomTableCell>
                <CustomTableCell
                  className="bold"
                  align="right"
                  sx={{ padding: "16px 50px 16px 16px" }}
                >
                  {new Intl.NumberFormat("en-DE").format(row.TotalAmount)} DKK
                </CustomTableCell>
              </CustomTableRow>
            ))}
          </TableBody>
          <TableHead>
            <TableRow>
              <CustomTableCell
                sx={{ fontWeight: 600, padding: "16px 16px 16px 30px" }}
              >
                {myData?.data[4]?.Status}
              </CustomTableCell>
              <CustomTableCell align="right" sx={{ fontWeight: 600 }}>
                {myData?.data[4]?.Quantity}
              </CustomTableCell>

              <CustomTableCell align="right" sx={{ fontWeight: 600 }}>
                {myData?.data[4]?.Percentage}%
              </CustomTableCell>
              <CustomTableCell
                align="right"
                sx={{ fontWeight: 600, padding: "16px 50px 16px 16px" }}
              >
                {new Intl.NumberFormat("en-DE").format(
                  myData?.data[4]?.TotalAmount
                )}
                &nbsp;DKK
              </CustomTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Paper>
    </>
  );
}

export default AnalysisTable;
