import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PostSigningsCancelOrder } from "../../../API/SigningsTable/SigningsCancelOrder";
import "./signings.css";

const CancelOrderPopup = ({
  zid,
  unitname,
  unitaddress,
  unitno,
  createdby,
  signers,
  handleCancelClose,
}) => {
  //  i18n
  const { t, i18n } = useTranslation();
  const [reasonToCancel, setReasonToCancel] = useState("");

  //API consumption
  const [errorCancelSignings, setErrorCancelSignings] = useState([
    { error: false, status: "", message: "" },
  ]);
  const SigningsCancelOrder = (data) => {
    try {
      PostSigningsCancelOrder(data)
        .then((response) => {})
        .catch((error) => {
          setErrorCancelSignings({
            error: error.message,
            status: error.status,
          });
        });
    } catch (e) {
      setErrorCancelSignings({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };
  const handleSigningsCancel = () => {
    SigningsCancelOrder({ Z_ID: zid, Reason: reasonToCancel });
  };
  return (
    <div className="data cancel-popup-container">
      <div className="cancel-order-head medium fs-18">
        {t("Are you sure that you will cancel this order")}?
      </div>
      <hr className="popup-hrzn-line" />
      <div className="cancel-popup-section">
        <div className="cancel-popup-sub-section">
          <div style={{color : '#00000'}} className="medium fs-13">{unitname}</div>
          <div className="light fs-13"> {unitaddress}</div>
          <div className="light fs-13">{unitno}</div>
        </div>
        <div className="cancel-popup-sub-section">
          <div className="light fs-13">{createdby}</div>
          <div className="light fs-13">{signers}</div>
          <div className="show-more-section">
            <div className="light fs-13 plus-one">+1</div>
            <div className="light fs-13 show-inherit">
              (
              <div className="light fs-13 show-more">
                {t("show more")}
              </div>
              )
            </div>
          </div>
        </div>
      </div>
      <div className="cancel-comment-head medium fs-14">
        {t("Attach a comment to the cancellation")}
      </div>
      <div>
        <input
          type="text"
          className="light fs-13 input-comment"
          value={reasonToCancel}
          onChange={(e) => setReasonToCancel(e.target.value)}
        />
      </div>
      <div className="light fs-10 popup-notify">
        {t(
          "Remember: Write clearly so others can understand what your comment is about."
        )}
      </div>
      <div className="order-cancel-details light fs-13">
        Kasper Nikolajsen cancel this order Sep 13, 2022
      </div>
      <div className="popup-cancel-btn-section">
        <div
          className="semi-bold fw-600 fs-14 no-btn-section"
          onClick={handleCancelClose}
        >
          {t("NO")}
        </div>
        <div
          className="semi-bold fw-600 fs-14 yes-btn-section"
          onClick={() => {
            handleSigningsCancel();
            handleCancelClose();
          }}
        >
          {t("YES")}
        </div>
      </div>
    </div>
  );
};

export default CancelOrderPopup;
