import React from "react";
import { useEffect, useState } from "react";
import "../../dashboard/dashboard.css";
import Map from "../economy/Map";

import { useTranslation } from "react-i18next";

import CountUp from "react-countup";
import { Grid } from "@mui/material";

import house from "../Icons/house.svg";
import Tooltip from "../components/Tooltip/Tooltip";
import { GetAllPropertiesOverview } from "../../../API/OverView/AllPropertiesOverview";
import DeltaArrow from "../components/DeltaArrows/DeltaArrows";
const PropertyData = ({ status, number, change, color, sideBorder, arrow }) => {
  return (
    <>
      <div style={{ display: "flex", padding: "15px" }}>
        <div>
          <div
            className="light fw-300 fs-13"
            style={{ marginBottom: "7px", width: "max-content" }}
          >
            {status}
          </div>

          <div className="bold fs-18 fw-700" style={{ display: "flex" }}>
            <CountUp end={number} duration={1} />
            <div
              className="regular fw-500 fs-11"
              style={{
                display: "flex",
                margin: " 0 0 10px 7px ",
                color: color,
              }}
            >
              {arrow}
              {change}
            </div>
          </div>
        </div>
        <div
          className="side-border"
          style={{
            borderLeft: sideBorder,
          }}
        ></div>
      </div>
    </>
  );
};

function AllPropertiesOverview({ sid, datefilter, ActivitiesProperty }) {
  //i18Next
  const { t, i18n } = useTranslation();

  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetAllProperties(sid);
  }, [sid]);

  const GetAllProperties = () => {
    try {
      GetAllPropertiesOverview(sid)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({ error: true, status: "", message: "Failed to retrieve data" });
    }
  };

  let TotalUnits = 0;
  let total = myData.reduce(function (sum, value) {
    return (TotalUnits += Number(value?.Nof));
  }, 0);

  return (
    <>
      <div
        style={{
          padding: "10px 0 ",
          backgroundColor: "#FFF",
          minHeight: "fit-content",
          boxSizing: "border-box",
          border: "1px solid #F4F5F5",
          display: "flex",
          justifyContent: "space-around",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <div
              className="dashboard-all-properties"
              style={{
                display: "flex",
                padding: "25px 32px",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: "24px" }}>
                <img height={24} src={house} alt="house" />
              </div>

              <div className="bold fs-14">
                {t("selected-properties")}&nbsp;(
                {ActivitiesProperty.length})
              </div>

              <Tooltip text={t("selected-properties")} />
            </div>
          </Grid>

          {myData.map((item, i) => (
            <Grid item xs={12} sm={12} md={1.6} key={i}>
              <PropertyData
                status={item?.Group_ID == 10 ? t("available") : item?.Group_ID == 50 ? t("reserved"): item?.Group_ID == 80 ? t("sold") :  t("leased")}
                number={item?.Nof}
                sideBorder="1px solid #000000"
                change={
                  item?.Nof - item?.Delta === 0
                    ? ""
                    : Math.abs(item?.Nof - item?.Delta)
                }
                color={
                  item?.Nof - item?.Delta > 0
                    ? "#77C7A1"
                    : item?.Nof - item?.Delta < 0
                    ? "#F1664B"
                    : ""
                }
                arrow={<DeltaArrow component={item?.Nof - item?.Delta} />}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={12} md={1.6}>
            <PropertyData status={t("total-units")} number={TotalUnits} />
          </Grid>
        </Grid>
      </div>
      <Map
        sid={sid}
        datefilter={datefilter}
        ActivitiesProperty={ActivitiesProperty}
      />
    </>
  );
}

export default AllPropertiesOverview;
