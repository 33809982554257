import React, { useRef } from "react";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Line } from "react-chartjs-2";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  MenuItem,
  TextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import "./economy.css";
import Tooltip from "../components/Tooltip/Tooltip";
import { GetEconomyProgress } from "../../../API/EconomyProgress/EconomyProgress";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";
import { ArrowUpDown } from "../components/ArrowUpDown/ArrowUpDown";
import usePopup from "../hooks/usePopup";
import ActivityDropdown from "../components/Dropdowns/AcitivityDropdown/ActivityDropdown";
import { GetAllPropertiesByRegionUnitType } from "../../../API/AllPropertiesByRegionUnitType/AllPropertiesByRegionUnitType";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 378px;
  z-index: 1;
`;

function LineChart({ sid, datefilter, ActivitiesProperty }) {
  //i18Next
  const { t, i18n } = useTranslation();

  const Type = [
    { id: 1, label: `${t("weekly")}` },
    { id: 2, label: `${t("monthly")}` },
    { id: 3, label: `${t("quarterly")}` },
  ];
  const [value, setValue] = useState([1]);

  const Activities = ActivitiesProperty;

  const [allChecked, setAllChecked] = React.useState(false);
  const [optionChecked, setOptionChecked] = React.useState([]);

  const OnClicked = (e, isCheckAll) => {
    const { id, checked } = e.currentTarget;

    if (isCheckAll) {
      if (checked) {
        setOptionChecked(Activities.map((list) => list.id));
        setAllChecked(true);
      } else {
        setOptionChecked([]);
        setAllChecked(false);
      }
      GetAllPropertiesByRegionUnitType(0);
    } else {
      if (checked) {
        const checkValue = [...optionChecked, id];
        setOptionChecked(checkValue);

        if (checkValue.length === Activities.length) {
          setAllChecked(true);
          GetAllPropertiesByRegionUnitType(0);
        } else {
          GetAllPropertiesByRegionUnitType(0, checkValue.toString());
        }
      } else {
        const checkValue = optionChecked.filter((item) => item !== id);
        if (checkValue.length != 0) {
          setOptionChecked(checkValue);
          GetAllPropertiesByRegionUnitType(0, checkValue.toString());
          setAllChecked(false);
        } else {
          setOptionChecked([]);
          setAllChecked(false);
          GetAllPropertiesByRegionUnitType(0);
        }
      }
    }
  };

  let eid =
    optionChecked === null ||
    optionChecked === undefined ||
    optionChecked.length === 0
      ? 0
      : optionChecked;

  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetEconomyByProgress(eid, value, datefilter);
  }, [eid, value, datefilter]);

  const GetEconomyByProgress = (eid, datefilter) => {
    try {
      GetEconomyProgress(eid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const data = {
    labels: myData.map((x) =>
      value == 1
        ? x.YearMonth
        : new Date(x.YearMonth).toLocaleDateString("en-En", {
            year: "numeric",
            month: "short",
          })
    ),

    datasets: [
      {
        label: "Available",
        fill: false,
        borderColor: "#77C7A1",
        backgroundColor: "#77C7A1",
        tension: 0.4,
        data: myData.map((x) => x.Ledig_TotalAmount),
        unitStatus: myData.map((x) => x.Ledig_NofStatus),
      },

      {
        label: "Leased",
        fill: false,
        borderColor: "#A2C8D0",
        backgroundColor: "#A2C8D0",

        tension: 0.4,
        data: myData.map((x) => x.Udlejet_TotalAmount),
        unitStatus: myData.map((x) => x.Udlejet_NofStatus),
      },
      {
        label: "Reserved",
        fill: false,

        borderColor: "#F6E651",
        backgroundColor: "#F6E651",
        tension: 0.4,
        data: myData.map((x) => x.Reserveret_TotalAmount),
        unitStatus: myData.map((x) => x.Reserveret_NofStatus),
      },
      {
        label: "Not listed",
        fill: false,
        borderColor: "#888888",
        backgroundColor: "#888888",
        tension: 0.4,
        data: myData.map((x) => x.Skjult_TotalAmount),
        unitStatus: myData.map((x) => x.Skjult_NofStatus),
      },
    ],
  };

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.background = "#fff";
      tooltipEl.style.borderRadius = "3px";
      tooltipEl.style.border = "1px solid #F4F5F5";
      tooltipEl.style.color = "black";
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";
      tooltipEl.style.zIndex = "10";

      const table = document.createElement("table");
      table.style.margin = "0px 10px";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      // console.log(tooltip.body,"body");
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map(
        (b) => `${b.before}, ${b.lines}, ${b.after}`
      );
      //  console.log(bodyLines,"bodylines");
      const tableHead = document.createElement("thead");

      titleLines.forEach((title) => {
        const tr = document.createElement("tr");
        tr.style.borderWidth = 0;

        const th = document.createElement("th");
        th.style.borderWidth = 0;
        const text = document.createTextNode(title);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement("tbody");
      bodyLines.forEach((body, i) => {
        const colors = tooltip.labelColors[i];
        //  console.log(body.split(","),i,"tablebody")
        const span = document.createElement("span");
        // span.style.background = tr;
        // span.style.borderColor = colors.borderColor;
        span.style.borderWidth = "2px";
        span.style.marginRight = "10px";
        span.style.height = "10px";
        span.style.width = "10px";
        span.style.fontSize = "13px";
        span.style.display = "block";

        const tr = document.createElement("tr");
        tr.style.backgroundColor = "inherit";
        tr.style.borderWidth = 0;
        tr.style.fontSize = "13px";

        const tr2 = document.createElement("tr");
        tr2.style.backgroundColor = "inherit";
        tr2.style.borderWidth = 0;
        tr2.style.fontSize = "13px";
        tr2.style.fontFamily = " Cerebri sans-light";

        const tr3 = document.createElement("tr");
        tr3.style.backgroundColor = "inherit";
        tr3.style.borderWidth = 0;
        tr3.style.fontSize = "13px";
        tr3.style.color = "#888888";
        tr3.style.fontFamily = " Cerebri sans-light";

        const tr4 = document.createElement("tr");
        tr2.style.fontSize = "13px";

        const td = document.createElement("td");
        const br1 = document.createElement("br");
        const br2 = document.createElement("br");

        const allBody = body.split(",");
        const text1 = document.createTextNode(allBody[0]);
        // text1.style.fontSize="13px";
        const text2 = document.createTextNode(allBody[1]);
        const text3 = document.createTextNode(allBody[2]);
        const text4 = document.createTextNode("");

        td.appendChild(span);
        tr.appendChild(text1);
        // tr.appendChild(br1);
        tr2.appendChild(text2);
        // tr.appendChild(br2);
        tr3.appendChild(text3);
        tr4.appendChild(text4);

        tableBody.appendChild(td);
        tableBody.appendChild(tr);
        tableBody.appendChild(tr2);
        tableBody.appendChild(tr3);
        tableBody.appendChild(tr4);
      });

      const tableRoot = tooltipEl.querySelector("table");

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
        position: "average",
        external: externalTooltipHandler,
        callbacks: {
          title: function (tooltipItem, index) {
            let title = "";
            // title.push(tooltipItem.dataset.label);
            // console.log(tooltipItem[0].dataset.label,tooltipItem[1].dataset.label,tooltipItem[2].dataset.label,tooltipItem[3].dataset.label, "title")
            return title;
          },
          beforeLabel: function (tooltipItem, index) {
            let beforlabel = [];
            beforlabel.push(tooltipItem.dataset.label);
            let myhome = beforlabel[0];
            // console.log(beforlabel[0]);
            // tooltipItem[0].dataset.label
            // console.log(tooltipItem, "title");
            // console.log(tooltipItem.dataset.label, "title");
            return myhome;
          },
          label: function (context) {
            let lable = "";
            if (context.parsed.y !== null) {
              lable +=
                new Intl.NumberFormat("en-DK").format(context.parsed.y) +
                " DKK";
            }
            return lable;
          },
          afterLabel: function (tooltipItem, context) {
            let unit = tooltipItem.dataset.unitStatus[tooltipItem.dataIndex];
            let unitTitle = tooltipItem.dataset.label;
            //  console.log(tooltipItem.dataIndex);
            //  console.log(tooltipItem);
            // console.log(tooltipItem.dataset.unitStatus[tooltipItem.dataIndex]);
            return unit + " units " + unitTitle;
          },
        },
        // backgroundColor: "#FFFFFF",
        // borderDash: [2, 2],
        // titleColor: "#000000",
        // bodyColor: "#000000",
        // padding: 20,
        // cornerRadius: 8,
        // borderColor: "#F4F5F5",
        // borderWidth: 1,
        // bodySpacing:5,
        // displayColors:false,
        // titleSpacing:20,
      },
      legend: {
        display: true,
        position: "bottom",
        align: "center",

        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 20,
          color: "#00000",
          font: { family: "Cerebri sans-medium", size: "12px" },
        },
      },
    },
    scales: {
      x: {
        grid: {
          color: "rgba(0,0,0,0.0)",
        },
        ticks: {
          fontSize: 18,
          padding: 0,
          color: "#888888",
          font: {
            family: "Cerebri sans-light",
          },
        },
      },
      y: {
        grid: {
          color: "rgba(0,0,0,0.0375)",
          fontColor: "#fff",
        },
        ticks: {
          color: "#888888",
          font: {
            family: "Cerebri sans-light",
          },
        },
      },
    },
  };

  const propertyRef = useRef(null);
  const propertyButtonRef = useRef(null);
  const [PropertyBinding] = usePopup(propertyRef, propertyButtonRef);

  const MonthRef = useRef(null);
  const monthlyButtonRef = useRef(null);
  const [MonthBinding] = usePopup(MonthRef, monthlyButtonRef);

  const myref = useRef();
  const [refObj] = useState(myref);

  return (
    <>
      <div
        ref={myref}
        style={{
          borderRadius: "8px",
          height: "content-fit",
          backgroundColor: "#FFF",
          position: "relative",
        }}
      >
        <div>
          <Card mb={6}>
            <div
              className="display-flex justify-content-between"
              // style={{ paddingTop: "15px" }}
            >
              <div className="margin-heading-card economy-progress-status">
                <div className="heading bold fs-14 fw-700 display-flex">
                  <div>{t("progress-over-time-divided-by-status")}</div>
                  <Tooltip
                    text={t("progress-over-time-divided-by-status")}
                    paragraph=""
                  />
                </div>
              </div>

              <div className="property-and-monthly-section">
                <div className="month-property-dropdown">
                  <div ref={propertyRef}>
                    <div>
                      <div
                        ref={propertyButtonRef}
                        style={{
                          minWidth: "170px",
                          cursor: "pointer",
                          height: "44px",
                          border: "1px solid #F4F5F5",
                          borderRadius: "8px",
                          margin: "10px 0 0 10px",
                          padding: "10px 10px 10px 20px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="light fs-14">
                          {t("all-properties")} ({optionChecked.length})
                        </div>
                        <div>
                          <ArrowUpDown component={PropertyBinding} />
                        </div>
                      </div>
                      {PropertyBinding && (
                        <div className="property-binding-dropdown">
                          <ActivityDropdown
                            parentId="0"
                            allChecked={allChecked}
                            optionChecked={optionChecked}
                            OnClicked={OnClicked}
                            Activities={Activities}
                            Label={t("all-properties")}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <TextField
                    focused="disabled"
                    sx={{
                      width: 116,
                      height: 44,
                      marginTop: "8px",
                      backgroundColor: "#ffff",
                      // border: "1px solid #f4f5f5",
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          // borderColor: "#f4f5f5",
                          border: "1px solid #f4f5f5",
                          borderRadius: "8px",
                        },
                      },
                    }}
                    variant="outlined"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    select
                  >
                    {Type.map((item, i) => {
                      return (
                        <MenuItem
                          sx={{ width: "116px" }}
                          key={i}
                          value={item.id}
                        >
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>

                <DownloadWidget
                  refObj={refObj}
                  fileName={"economy-progress-status"}
                  type={true}
                />
              </div>
            </div>
            <CardHeader />
            <CardContent>
              <ChartWrapper>
                <Line data={data} options={options} />
              </ChartWrapper>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}
export default LineChart;
