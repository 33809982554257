import { useRef, useState } from "react";
// import TooltipPopup from "./TooltipPopup";
import questionmark from "../../Icons/questionmark.svg";
import "./TooltipPopup.css";
import usePopup from "../../hooks/usePopup";

const Tooltip = ({ text, paragraph }) => {
  // const [showPopup, setShowPopup] = useState(false);

  const filterModalRef = useRef(null);
  const filterModalToggleButtonRef = useRef(null);
  const [showTooltip] = usePopup(filterModalRef, filterModalToggleButtonRef);
  // const onClickHandler = () => {
  //   setShowPopup(!showPopup);
  // };

  return (
    <div>
      <div ref={filterModalRef}>
        <div
          className="button"
          ref={filterModalToggleButtonRef}
          // onClick={onClickHandler}
        >
          <img
            height={12}
            className="question-mark"
            src={questionmark}
            alt="Question mark"
          />
        </div>
        {showTooltip && (
          <>
            <div className="box">
              <div className="parent semi-bold  fs-13">{text}</div>
              <div className="children light">{paragraph}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Tooltip;
