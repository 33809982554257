import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "./traffic.css";
import CloseIcon from "@mui/icons-material/Close";

import Tooltip from "../components/Tooltip/Tooltip";
import funnelArrow from "../Icons/moveable-icon.svg";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";
import { PostTrafficAddFunnel } from "../../../API/TrafficAddFunnel/TrafficAddFunnel";
import { GetTrafficFunnelStepUrlList } from "../../../API/Traffic/TrafficGetFunnelStepUrlList";

import { GetTrafficFunnels } from "../../../API/Traffic/TrafficGetFunnel";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FunnelPage from "./FunnelPage";
import { useForm, Controller } from "react-hook-form";
import { REQUIRED_ERR } from "../../../utils/validationConstants";

const FunnelPopup = ({
  handleCreateFunnelClose,
  sid,
  type,
  datefilter,
  GetTrafficFunnel,
}) => {
  //i18Next
  const { t, i18n } = useTranslation();

  //Add steps
  const [inputFields, setInputFields] = useState([{ url: "", name: "" }]);
  const [funnelName, setfunnelName] = useState("");

  const addStep = () => {
    // console.log(inputFields, "inputfields")
    // let newFields = [...inputFields, { url: "", name: "" }]
    // console.log(newFields)
    setInputFields([...inputFields, { url: "", name: "" }]);
  };

  // Add Step

  const AddNewStep = ({
    index,
    removeFormFields,
    handleChange,
    data,
    sid,
    type,
  }) => {
    const clickHandler = useCallback(() => {
      removeFormFields(index);
    }, [index, removeFormFields]);

    //i18Next
    const { t, i18n } = useTranslation();

    //API Consumption
    const [myData, setMyData] = useState([]);
    const [error, setError] = useState([
      { error: false, status: "", message: "" },
    ]);

    useEffect(() => {
      TrafficFunnelStepUrlList(sid, type);
    }, []);

    const TrafficFunnelStepUrlList = (sid, type) => {
      try {
        GetTrafficFunnelStepUrlList(sid, type)
          .then((data) => {
            if (data != null && data !== undefined) {
              setMyData(data.data);
            } else {
              setMyData([null]);
            }
          })
          .catch((error) => {
            setError({ error: error.message, status: error.status });
            setMyData([null]);
          });
      } catch (e) {
        setMyData([null]);
        setError({
          error: true,
          status: "",
          message: "Failed to retrieve data",
        });
      }
    };

    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="funnel-texboxes">
            <div className="regular fw-500 fs-14 funnel-rank">#{index + 1}</div>
            <div>
              <Controller
                name="category"
                {...register("nameOfPage")}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    // onChange={(e) => handleChange(index, e)}
                    label={t("name-of-page")}
                    className="funnel-namepage"
                    sx={{ width: 196, backgroundColor: "#fff" }}
                    select
                    defaultValue={""}
                    // value={data.url}
                    onChange={(e) => {
                      onChange(e);
                      // setEid(e.target.value);
                    }}
                    value={value}
                    name="url"
                  >
                    {myData.map((option) => (
                      <MenuItem key={option.SYS_ID} value={option.Pages}>
                        {option.Pages}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              {errors?.nameOfPage && (
                <p
                  style={{
                    color: "red",
                  }}
                >
                  {REQUIRED_ERR}
                </p>
              )}
            </div>
            <div>
              <TextField
                value={data.name}
                type="text"
                name="name"
                onChange={(e) => handleChange(index, e)}
                label={t("alias-optional")}
                className="regular fw-300 fs-14 funnel-namepage"
                sx={{ width: 196, backgroundColor: "#fff" }}
              ></TextField>
            </div>
            <div className="regular handleDrag fw-500 fs-14 funnel-icons">
              <img height={20} src={funnelArrow} alt="funnelArrow" />
            </div>
          </div>

          <div
            className="remove-funnel light-italic fw-300 fs-14"
            onClick={clickHandler}
          >
            {t("remove")}
          </div>
        </div>
      </div>
    );
  };

  //

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      nameOfFunnel: "",
      nameOfPage: "",
    },
  });

  const removeFormFields = (i) => {
    let newSteps = [...inputFields];
    newSteps.splice(i, 1);
    setInputFields(newSteps);
  };
  const handleChange = (i, e) => {
    let newStepValue = [...inputFields];
    newStepValue[i][e.target.name] = e.target.value;
    setInputFields(newStepValue);
  };

  const handleAddFunnel = (data) => {
    console.log("datata", data);
    TrafficAddFunnel({
      E_ID: sid,
      Type: type,
      Funnel: { name: funnelName, steps: inputFields },
    });
    setTimeout(() => {
      GetTrafficFunnel(sid, type, datefilter);
    }, 4000);
  };

  //API consumption
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);
  const TrafficAddFunnel = (data) => {
    try {
      PostTrafficAddFunnel(data)
        .then((response) => {})
        .catch((error) => {
          setError({ error: error.message, status: error.status });
        });
    } catch (error) {
      setError({ error: true, status: "", message: "Failed to retrieve data" });
    }
  };

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const updatedList = [...inputFields];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setInputFields(updatedList);
  };

  return (
    <div className="funnel-popup-container">
      <div>
        <div
          className="display-flex justify-content-between"
          style={{ height: "64px" }}
        >
          <div className="display-flex margin-20">
            <div>
              <div className="funnel-header bold fw-700 fs-14">
                {t("create-funnel")}
              </div>
            </div>
            <Tooltip text={t("create-funnel")} paragraph="" />
          </div>
          <div className="div-close-button">
            <a className="close" onClick={handleCreateFunnelClose}>
              <div className="mt-7px ml-6px">
                <CloseIcon fontSize="large" />
              </div>
            </a>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}></div>
      </div>
      <hr className="horizonal-line" />

      <form onSubmit={handleSubmit(handleAddFunnel)}>
        <div
          className="scroller"
          style={{ height: "291px", paddingBottom: "30px" }}
        >
          <div>
            <Controller
              control={control}
              {...register("nameOfFunnel")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  // onChange={(e) => setfunnelName(e.target.value)}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("nameOfFunnel");
                  }}
                  type="text"
                  label={t("name-of-funnel")}
                  className="regular fw-500 fs-14 funnel-popup"
                />
              )}
            />
            {errors?.nameOfFunnel && (
              <p
                style={{
                  color: "red",
                  marginLeft: "25px",
                }}
              >
                {REQUIRED_ERR}
              </p>
            )}
          </div>

          <div>
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId="list-container">
                {(provided) => (
                  <div
                    className="list-container"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {inputFields.map((input, index) => (
                      <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="item-container"
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <AddNewStep
                              sid={sid}
                              type={type}
                              data={input}
                              index={index}
                              removeFormFields={removeFormFields}
                              handleChange={handleChange}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <div className="add-step" onClick={addStep}>
            <div className="dash-funnel-border">
              <div className="add-step-text medium fw-500 fs-14 uppercase">
                {t("add-step")}
              </div>
            </div>
          </div>
        </div>

        <div className="regular fw-500 fs-14 funnel-btn">
          <div className="funnel-btn-btn">
            <div
              onClick={handleCreateFunnelClose}
              className="medium fw-500 fs-14 btn-text-no uppercase"
            >
              {t("cancel")}
            </div>
          </div>
          <button type="submit" className="funnel-btn-green">
            <div className="medium fw-500 fs-14  uppercase">{t("save")}</div>
          </button>
        </div>
      </form>
    </div>
  );
};

function Funnels({ sid, datefilter }) {
  //i18Next
  const { t, i18n } = useTranslation();

  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    // 👇️ toggle shown state
    setIsShown((current) => !current);

    // 👇️ or simply set it to true
    // setIsShown(true);
  };

  //Download Widget
  const myref = useRef();
  const [refObj] = useState(myref);

  //Switch for Eido & website
  const [invisible, setInvisible] = React.useState(false);
  const [typeSwitch, setTypeSwitch] = useState(1);
  const toggleSwitch = (e) => {
    if (typeSwitch == 1) {
      setTypeSwitch(2);
    } else {
      setTypeSwitch(1);
    }
    setInvisible(!invisible);
  };

  //Create funnel dialogue box
  const [openCreateFunnel, setOpenCreateFunnel] = React.useState(false);
  const handleCreateFunnel = () => {
    setOpenCreateFunnel(true);
  };
  const handleCreateFunnelClose = () => {
    setOpenCreateFunnel(false);
  };
  //Create funnel dialogue box end

  //API Consumption get funnel
  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  const GetTrafficFunnel = (sid, typeSwitch, datefilter) => {
    try {
      GetTrafficFunnels(sid, typeSwitch, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([]);
        });
    } catch (e) {
      setMyData([]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  useEffect(() => {
    GetTrafficFunnel(sid, typeSwitch, datefilter);
  }, [sid, typeSwitch, datefilter]);
  return (
    <>
      <div
        ref={myref}
        style={{
          borderRadius: "8px",
          height: "content-fit",
          backgroundColor: "#FFF",
          position: "relative",
        }}
      >
        <div className="paper">
          <div className="card-wrapper">
            <div className="card-header">
              <div className="margin-heading-card header-text display-flex justify-content-between">
                <div className="bold fw-700 fs-14">{t("funnels")}</div>
                <Tooltip text={t("funnels")} paragraph="" />
              </div>
              <div style={{ display: "flex" }}>
                <Grid
                  item
                  sx={{
                    justifyContent: "space-between",
                    marginTop: "16px",
                    marginRight: "26px",
                  }}
                >
                  <Typography
                    display="inline"
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Cerebri sans-light",
                      fontWeight: "300",
                    }}
                  >
                    {t("Ei.DO")}
                  </Typography>
                  <FormControlLabel
                    sx={{ alignItems: "center", marginLeft: "5px" }}
                    control={
                      <Switch
                        color="success"
                        checked={invisible}
                        onChange={toggleSwitch}
                      />
                    }
                  />
                  <Typography
                    display="inline"
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Cerebri sans-light",

                      fontWeight: "300",
                    }}
                  >
                    {t("website")}
                  </Typography>
                </Grid>
                <Button
                  onClick={handleCreateFunnel}
                  className="medium fs-13 fw-500"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "44px",

                    backgroundColor: "#619130",
                    color: "#FFFFFF",
                    borderRadius: "8px",
                    cursor: "pointer",
                    marginTop: "16px",
                    padding: "10px",
                    width: "fit-content",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                  }}
                >
                  <span style={{ margin: "auto" }}>
                    &nbsp;
                    <AddIcon />
                  </span>
                  <span className="medium fs-13 fw-500 uppercase">
                    &nbsp;{t("create-funnel")}
                  </span>
                </Button>
                <Dialog
                  open={openCreateFunnel}
                  maxWidth="md"
                  fullWidth
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "fit-content",
                        // overflow : 'hidden',
                        // position: 'relative'
                      },
                    },
                  }}
                  onClose={handleCreateFunnelClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <FunnelPopup
                    GetTrafficFunnel={GetTrafficFunnel}
                    sid={sid}
                    type={typeSwitch}
                    datefilter={datefilter}
                    handleCreateFunnelClose={handleCreateFunnelClose}
                  />
                </Dialog>

                <div>
                  <DownloadWidget
                    refObj={refObj}
                    fileName={"traffic-funnel"}
                    type={true}
                  />
                </div>
              </div>
            </div>

            <div>
              <FunnelPage
                sid={sid}
                type={typeSwitch}
                datefilter={datefilter}
                funnelData={myData}
                GetTrafficFunnel={GetTrafficFunnel}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Funnels;
