import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./signings.css";

import {
  Dialog,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  Select,
  InputLabel,
  tableCellClasses,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { fontSize } from "@mui/system";
import usePopup from "../hooks/usePopup";
import { useTranslation } from "react-i18next";
import { GetSigningsActor } from "../../../API/SigningsTable/SigningsGetActor";
import { GetSigningReminders } from "../../../API/SigningsTable/SigningsGetReminder";
import { borderRadius } from "polished";
import { PostSigningEdit } from "../../../API/SigningsTable/SigningsEditSignings";
import { PostSigningsRemoveSigner } from "../../../API/SigningsTable/SigningsRemoveSigner";
import RemindPopup from "./RemindPopup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { REQUIRED_ERR } from "../../../utils/validationConstants";

// moreovericon section

const MoreOverPopup = ({
  zyzsid,
  zyzssid,
  stepNumber,
  SigningsReminder,
  eid,
  zid,
  name,
  actor,
  orderId,
}) => {
  const [openRemindPopup, setOpenRemindPopup] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCancel, setOpenCancel] = React.useState(false);

  const handleCancelClickOpen = () => {
    setOpenCancel(true);
  };
  const handleEditClickOpen = () => {
    setOpenEdit(true);
  };

  const handleCancelClose = () => {
    setOpenCancel(false);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };

  //i18n
  const { t, i18n } = useTranslation();
  //API Consumption Remover signer
  const [signerError, setSignerError] = useState([
    { error: false, status: "", message: "" },
  ]);
  const RemoveSigner = (data) => {
    try {
      PostSigningsRemoveSigner(data)
        .then((data) => {})
        .catch((error) => {
          setSignerError({ error: error.message, status: error.status });
        });
    } catch (e) {
      setSignerError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const handleRemoveSigner = () => {
    RemoveSigner({
      ZYZ_SS_ID: zyzssid,
      ZYZ_S_ID: zyzsid,
      Step: stepNumber,
    });
    setTimeout(() => {
      SigningsReminder(eid, zid);
    }, 1000);
  };

  const handleClickRemind = () => {
    setOpenRemindPopup(true);
    handleClose();
  };
  const handleRemindPopup = () => {
    setOpenRemindPopup(false);
  };

  return (
    <>
      <div>
        <div
          style={{
            backgroundColor: "#ffffff",
            border: "1px solid #f4f5f5",
            borderRadius: "8px",
            height: "44px",
            width: "44px",
            transform: `rotate(${anchorEl ? "90" : "0"}deg)`,
          }}
        >
          <IconButton
            // aria-label="more"
            onClick={handleClick}
            aria-haspopup="true"
            aria-controls="long-menu"
            sx={{
              ml: 1,
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
              },
            }}
          >
            <span className="moreverticons-span">
              <MoreVertIcon style={{ fill: "#619130" }} />
            </span>
          </IconButton>
        </div>
        <div className="download-dropdown-signings">
          <div className="regular button-label">
            <Menu
              id="simple-menu"
              align="center"
              anchorEl={anchorEl}
              keepMounted
              onClose={handleClose}
              open={open}
              sx={{ marginTop: "10px" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <span>
                <MenuItem onClick={handleClickRemind} className="hover-gray">
                  <div className="light fs-14">{t("remind")}</div>
                </MenuItem>
              </span>
              <hr className="download-hr-signings" />
              <span onClick={handleCancelClickOpen}>
                <MenuItem onClick={handleRemoveSigner} className="hover-gray">
                  {t("delete")}
                </MenuItem>
              </span>
            </Menu>
          </div>
        </div>
        <Dialog
          open={openRemindPopup}
          onClose={handleRemindPopup}
          maxWidth="xl"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                height: "260px",
                borderRadius: "8px",
                maxWidth: "384px",
                overflowX: "hidden",
                overflowY: "hidden",
              },
            },
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <RemindPopup
            handleClose={handleRemindPopup}
            stepNumber={stepNumber}
            zid={zid}
            name={name}
            actor={actor}
            orderId={orderId}
          />
        </Dialog>
      </div>
    </>
  );
};

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const LowerTableRow = ({ eid, zid, handleEditClose, created }) => {
  //i18n
  const { t, i18n } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm({
    // defaultValues: {
    //   name: "",
    //   email: "",
    //   actor: "",
    // },
  });

  // const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
  //   control, // control props comes from useForm (optional: if you are using FormContext)
  //   name: "name", // unique name for your Field Array
  // });
  //API Consumption Get signings reminder
  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  const SigningsReminder = (eid, zid) => {
    try {
      GetSigningReminders(eid, zid)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  useEffect(() => {
    SigningsReminder(eid, zid);
  }, [eid, zid]);

  //API Consumption Signings Actors
  const [actorData, setActorData] = useState([]);
  const [errorActor, setErrorActor] = useState([
    { error: false, status: "", message: "" },
  ]);
  const signingsActor = (eid) => {
    try {
      GetSigningsActor(eid)
        .then((data) => {
          if (data != null && data != undefined) {
            setActorData(data.data);
          } else {
            setActorData([null]);
          }
        })
        .catch((error) => {
          setErrorActor({ error: error.message, status: error.status });
          setActorData([null]);
        });
    } catch (e) {
      setActorData([null]);
      setErrorActor({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  useEffect(() => {
    signingsActor(eid);
  }, [eid]);

  //API Consumption Edit signings
  const [signingError, setSigningError] = useState([
    { error: false, status: "", message: "" },
  ]);
  const EditSigningOrder = (data) => {
    try {
      PostSigningEdit(data)
        .then((data) => {})
        .catch((error) => {
          setSigningError({ error: error.message, status: error.status });
        });
    } catch (e) {
      setSigningError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const handleChange = (e, index) => {
    let newData = [...myData];
    newData[index][e.target.name] = e.target.value;

    setMyData(newData);
  };

  useEffect(() => {}, [myData]);

  const handleUpdateSignings = (data) => {
    console.log(data, "data");
    // let editData = myData.map((item) => ({
    //   Name: item.Name,
    //   Email: item.Email,
    //   SG_ID: item.ActorId,
    //   Step: item.StepNumber,
    //   Zyz_S_Id: item.Zyz_S_Id,
    //   ZYZ_SS_ID: item.ZYZ_SS_ID,
    // }));
    // EditSigningOrder(editData);
    // handleEditClose();
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleUpdateSignings)}>
        <div className="customer-lower-table-section scrollable-container">
          {myData?.map((item, index) => (
            <div style={{ marginBottom: "11px" }} key={item.StepNumber}>
              <div className="lowerTableRow-section">
                <CustomTableRow>
                  <TableCell
                    width="10%"
                    align="center"
                    className="regular fw-300 fs-13"
                    sx={{ border: "none", padding: "11px 15px 11px 15px" }}
                  >
                    <div className="light fs-13">{t(item.Status)}</div>
                  </TableCell>

                  <TableCell
                    width="15%"
                    sx={{ border: "none", padding: "11px 15px 11px 15px" }}
                  >
                    <TextField
                      width="12%"
                      sx={{
                        height: "44px",
                        width: "100%",
                        paddingRight: "",
                        backgroundColor: "#ffff",
                      }}
                      id="outlined-basic"
                      label={t("name")}
                      variant="outlined"
                      {...register(`name-${index}`, {
                        required: true,
                        onChange: (e) => {
                          handleChange(e, index);
                        },
                      })}
                      defaultValue={item.Name}
                    />

                    {errors[`name-${index}`] && (
                      <p style={{ color: "red" }}>{REQUIRED_ERR}</p>
                    )}
                  </TableCell>

                  <TableCell
                    width="20%"
                    sx={{ border: "none", padding: "11px 15px 11px 15px" }}
                  >
                    <TextField
                      sx={{
                        height: "44px",
                        width: "100%",
                        backgroundColor: "#ffff",
                      }}
                      type="text"
                      label={t("e-mail")}
                      variant="outlined"
                      {...register(`email-${index}`, {
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "invalid email address"
                        },
                        onChange: (e) => {
                          handleChange(e, index);
                        },
                      })}
                      
                      defaultValue={item.Email}
                    />
                    {errors[`email-${index}`] && (
                      <p style={{ color: "red" }}>{REQUIRED_ERR}</p>
                    )}
                  </TableCell>
                  <TableCell
                    width="15%"
                    sx={{
                      border: "none",
                      padding: "11px 15px 11px 15px",
                      fontFamily: "Cerebri sans-light",
                    }}
                  >
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        {t("actor")}
                      </InputLabel>
                      <Select
                        sx={{ width: "118px", backgroundColor: "#fff" }}
                        labelId="demo-simple-select-label"
                        name="ActorId"
                        id="Actor"
                        value={item.ActorId}
                        onChange={(e) => handleChange(e, index)}
                        label={t("actor")}
                      >
                        {actorData?.map((option) => (
                          <MenuItem value={option.SYS_SG_ID}>
                            {option.Group_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell
                    width="10%"
                    sx={{
                      whiteSpace: "nowrap",
                      padding: "25px 15px 25px 15px",
                      fontFamily: "Cerebri Sans",
                      fontSize: "13px",
                      border: "none",
                    }}
                  >
                    <div className="light fs-13">
                      {new Date(created).toLocaleDateString("en-En", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </div>
                  </TableCell>
                  <TableCell
                    width="10%"
                    sx={{
                      whiteSpace: "nowrap",
                      fontFamily: "Cerebri Sans",
                      padding: "25px 15px 25px 15px",
                      fontSize: "13px",
                      border: "none",
                    }}
                  >
                    <div className="light fs-13">
                      {item.SignedDate == "-"
                        ? "-"
                        : new Date(item.SignedDate).toLocaleDateString(
                            "en-En",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            }
                          )}
                    </div>
                  </TableCell>
                  <TableCell
                    width="10%"
                    sx={{
                      whiteSpace: "nowrap",
                      fontFamily: "Cerebri Sans",
                      padding: "25px 15px 25px 15px",
                      fontSize: "13px",
                      border: "none",
                    }}
                  >
                    <div className="light fs-13">
                      {item.LastReminder == "-"
                        ? "-"
                        : new Date(item.LastReminder).toLocaleDateString(
                            "en-En",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            }
                          )}
                    </div>
                  </TableCell>
                  <TableCell
                    width="10%"
                    sx={{ border: "none", paddingLeft: "52px" }}
                  >
                    <MoreOverPopup
                      zyzsid={item.Zyz_S_Id}
                      zyzssid={item.ZYZ_SS_ID}
                      stepNumber={item.StepNumber}
                      SigningsReminder={SigningsReminder}
                      eid={item.E_ID}
                      zid={zid}
                      name={item.Name}
                      actor={item.Actor}
                      orderId={item.OrderId}
                    />
                  </TableCell>
                </CustomTableRow>
              </div>
            </div>
          ))}
        </div>
        <div className="popup-update-btn-section">
          <button
            type="submit"
            // onClick={handleUpdateSignings}
            className="regular fw-600 fs-14 yes-btn-section uppercase"
          >
            {t("update")}
          </button>
        </div>
      </form>
    </>
  );
};

const EditSigninPopup = ({
  eid,
  zid,
  handleEditClose,
  unitname,
  unitaddress,
  unitno,
  status,
  createdby,
  signers,
  created,
  expires,
  reminder,
  interval,
}) => {
  //i18n
  const { t, i18n } = useTranslation();

  const CustomTableCell = styled(TableCell)`
    &.${tableCellClasses.head} {
      background: #ffffff;
      color: #000000;
      border: none;
      font-family: "Cerebri sans-medium";
      font-size: 12px;
      color: #888888;
      background-color: #f2f6ee;
      padding: 19px 0 9px 25px;
      top: 20px;
      height: 20px;
    }
    &.${tableCellClasses.body} {
      border: none;
      font-family: "Cerebri sans-light";
      background-color: #f2f6ee;
      padding: 1px 0 24px 25px;

      height: 20px;

      font-size: 13px;
    }
  `;
  const CustomLowerTableCell = styled(TableCell)`
    &.${tableCellClasses.head} {
      background: #ffffff;
      color: #000000;
      border: none;
      font-family: "Cerebri sans-medium";
      font-size: 12px;
      line-height: 22px;
      white-space: nowrap;
    }
    &.${tableCellClasses.body} {
      border: none;
      font-family: "Cerebri sans-light";
      font-size: 13px;
    }
  `;

  const CustomTableRow = styled(TableRow)`
    // background-color: #f2f6ee;
  `;

  //API Consumption Get signings reminder
  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  const SigningsReminder = (eid, zid) => {
    try {
      GetSigningReminders(eid, zid)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  useEffect(() => {
    SigningsReminder(eid, zid);
  }, [eid, zid]);

  return (
    <div>
      <div className="Edit-signin-container">
        <div className="zindex sticky">
          <div className="Edit-signin-header-section">
            <div className="Edit-signin-header-subsection">
              <div className="bold fs-14">{unitaddress}</div>
              <div className="signin-text medium fs-14">
                {unitname} | {unitno}
              </div>
            </div>
            <div className="div-close-button" onClick={handleEditClose}>
              <a className="close">
                <div style={{ marginLeft: "5px", marginTop: "5px" }}>
                  <CloseIcon fontSize="large" />
                </div>
              </a>
            </div>
          </div>
          <hr className="sticky edit-signin-order-hr-line" />
        </div>

        <div className="customer-up-table-section">
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell style={{ paddingLeft: "36px" }}>
                  {t("status")}
                </CustomTableCell>
                <CustomTableCell align="left">{t("created")}</CustomTableCell>
                <CustomTableCell align="left">{t("expires")}</CustomTableCell>
                <CustomTableCell align="left">
                  {t("created-by")}
                </CustomTableCell>
                <CustomTableCell align="left">{t("reminder")}</CustomTableCell>
                <CustomTableCell align="left">{t("interval")}</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <CustomTableRow sx={{ position: "relative", bottom: "6px" }}>
                <CustomTableCell component="th" scope="row">
                  <div
                    className="medium fs-12  status"
                    style={{
                      backgroundColor:
                        status.slice(0, 1) === "0"
                          ? "#FF6868"
                          : status.slice(0, 1) > status.slice(2, 3)
                          ? "#FFE49E"
                          : status.slice(0, 1) === status.slice(2, 3)
                          ? "#B5E2AF"
                          : "null",
                    }}
                  >
                    <div>{status}</div>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="left" width="15%">
                  <div className="light fs-13">
                    {created == "-"
                      ? "-"
                      : new Date(created).toLocaleDateString("en-En", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                  </div>
                </CustomTableCell>
                <CustomTableCell align="left">
                  <div className="light fs-13">
                    {expires == "-"
                      ? "-"
                      : new Date(expires).toLocaleDateString("en-En", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                  </div>
                </CustomTableCell>
                {/* <CustomTableCell align="left">{createdby}</CustomTableCell> */}
                <CustomTableCell align="left">{createdby}</CustomTableCell>
                <CustomTableCell align="left">
                  <div className="light fs-14">
                    {reminder == "-"
                      ? "-"
                      : new Date(reminder).toLocaleDateString("en-En", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                  </div>
                </CustomTableCell>
                <CustomTableCell align="left" width="15%">
                  <div className="light fs-14">{interval}</div>
                </CustomTableCell>
              </CustomTableRow>
            </TableBody>
          </Table>
        </div>
        <div className="customer-down-table-section">
          <Table>
            <TableHead>
              <TableRow>
                <CustomLowerTableCell align="center" width="10%">
                  {t("status")}
                </CustomLowerTableCell>
                <CustomLowerTableCell
                  align="left"
                  width="15%"
                  sx={{ paddingLeft: "22px" }}
                >
                  {t("name")}
                </CustomLowerTableCell>
                <CustomLowerTableCell align="left" width="20%">
                  {t("e-mail")}
                </CustomLowerTableCell>
                <CustomLowerTableCell align="left" width="15%">
                  {t("actor")}
                </CustomLowerTableCell>
                <TableCell
                  align="left"
                  width="10%"
                  sx={{
                    border: "none",
                    padding: 0,
                    fontFamily: "Cerebri sans-medium",
                    fontSize: "12px",
                  }}
                >
                  {t("received-on")}
                </TableCell>
                <TableCell
                  align="left"
                  width="10%"
                  sx={{
                    border: "none",
                    padding: 0,
                    fontFamily: "Cerebri sans-medium",
                    fontSize: "12px",
                  }}
                >
                  {t("date-of-signing")}
                </TableCell>
                <TableCell
                  align="left"
                  width="10%"
                  sx={{
                    border: "none",
                    padding: 0,
                    fontFamily: "Cerebri sans-medium",
                    fontSize: "12px",
                  }}
                >
                  {t("last-reminder")}
                </TableCell>
                <CustomLowerTableCell width="10%"></CustomLowerTableCell>
              </TableRow>
            </TableHead>
          </Table>
        </div>
        <LowerTableRow
          zid={zid}
          eid={eid}
          handleEditClose={handleEditClose}
          created={created}
        />
      </div>
    </div>
  );
};

export default EditSigninPopup;
