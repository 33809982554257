import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ColorToggleButton({ value, onChange }) {
  //i18N
  const { t, i18n } = useTranslation();

  // const [alignment, setAlignment] = React.useState("web");

  // const handleChange = (event, newAlignment) => {
  //   setDisplayTypeID(newAlignment);
  // };

  const StyledToggleButton = styled(ToggleButton)(({ selectedColor }) => ({
    textTransform: "capitalize",
    "&.Mui-selected": {
      color: "rgb(97, 145, 48)",
      backgroundColor: "rgb(97, 145, 48, 0.05)",
    },
    "&:hover": {
      color: "rgb(97, 145, 48)",
      backgroundColor: "rgb(97, 145, 48, 0.05)",
    },
  }));

  const theme = createTheme({
    palette: {
      text: {
        primary: "#619130",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={onChange}
        aria-label="Platform"
      >
        <StyledToggleButton
          sx={{
            borderRadius: "8px 0 0 8px",
            width: "126px",
            fontSize: "14px",
            fontWeight: "400",
          }}
          value="Map Milestone"
        >
          {t("map-milestone")}
        </StyledToggleButton>
        <StyledToggleButton
          value="Chart Milestone"
          sx={{
            borderRadius: "0px 8px 8px 0px",
            width: "126px",
            whiteSpace: "nowrap",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {t("chart-milestone")}
        </StyledToggleButton>
      </ToggleButtonGroup>
    </ThemeProvider>
  );
}
