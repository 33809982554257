import React, { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./signings.css";

import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "../components/Tooltip/Tooltip";
import { GetSigningReminders } from "../../../API/SigningsTable/SigningsGetReminder";

const CustomTabelCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: Cerebri sans-medium;
    font-size: 12px;
    border: none;
    white-space: nowrap;
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family: Cerebri sans-light;
    font-size: 13px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
  }
`;

const LastReminder = ({ remindStatus }) => {
  return (
    <>
      <div className="pop-container regular fs-13 fw-500">
        <span>{remindStatus}</span>
      </div>
    </>
  );
};

function createData(
  status,
  name,
  actor,
  dateOfSigning,
  lastReminder,
  remindStatus
) {
  return { status, name, actor, dateOfSigning, lastReminder, remindStatus };
}

const rows = [
  createData("Done", "Stig Werner Isaksen", "Tenant", "Sep 23, 10:14", "-"),
  createData(
    "Pending",
    "Stig Werner Isaksen",
    "Tenant",
    "-",
    "Sep 23, 10:14",
    <LastReminder remindStatus="REMIND AGAIN" />
  ),
  createData(
    "Pending",
    "Stig Werner Isaksen",
    "Tenant",
    "-",
    "-",
    <LastReminder remindStatus="REMIND" />
  ),
];

function SigningDetail({ close, zid, eid }) {
  // API Consumption
  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  const SigningsReminder = (eid, zid) => {
    try {
      GetSigningReminders(eid, zid)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  useEffect(() => {
    SigningsReminder(eid, zid);
  }, [eid, zid]);

  return (
    <>
      <div className="reserved-container">
        <div
          className="display-flex justify-content-between"
          style={{ height: "64px" }}
        >
          <div className="display-flex margin-20">
            <div className="bold fs-14">Signers Details</div>
            <Tooltip text="Signing order" paragraph="" />
          </div>
          <div className="div-close-button">
            <a className="close" onClick={close}>
              <div className="mt-7px ml-6px">
                <CloseIcon fontSize="large" />
              </div>
            </a>
          </div>
        </div>
        <hr className="horizontal-line" />

        <TableContainer className="padding-15px" component={Paper}>
          <div className="scrollable-container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className="sticky">
                  <CustomTabelCell>Status</CustomTabelCell>
                  <CustomTabelCell align="center">Name</CustomTabelCell>
                  <CustomTabelCell align="center">Actor</CustomTabelCell>
                  <CustomTabelCell align="center">
                    Date of Signing
                  </CustomTabelCell>
                  <CustomTabelCell align="center">
                    Last reminder
                  </CustomTabelCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myData.map((row, id) => (
                  <CustomTableRow
                    key={id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <CustomTabelCell component="th" scope="row">
                      {row.Status}
                    </CustomTabelCell>
                    <CustomTabelCell align="left">{row.Name}</CustomTabelCell>
                    <CustomTabelCell align="center">
                      {row.Actor}
                    </CustomTabelCell>
                    <CustomTabelCell align="center">
                      {row.SignedDate}
                    </CustomTabelCell>
                    <CustomTabelCell align="center">
                      {row.LastReminder}
                    </CustomTabelCell>
                  </CustomTableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </div>
    </>
  );
}

export default SigningDetail;
