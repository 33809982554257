import React from "react";
import "./overviews.css";

import { Grid } from "@mui/material";

import UnitStatus from "./UnitStatus";
import StatusActivity from "./StatusActivity";
import NotesActivity from "./NotesActivity";
import ActionActivity from "./ActionActivity";
import AllPropertiesOverview from "./AllPropertiesOverview";
import UnitProgressOverTime from "./UnitProgressOverTime";

function Overview({ sid, datefilter, ActivitiesProperty }) {
  return (
    <>
      <div className="container-overview">
        <div style={{ zIndex: "-1" }}>
          <AllPropertiesOverview
            sid={sid}
            ActivitiesProperty={ActivitiesProperty}
            datefilter={datefilter}
          />
        </div>

        <div className="overview-top-section">
          <Grid container spacing={6}>
            <Grid item xs={12} lg={6}>
              <div className=" container-unitstatus">
                <UnitStatus sid={sid} datefilter={datefilter} />
              </div>
            </Grid>

            <Grid item xs={12} lg={6}>
              <div className=" container-statusactivity">
                <StatusActivity sid={sid} datefilter={datefilter} />
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="overview-bottom-section">
          <Grid container spacing={6}>
            <Grid item xs={12} lg={6}>
              <div className="container-notesactivity">
                <NotesActivity sid={sid} datefilter={datefilter} />
              </div>
            </Grid>

            <Grid item xs={12} lg={6}>
              <div className="container-actionactivity">
                <ActionActivity sid={sid} datefilter={datefilter} />
              </div>
            </Grid>
          </Grid>
          <Grid>
            <UnitProgressOverTime sid={sid} datefilter={datefilter} />
          </Grid>
        </div>
        {/* <Grid>
            <AddComments />
          </Grid> */}
      </div>
    </>
  );
}

export default Overview;
