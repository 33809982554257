import React, { useRef, useState, useEffect } from "react";
import "./overviews.css";

import { Grid } from "@mui/material";

import styled from "@emotion/styled";
import { Doughnut } from "react-chartjs-2";

import { Card as MuiCard } from "@mui/material";

import { spacing } from "@mui/system";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import usePopup from "../hooks/usePopup";

import Tooltip from "../components/Tooltip/Tooltip";
import { GetOverviewAllPropertiesOverview } from "../../../API/OverviewAllPropertiesOverview/OverviewAllPropertiesOverview";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 201px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  line-height: 20px;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: Cerebri sans-medium;
    font-weight: 500;
    font-size: 13px;
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family: Cerebri sans-light;
    font-weight: 300;
    font-size: 13px;
  }
`;

function UnitStatus({ sid, datefilter }) {
  //i18Next
  const { t, i18n } = useTranslation();

  const options = {
    maintainAspectRatio: false,
    cutout: "80%",
    borderWidth: 2,
    plugins: {
      tooltip: {
        yAlign: "top",
        backgroundColor: "#FFFFFF",
        borderWidth: 2,
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
      },
      legend: {
        display: false,
      },
    },
  };

  const plugins = [
    {
      afterDraw: function (chart) {
        if (chart.data.datasets[0].data.every((item) => item === 0)) {
          let ctx = chart.ctx;
          let width = chart.width;
          let height = chart.height;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "25px Cerebri sans";
          ctx.fillText("No data available", width / 2, height / 2);
          ctx.restore();
        }
      },
    },
  ];

  const CustomTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #f9fafa;
    }
  `;

  const StatusLabel = ({ text, color }) => {
    return (
      <div>
        <span
          style={{
            background: color,
            minWidth: "1em",
            minHeight: "1em",
            display: "inline-block",
            marginRight: "1em",
            borderRadius: "4px",
            fontWeight: "500px",
            fontSize: "13px",
            fontFamily: "cerebri-sans",
          }}
        ></span>
        {text}
      </div>
    );
  };

  const TotalLabel = ({ total, quantity }) => {
    return (
      <>
        <div className="medium fs-13 fw-500">
          <div>{total}</div>
          <div>{quantity}</div>
        </div>
      </>
    );
  };

  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetAllPropertiess();
  }, [sid, datefilter]);

  const GetAllPropertiess = () => {
    try {
      GetOverviewAllPropertiesOverview(sid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({ error: true, status: "", message: "Failed to retrieve data" });
    }
  };

  const [hidden, available, reserved, sold, leased] = myData;

  const TotalUnits =
    parseInt(available?.Nof) +
    parseInt(reserved?.Nof) +
    parseInt(sold?.Nof) +
    parseInt(leased?.Nof);

  const data = {
    labels: [
      `${t("not-Listed")}`,
      `${t("available")}`,
      `${t("reserved")}`,
      `${t("sold")}`,
      `${t("leased")}`,
    ],
    datasets: [
      {
        data: [0, available?.Nof, reserved?.Nof, sold?.Nof, leased?.Nof],
        backgroundColor: [
          "#888888",
          "#77C7A1",
          "#F6E651",
          "#F1664B",
          "#A2C8D0",
        ],
      },
    ],
  };

  function createData(status, quantity) {
    return { status, quantity };
  }
  const rows = [
    createData(<StatusLabel text={t("not-Listed")} color="#888888" />, 0),
    createData(
      <StatusLabel text={t("available")} color="#77C7A1" />,
      available?.Nof
    ),
    createData(
      <StatusLabel text={t("reserved")} color="#F6E651" />,
      reserved?.Nof
    ),
    createData(<StatusLabel text={t("sold")} color="#F1664B" />, sold?.Nof),
    createData(<StatusLabel text={t("leased")} color="#A2C8D0" />, leased?.Nof),
    createData(
      <TotalLabel total={t("total")} />,
      <TotalLabel quantity={TotalUnits} />
    ),
  ];

  const filterModalRef = useRef(null);
  const filterModalToggleButtonRef = useRef(null);
  const [showDownloadLabel] = usePopup(
    filterModalRef,
    filterModalToggleButtonRef
  );

  const myref = useRef();
  const [refObj] = useState(myref);

  return (
    <>
      <div
        ref={myref}
        style={{
          borderRadius: "8px",
          height: "content-fit",
          backgroundColor: "#FFF",
          position: "relative",
        }}
      >
        <div className="unitstatus-card-header">
          <div className="display-flex justify-content-between">
            <div className="unitstatus-card-title">
              <div className="heading bold fs-14 fw-700">
                {t("unit-status")}
              </div>
              <Tooltip text={t("unit-status")} paragraph="" />
            </div>
            <DownloadWidget
              refObj={refObj}
              fileName={"overview-unit-status"}
              type={true}
            />
          </div>
        </div>

        <div
          style={{ justifyContent: "space-around" }}
          className="display-flex  align-items-center"
        >
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <div className="position-relative">
                <Spacer mb={6} />

                <ChartWrapper>
                  <DoughnutInner>
                    {!data.datasets[0].data.every((item) => item === 0) && (
                      <>
                        <div className="light fs-14 fw-300">
                          {t("total-units")}
                        </div>

                        <div
                          style={{ marginTop: "4px" }}
                          className="bold fs-16 fw-700"
                        >
                          {TotalUnits}
                        </div>
                      </>
                    )}
                  </DoughnutInner>
                  <Doughnut data={data} options={options} plugins={plugins} />
                </ChartWrapper>
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div style={{ margin: "0 20px", paddingBottom: "30px" }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <CustomTableCell>{t("status")}</CustomTableCell>
                      <CustomTableCell align="right">
                        {t("quantity")}
                      </CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, key) => (
                      <CustomTableRow
                        key={key}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <CustomTableCell component="th" scope="row">
                          {row.status}
                        </CustomTableCell>
                        <CustomTableCell align="right">
                          {row.quantity}
                        </CustomTableCell>
                      </CustomTableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default UnitStatus;
