import * as React from "react";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import Calender from "../../Icons/calendar.svg";

export default function ManualMileStoneDatePicker({
  label,
  setTargetEndDate,
  targetEndDate,
  onChange,
}) {
  const StyledTextField = styled(TextField, {
    name: "StyledTextField",
  })({
    width: 254,
    "& .MuiInputBase-root": {
      "& fieldset": {
        borderColor: "#f4f5f5",
      },
      "&:hover fieldset": {
        borderColor: "#f4f5f5",
      },
      height: 48,
      borderRadius: "8px",
      borderColor: "1px solid #f4f5f5",
    },
  });

  const handleChange = (newValue) => {
    setTargetEndDate(newValue);
  };

  function DateIcon() {
    return (
      <img
        height={17}
        src={Calender}
        alt="calendar"
        style={{ padding: "0 7px" }}
      />
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DesktopDatePicker
          components={{
            OpenPickerIcon: DateIcon,
          }}
          sx={{
            "& .MuiInputBase-root": {
              "& fieldset": {
                borderColor: "#f4f5f5",
              },
              "&:hover fieldset": {
                borderColor: "#f4f5f5",
              },
              height: 48,
              borderRadius: "8px",
              borderColor: "#f4f5f5",
            },
          }}
          label={label}
          inputFormat="D.MMM.YYYY"
          value={targetEndDate}
          onChange={handleChange}
          renderInput={(params) => (
            <StyledTextField
              InputLabelProps={{
                style: { color: "rgb(144,144,144)", border: "#f4f5f5" },
              }}
              sx={{
                svg: { Calender },
                "& .css-1khnrup-MuiButtonBase-root-MuiIconButton-root:hover": {
                  backgroundColor: "transparent",
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                  "& > fieldset": {
                    borderColor: "#888888",
                    color: "#f4f5f5",
                  },
                },
              }}
              {...params}
              fullWidth
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}
