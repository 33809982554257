import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { GetTrafficHeatMap } from "../../../API/TrafficHeatMap/TrafficHeatMap";
import styled from "@emotion/styled";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import Tooltip from "../components/Tooltip/Tooltip";

import "./traffic.css";
import { GetAllPropertiesByRegionUnitType } from "../../../API/AllPropertiesByRegionUnitType/AllPropertiesByRegionUnitType";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";

import img1 from "../heatmapStaticImg/1.png";
import img2 from "../heatmapStaticImg/2.png";
import img3 from "../heatmapStaticImg/3.png";
import img4 from "../heatmapStaticImg/4.png";
import img5 from "../heatmapStaticImg/5.png";
import img6 from "../heatmapStaticImg/6.png";
import img7 from "../heatmapStaticImg/7.png";
import moment from "moment";
import NoDataScreen from "../components/NoDataScreen";
const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
];

// HeatMap Show Details Table

function HeatMapTableDetails({ myData, setiframePageID }) {
  const handleSeeMore = (PageID) => {
    setiframePageID(PageID - 8);
  };

  const CustomTableCell = styled(TableCell)`
    &.${tableCellClasses.head} {
      background: #ffffff;
      color: #000000;
      // border-bottom: none;
      font-family: "Cerebri sans-medium";
      font-size: 13px;
      font-weight: 500;
      border: none;
      white-space: nowrap;
    }
    &.${tableCellClasses.body} {
      border-bottom: none;
      font-family: "Cerebri sans-medium";
      font-size: 13px;
      font-weight: 500;
      white-space: nowrap;

      border: none;
    }
  `;
  //i18Next
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="heatmap-container">
        <div>
          <TableContainer component={Paper}>
            <Table aria-label="simple table" sx={{ marginTop: "19px" }}>
              <TableHead>
                <TableRow>
                  <CustomTableCell align="left"></CustomTableCell>
                  <CustomTableCell align="left">{t("page")}</CustomTableCell>
                  <CustomTableCell align="left">{t("views")} </CustomTableCell>
                  <CustomTableCell align="center">
                    {t("visit-time")}
                  </CustomTableCell>
                  <CustomTableCell align="center">
                    {t("clicks")}
                  </CustomTableCell>
                  <CustomTableCell align="center"></CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myData.map((row, i) => (
                  <CustomTableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <CustomTableCell
                      width="8%"
                      align="left"
                      component="th"
                      scope="row"
                    >
                      <div>
                        <img
                          style={{ borderRadius: "8px" }}
                          alt="myFrame"
                          width="50px"
                          frameBorder="0"
                          height="50px"
                          scrolling="no"
                          src={images[i]}
                        ></img>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell
                      width="35%"
                      align="left"
                      component="th"
                      scope="row"
                    >
                      <div className="title-case">
                        {row.Pages === "/"
                          ? "Frontpage"
                          : row.Pages.split("").slice(1).join("")}
                      </div>

                      <div className="light fw-300 fs-14">{row.Pages}</div>
                    </CustomTableCell>
                    <CustomTableCell align="left">
                      <div className="light fw-300 fs-13">{row.Views}</div>
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      <div className="light fw-300 fs-13">{row.Visit_Time}</div>
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      <div className="light fw-300 fs-13">{row.Clicks}</div>
                    </CustomTableCell>
                    <CustomTableCell width="14%" align="center">
                      <button className="heatmap-btn">
                        <div
                          className="medium fs-12 fw-500"
                          onClick={() => handleSeeMore(row.PageID)}
                        >
                          {t("see-details")}
                        </div>
                      </button>
                    </CustomTableCell>
                  </CustomTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}
///

// Listview Table section

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    // border-bottom: none;
    font-family: "Cerebri Sans";
    font-weight: 700;
    font-size: 13px;
    width: 100%;
    // border-top : 1px solid #E0E0E0
    white-space: nowrap;
  }
  &.${tableCellClasses.body} {
    font-size: 13px;
    border: none;
    font-family: "Cerebri sans-light";
    font-weight: 300;
    width: 100%;
    // border-bottom : 1px solid #E0E0E0
    white-space: nowrap;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
    height: 44px;
    border: none;
    border-radius: 4px;
  }
`;

// Data
let id = 0;
function createDataListView(participants, value) {
  id += 1;
  return { id, participants, value };
}

const ListViewTable = ({ myData, iframePageID }) => {
  const rows = [
    createDataListView("Views", myData?.[iframePageID]?.Views),
    createDataListView("Clicks", myData?.[iframePageID]?.Clicks),
    createDataListView("Visit time", myData?.[iframePageID]?.Visit_Time),
    createDataListView(
      "Scroll",
      myData?.[iframePageID]?.Scroll === undefined
        ? ""
        : myData?.[iframePageID]?.Scroll + " %"
    ),
    createDataListView("Engagement", myData?.[iframePageID]?.Engagement),
    createDataListView("Entry page", myData?.[iframePageID]?.Entry_Page),
  ];

  //i18Next
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="heatMap-table-section">
        <Table mb={6}>
          <TableHead>
            <div style={{ marginBottom: "20px" }}>
              <TableRow>
                <div
                  style={{ marginLeft: "14px" }}
                  className="medium fw-500 fs-14"
                >
                  {t("frontpage")}
                </div>
              </TableRow>
            </div>
          </TableHead>
          <div>
            <TableBody>
              {rows.map((row) => (
                <CustomTableRow key={row.id}>
                  <CustomTableCell component="th" scope="row">
                    {row.participants}
                  </CustomTableCell>
                  <CustomTableCell align="right">{row.value}</CustomTableCell>
                </CustomTableRow>
              ))}
            </TableBody>
          </div>
        </Table>
      </div>
    </>
  );
};

const HeatMapWebsite = ({ sid, datefilter }) => {
  const [iframePageID, setiframePageID] = useState(0);

  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);
  const [propertyName, setPropertyName] = useState([]);

  useEffect(() => {
    GetHeatMapData(sid, 2, datefilter);
    GetPropertyName();
  }, [sid, datefilter]);

  const GetHeatMapData = (sid, type, datefilter) => {
    try {
      GetTrafficHeatMap(sid, 2, datefilter)
        .then((data) => {
          if (data != null && data !== undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };
  const GetPropertyName = () => {
    try {
      GetAllPropertiesByRegionUnitType(0)
        .then((data) => {
          if (data != null && data !== undefined) {
            setPropertyName(data.data);
          } else {
            setPropertyName([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setPropertyName([null]);
        });
    } catch (e) {
      setPropertyName([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const PropertyNameList = () => {
    return (
      <>
        {propertyName.map((PropertyName) => {
          return (
            <div className="download-property">
              <div className="regular dropdown-property-cell">
                <div>{PropertyName.PropertyName}</div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  //Options for CSV data
  const options = {
    keys: [
      { field: "PageID", title: "PageID" },
      { field: "EIDO_WID", title: "EIDO_WID" },
      { field: "Pages", title: "Pages" },
      { field: "Title", title: "Title" },
      { field: "Views", title: "Views" },
      { field: "Visit_Time", title: "Visit_Time" },
      { field: "Scroll", title: "Scroll" },
      { field: "Engagement", title: "Engagement" },
      { field: "Entry_Page", title: "Entry_Page" },
      { field: "Share_Url", title: "Share_Url" },
      { field: "IsDefault", title: "IsDefault" },
      { field: "Last_Update", title: "Last_Update" },
    ],
  };

  //i18Next
  const { t, i18n } = useTranslation();

  return (
    <div
      style={{
        backgroundColor: "#ffff",
        borderRadius: "8px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div style={{ display: "flex", margin: "20px", zIndex: "2" }}>
          <div className="heatmap-heading bold fw-700 fs-14">
            {t("heatmap")}{" "}
          </div>
          <Tooltip text={t("heatmap")} paragraph="" />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                //   margin: "20px",
                //   border: "1px solid #F4F5F5",
                //   borderRadius: "8px",
                //   height: "44px",
                //   width: "44px",
                //   alignItems: "center",
                zIndex: 2,
              }}
            >
              <DownloadWidget
                options={options}
                data={myData}
                fileName={"heatmap-website"}
                type={false}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: "relative", bottom: "26px", left: "24px" }}>
        <div className="regular fw-300 fs-14">{t("website")}</div>
      </div>
      {myData?.length != 0 ? (
        <Grid className="iframe-listview-section" container spacing={2}>
          <Grid item md={8} lg={8} xs={12}>
            <div
              className="medium fw-500 fs-12"
              style={{
                marginBottom: "18px",
                position: "relative",
                top: "49px",
              }}
            >
              {t("Last update")}:{" "}
              {moment(myData[iframePageID]?.Last_Update).format("MMM DD")}
            </div>
            <div
              style={{
                borderRadius: "8px",
                overflow: "hidden",
                zIndex: "10",
                position: "relative",
                top: "52px",
              }}
            >
              <iframe
                frameBorder="0"
                title="mouseflow"
                scrolling="no"
                width="100%"
                height="438px"
                src={myData[iframePageID]?.Share_Url}
                // src={images[iframePageID]}
              ></iframe>
            </div>
          </Grid>
          <Grid item md={4} lg={4} xs={12}>
            <ListViewTable iframePageID={iframePageID} myData={myData} />
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <HeatMapTableDetails
              setiframePageID={setiframePageID}
              myData={myData}
            />
          </Grid>
        </Grid>
      ) : (
        <NoDataScreen />
      )}
    </div>
  );
};

export default HeatMapWebsite;
