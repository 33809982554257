import { Get } from "../../estateTool/dashboard/utils/Axios";
import { endpoint } from "../EndPoint";
import { GetHeader } from "../Header";

// export const GetAllPropertiesByRegionUnitType = (RegionID, Type) => {
//   RegionID =
//     RegionID === "" || RegionID === null || RegionID === undefined
//       ? 0
//       : RegionID;
//   Type = Type === "" || Type === null || Type === undefined ? 0 : Type;
//   return new Promise((resolve, reject) => {
//     Get(
//       endpoint.Common_AllProperties_ByRegion_UnitType,
//       GetHeader(),
//       RegionID + "/" + Type
//     )
//       .then((response) => {
//         resolve(response.data);
//       })
//       .catch((error) => {
//         reject({ error: true, message: error.message });
//       });
//   });
// };

export const GetAllPropertiesByRegionUnitType = (
  RegionID,
  Type,
  searchText
) => {
  RegionID =
    RegionID === "" || RegionID === null || RegionID === undefined
      ? 0
      : RegionID;
  Type = Type === "" || Type === null || Type === undefined ? 0 : Type;
  searchText =
    searchText === "" || searchText === null || searchText === undefined
      ? ""
      : searchText;
  return new Promise((resolve, reject) => {
    let data =
      RegionID + "/" + Type + (searchText === "" ? "" : "/" + searchText);
    Get(endpoint.Common_AllProperties_ByRegion_UnitType, GetHeader(), data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
