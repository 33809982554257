import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { PutCommonUpdateStarProperty } from "../../../../API/Common/CommonUpdateStarProperty";
import StarredActive from "../../Icons/starred-active.svg";
import StarredInactive from "../../Icons/starred-inactive.svg";

const Star = ({ eid, isStar, AllStarProperty}) => {
  const [star, setStar] = useState(isStar);

  //API consumption
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);
  const CommonUpdateStarProperty = (data) => {
    try {
      PutCommonUpdateStarProperty(data)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
        });
    } catch (error) {
      setError({ error: true, status: "", message: "Failed to retrieve data" });
    }
  };


  

  const handleStarClick = (eid, star) => {
    let starFlag = star === "1" ? "0" : "1";
    CommonUpdateStarProperty({ E_ID: eid, IsStar: starFlag });
    setStar(starFlag);
    AllStarProperty()
   
  };

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <img
          height={16}
          src={star == "1" ? StarredActive : StarredInactive}
          alt="star"
          onClick={() => handleStarClick(eid, star)}
        />
      </div>
    </>
  );
};

export default Star;
