import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "../components/Tooltip/Tooltip";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { PostAddComments } from "../../../API/OverView/PostComments";
import {
  GetUnitProgressNotesActivity,
  GetUnitProgressNotesCategory,
} from "../../../API/OverView/GetCommentsNotesCategory";
import { GetUnitProgressGetComments } from "../../../API/OverView/GetComments";
import { position } from "polished";
import { useForm, Controller } from "react-hook-form";
import { REQUIRED_ERR } from "../../../utils/validationConstants";

const AddComments = ({
  AllComments,
  handleAddCommentClose,
  rpId,
  bid,
  eId,
  pId,
  mfId,
  cId,
}) => {
  // API Consumption for Notes Category

  const [getNotesCategory, setNotesCategory] = useState([]);
  const [getNotesCategoryError, setNotesCategoryError] = useState([
    { error: false, status: "", message: "" },
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      category: "",
      title: "",
      note: "",
    },
  });

  const GetAllComments = () => {
    try {
      GetUnitProgressNotesCategory(rpId)
        .then((data) => {
          if (data != null && data != undefined) {
            setNotesCategory(data.data);
          } else {
            setNotesCategory([null]);
          }
        })
        .catch((error) => {
          setNotesCategoryError({ error: error.message, status: error.status });
          setNotesCategory([null]);
        });
    } catch (e) {
      setNotesCategory([null]);
      setNotesCategoryError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  useEffect(() => {
    GetAllComments(rpId);
  }, [rpId]);

  // API Consumption for Get Comments

  const [category, setCategory] = useState(getNotesCategory);
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");

  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);
  const OverViewUnitProgressAddComments = (data) => {
    try {
      PostAddComments(data)
        .then((response) => {})
        .catch((error) => {
          setError({ error: error.message, status: error.status });
        });
    } catch (error) {
      setError({ error: true, status: "", message: "Failed to retrieve data" });
    }
  };

  const HandleAddComment = (value) => {
    console.log("data", value);
    const data = {
      SYS_RP_ID: rpId,
      Title: value?.title,
      Note: value?.note,
      Note_Category: value?.category,
      B_ID: bid,
      E_ID: eId,
      P_ID: pId,
      MF_ID: mfId,
      C_ID: cId,
    };
    OverViewUnitProgressAddComments(data);
    console.log("objectData", data);
  };

  const HandleCommentClick = () => {
    handleAddCommentClose();
    HandleAddComment();

    setTimeout(() => {
      AllComments();
    }, 1000);
  };

  return (
    <form onSubmit={handleSubmit(HandleAddComment)}>
      <div className="Add-comments-container">
        <div className="sticky">
          <div className="new-comments-subcontainer">
            <div className="new-events-header">
              <div className="bold fs-14">Add Comment</div>
            </div>
            <div
              className="new-events-moreovericon"
              onClick={handleAddCommentClose}
            >
              <a>
                <span
                  style={{
                    position: "relative",
                    top: "10px",
                    left: "10px",
                  }}
                >
                  <CloseIcon style={{ fill: "#000000", margin: "1px 1px" }} />
                </span>
              </a>
            </div>
          </div>
          <hr style={{ border: "1px solid #F4F5F5" }} />
        </div>
        <div className="scrollable-container" style={{ height: "300px" }}>
          <div className="add-commments-input-section">
            <div className="medium fs-14">Category</div>
            <div>
              <div className="status-section-ss">
                <FormControl>
                  <Controller
                    name="category"
                    {...register("category")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        select
                        label="Category"
                        type="text"
                        value={value}
                        // onChange={(e) => setCategory(e.target.value)}
                        onChange={(e) => {
                          onChange(e);
                          // setEid(e.target.value);
                        }}
                        sx={{ width: 254, height: 44, backgroundColor: "#fff" }}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {getNotesCategory.map((item, i) => {
                          return (
                            <MenuItem
                              sx={{ width: "236px" }}
                              key={i}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    )}
                  />
                  {errors?.category && (
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </FormControl>
              </div>
            </div>
          </div>

          <div className="add-commments-title-section">
            <div className="medium fs-14">Title</div>
            <div>
              <div className="status-section-ss">
                <div>
                  <Controller
                    control={control}
                    {...register("title")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        sx={{ width: 436, height: 44, backgroundColor: "#fff" }}
                        label="Title"
                        value={value}
                        // onChange={(e) => setTitle(e.target.value)}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("title");
                        }}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      ></TextField>
                    )}
                  />
                  {errors?.title && (
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="add-commments-desc-section">
            <div className="medium fs-14">Comments</div>
            <div>
              <div className="status-section-ss">
                <div>
                  <Controller
                    control={control}
                    {...register("note")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="text"
                        value={value}
                        placeholder="Comment"
                        className="light fs-13 input-add-comments"
                        // onChange={(e) => setNote(e.target.value)}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("note");
                        }}
                      />
                    )}
                  />
                  {errors?.note && (
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="popup-add-btn-section"
          // style={{ width: "100%", position: "absolute" }}
        >
          <button
            type="submit"
            // onClick={HandleCommentClick}
            className="regular fw-600 fs-14 yes-btn-section"
          >
            ADD
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddComments;
