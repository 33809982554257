import React from "react";
import styled from "@emotion/styled";
// import { Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import questionmark from "../Icons/questionmark.svg";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "../components/Tooltip/Tooltip";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";

function PopularComponents() {
  //i18Next
  const { t, i18n } = useTranslation();

  const CustomTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #f9fafa;
    }
  `;
  const CustomTableCell = styled(TableCell)`
    &.${tableCellClasses.head} {
      background: #ffffff;
      color: #000000;
      border-bottom: none;
      font-family: "Cerebri sans-medium";
      font-size: 13px;
      font-weight: 500;
    }
    &.${tableCellClasses.body} {
      border-bottom: none;
      font-family: "Cerebri sans-light";
      font-size: 13px;
      font-weight: 300;
    }
  `;

  function createData(components, views) {
    return { components, views };
  }

  const rows = [
    createData("List View", 4),
    createData("3D View", 43),
    createData("Gallery", 22),
    createData("Download", 22),
    createData("Like and Compare", 22),
    createData("Filters", 22),
  ];

  //Options for CSV data
  const options = {
    keys: [{ field: "List View", title: "List View" }],
  };

  return (
    <>
      <div className="paper">
        <div className="card-wrapper popular-components ">
          <div className="card-header">
            <div className="margin-heading-card header-text">
              <div>
                <div className="bold fw-700 fs-14">
                  {t("popular-components")}
                </div>
              </div>
              <Tooltip text={t("popular-components")} paragraph="" />
            </div>
            <div>
              <DownloadWidget
                options={options}
                data={rows}
                fileName={"traffic-popular-components"}
                type={false}
              />
            </div>
          </div>
          <div>
            <TableContainer component={Paper} sx={{ padding: "0 24px 24px" }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <CustomTableCell>{t("component")}</CustomTableCell>
                    <CustomTableCell align="right">
                      {t("views")}
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, id) => (
                    <CustomTableRow
                      key={id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <CustomTableCell component="th" scope="row">
                        {row.components}
                      </CustomTableCell>
                      <CustomTableCell align="right">
                        {row.views}
                      </CustomTableCell>
                    </CustomTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopularComponents;
