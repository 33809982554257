import React from "react";
import { Grid } from "@mui/material";
import TransactionAge from "./TransactionAge";
import TransactionRoom from "./TransactionRoom";
import TransactionSquareMeters from "./TransactionSquareMeters";
import CustomerJourney from "./CustomerJourney";
import BreakdownChart from "./BreakDownChart";

const Customer = ({ sid, datefilter }) => {
  return (
    <>
      <Grid
        sx={{
          backgroundColor: "#F1F4EF",
          padding: "30px 30px 10px 30px",
          borderRadius: "10px",
        }}
      >
        <Grid>
          <BreakdownChart sid={sid} datefilter={datefilter} />
        </Grid>
        <Grid>
          <TransactionAge sid={sid} datefilter={datefilter} />
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={6}>
            <div
              style={{
                backgroundColor: "#FFF",
                borderRadius: "8px",
                marginBottom: "14px",
                
              }}
            >
              <TransactionRoom sid={sid} datefilter={datefilter} />
            </div>
          </Grid>

          <Grid item xs={12} lg={6}>
            <div
              style={{
                backgroundColor: "#FFF",
                borderRadius: "8px",
                marginLeft: "9px",
              }}
            >
              <TransactionSquareMeters sid={sid} datefilter={datefilter} />
            </div>
          </Grid>
        </Grid>
        <Grid xs={12} lg={6}>
          <div style={{ marginTop: "14px" }}>
            {/* <CustomerJourney sid={sid} datefilter={datefilter} />  */}
            {/* To be hided as discussed with claus & Supriya ma'am */}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Customer;
