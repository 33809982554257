export const endpoint = {
  Common_AllPropertiesBy_Search: "/properties/0",

  Common_AllProperties_ByRegion_UnitType: "/properties",
  Common_All_Regions: "/regions",
  Common_UserbyProperty: "/property/user",
  Common_UpdateStarProperty: "/property/star",
  Common_Get_Star_Properties: "/property/star/get",

  Overview_All_Properties_Overview: "/overview/units",
  Overview_Status_Activity: "/overview/status",
  Overview_Notes_Activity: "/overview/notes",
  Overview_Notes_Category_By_EID: "/overview/note/category",
  Overview_Action_Activity: "/overview/actions",
  Overview_Unit_Progress: "/overview/unit/progress",
  Overview_Unit_ProgressDetail_By_Bid: "/overview/unit/progress-details",
  Overview_Update_Like_Status: "/overview/like/status",

  Economy_Analysis: "/economy/analysis",
  Economy_Idleness: "/economy/idle",
  Economy_Progress: "/economy/progress",
  Economy_Availability_Rooms: "/economy/rooms",
  Economy_Availability_Sqmtr: "/economy/sqmtr",
  Economy_IdlenessRate: "/economy/idle/rate",
  Economy_UpcomingIdleness: "/economy/idle/upcoming",

  Traffic_Graph: "/traffic/graph",
  Traffic_Overview: "/traffic/overview",
  Traffic_Popular_Units: "/traffic/units",
  Traffic_HeatMap: "/traffic/heatmap",
  Traffic_AddFunnel: "/traffic/funnels/add",
  Traffic_UpdateFunnel: "/traffic/funnels/update",
  Traffic_DeleteFunnel: "/traffic/funnels/delete",
  Traffic_PopularFilters: "/traffic/filters",
  Traffic_GetFunnelStepUrlsList: "/traffic/funnels/urls",

  Customers_BreakdownRegions: "/customers/regions",
  Customers_GetAge: "/customers/age",
  Customers_Rooms: "/customers/rooms",
  Customers_Sqrmtr: "/customers/sqmtr",
  Customers_Journey: "/customers/journey",
  Customers_BreakdownRegion_By_ZipCode: "/customers/zipcodes",

  Event_Age: "/events/age",
  Event_Gender: "/events/gender",
  Event_Residence: "/events/residence",
  Event_HeardProperty: "/events/social",
  Events_FutureEvents: "/events/future",
  Events_AddEvents: "/events/add",

  Signings_Table: "/signings/get",

  Traffic_Get_Funnels: "/traffic/funnels/get",
  Signing_Send_Reminder: "/signings/reminder",
  Signings_Cancel_Order: "/signings/cancel",
  Signings_Get_Actors: "/signings/actors",
  Signings_Get_Reminders: "/signings/reminders",
  Signings_Edit_Signing: "/signings/edit",
  Signings_Remove_Signer: "/signings/signer/remove",

  OverView_Unit_progress_GetComments: "/overview/unit/comment/get",
  OverView_Unit_progress_GetNotesCategory: "/overview/note/category",

  OverView_Unit_progress_PostComments: "/overview/unit/comment/add",
  OverView_Unit_progress_DeleteComments: "/overview/unit/comment/delete",

  Events_CompletedEvents: "/events/completed",
  Events_EventsHistory: "/events/history",
  Events_CivilStatus: "/events/civil",
  Events_statistics: "/events/statistics",
  Events_CompletedParticipants: "/events/completed/participants",
  Events_Delete_CompletedEvents: "/events/delete",
  Events_Delete_Participant: "/events/participant/delete",
  Economy_Idleness_Show: "/economy/idle/units",
  Overview_Unit_Progress_Tenants: "/overview/unit/tenants",
  Add_Milestone: "/milestones/add",
  Get_Milestone: "/milestones/get",
  Delete_Milestone: "/milestones/delete",
  Milestone_Active: "/milestones/active",
  Events_AddParticipants: "/events/participants/add",
  Events_PreferredUnits: "/events/preffered/units",
  Events_ParticipantsById: "/events/participants/details",
  Events_CompletedEevents_Countries: "/countries",
  Events_EditParticipant: "/events/participants/update",
  Common_GetPropertiesById: "/property",
  Common_GetMilestoneProperties: "/milestones/properties",
  Common_EditMilestone:"/milestones/update"
};
