import { Dialog, MenuItem, TextField } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { GetTrafficFunnels } from "../../../API/Traffic/TrafficGetFunnel";
import { GetTrafficFunnelStepUrlList } from "../../../API/Traffic/TrafficGetFunnelStepUrlList";
import { DeleteTrafficFunnel } from "../../../API/TrafficDeleteFunnel/TrafficDeleteFunnel";
import pencil from "../Icons/edit-pencil.svg";
import CloseIcon from "@mui/icons-material/Close";
import { PutTrafficUpdateFunnel } from "../../../API/TrafficUpdateFunnel/TrafficUpdateFunnel";
import funnelArrow from "../Icons/moveable-icon.svg";
import Tooltip from "../components/Tooltip/Tooltip";
import { ref } from "yup";
import { useRef } from "react";
import { parse } from "date-fns/esm";
import SouthIcon from "@mui/icons-material/South";
import { linearGradient } from "polished";

function FunnelPage({ sid, type, datefilter, funnelData, GetTrafficFunnel }) {
  //i18Next
  const { t, i18n } = useTranslation();

  const FunnelPopup = ({ newName, steps, handleEditFunnelClose, funnelId }) => {
    const [funnelName, setfunnelName] = useState(newName);
    const [funnelSteps, setFunnelSteps] = useState(steps);

    const addStep = () => {
      setFunnelSteps([...funnelSteps, { url: "", name: "" }]);
    };

    const removeFormFields = (i) => {
      let newSteps = [...funnelSteps];
      newSteps.splice(i, 1);
      setFunnelSteps(newSteps);
    };
    const handleChange = (i, e) => {
      let newStepValue = [...funnelSteps];
      newStepValue[i][e.target.name] = e.target.value;
      setFunnelSteps(newStepValue);
    };

    const handleUpdateFunnel = () => {
      let data = {
        FunnelId: funnelId,
        Funnel: { name: funnelName, steps: funnelSteps },
      };
      TrafficUpdateFunnel(data);
    
      setTimeout(() => {
        GetTrafficFunnel(sid, type, datefilter);
      }, 1);
    };

    //API consumption for Update funnel
    const [errorUpdateFunnel, setErrorUpdateFunnel] = useState([
      { error: false, status: "", message: "" },
    ]);
    const TrafficUpdateFunnel = (data) => {
      try {
        PutTrafficUpdateFunnel(data)
          .then((response) => {})
          .catch((error) => {
            setErrorUpdateFunnel({
              error: error.message,
              status: error.status,
            });
          });
      } catch (e) {
        setErrorUpdateFunnel({
          error: true,
          status: "",
          message: "Failed to retrieve data",
        });
      }
    };

    const handleDrop = (droppedItem) => {
      // Ignore drop outside droppable container
      if (!droppedItem.destination) return;
      const updatedList = [...funnelSteps];
      // Remove dragged item
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      // Add dropped item
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      // Update State
      setFunnelSteps(updatedList);
    };

    return (
      <>
        <Dialog
          open={openCreateFunnel}
          maxWidth="md"
          fullWidth
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "fit-content",
                // overflow : 'hidden',
                // position: 'relative'
              },
            },
          }}
          onClose={(e) => handleEditFunnelClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="funnel-popup-container">
            <div>
              <div className="display-flex justify-content-between">
                <div className="display-flex margin-20">
                  <div>
                    <div className="funnel-header bold fw-700 fs-14">
                      {t("edit")} {t("funnel")}
                    </div>
                  </div>
                  <Tooltip text={`${t("edit")} ${t("funnel")}`} paragraph="" />
                </div>
                <div className="div-close-button">
                  <a className="close" onClick={handleEditFunnelClose}>
                    <div className="mt-7px ml-6px">
                      <CloseIcon fontSize="large" />
                    </div>
                  </a>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}></div>
            </div>
            <hr className="horizonal-line" />
            <div className="scroller" style={{height : '291px',paddingBottom : '30px'}}>
            <div>
              <TextField
                value={funnelName}
                onChange={(e) => setfunnelName(e.target.value)}
                type="text"
                label={t("Funnel name")}
                className="light fw-500 fs-14 funnel-popup"
              />
            </div>
            <div>
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container">
                  {(provided) => (
                    <div
                      className="list-container"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {funnelSteps?.map((steps, i) => (
                        <Draggable key={i} draggableId={i.toString()} index={i}>
                          {(provided) => (
                            <div
                              className="item-container"
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                            >
                              <AddNewStep
                                name={steps.name}
                                url={steps.url}
                                index={i}
                                removeFormFields={removeFormFields}
                                handleChange={handleChange}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div className="add-step" onClick={addStep}>
              <div className="dash-funnel-border">
                <div className="add-step-text medium fw-500 fs-14 uppercase">
                  {t("add-step")}
                </div>
                </div>
              </div>
            </div>
            <div
              onClick={handleEditFunnelClose}
              className="medium fw-500 fs-14 funnel-btn"
            >
              <div className="funnel-btn-btn">
                <div className="medium fw-500 fs-14 btn-text-no uppercase">{t("cancel")}</div>
              </div>
              <div onClick={handleUpdateFunnel} className="funnel-btn-green">
                <div className="medium fw-500 fs-14 btn-text-no uppercase">{t("save")}</div>
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  };

  const AddNewStep = ({ index, removeFormFields, handleChange, name, url }) => {
    const clickHandler = useCallback(() => {
      removeFormFields(index);
    }, [index, removeFormFields]);

    //API Consumption traffic step url
    const [myData, setMyData] = useState([]);
    const [error, setError] = useState([
      { error: false, status: "", message: "" },
    ]);

    useEffect(() => {
      TrafficFunnelStepUrlList(sid, type);
    }, []);

    const TrafficFunnelStepUrlList = (sid, type) => {
      try {
        GetTrafficFunnelStepUrlList(sid, type)
          .then((data) => {
            if (data != null && data !== undefined) {
              setMyData(data.data);
            } else {
              setMyData([null]);
            }
          })
          .catch((error) => {
            setError({ error: error.message, status: error.status });
            setMyData([null]);
          });
      } catch (e) {
        setMyData([null]);
        setError({
          error: true,
          status: "",
          message: "Failed to retrieve data",
        });
      }
    };

    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="funnel-texboxes">
            <div className="medium fw-500 fs-14 funnel-rank">#{index + 1}</div>
            <div>
              <TextField
                onChange={(e) => handleChange(index, e)}
                label={t("name-of-page")}
                className="funnel-namepage"
                sx={{ width: 196, backgroundColor: "#fff" }}
                select
                value={url}
                name="url"
              >
                {myData.map((option) => (
                  <MenuItem key={option.SYS_ID} value={option.Pages}>
                    {option.Pages}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div>
              <TextField
                value={name}
                type="text"
                name="name"
                onChange={(e) => handleChange(index, e)}
                label={t("Alias (optional)")}
                className="regular fw-300 fs-14 funnel-namepage"
                sx={{ width: 196, backgroundColor: "#fff" }}
              ></TextField>
            </div>
            <div className="regular handleDrag fw-500 fs-14 funnel-icons">
              <img height={20} src={funnelArrow} alt="funnelArrow" />
            </div>
          </div>

          <div
            className="remove-funnel light-italic fw-300 fs-14"
            onClick={clickHandler}
          >
            {t("remove")}
          </div>
        </div>
      </div>
    );
  };

  // API consumption Delete funnel
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  const TrafficDeleteFunnel = (data) => {
    try {
      DeleteTrafficFunnel(data)
        .then((response) => {})
        .catch((error) => {
          setError({ error: error.message, status: error.status });
        });
    } catch (error) {
      setError({ error: true, status: "", message: "Failed to retrieve data" });
    }
  };

  const handleDeleteFunnel = (id) => {
    TrafficDeleteFunnel(id);
    setTimeout(() => {
      GetTrafficFunnel(sid, type, datefilter);
    }, 1000);
  };

  //Create funnel dialogue box
  const [openCreateFunnel, setOpenCreateFunnel] = React.useState(false);

  const [filteredData, setFilteredData] = useState();
  const [newName, setNewName] = useState();
  const [newSteps, setNewSteps] = useState();
  const [funnelId, setFunnelId] = useState();

  const handleEditFunnel = (id, index) => {
    let newFunnelData = funnelData.filter((data) => data.funnel_id === id);
    let funnelSteps = funnelData.filter((data) => data.funnel_data);
    setFunnelId(id);
    setNewSteps(funnelSteps[index].funnel_data);
    setNewName(newFunnelData[0].name);
    setFilteredData(newFunnelData);
    setOpenCreateFunnel(true);
  };
  const handleEditFunnelClose = () => {
    setOpenCreateFunnel(false);
  };
  //Create funnel dialogue box end
  return (
    <>
      <div>
        {funnelData.length == 0 ? (
          <div className="no-funnel-container bold fw-700 fs-14">
            {t("empty-funnel")}
          </div>
        ) : (
          <div
            style={{
              margin: "37px 0px 28px 0px",
              // height: "480px",
              // overflowX: "hidden",
              // overflowY: "scroll",
            }}
          >
            {funnelData.map((data, index) => (
              <div className="regular fw-700 fs-14 funnel-page-container ">
                <div className="funnel-page-header">
                  <div className="bold fw-700 fs-14">{data.name}</div>
                  <div className="justify-content-center display-flex align-items-center regular fw-300 fs-12">
                    <div className="light fw-300 fs-12">{("edit")} {t("funnel")}</div>
                    <div>
                      <img
                        onClick={() => handleEditFunnel(data.funnel_id, index)}
                        height={15}
                        className="question-mark"
                        src={pencil}
                        alt="pencil"
                      />
                    </div>
                  </div>
                </div>
                <div className="funnel-visit">
                  <div className="light fw-300 fs-14">Visits:</div>
                  <div className="medium fw-700 fs-14 funnel-visit-value">
                    {data.Visits}
                  </div>
                </div>
                <div className="conservation">
                  <div className="light fw-300 fs-14">Conversion Rate:</div>
                  <div className="cons-rate-prcnt medium fw-700 fs-14">
                    {data.Conversion_Rate}%
                  </div>
                </div>
                <div className="funnel-cards">
                  {data?.funnel_data?.map((item, i) => (
                    <>
                      <div className="new-funnel-container">
                        <div className="new-funnel-heading">
                          <div className="medium fw-500 fs-12 funnel-count">
                            #{[i + 1]}&nbsp;{item.url}
                          </div>
                        </div>

                        <div
                          style={{
                            background: `linear-gradient(transparent ${
                              100 - parseFloat(item.CountPercentage)
                            }%, #A7C690 ${
                              100 - parseFloat(item.CountPercentage)
                            }%`,
                          }}
                          className="funnel-traffic"
                        >
                          <span className="bold fw-700 traffic-percent">
                            {item.CountPercentage}%
                          </span>
                        </div>
                        <div className="funnel-traffic-footer">
                          <div className="funnel-traffic-value">
                            <div className="light fw-300 fs-12 funnel-value-str">
                              {t("visits")}
                            </div>
                            <div className="funnel-value">
                              <div className="medium fw-500 fs-12 funnel-number">
                                {item.count.toString().length < 4
                                  ? `${item.count}`
                                  : `${(parseFloat(item.count) / 1000).toFixed(
                                      1
                                    )}K`}
                              </div>
                              <div className="medium fw-500 fs-12 funnel-prcnt">
                                {Math.round(parseFloat(item.CountPercentage))}%
                              </div>
                            </div>
                          </div>
                          <div className="funnel-traffic-value-2">
                            <div className="light fw-300 fs-12 funnel-value-str">
                              {i < data.funnel_data.length - 1
                                ? `${t("Dropped")}`
                                : `${t("Converted")}`}
                            </div>
                            <div className="funnel-value">
                              <div className="medium fw-500 fs-12 funnel-number">
                                {item.Dropped.toString().length < 4
                                  ? `${item.Dropped}`
                                  : `${(
                                      parseFloat(item.Dropped) / 1000
                                    ).toFixed(1)}K`}
                              </div>
                              <div
                                style={
                                  i == 1
                                    ? { color: "red" }
                                    : { color: "inherit" }
                                }
                                className="medium fw-500 fs-12 funnel-prcnt"
                              >
                                {Math.round(parseFloat(item.DroppedPercentage))}
                                %
                                {i == 1 && (
                                  <SouthIcon
                                    style={{ margin: "0px -12px 0px 0px" }}
                                    fontSize="0.23rem"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="funnel-traffic-value-2">
                            <div className="light fw-300 fs-12 funnel-value-str">
                              {t("page-friction")}
                            </div>
                            <div className="funnel-value">
                              <div className="medium fw-500 fs-12 funnel-prcnt">
                                {item["Page Friction"]}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {i < data.funnel_data.length - 1 && (
                        <div class="up-arrow"></div>
                      )}
                    </>
                  ))}
                </div>
                <div>
                  <div className="delete-funnel">
                    <div></div>

                    <div
                      onClick={() => {
                        handleDeleteFunnel(data.funnel_id);
                      }}
                      style={{
                        color: "rgba(136, 136, 136, 1)",
                        // fontStyle: "italic",
                        cursor: "pointer",
                      }}
                      className="light-italic fw-300 fs-14"
                    >
                      {t("delete")} {t("funnel")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <FunnelPopup
              funnelId={funnelId}
              newName={newName}
              steps={newSteps}
              filteredData={filteredData}
              handleEditFunnelClose={handleEditFunnelClose}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default FunnelPage;
