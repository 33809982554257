import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import GoogleMapReact from "google-map-react";
import clientMarker from "../Icons/client-color_property-marker.svg";
import "./events.css";
import { Card as MuiCard, Grid } from "@mui/material";
import { spacing } from "@mui/system";
import { GetEventsStatistics } from "../../../API/Events/EventsStatistics";
import MapTable from "./MapTable";
import { useTranslation } from "react-i18next";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const GoogleMapReactWrapper = styled.div`
  height: 480px;
  width: 100%;
  border-radius: "8px";
`;

const AnyReactComponent = (props) => (
  <div {...props}>
    <img src={clientMarker} alt="marker" width={36} height={49} />
  </div>
);

function EventsMap({ sid, datefilter }) {
  //i18n
  const { t, i8n } = useTranslation();

  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetEventsByStatistics(sid, "2023-01-01/2023-06-30");
  }, [sid, datefilter]);

  const GetEventsByStatistics = (sid, datefilter) => {
    try {
      GetEventsStatistics(sid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const options = {
    center: {
      lat: 55.67945641,
      lng: 12.51562935,
    },
    zoom: 10,
    imagePath: { clientMarker },
  };

  const getMapOptions = (maps) => {
    return {
      fullscreenControl: false,
      mapTypeControl: false,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      scrollwheel: true,
      streetViewControl: true,
    };
  };

  const renderMarkers = (map, maps, myData) => {
    // console.log(map,"map");
    // console.log(maps,"maps");
    // {myData.map((i)=>{

    // })}
    new maps.Marker({
      position: {
        lat: 55.67945641,
        lng: 12.51562935,
      },
      map,
      title: "Hello World!",
    });
  };

  const [markerIndex, setMarkerIndex] = useState("0");
  const [tooltipShow, setTooltipShow] = useState(false);
  const [markerData, setMarkerData] = useState([]);
  const [showMarker, setShowMarker] = useState(false);

  const MarkersComponent = (props) => {
    return (
      <div
        key={props.id}
        {...props}
        className="myDivMarker"
        // onMouseOver={() => onMouseEnter()}
        // onMouseLeave={() => {
        //   setTooltipShow(false);
        // }}
      >
        {markerIndex === props.id && showMarker && (
          <img
            src={clientMarker}
            width={36}
            height={49}
            style={{ position: "absolute", bottom: "-8px", left: "-10px" }}
          />
        )}

        {markerData?.Latitude === props.lat && tooltipShow && (
          <div key={props.id} className="markerTooltip">
            <div style={{ marginBottom: "5px" }}>
              <span className="fs-14 bold">{props.City}</span>
            </div>
            <div>
              <span className="fs-12" style={{ paddingBottom: "3px" }}>
                {props.Participants} {t("participants")}
              </span>
            </div>
          </div>
        )}
        <span Latitude={props.lat} Longitude={props.lng} className="mapMarker">
          <span style={{ visibility: "hidden" }}>2</span>
        </span>
      </div>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={8.5} lg={8.5} xs={12}>
          <div className="stattis-map-section">
            <div style={{ overflow: "hidden", borderRadius: "8px" }}>
              <GoogleMapReactWrapper>
                <GoogleMapReact
                  options={getMapOptions}
                  bootstrapURLKeys={{
                    key: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
                  }}
                  defaultCenter={options.center}
                  defaultZoom={options.zoom}
                  hoverDistance={30}
                  // onChildMouseEnter={(key) => _onChildMouseEnter(key)}
                  // onChildMouseLeave={() => setTooltipShow(false)}
                  // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                >
                  {myData.map((item, i) => (
                    <MarkersComponent
                      key={i}
                      lat={item.Latitude}
                      lng={item.Longitude}
                      City={item.City}
                      Participants={item.Participants}
                      onClick={() => {
                        setMarkerData(item);
                        setMarkerIndex(i);
                        setShowMarker(true);
                      }}
                      onMouseEnter={() => {
                        setMarkerData(item);
                        setTooltipShow(true);
                      }}
                      onMouseLeave={() => {
                        setTooltipShow(false);
                      }}
                      show={true}
                      id={i}
                    />
                  ))}
                </GoogleMapReact>
              </GoogleMapReactWrapper>
            </div>
          </div>
        </Grid>
        <Grid item md={3.5} lg={3.5} xs={12}>
          <MapTable markerIndex={markerIndex} myData={myData} />
        </Grid>
      </Grid>
    </>
  );
}

export default EventsMap;
