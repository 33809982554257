import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { spacing } from "@mui/system";
import { GetCustomersTransactionAge } from "../../../API/Customers/CustomersTransactionAge";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 427px;
  width: 100%;
  position: relative;
  bottom: 25px;
`;

const TransactionAgeChart = ({ sid, datefilter }) => {
  //i18n
  const { t, i18n } = useTranslation();

  // API Consumption

  const [myData, setMyData] = useState({ data: [] });
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  console.log("object-----myData",myData)

  useEffect(() => {
    GetCustomersTransactionByAge(sid, datefilter);
  }, [sid, datefilter]);

  const GetCustomersTransactionByAge = (sid, datefilter) => {
    try {
      GetCustomersTransactionAge(sid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const data = {
    labels: myData?.data?.Data?.map((x) => x.Age),
    datasets: [
      {
        backgroundColor: "#619130",
        data: myData?.data?.Data?.map((x) => x.Tenants),
        stack: 1,

        maxBarThickness: 55,
        barThickness: 55,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        backgroundColor: "#FFFFFF",
        borderWidth: 2,
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          borderDash: [5, 10],
          color: "#F4F5F5",
        },
        stacked: false,
        ticks: {
          stepSize: 20,
        },
      },

      x: {
        stacked: false,
        grid: {
          color: "transparent",
        },
      },
    },
  };

  return (
    <div>
      <Spacer mb={15} />

      <ChartWrapper>
        <Bar responsive={true} data={data} options={options} />
      </ChartWrapper>
    </div>
  );
};

export default TransactionAgeChart;
