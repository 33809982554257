import React, { useRef } from "react";
import { withTheme } from "@emotion/react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import { Grid, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import usePopup from "../hooks/usePopup";
import { useTranslation } from "react-i18next";
import ArrowDown from "../Icons/dropdown-chevron-down.svg";
import ArrowUp from "../Icons/dropdown-chevron-up.svg";

const AllDataDropdown = () => {
  //i18Next
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="download-dropdown-traffic">
        <div className="light fw-300 fs-14 button-label">
          <div className="hover-gray">{t("all")}</div>
          <hr className="download-hr-signings" />
          <div className="hover-gray">Boligvælger</div>
          <hr className="download-hr-signings" />
          <div className="hover-gray">Hjemmeside</div>
          <hr className="download-hr-signings" />
          <div className="hover-gray">Annoncering</div>
        </div>
      </div>
    </>
  );
};

function Visitors() {
  //i18Next
  const { t, i18n } = useTranslation();

  const [invisible, setInvisible] = React.useState(false);

  const handleBadgeVisibility = () => {
    setInvisible(!invisible);
  };
  const filterModalRef = useRef(null);
  const filterModalToggleButtonRef = useRef(null);
  const [showAllDataDropdown] = usePopup(
    filterModalRef,
    filterModalToggleButtonRef
  );
  return (
    <>
      <Grid container justifyContent="space-between" ref={filterModalRef}>
        <Grid
          item
          sx={{
            justifyContent: "space-between",
            marginTop: "16px",
            marginRight: "10px",
          }}
        >
          <Typography
            display="inline"
            sx={{
              fontSize: "14px",
              fontFamily: "Cerebri sans-light",
              fontWeight: "300",
            }}
          >
            {t("visitors")}
          </Typography>
          <FormControlLabel
            sx={{ alignItems: "center", marginLeft: "10px" }}
            control={
              <Switch
                checked={!invisible}
                onChange={handleBadgeVisibility}
                color="success"
              />
            }
          />
          <Typography
            display="inline"
            sx={{
              fontSize: "14px",
              fontFamily: "Cerebri sans-light",

              fontWeight: "300",
            }}
          >
            {t("new-visitors")}
          </Typography>
        </Grid>
        <Grid
          ref={filterModalToggleButtonRef}
          style={{
            width: "161px",
            height: "44px",
            border: "1px solid #F4F5F5",
            borderRadius: "8px",
            margin: "16px 10px 0 10px",
            cursor : 'pointer'
          }}
        >
          <span 
          className="light fw-300 fs-13 cursor"
            style={{
              position: "relative",
              top: "9px",
              left: "15px",
              // fontSize: "13px",
              // fontFamily: "Cerebri sans-light",
              // fontWeight: "300",
            }}
          >
             {t("all-data")}
          </span>

          <img
            height={8}
            style={{
              position: "relative",
              left: "53px",
              top: "10px",
              marginLeft: "26px",
            }}
            src={showAllDataDropdown ? ArrowUp : ArrowDown}
            alt="arrowIcon"
          />
        </Grid>
        {showAllDataDropdown && (
          <>
            <div
              style={{
                position: "absolute",
                marginTop: "60px",
                right: "0px",
              }}
            >
              <AllDataDropdown />
            </div>
          </>
        )}
      </Grid>
    </>
  );
}

export default Visitors;
