import React, { useCallback, useRef } from "react";
import usePopup from "../../hooks/usePopup";
import { toPng, toJpeg, toSvg } from "html-to-image";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
const converter = require("json-2-csv"); //Json-2csv npm

const DownloadWidget = ({ fileName, refObj, type, data, options }) => {
  const filterModalRef = useRef(null);
  const filterModalToggleButtonRef = useRef(null);
  const [showDownloadLabel] = usePopup(
    filterModalRef,
    filterModalToggleButtonRef
  );

  const pngClick = useCallback(() => {
    setTimeout(function () {
      if (refObj.current === null) {
        return;
      }

      toPng(refObj.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `${fileName}.png`;
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }, 500);
  }, [refObj]);

  const jpgClick = useCallback(() => {
    setTimeout(function () {
      if (refObj.current === null) {
        return;
      }

      toJpeg(refObj.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `${fileName}.jpeg`;
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }, 500);
  }, [refObj]);

  const svgClick = useCallback(() => {
    setTimeout(function () {
      if (refObj.current === null) {
        return;
      }

      toSvg(refObj.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `${fileName}.svg`;
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }, 500);
  }, [refObj]);

  const csvClick = () => {
    let json2csvCallback = function (err, csv) {
      if (err) throw err;
      const downloadFile = ({ data, fileName, fileType }) => {
        // Create a blob with the data we want to download as a file
        const blob = new Blob([data], { type: fileType });
        // Create an anchor element and dispatch a click event on it
        // to trigger a download
        const a = document.createElement("a");
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
      };
      downloadFile({
        data: csv,
        fileName: `${fileName}.csv`,
        fileType: "text/csv",
      });
    };
    converter.json2csv(data, json2csvCallback, options);
  };

  //i18Next
  const { t, i18n } = useTranslation();

  return (
    <>
      <div>
        <div
          ref={filterModalToggleButtonRef}
          className={`download-widget ${showDownloadLabel ? "active" : ""}`}
          style={
            {
              // transform: `rotate(${showDownloadLabel ? "90" : "0"}deg)`,
              // boxShadow: `${
              //   showDownloadLabel ? " 1px 0px rgba(0, 0, 0, 0.04)" : "none"
              // }`,
              // backgroundColor: `${
              //   showDownloadLabel ? "rgba(0, 0, 0, 0.02)" : ""
              // }`,
            }
          }
        >
          <MoreVertIcon
            ref={filterModalRef}
            className="user-icon-color"
            style={{ margin: "10px" }}
          />
        </div>

        {showDownloadLabel && (
          <div
            style={{
              position: "absolute",
              backgroundColor: "#ffffff",
              right: "0px",
              zIndex: "1",
              marginRight: "16px",
              top: "68px",
              borderRadius: "8px",
            }}
          >
            {type ? (
              <div
                style={{
                  display: "inline-block",
                  width: "max-content",
                  minHeight: "fit-content",
                  filter: "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.04))",
                  borderRadius: "8px",
                  border: "1px solid #f4f5f5",
                  padding: "10px 0",
                  lineHeight: "22px",
                }}
                className="light"
              >
                <div
                  className="light fw-500 fs-14"
                  style={{ padding: "0 66px 0 20px", cursor: "pointer" }}
                  onClick={pngClick}
                >
                  {t("download-widget-as-png")}
                </div>
                <hr
                  style={{ padding: "0 66px 0 20px", cursor: "pointer" }}
                  className="download-hr"
                />
                <div
                  className="light fw-500 fs-14"
                  style={{ padding: "0 66px 0 20px", cursor: "pointer" }}
                  onClick={jpgClick}
                >
                  {t("download-widget-as-jpg")}
                </div>
                <hr className="download-hr" />
                <div
                  className="light fw-500 fs-14"
                  style={{ padding: "0 66px 0 20px", cursor: "pointer" }}
                  onClick={svgClick}
                >
                  {t("download-widget-as-svg")}
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "inline-block",
                  width: "max-content",
                  minHeight: "fit-content",
                  filter: "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.04))",
                  borderRadius: "8px",
                  border: "1px solid #f4f5f5",
                  padding: "10px 0",
                  lineHeight: "22px",
                }}
                className="light"
              >
                <div
                  className="light fw-500 fs-14"
                  style={{ padding: "0 66px 0 20px", cursor: "pointer" }}
                  onClick={csvClick}
                >
                  {t("download-widget-as-csv")}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DownloadWidget;
