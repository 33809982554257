import React from "react";
import styled from "@emotion/styled";

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Typography,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: "Cerebri sans-medium";
    font-weight: 500;
    font-size: 13px;
  }
  &.${tableCellClasses.body} {
    font-size: 13px;
    border-bottom: none;
    font-family: "Cerebri sans-light";
    font-weight: 300;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
  }
`;

// Data
let id = 0;
function createData(name, calories, fat, carbs) {
  id += 1;
  return { id, name, calories, fat, carbs };
}

const StatusLabel = ({ text, color }) => {
  return (
    <div>
      <span
        style={{
          background: color,
          minWidth: "1em",
          minHeight: "1em",
          display: "inline-block",
          marginRight: "1em",
          borderRadius: "4px",
        }}
      ></span>
      {text}
    </div>
  );
};

const TotalLabel = ({ total, quantity, percentage, amount }) => {
  return (
    <>
      <div className="regular fw-600 fs-13">
        <div>{total}</div>
        <div>{quantity}</div>
        <div>{percentage}</div>
        <div>{amount}</div>
      </div>
    </>
  );
};
const rows = [
  createData("Social Media", 8, "6%"),
  createData("Outdoor marketing", 56, "39%"),
  createData("Website", 11, "8%"),
  createData("Newspaper", 70, "48%"),
  createData("Instagram", 70, "48%"),
  createData("Facebook", 70, "48%"),
  createData("Google Ads", 70, "48%"),
  createData("Newspaper", 70, "48%"),
  createData(
    <TotalLabel total="Total" />,
    <TotalLabel quantity="360" />,
    <TotalLabel percentage="100%" />
  ),
];
const styles = {
  rectangle: {
    width: "50px",
    height: "50px",
  },
};

function SimpleTable({ myData, Colors }) {
  //i18n
  const { t, i18n } = useTranslation();

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <CustomTableCell sx={{paddingLeft : '30px'}}>{t("status")}</CustomTableCell>
            <CustomTableCell align="center">{t("quantity")}</CustomTableCell>
            <CustomTableCell align="right">{t("percentage")}</CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {myData?.data?.Data.map((row, i) => (
            <CustomTableRow key={row.id}>
              <CustomTableCell
                component="th"
                scope="row"
                sx={{ paddingLeft: "30px" }}
              >
                <div className="color-section">
                  <span
                    style={{
                      background: Colors[i],
                      minWidth: "16px",
                      height: "16px",
                      minHeight: "1em",
                      display: "inline-block",
                      borderRadius: "4px",
                      fontSize: "13px",
                    }}
                  ></span>

                  {row.Type}
                </div>
              </CustomTableCell>
              <CustomTableCell align="center">{row.Quantity}</CustomTableCell>
              <CustomTableCell align="right">{row.Percentage}%</CustomTableCell>
            </CustomTableRow>
          ))}
        </TableBody>
        <TableHead sx={{ fontWeight: "700" }}>
          <CustomTableRow className="abcd">
            <TableCell
              align="left"
              sx={{
                backgroundColor: "#FFFF",
                border: "none",
                fontWeight: "500",
                paddingLeft: "30px",
                fontFamily: "Cerebri sans-medium",
                fontSize: "13",
                lineHeight: "20px",
              }}
            >
              {myData?.data?.Footer?.Type}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                backgroundColor: "#FFFF",
                border: "none",
                fontWeight: "500",
                fontFamily: "Cerebri sans-medium",
                fontSize: "13",
                lineHeight: "20px",
              }}
            >
              {myData?.data?.Footer?.Quantity}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor: "#FFFF",
                border: "none",
                fontWeight: "500",
                fontFamily: "Cerebri sans-medium",
                fontSize: "13",
                lineHeight: "20px",
              }}
            >
              {myData?.data?.Footer?.Percentage}%
            </TableCell>
          </CustomTableRow>
        </TableHead>
      </Table>
    </>
  );
}

export default SimpleTable;
