import React from "react";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Bar } from "react-chartjs-2";

import { CardContent, Card as MuiCard, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { GetTrafficGraph } from "../../../API/TrafficGraph/TrafficGraph";
import NoDataScreen from "../components/NoDataScreen";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 340px;
  width: 100%;
`;

const MixedChart2 = ({ sid, datefilter }) => {
  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetTrafficByGraph();
  }, [sid, datefilter]);

  const GetTrafficByGraph = () => {
    try {
      GetTrafficGraph(sid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const data = {
    labels: myData.map((x) => x.MonthYear),
    datasets: [
      {
        label: "Boligvælger",
        data: myData.map((x) => x.TotalRecordings),
        backgroundColor: "rgba(241, 102, 75, 1)",
        borderColor: ["rgba(241, 102, 75, 1)"],
        type: "line",
      },

      {
        label: "Hjemmeside",
        fill: false,
        type: "line",
        borderColor: "rgba(119, 199, 161, 1)",
        backgroundColor: "rgba(119, 199, 161, 1)",
        tension: 0.4,
        data: myData.map((x) => x.TotalClicks),
      },

      {
        label: "Annoncering",
        data: myData.map((x) => x.TotalSpend),
        backgroundColor: "rgba(162, 200, 208, 1)",
        fillColor: "rgba(162, 200, 208, 1)",
        borderColor: "rgba(162, 200, 208, 1)",
        borderWidth: 1,
      },
    ],
  };

  // config
  const config = {
    type: "bar",
    data,
    maintainAspectRatio: false,

    plugins: {
      tooltip: {
        callbacks: {
          title: function (tooltipItem, index) {
            let label = tooltipItem[0]?.dataset?.label;
            let title = label == "Annoncering" ? "Spend" : "Users";
            return title;
          },

          label: function (context) {
            let label = "";

            if (label) {
              label += "";
            }
            if (context.parsed.y !== null) {
              if (context.dataset.label === "Annoncering") {
                label +=
                  new Intl.NumberFormat("en-DK").format(context.parsed.y) +
                  " DKK";
              } else {
                label += context.parsed.y;
              }
            }

            return label;
          },
        },
        displayColors: false,
        padding: 10,
        backgroundColor: "#FFFFFF",
        borderWidth: 2,
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
        titleFont: {
          size: "14",
        },
        bodyFont: {
          size: "14",
          family: " Cerebri sans-light",
        },
      },
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        // font: { size: '52px',fontFamily:"Cerebri sans-medium" },
        // title: {
        // font: { size: '52px',fontFamily:"Cerebri sans-medium" },
        // },
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          padding: 20,
          font: { family: "Cerebri Sans-medium", size: "12px" },
        },
      },
    },
    scales: {
      y: {
        grid: {
          borderDash: [5, 10],
          color: "#F4F5F5",
        },
        beginAtZero: true,
        stacked: false,
        ticks: {
          stepSize: 1000,
          color: "#888888",
          font: {
            family: "Cerebri sans-light",
          },
        },
      },
      x: {
        grid: {
          borderDash: [5, 10],
          color: "#ffff",
        },
        ticks: {
          color: "#888888",
          font: {
            family: "Cerebri sans-light",
          },
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      {myData.length != 0 ? (
        <div className="mixed-traffic-section">
          {/* <div>
            <p className="fs-13 ">Total users</p>
            <p className="fs-18 ">2876</p>
          </div> */}
          <Spacer mb={1} />

          <ChartWrapper>
            <Bar data={data} options={config} />
          </ChartWrapper>
        
        </div>
      ) : (
        <NoDataScreen />
      )}
    </>
  );
};

export default MixedChart2;
