import React, { useEffect, useState } from "react";

import { Dialog, Typography } from "@mui/material";
import styled from "@emotion/styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./signings.css";

import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "../components/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import RemindPopup from "./RemindPopup";
import { GetSigningReminders } from "../../../API/SigningsTable/SigningsGetReminder";

const CustomTabelCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: Cerebri sans-medium;
    font-size: 12px;
    border: none;
    white-space: nowrap;
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family: Cerebri sans-light;
    font-size: 13px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
  }
`;

const LastReminder = ({ remindStatus }) => {
  return (
    <>
      <div className="pop-container regular fs-13 fw-500">
        <span>{remindStatus}</span>
      </div>
    </>
  );
}; 

function createData(
  status,
  name,
  actor,
  dateOfSigning,
  lastReminder,
  remindStatus
) {
  return { status, name, actor, dateOfSigning, lastReminder, remindStatus };
}

const rows = [
  createData("Done", "Stig Werner Isaksen", "Tenant", "Sep 23, 10:14", "-"),
  createData(
    "Pending",
    "Stig Werner Isaksen",
    "Tenant",
    "-",
    "Sep 23, 10:14",
    <LastReminder remindStatus="REMIND AGAIN" />
  ),
  createData(
    "Pending",
    "Stig Werner Isaksen",
    "Tenant",
    "-",
    "-",
    <LastReminder remindStatus="REMIND" />
  ),
];

function SigningOrder({ eid, zid, SigninOrderClose }) {
  //i18n
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [remindData, setRemindData] = React.useState();
  const [newName, setNewName] = useState();
  const [newActor, setNewActor] = useState();
  const [newOrderId, setNewOrderId] = useState();
  const [newZid, setNewZid] = useState();
  const [newStep, setNewStep] = useState();
  const handleClickOpen = (StepNumber, name, actor, orderId, zid) => {
    setOpen(true);
    // let remindDetails = myData.filter((item) => item.StepNumber === StepNumber);
    // setRemindData(remindDetails);
    setNewName(name);
    setNewActor(actor);
    setNewOrderId(orderId);
    setNewZid(zid);
    setNewStep(StepNumber);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //API Consumption Get signings reminder
  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  const SigningsReminder = (eid, zid) => {
    try {
      GetSigningReminders(eid, zid)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  useEffect(() => {
    SigningsReminder(eid, zid);
  }, [eid, zid]);

  return (
    <>
      <div>
        <div className="sticky" style={{ backgroundColor: "#fff" }}>
          <div
            className="display-flex justify-content-between"
            style={{ height: "64px" }}
          >
            <div className="display-flex margin-20">
              <div className="bold fs-14">{t("signing-order")}</div>
              <Tooltip text={t("signing-order")} paragraph="" />
            </div>
            <div className="div-close-button">
              <a className="close" onClick={SigninOrderClose}>
                <div className="mt-7px ml-6px">
                  <CloseIcon fontSize="large" />
                </div>
              </a>
            </div>
          </div>
          <hr className="horizontal-line" />
        </div>
        <div>
          <TableContainer className="padding-15px" component={Paper}>
            <div className="scrollable-container" style={{ height: "180px" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow className="sticky">
                    <CustomTabelCell>Status</CustomTabelCell>
                    <CustomTabelCell align="left">{t("name")}</CustomTabelCell>
                    <CustomTabelCell align="left">{t("actor")}</CustomTabelCell>
                    <CustomTabelCell align="left">
                      {t("date-of-signing")}
                    </CustomTabelCell>
                    <CustomTabelCell align="left">
                      {t("last-reminder")}
                    </CustomTabelCell>
                    <CustomTabelCell></CustomTabelCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {myData.map((row, Nameid) => (
                    <CustomTableRow
                      key={Nameid}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <CustomTabelCell component="th" scope="row">
                        {row.Status}
                      </CustomTabelCell>
                      <CustomTabelCell align="left">{row.Name}</CustomTabelCell>
                      <CustomTabelCell align="left">
                        {row.Actor}
                      </CustomTabelCell>
                      <CustomTabelCell align="left">
                        {row.SignedDate == "-"
                          ? "-"
                          : new Date(row.SignedDate).toLocaleDateString(
                              "en-En",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                      </CustomTabelCell>
                      <CustomTabelCell align="left">
                        {row.LastReminder}
                      </CustomTabelCell>
                      <CustomTabelCell align="left">
                        <div
                          className="pop-container medium fs-12"
                          onClick={() =>
                            handleClickOpen(
                              row.StepNumber,
                              row.Name,
                              row.Actor,
                              row.OrderId,
                              row.Zyz_S_Id
                            )
                          }
                        >
                          <span className="medium fs-12">
                            {row.RemindButton.toUpperCase()}
                          </span>
                        </div>
                      </CustomTabelCell>
                    </CustomTableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <Dialog
              maxWidth="xl"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    height: "260px",
                    borderRadius: "8px",
                    maxWidth: "384px",
                    overflowX: "hidden",
                    overflowY: "hidden",
                  },
                },
              }}
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <RemindPopup
                handleClose={handleClose}
                stepNumber={newStep}
                zid={newZid}
                name={newName}
                actor={newActor}
                orderId={newOrderId}
              />
            </Dialog>
          </TableContainer>
        </div>
      </div>
    </>
  );
}

export default SigningOrder;
