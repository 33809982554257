import { Get } from "../../estateTool/dashboard/utils/Axios";
import { endpoint } from "../EndPoint";
import { GetHeader } from "../Header";

export const GetOverviewUnitProgressTenants = (cid) => {
  cid = cid === "" || cid === null || cid === undefined ? 0 : cid;

  return new Promise((resolve, reject) => {
    Get(endpoint.Overview_Unit_Progress_Tenants, GetHeader(), cid)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
