import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { Switch, ThemeProvider } from "@mui/material";
import { theme } from "../../../utils/Helper";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { t } from "i18next";

Chart.register(annotationPlugin);

function createDiagonalPattern(color = "black") {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  const size = 10;
  const stroke = 4;
  const strokeOffset = stroke / 2;
  canvas.width = size;
  canvas.height = size;

  context.strokeStyle = color; // 'rgba(0, 0, 0, 1)';
  context.lineWidth = stroke;

  context.moveTo(size / 2 - strokeOffset, -strokeOffset);
  context.lineTo(size + strokeOffset, size / 2 + strokeOffset);
  context.moveTo(-strokeOffset, size / 2 - strokeOffset);
  context.lineTo(size / 2 + strokeOffset, size + strokeOffset);
  context.stroke();

  return context.createPattern(canvas, "repeat");
}

export const StatusChangeChart = () => {
  const [compareData, setCompareData] = useState(true);
  const [prevAction, setPrevAction] = useState(true);
  const [nextAction, setNextAction] = useState(false);

  const [pageIndex, setPageIndex] = useState({ min: 0, max: 13 });

  const handleToggle = () => {
    setCompareData(!compareData);
  };

  const options2 = {
    plugins: {
      tooltip: {
        backgroundColor: "#FFFFFF",
        borderWidth: 2,
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
      },
      legend: {
        display: false,
      },

      annotation: {
        annotations: [
          {
            type: "box",
            xScaleID: "x",
            // xMin: 0,
            xMax: 0.5,
            backgroundColor: "rgba(97, 145, 48, 0.2)",

             // Initial border width
            borderColor: "rgba(97, 145, 48, 1)",
          },
          {
            type: "box",
            xScaleID: "x",
            xMin: 6.5,
            xMax:5.5,
            backgroundColor: "rgba(97, 145, 48, 0.2)",

            borderWidth: 1, // Initial border width
            borderColor: "rgba(97, 145, 48, 1)",
          },
          {
            type: "box",
            xScaleID: "x",
            xMin: 13.5,
            xMax: 12.5,
            backgroundColor: "rgba(97, 145, 48, 0.2)",

            borderWidth: 1, // Initial border width
            borderColor: "rgba(97, 145, 48, 1)",
          },
          {
            type: "box",
            xScaleID: "x",
            xMin: 19.5,
            xMax: 18.5,
            backgroundColor: "rgba(97, 145, 48, 0.2)",

            borderWidth: 1, // Initial border width
            borderColor: "rgba(97, 145, 48, 1)",
          },
          {
            type: "box",
            xScaleID: "x",
            xMin: 26.5,
            xMax: 25.5,
            backgroundColor: "rgba(97, 145, 48, 0.2)",

            borderWidth: 1, // Initial border width
            borderColor: "rgba(97, 145, 48, 1)",
          },
          {
            type: "box",
            xScaleID: "x",
            xMin: 32.5,
            xMax: 31.5,
            backgroundColor: "rgba(97, 145, 48, 0.2)",

            borderWidth: 1, // Initial border width
            borderColor: "rgba(97, 145, 48, 1)",
          },
          {
            type: "box",
            xScaleID: "x",
            xMin: 39.5,
            xMax: 38.5,
            backgroundColor: "rgba(97, 145, 48, 0.2)",

            borderWidth: 1, // Initial border width
            borderColor: "rgba(97, 145, 48, 1)",
          },
        ],
      },
    },
    scales: {
      y: {
        grid: {
          borderDash: [5, 10],
          border: "#F4F5F5",
        },
        beginAtZero: true,
        stacked: false,
        ticks: {
          stepSize: 5,
          color: "#888888",

          font: {
            family: "Cerebri sans-light",
          },
        },
      },
      x: {
        min: pageIndex.min, // 0
        max: pageIndex.max, // 13
        grid: {
          border: "#888888",
        },

        ticks: {
          color: "#619130",
          font: {
            family: "Cerebri sans-bold",
            size: 12,
          },
        },
        beginAtZero: true,
      },
      // secondXAxis: {
      //   axis: "x",
      //   labels: [
      //     "21 mar, 2022",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "29 Dec, 2022",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "12 Jan, 2023",
      //   ],
      //   grid: {
      //     drawOnChartArea: false,
      //     borderColor: "#fff",
      //   },
      //   ticks: {
      //     color: "rgba(136, 136, 136, 1)",
      //     font: {
      //       family: "Cerebri sans-light",
      //       size: 12,
      //     },
      //   },
      // },
    },
  };

  const doubleArrow = (start, end) => {
    if (start === 0) {
      setPrevAction(true); // disable color  when index is 0
      setNextAction(false); // enable the next button when is 0
    } else {
      setPrevAction(false);
      setNextAction(true);
    }
    setPageIndex({ ...pageIndex, min: start, max: end });
  };

  const nextData = (start, end) => {
    const startScale = options2.scales.x.min + start;
    const endScale = options2.scales.x.max + end;

    if (endScale >= currentData.labels.length - 1) {
      options2.scales.x.min = currentData.labels.length - 7;
      options2.scales.x.max = currentData.labels.length - 1;
      setNextAction(true);
    } else {
      setNextAction(false);
    }
    if (startScale <= 0) {
      options2.scales.x.min = currentData.labels.length - 0;
      options2.scales.x.max = currentData.labels.length - 6;
      setPrevAction(true);
    } else {
      setPrevAction(false);
    }

    if (endScale <= currentData.labels.length || startScale > 0) {
      setPageIndex({ ...pageIndex, min: startScale, max: endScale });
    }
  };

  const strippedData = {
    labels: [
      "42 days ago",
      "",
      "",
      "",
      "",
      "",
      "35 days ago",
      "",
      "",
      "",
      "",
      "",
      "",
      "28 days ago",
      "",
      "",
      "",
      "",
      "",
      "21 days ago",
      "",
      "",
      "",
      "",
      "",
      "",
      "14 days ago",
      "",
      "",
      "",
      "",
      "",
      "7 days ago",
      "",
      "",
      "",
      "",
      "",
      "",
      "Today",
    ],
    datasets: [
      {
        label: "Not listed",
        data: [
          0, -10, 50, 20, 44, -20, 33, 44, 55, 84, 13, -33, 67, -33, 0, -23, 90,
          80, 70, 25, 33, 44, -88, 40, 51, -59, 67, 89, 11, 15, 66, -33, -44,
          76, -89, 12, 16, 18, -22, 24,
        ],
        backgroundColor: "#888888",
        maxBarThickness: 11,
      },

      {
        label: "Available",
        data: [
          0, 100, -90, 80, -70, 25, 33, -44, 88, -90, 55, 54, -67, 89, 33, 44,
          55, 84, 13, -33, 67, -33, 0, 23, -90, 80, 70, 25, 28, -77, 55, 54,
          -67, -89, 33, -85, 88, 78, -23, 29,
        ],
        backgroundColor: "#77C7A1",
        maxBarThickness: 11,
      },
      {
        label: "Reserved",
        data: [
          0, 30, 80, 28, 77, 55, 54, 67, 89, -33, 11, 23, 44, -66, 33, 44, 88,
          90, -55, 54, 67, 89, 33, -44, 55, 84, 13, 33, -44, 88, 90, -55, 54,
          67, 89, -12, 44, 33, 11, 33,
        ],
        backgroundColor: "#F6E651",
        maxBarThickness: 11,
      },
      {
        label: "Tenancy agreement in process",
        data: [
          -60, 30, 80, 10, -50, 33, -11, 15, -66, 33, 44, 76, -89, 80, 0, 80,
          22, 44, 66, 55, -39, 56, 76, 22, 44, 55, 95, 76, 44, 20, 33, 44, 55,
          -84, -13, -33, 11, 23, -44, 66,
        ],
        backgroundColor: "#BF87D2",
        maxBarThickness: 11,
      },
      {
        label: "Leased",
        data: [
          60, 0, 80, 22, -4, 66, 55, 39, 56, 76, -22, 44, 55, 95, 37, 10, 50,
          33, 11, 15, -66, -33, 44, 76, -89, -80, 0, 80, 87, 0, 55, 0, 0, 66,
          55, 66, 55, 39, 56, 76,
        ],
        backgroundColor: "#A2C8D0",
        maxBarThickness: 11,
      },
      {
        label: "Terminated",
        data: [
          60, 0, 80, 21, -0, 45, 12, 0, 0, 87, 0, 58, 0, 0, 66, 51, -9, 56, 76,
          22, 44, 55, 95, 37, 10, -50, 33, 11, 87, 0, 57, 0, 0, 6, 55, 26, 76,
          89, 80, 0, 80,
        ],
        backgroundColor: "#F1664B",
        maxBarThickness: 11,
      },
      {
        label: "Not listed",
        data: [
          60, 0, 80, -4, 66, 55, 39, 56, 76, -22, 44, 55, 95, 37, 10, 21, -0,
          45, 12, 37, 10, -50, 33, 11, 87, 0, 57, 0, 0, 6, 55, 26, 76, 0, 0,
          -87, 0, 55, 78, 77,
        ],
        backgroundColor: createDiagonalPattern("#888888"),
        borderColor: "#888888",
        borderWidth: 0.5,
        maxBarThickness: 11,
      },

      {
        label: "Available",
        data: [
          -60, 0, 80, -4, 66, 55, -39, 56, 76, 22, 44, 55, 95, 37, 10, 21, 0,
          45, 12, 37, 10, 50, 33, -11, 87, 0, -57, 0, 0, 6, -55, 26, -76, 70, 0,
          -87, 0, -55, 78, 77,
        ],
        backgroundColor: createDiagonalPattern("#77C7A1"),
        borderColor: "#77C7A1",
        borderWidth: 0.5,
        maxBarThickness: 11,
      },
      {
        label: "Reserved",
        data: [
          60, 0, 80, -4, -66, 5, 39, 46, 76, -22, -74, 55, 95, 37, 10, 21, 0,
          45, 12, 37, 30, 50, -33, -11, 87, 0, -57, 0, 0, 60, 55, 26, 76, 0, 0,
          -87, 0, -55, 78, 77,
        ],
        backgroundColor: createDiagonalPattern("#F6E651"),
        borderColor: "#F6E651",
        borderWidth: 0.5,
        maxBarThickness: 11,
      },
      {
        label: "Tenancy agreement in process",
        data: [
          -60, 0, 80, -4, 16, 5, -39, 46, 76, 22, -74, 55, -95, 37, 10, 21, 0,
          45, -12, 37, -30, 50, -33, -11, -87, 0, -57, 30, 40, -60, 55, 26, 76,
          0, 0, -87, 0, -55, 78, 77,
        ],
        backgroundColor: createDiagonalPattern("#BF87D2"),
        borderColor: "#BF87D2",
        borderWidth: 0.5,
        maxBarThickness: 11,
      },
      {
        label: "Leased",
        data: [
          60, 0, 80, -40, -66, 50, 39, 46, 76, -22, -74, 95, 19, 97, 10, 21, 0,
          45, 12, 37, 30, 50, -33, -11, 87, 0, -57, 0, 0, 60, 55, 26, 76, 0, 0,
          -87, 0, -55, 78, 77,
        ],
        backgroundColor: createDiagonalPattern("#A2C8D0"),
        borderColor: "#A2C8D0",
        borderWidth: 0.5,
        maxBarThickness: 11,
      },
      {
        label: "Terminated",
        data: [
          60, 0, -80, -4, -66, 5, 39, 46, 76, -22, -74, 55, 95, -37, 10, 21, 0,
          45, 12, 37, -30, 50, -3, -11, -87, 0, -57, 0, 0, -60, -55, 26, -76, 0,
          0, -87, 0, -55, 78, -77,
        ],
        backgroundColor: createDiagonalPattern("#F1664B"),
        borderColor: "#F1664B",
        borderWidth: 0.5,
        maxBarThickness: 11,
      },
    ],
  };

  const currentData = {
    labels: [
      "42 days ago",
      "",
      "",
      "",
      "",
      "",
      "35 days ago",
      "",
      "",
      "",
      "",
      "",
      "",
      "28 days ago",
      "",
      "",
      "",
      "",
      "",
      "21 days ago",
      "",
      "",
      "",
      "",
      "",
      "",
      "14 days ago",
      "",
      "",
      "",
      "",
      "",
      "7 days ago",
      "",
      "",
      "",
      "",
      "",
      "",
      "Today",
    ],
    datasets: [
      {
        label: "Not listed",
        data: [
          0, -10, 50, 20, 44, -20, 33, -44, 55, -84, 13, -33, 67, -33, 0, 23,
          90, 80, 70, 25, 33, -44, 88, 40, 51, 59, 67, 89, -11, -15, -66, 33,
          44, 76, -89, 12, 16, 18, 22, 24,
        ],
        backgroundColor: "#888888",
        maxBarThickness: 11,
      },
      {
        label: "Available",
        data: [
          0, -100, 90, -80, 70, 25, 33, -44, 88, -90, 55, 54, -67, 89, 33, -44,
          55, -84, 13, -33, 67, -33, 0, 23, 90, 80, 70, 25, 28, -77, 55, 54,
          -67, 89, 33, -85, 88, 78, -23, 29,
        ],
        backgroundColor: "#77C7A1",
        maxBarThickness: 11,
      },
      {
        label: "Reserved",
        data: [
          0, -30, 80, 28, -77, 55, 54, -67, 89, 33, 11, 23, -44, 66, 33, -44,
          88, -90, 55, 54, -67, 89, 33, -44, 55, -84, 13, -33, -44, 88, -90, 55,
          54, -67, 89, -12, 44, -33, -11, 33,
        ],
        backgroundColor: "#F6E651",
        maxBarThickness: 11,
      },
      {
        label: "Tenancy agreement in process",
        data: [
          -60, -30, 80, 10, -50, 33, -11, -15, -66, 33, 44, 76, -89, -80, 0, 80,
          -22, 44, 66, 55, -39, -56, 76, 22, 44, 55, -95, 76, 44, -20, 33, -44,
          55, -84, 13, 33, 11, 23, -44, 66,
        ],
        backgroundColor: "#BF87D2",
        maxBarThickness: 11,
      },
      {
        label: "Leased",
        data: [
          -60, 0, 80, -22, 44, 66, 55, -39, -56, 76, 22, 44, 55, -95, 37, 10,
          -50, 33, -11, -15, -66, 33, 44, 76, -89, -80, 0, 80, -87, 0, 55, 0, 0,
          66, 55, 66, -55, -39, -56, 76,
        ],
        backgroundColor: "#A2C8D0",
        maxBarThickness: 11,
      },
      {
        label: "Terminated",
        data: [
          60, 0, 80, 21, -0, 45, 12, 0, 0, -87, 0, 58, 0, 0, 66, 51, -39, -56,
          76, 22, 44, 55, -95, 37, 10, -50, 33, -11, -87, 0, 57, 0, 0, 66, 55,
          26, 76, -89, -80, 0, 80,
        ],
        backgroundColor: "#F1664B",
        maxBarThickness: 11,
      },
    ],
  };

  return (
    <div>
      <div
        className="display-flex justify-content-between"
        style={{ padding: "0 70px 15px 70px" }}
      >
        <div className="display-flex align-items-center" style={{ gap: "5px" }}>
          <ThemeProvider theme={theme}>
            <button
              onClick={handleToggle}
              style={{
                border: "none",
                backgroundColor: "#fff",
                marginRight: "16px",
                marginTop: "10px",
              }}
            >
              <Switch color="success" />
            </button>
          </ThemeProvider>
          <div className="light fw-300 fs-14">
            {t("Compare with data from last period")}
          </div>
        </div>
        <div
          className="display-flex align-items-center"
          style={{ gap: "20px" }}
        >
          <div className="light fw-300 fs-14" style={{ color: "#888888" }}>
            {t("Use arrows to go back in period")}
          </div>
          <div style={{ cursor: "pointer", display: "flex", gap: "10px" }}>
            <button
              style={{
                backgroundColor: prevAction
                  ? "rgba(136, 136, 136, 0.16)"
                  : "#619130",
              }}
              className="pagination-style"
              onClick={() => doubleArrow(0, 13)}
            >
              <KeyboardDoubleArrowLeftIcon />
            </button>
            <button
              className="pagination-style"
              disabled={prevAction}
              style={{
                backgroundColor: prevAction
                  ? "rgba(136, 136, 136, 0.16)"
                  : "#619130",
              }}
              onClick={() => nextData(-13, -13)}
            >
              <KeyboardArrowLeftIcon />
            </button>
            <button
              className="pagination-style"
              disabled={nextAction}
              style={{
                backgroundColor: nextAction
                  ? "rgba(136, 136, 136, 0.16)"
                  : "#619130",
              }}
              onClick={() => nextData(13, 13)}
            >
              <KeyboardArrowRightIcon />
            </button>
            <button
              className="pagination-style"
              style={{
                backgroundColor: nextAction
                  ? "rgba(136, 136, 136, 0.16)"
                  : "#619130",
              }}
              onClick={() =>
                doubleArrow(
                  currentData.labels.length - 14,
                  currentData.labels.length
                )
              }
            >
              <KeyboardDoubleArrowRightIcon />
            </button>
          </div>
        </div>
      </div>
      <Bar
        options={options2}
        // data={currentData}
        data={compareData ? currentData : strippedData}
        height={150}
      />
    </div>
  );
};
