import React, { useEffect, useRef, useState } from "react";

// import "./tabMenu.css";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Box,
  Grid,
  CardContent,
  Card as MuiCard,
  Typography,
  colors,
} from "@mui/material";
import { spacing } from "@mui/system";
import { orange, red } from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import SimpleTable from "./SimpleTable";
import CreateNewEvents from "./CreateNewEvents";
import Tooltip from "../components/Tooltip/Tooltip";
import { GetEventHeardProperty } from "../../../API/Events/EventHeardProperty";
import { useTranslation } from "react-i18next";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import NoDataScreen from "../components/NoDataScreen";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 217px;
`;

function PieChart({ sid, datefilter }) {
  //i18n
  const { t, i8n } = useTranslation();

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetEventsByHeardProperty(sid, datefilter);
  }, [sid, datefilter]);

  const GetEventsByHeardProperty = (sid, datefilter) => {
    try {
      GetEventHeardProperty(sid, datefilter)
        .then((data) => {
          if (data != null && data !== undefined) {
            setMyData(data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };
  const Colors = [
    "#d49fea",
    "#85a9f2",
    "#bbe3f7",
    "#efa970",
    "#edf796",
    "#76f27e",
    "#fccba6",
    "#90daf9",
    "#cfccff",
    "#3f92a3",
    "#f2f29b",
  ];

  const data = {
    // labels: ["Not Listed", "Available", "Reserved", "Leased"],
    labels: myData?.data?.Data.map((x) => x.Type),
    datasets: [
      {
        data: myData?.data?.Data.map((x) => x.Percentage),
        // data: [25, 56, 40, 70],
        backgroundColor: Colors,
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        yAlign: "top",
        backgroundColor: "#FFFFFF",
        borderWidth: 2,
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
      },
      datalabels: {
        formatter: function (value, index, values) {
          if (value > 0) {
            value = value.toString();
            value = value.split(/(?=(?:...)*$)/);
            value = value.join(",");
            return value + "%";
          } else {
            value = "";
            return value;
          }
        },
        display: true,
        color: "white",
      },
      legend: {
        display: false,
      },
      plugins: [ChartDataLabels],
    },
  };

  const myref = useRef();
  const [refObj] = useState(myref);

  return (
    <>
      <div
        ref={myref}
        style={{
          backgroundColor: "#FFF",
          borderRadius: "10px",
          width: "100%",
          padding: "0 44px 40px 0",
          margin: "0px 0 16px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <div className="margin-heading-card" style={{ display: "flex" }}>
            <div className="bold fw-700 fs-14">
              {t("heard-about-the-property")}
            </div>
            <Tooltip text={t("heard-about-the-property")} paragraph="" />
          </div>
          <div>
            <DownloadWidget
              refObj={refObj}
              fileName={"events-heard-property"}
              type={true}
            />
          </div>
        </div>
        {myData?.data?.Footer?.Quantity != "0" ? (
          <Grid container spacing={6}>
            <Grid item xs={12} md={4.2}>
              <CardContent>
                <Spacer mb={6} />

                <ChartWrapper>
                  <Pie
                    data={data}
                    plugins={[ChartDataLabels]}
                    options={options}
                  />
                </ChartWrapper>
              </CardContent>
            </Grid>
            <Grid item xs={12} md={7.8}>
              <div>
                <SimpleTable myData={myData} Colors={Colors} />
              </div>
            </Grid>
          </Grid>
        ) : (
          <NoDataScreen />
        )}
      </div>
    </>
  );
}

export default withTheme(PieChart);
