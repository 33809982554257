import React from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Overview from "../overview/index";
import Economy from "./index";
import "./economy.css";
import Events from "../Events";
import Traffic from "../traffic/Index";
import Customer from "../Customer";
import Signings from "../Signings";
import { Trans, useTranslation } from "react-i18next";

function TabMenu({ sid, datefilter, ActivitiesProperty }) {
  //i18Next
  const { t, i18n } = useTranslation();

  return (
    <>
      <div
        className="app"
        style={{
          backgroundColor: "#F9FAFA",
        }}
      >
        <Tabs>
          <TabList className="light" style={{ marginBottom: "26px" }}>
            <Tab>{t("overview")}</Tab>
            <Tab>{t("economy")}</Tab>
            <Tab>{t("traffic")}</Tab>
            {/* <Tab>{t("events")}</Tab> */}
            <Tab>{t("customers")}</Tab>
            <Tab>{t("signings")}</Tab>
          </TabList>

          <TabPanel>
            <Overview
              sid={sid}
              datefilter={datefilter}
              ActivitiesProperty={ActivitiesProperty}
            />
          </TabPanel>
          <TabPanel>
            <Economy sid={sid} datefilter={datefilter}
            ActivitiesProperty={ActivitiesProperty}
            />
          </TabPanel>
          <TabPanel>
            <Traffic
              sid={sid}
              ActivitiesProperty={ActivitiesProperty}
              datefilter={datefilter}
            />
          </TabPanel>
          {/* <TabPanel>
            <Events
              sid={sid}
              datefilter={datefilter}
              ActivitiesProperty={ActivitiesProperty}
            />
          </TabPanel> */}
          <TabPanel>
            <Customer sid={sid} datefilter={datefilter} />
          </TabPanel>
          <TabPanel>
            <Signings sid={sid} datefilter={datefilter} />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
}

export default TabMenu;
