import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { styled } from "@mui/system";
import NavTabs from "./VisibilityTabsMenu";
import UseAsTabMenu from "./UseasTabMenu";
import Tooltip from "../../components/Tooltip/Tooltip";
import "./Milestonepopup.css";
import MileStoneResolution from "./MileStoneResolutionTabsMenu";
import ManualMileStoneDatePicker from "./DatePickerMilestone";
import sx from "@mui/system/sx";
import DropDown from "../../components/DropDown";
import { useState } from "react";
import dayjs from "dayjs";
import { DateRange, DateRangePicker } from "react-date-range";
import { endOfDay, format, startOfMonth } from "date-fns";
import calendar from "../../../dashboard/Icons/calendar.svg";
import { useEffect } from "react";
import moment from "moment";
import { differenceInCalendarWeeks } from "date-fns/esm";
import { PostAddMilestone } from "../../../../API/OverView/AddMilestone";
import ActivityDropdown from "../../components/Dropdowns/AcitivityDropdown/ActivityDropdown";
import { ArrowUpDown } from "../../components/ArrowUpDown/ArrowUpDown";
import { GetuserbyProperty } from "../../../../API/Common/CommonUserbyProperty";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";

const StyledTextField = styled(TextField, {
  name: "StyledTextField",
})({
  width: 254,
  "& .MuiInputBase-root": {
    "& fieldset": {
      borderColor: "#f4f5f5",
    },
    "&:hover fieldset": {
      borderColor: "#f4f5f5",
    },
    height: 48,
    borderRadius: "8px",
    borderColor: "1px solid #f4f5f5",
  },
});

const ColorsObj = ({ color, name }) => {
  return (
    <>
      <div className="color-section">
        <span
          style={{
            backgroundColor: color,
            minWidth: "1.4em",
            height: "18px",
            minHeight: "1em",
            display: "inline-block",
            borderRadius: "4px",
            fontSize: "13px",
          }}
        ></span>
        <div className="regular fw-400 fs-14">{name}</div>
      </div>
    </>
  );
};
const Colors = [
  {
    value: "Pink",
    name: <ColorsObj color="#B23977" name="Pink" />,
    id: "#B23977",
  },
  {
    value: "Black",
    name: <ColorsObj color="#000000" name="Black" />,
    id: "#000000",
  },
  {
    value: "Blue",
    name: <ColorsObj color="#A2C8D0" name="Blue" />,
    id: "#A2C8D0",
  },
  {
    value: "Black",
    name: <ColorsObj color="#AAC992" name="Green" />,
    id: "#AAC992",
  },
];

const ManualMileStone = ({ handleClose, ActivitiesProperty,GetByMilestones }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      milestoneName: "",
      selectedProp: "",
      // Period : "",
      mileStoneResolution: "",

      statusValue: "",
      is: "",
      value: "",
      parameter: "",

      color: "",
      visibility: "",
      useAs: "",
      sharedWith: "",
    },
  });

  //i18Next
  const { t, i18n } = useTranslation();

  const currencies = [
    {
      value: "USD",
      label: "is",
    },
    {
      value: "EUR",
      label: "over",
    },
    {
      value: "BTC",
      label: "under",
    },
  ];
  const Status = [
    {
      value: "Available",
      name: `${t("available")}`,
      id: "10",
    },
    {
      value: "Reserved",
      name: `${t("reserved")}`,
      id: "50",
    },
    {
      value: "Sold",
      name: `${t("sold")}`,
      id: "80",
    },
    {
      value: "Leased",
      name: `${t("leased")}`,
      id: "90",
    },
    {
      value: "idleness",
      name: `${t("idleness")}`,
      id: "11",
    },
  ];

  const Parameter = [
    {
      value: "Units",
      name: `${t("Units")}`,
      id: "1",
    },
    {
      value: "Percentage",
      name: `${t("percentage")}`,
      id: "2",
    },
  ];

  const Comparision = [
    {
      value: "is",
      name: `${t("is")}`,
      id: "1",
    },
    {
      value: "over",
      name: `${t("over")}`,
      id: "2",
    },
    {
      value: "under",
      name: `${t("under")}`,
      id: "3",
    },
  ];
  const Value = [
    {
      value: "1",
      label: "1",
    },
    {
      value: "2",
      label: "2",
    },
    {
      value: "3",
      label: "3",
    },
  ];

  const [currency, setCurrency] = React.useState("EUR");
  // const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  const [milestoneName, setMilestoneName] = useState("");
  const [targetEndDate, setTargetEndDate] = useState(
    dayjs("2023-01-01").format("YYYY-MM-DD")
  );
  const [open, setOpen] = useState(false);
  const [MilestoneResolutionID, setMilestoneResolutionID] = useState();
  const [eid, setEid] = useState("");
  const [isOngoingTarget, setIsOngoingTarget] = useState(""); //pending
  const [status, setStatus] = useState("");
  const [statusConditionID, setStatusConditionID] = useState("1");
  const [statusValue, setStatusValue] = useState("");
  const [statusParameterID, setStatusParameterID] = useState("");
  const [Color, setColor] = useState("");
  const [numberofunits, setnumberofunits] = useState("");
  const [numberofunitsdate, setnumberofunitsdate] = useState("");
  const [displayTypeID, setDisplayTypeID] = useState("");
  const [visibilityID, setVisibilityID] = useState("");
  const [overWriteData, setOverWriteData] = useState([]);
  const [goalsData, setGoalsData] = useState([]);
  const [goal, setGoal] = useState([]);

  const [userSelect, setUserSelect] = useState(false);
  const handleUserSelect = (event) => {
    setUserSelect(event.currentTarget);
  };
  const handleUserSelectClose = () => {
    setUserSelect(null);
  };

  const [anchorCalendar, setanchorCalendar] = useState(false);
  const handleClickCalendar = (event) => {
    setanchorCalendar(event.currentTarget);
  };
  const handleCloseCalendar = () => {
    setanchorCalendar(null);
  };
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // Exclude user from dropdown
  const ExcludeUser = sessionStorage.getItem("uid");
  // API Consumption for User

  const [myUser, setMyUser] = useState([]);
  const [errorUser, setErrorUser] = useState([
    { error: false, status: "", message: "" },
  ]);
  useEffect(() => {
    GetuserbyByProperty();
  }, []);

  const GetuserbyByProperty = () => {
    try {
      GetuserbyProperty(0, ExcludeUser)
        .then((data) => {
          if (data != null && data != undefined) {
            const categoryList = data.data.map(({ U_ID, Navn }) => {
              return { id: U_ID.toString(), name: Navn };
            });
            setMyUser(categoryList);
          } else {
            setMyUser([null]);
          }
        })
        .catch((error) => {
          setErrorUser({ error: error.message, status: error.status });
          setMyUser([null]);
        });
    } catch (e) {
      setMyUser([null]);
      setErrorUser({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  // Checkbox logic for User Dropdown
  const Activities = myUser;
  const [allChecked, setAllChecked] = useState(false);
  const [optionChecked, setOptionChecked] = useState([]);

  const OnClicked = (e, isCheckAll) => {
    const { id, checked } = e.currentTarget;

    if (isCheckAll) {
      if (checked) {
        setOptionChecked(Activities.map((list) => list.id));
        setAllChecked(true);
      } else {
        setOptionChecked([]);
        setAllChecked(false);
      }
      // GetSigningsByTable(sid);
    } else {
      if (checked) {
        const checkValue = [...optionChecked, id];
        setOptionChecked(checkValue);

        if (checkValue.length == Activities.length) {
          setAllChecked(true);
          // GetSigningsByTable(sid);
        } else {
          // GetSigningsByTable(sid, checkValue.toString());
        }
      } else {
        const checkValue = optionChecked.filter((item) => item !== id);
        if (checkValue.length !== 0) {
          setOptionChecked(checkValue);
          // GetSigningsByTable(sid, checkValue.toString());
          setAllChecked(false);
        } else {
          setOptionChecked([]);
          setAllChecked(false);
          // GetSigningsByTable(sid);
        }
      }
    }
  };

  //Post request for Add Milestone
  const [posterror, setPostError] = useState([
    { error: false, status: "", message: "" },
  ]);
  const [postData, setPostData] = useState([]);
  const OverviewAddMilestone = (data) => {
    try {
      PostAddMilestone(data)
        .then((response) => {
          setPostData(response);
          setOpen(response?.data?.bExist);
          if(!response?.data?.bExist){
            handleClose()
            GetByMilestones()
          }
        })
        .catch((error) => {
          setPostError({ error: error.message, status: error.status });
        });
    } catch (e) {
      setPostError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const handleAddManualMilestone = (data) => {
    setOverWriteData(data);
    const myData = {
      E_ID: data?.selectedProp,
      MilestoneType: "1",
      Name: data?.milestoneName,
      TargetStartDate: dayjs(date[0]?.startDate).format("YYYY-MM-DD"),
      TargetEndDate: dayjs(date[0]?.endDate).format("YYYY-MM-DD"),
      IsOngoingTarget: "1",

      mileStoneResolution: data?.mileStoneResolution,

      StatusID: data?.statusValue,

      StatusConditionID: data?.is,
      StatusValue: data?.value,
      StatusParameterID: data?.parameter,

      Color: data?.color,

      VisibilityID:
        visibilityID === "Private"
          ? "1"
          : visibilityID === "Public"
          ? "2"
          : visibilityID === "Shared"
          ? "3"
          : "",
      DisplayTypeID:
        data?.useAs === "Map Milestone"
          ? "1"
          : data?.useAs === "Chart Milestone"
          ? "2"
          : "",
      // DisplayTypeID: data?.useAs,
      Users: visibilityID === "Shared" ? optionChecked.toString() : "",
      Goal: goal,
      OverWrite: "0",
    };
    OverviewAddMilestone(myData);
  };

  const generateGoal = (resolutionID) => {
    setGoalsData([]);
    const startYear = moment(date[0].startDate).year();
    const endYear = moment(date[0].endDate).year();

    let startNumber = 0;
    let endNumber = 0;

    for (let year = startYear; year <= endYear; year++) {
      if (startYear === endYear) {
        startNumber =
          resolutionID === "1"
            ? moment(date[0].startDate, "YYYY-MM-DD").isoWeek()
            : resolutionID === "2"
            ? moment(date[0].startDate, "YYYY-MM-DD").month()
            : resolutionID === "3"
            ? moment(date[0].startDate, "YYYY-MM-DD").quarter()
            : 0;
        endNumber =
          resolutionID === "1"
            ? moment(date[0].endDate, "YYYY-MM-DD").isoWeek()
            : resolutionID === "2"
            ? moment(date[0].endDate, "YYYY-MM-DD").month()
            : resolutionID === "3"
            ? moment(date[0].endDate, "YYYY-MM-DD").quarter()
            : 0;
      } else if (startYear !== endYear) {
        if (year === startYear && year !== endYear) {
          startNumber =
            resolutionID === "1"
              ? moment(date[0].startDate, "YYYY-MM-DD").isoWeek()
              : resolutionID === "2"
              ? moment(date[0].startDate, "YYYY-MM-DD").month()
              : resolutionID === "3"
              ? moment(date[0].startDate, "YYYY-MM-DD").quarter()
              : 0;
          endNumber =
            resolutionID === "1"
              ? moment(
                  moment([year]).endOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).isoWeek()
              : resolutionID === "2"
              ? moment(
                  moment([year]).endOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).month()
              : resolutionID === "3"
              ? moment(
                  moment([year]).endOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).quarter()
              : 0;
        } else if (year !== startYear && year === endYear) {
          //durationNumber = moment(date[0].endDate, "YYYY-MM-DD").diff(moment(moment([year]).startOf('year').format('YYYY-MM-DD'), "YYYY-MM-DD"),"week");
          startNumber =
            resolutionID === "1"
              ? moment(
                  moment([year]).startOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).isoWeek()
              : resolutionID === "2"
              ? moment(
                  moment([year]).startOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).month()
              : resolutionID === "3"
              ? moment(
                  moment([year]).startOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).quarter()
              : 0;
          endNumber =
            resolutionID === "1"
              ? moment(date[0].endDate, "YYYY-MM-DD").isoWeek()
              : resolutionID === "2"
              ? moment(date[0].endDate, "YYYY-MM-DD").month()
              : resolutionID === "3"
              ? moment(date[0].endDate, "YYYY-MM-DD").quarter()
              : 0;
        } else if (year !== startYear && year !== endYear) {
          //let durationNumber1 = moment(moment([year]).endOf('year').format('YYYY-MM-DD'), "YYYY-MM-DD").diff(moment(moment([year]).startOf('year').format('YYYY-MM-DD'), "YYYY-MM-DD"),"week");
          startNumber =
            resolutionID === "1"
              ? 1
              : resolutionID === "2"
              ? moment(
                  moment([year]).startOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).month()
              : resolutionID === "3"
              ? moment(
                  moment([year]).startOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).quarter()
              : 0;
          endNumber =
            resolutionID === "1"
              ? moment(
                  moment([year]).endOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).diff(
                  moment(
                    moment([year]).startOf("year").format("YYYY-MM-DD"),
                    "YYYY-MM-DD"
                  ),
                  "week"
                )
              : resolutionID === "2"
              ? moment(
                  moment([year]).endOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).month()
              : resolutionID === "3"
              ? moment(
                  moment([year]).endOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).quarter()
              : 0;
        }
      }
      startNumber = resolutionID === "2" ? startNumber + 1 : startNumber;
      endNumber = resolutionID === "2" ? endNumber + 1 : endNumber;
      for (
        let durationNumber = startNumber;
        durationNumber <= endNumber;
        durationNumber++
      ) {
        setGoalsData((old) => [
          ...old,
          {
            year: year,
            durationNo: durationNumber, // Number of Week, Month, Quarter
            value: 0,
            label:
              (resolutionID === "1"
                ? "Week-" + durationNumber
                : resolutionID === "2"
                ? moment()
                    .month(durationNumber - 1)
                    .format("MMM")
                : resolutionID === "3"
                ? moment()
                    .quarter(durationNumber)
                    .startOf("quarter")
                    .format("MMM") +
                  "-" +
                  moment()
                    .quarter(durationNumber)
                    .endOf("quarter")
                    .format("MMM")
                : " ") +
              ", " +
              year, // Label as per Milestone resolution
          },
        ]);
      }
    }
  };
  const handleMilestoneResolution = (e) => {
    const selectedResolutionID = e.target.value;
    setMilestoneResolutionID((old) => selectedResolutionID);
    generateGoal(selectedResolutionID);
  };

  const handleReplaceMilestone = () => {
    const myData = {
      E_ID: overWriteData?.selectedProp,
      MilestoneType: "1",
      Name: overWriteData?.milestoneName,
      TargetStartDate: dayjs(date[0]?.startDate).format("YYYY-MM-DD"),
      TargetEndDate: dayjs(date[0]?.endDate).format("YYYY-MM-DD"),
      IsOngoingTarget: "1",

      mileStoneResolution: overWriteData?.mileStoneResolution,

      StatusID: overWriteData?.statusValue,

      StatusConditionID: overWriteData?.is,
      StatusValue: overWriteData?.value,
      StatusParameterID: overWriteData?.parameter,

      Color: overWriteData?.color,

      VisibilityID:
        visibilityID === "Private"
          ? "1"
          : visibilityID === "Public"
          ? "2"
          : visibilityID === "Shared"
          ? "3"
          : "",
      DisplayTypeID:
      overWriteData?.useAs === "Map Milestone"
          ? "1"
          : overWriteData?.useAs === "Chart Milestone"
          ? "2"
          : "",
      // DisplayTypeID: data?.useAs,
      Users: visibilityID === "Shared" ? optionChecked.toString() : "",
      Goal: goal,
      OverWrite: "1",
    };
    OverviewAddMilestone(myData);
  };

  const handleCloseReplace = () => {
    setOpen(false);
  };

  const handleUnitsChange = (e, i, year, duration) => {
    let newGoals = [...goal];
    newGoals[i] = { date: `${year}-${duration}`, value: e.target.value };
    setGoal(newGoals);
    // setGoal([...goal, { date: numberofunitsdate, value: e.target.value }]);
  };
  return (
    <form onSubmit={handleSubmit(handleAddManualMilestone)}>
      <div className="automatic-milestone-container">
        <div className="automatic-milestone-header">
          <div className="milestone-header-section">
            <div className="bold fs-14">{t("create-manual-milestone")}</div>

            <div className="milestone-que-mrk">
              <Tooltip text={t("create-manual-milestone")} paragraph="" />
            </div>
          </div>
          <div className="new-events-moreovericon" onClick={handleClose}>
            <a>
              <span
                style={{
                  position: "relative",
                  top: "10px",
                  left: "10px",
                }}
              >
                <CloseIcon style={{ fill: "#000000" }} />
              </span>
            </a>
          </div>
        </div>

        <div
          className="scrollable-container"
          style={{ margin: "10px 0", padding: "0 30px", maxHeight: "400px" }}
        >
          <div className="name-of-milestone">
            <div
              className="medium fw-600 fs-14"
              style={{ marginRight: "10px" }}
            >
              {t("name-of-milestone")}
            </div>
            <div>
              {/* <StyledTextField
                // focused="disabled"
                onChange={(e) => setMilestoneName(e.target.value)}
                InputLabelProps={{
                  style: { color: "rgb(144,144,144)", border: "#f4f5f5" },
                }}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: "#888888",
                      color: "#f4f5f5",
                    },
                  },
                }}
                type="text"
                label={t("name")}
                value={milestoneName}
              /> */}
              <Controller
                control={control}
                {...register("milestoneName")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StyledTextField
                  onKeyDown={(e)=> e.stopPropagation()}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("milestoneName");
                    }}
                    InputLabelProps={{
                      style: { color: "rgb(144,144,144)", border: "#f4f5f5" },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          borderColor: "#888888",
                          color: "#f4f5f5",
                        },
                      },
                    }}
                    type="text"
                    label={t("name")}
                  />
                )}
              />
              {errors?.milestoneName && (
                <p
                  style={{
                    color: "red",
                  }}
                >
                  {REQUIRED_ERR}
                </p>
              )}
            </div>
          </div>
          <div style={{ paddingTop: "0" }} className="name-of-milestone">
            <div
              className="medium fw-600 fs-14"
              style={{ marginRight: "10px" }}
            >
              {t("select-property")}
            </div>
            <div style={{ paddingLeft: "19px" }}>
              <Controller
                name="selectedProp"
                {...register("selectedProp")}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <DropDown
                    width={254}
                    data={ActivitiesProperty}
                    label={t("select-property")}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    // onChange={(e) => setEid(e.target.value)}
                    // value={eid}
                  />
                )}
              />
              {errors?.selectedProp && (
                <p
                  style={{
                    color: "red",
                  }}
                >
                  {REQUIRED_ERR}
                </p>
              )}
            </div>
          </div>
          <div className="target-date-field">
            <div className="Target-date-section">
              <div className="medium fw-600 fs-14">{t("period")}</div>
              <div>
                <div className="milestone-que-mrk">
                  <Tooltip text={t("period")} paragraph="" />
                </div>
              </div>
            </div>

            <div
              className="datefield-toogle-section"
              style={{ paddingLeft: "28px" }}
            >
              <div className="target-textfield">
                {/* <ManualMileStoneDatePicker
                  label="Period"
                  targetEndDate={targetEndDate}
                  setTargetEndDate={setTargetEndDate}
                /> */}
                <Button
                  id="basic-button-calendar"
                  aria-controls={anchorCalendar ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={anchorCalendar ? "true" : undefined}
                  onClick={handleClickCalendar}
                  sx={{
                    "&:hover": { backgroundColor: "transparent" },
                    padding: "0px",
                  }}
                >
                  <div className="date-picker-filter">
                    <div className="date-filter-styling">
                      <div className="light fs-14 ">
                        <div className="light fs-14 ">
                          {`${format(
                            date[0].startDate,
                            "dd. MMM. yyyy"
                          )} - ${format(date[0].endDate, "dd. MMM. yyyy")}`}
                        </div>
                      </div>
                      <div>
                        <img
                          className="img-cal"
                          height={15}
                          src={calendar}
                          alt="calendar"
                        />
                      </div>
                    </div>
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorCalendar}
                  open={anchorCalendar}
                  onClose={handleCloseCalendar}
                  MenuListProps={{
                    "aria-labelledby": "basic-button-calendar",
                  }}
                  sx={{
                    marginTop: "8px",
                    backgroundColor: "rgba(0, 0, 0, 0.02)",
                  }}
                >
                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                      borderRadius: "8px",
                      padding: "0px",
                      overflow: "hidden",
                      border: "1px solid #F4F5F5",
                    }}
                  >
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => setDate([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={date}
                      rangeColors={["#619130"]}
                    />
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          <div className="milestone-resolution">
            <div className="Target-date-section">
              <div className="medium fw-600 fs-14">
                {t("milestone-resolution")}
              </div>
              <div>
                <div className="milestone-que-mrk">
                  <Tooltip text={t("milestone-resolution")} paragraph="" />
                </div>
              </div>
            </div>

            <div className="use-as-container">
              <div>
                {/* <MileStoneResolution
                  value={MilestoneResolutionID}
                  // onChange={(e) => setMilestoneResolutionID(e.target.value)}
                  onChange={(e) => handleMilestoneResolution(e)}
                /> */}
                <Controller
                  name="mileStoneResolution"
                  {...register("mileStoneResolution")}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ToggleButtonGroup
                      color="success"
                      exclusive
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        // setVisibilityID(e.target.value);
                        handleMilestoneResolution(e);
                      }}
                      // value={MilestoneResolutionID}
                      // onChange={(e) => handleMilestoneResolution(e)}
                      aria-label="Platform"
                    >
                      <ToggleButton
                        sx={{ borderRadius: "8px 0 0 8px", fontSize: "14px" }}
                        value="1"
                      >
                        {t("weekly")}
                      </ToggleButton>
                      <ToggleButton
                        sx={{ borderRadius: "0px 0 0 0px", fontSize: "14px" }}
                        value="2"
                      >
                        {t("monthly")}
                      </ToggleButton>
                      <ToggleButton
                        sx={{
                          borderRadius: "0px 8px 8px 0px",
                          fontSize: "14px",
                        }}
                        value="3"
                      >
                        {t("quarterly")}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
                {errors?.mileStoneResolution && (
                  <p
                    style={{
                      color: "red",
                    }}
                  >
                    {REQUIRED_ERR}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* statusValue: "",
      is: "",
      value: "",
      parameter: "", */}

          <div className="Status-value-parameter">
            <div className="medium fw-600 fs-14" style={{ marginLeft: "10px" }}>
              {t("status-value-and-parameter")}
            </div>

            <div className="statusparametr-textfield-sub-part">
              <div className="status-section-ss">
                <FormControl m={2}>
                  <Controller
                    name="statusValue"
                    {...register("statusValue")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DropDown
                        width={254}
                        data={Status}
                        // onChange={(e) => setStatus(e.target.value)}
                        label={t("status")}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        value={value}
                      />
                    )}
                  />
                  {errors?.statusValue && (
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </FormControl>
              </div>
              <div className="status-section-ss">
                <FormControl m={2}>
                  <Controller
                    name="is"
                    {...register("is")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DropDown
                        width={100}
                        data={Comparision}
                        value={value}
                        // onChange={(e) => setStatusConditionID(e.target.value)}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors?.is && (
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                  {/* <DropDown
                    width={100}
                    data={Comparision}
                    value={statusConditionID}
                    onChange={(e) => setStatusConditionID(e.target.value)}
                  /> */}
                </FormControl>
              </div>
              <div className="status-section-ss">
                <FormControl>
                  {/* <StyledTextField
                    onChange={(e) => setStatusValue(e.target.value)}
                    type="text"
                    label={t("value")}
                    InputLabelProps={{
                      style: { color: "rgb(144,144,144)", border: "#f4f5f5" },
                    }}
                    sx={{
                      width: 100,
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          borderColor: "#888888",
                          color: "#f4f5f5",
                        },
                      },
                    }}
                  ></StyledTextField> */}
                  <Controller
                    control={control}
                    {...register("value")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <StyledTextField
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("value");
                          }}
                          type="text"
                          label={t("value")}
                          InputLabelProps={{
                            style: {
                              color: "rgb(144,144,144)",
                              border: "#f4f5f5",
                            },
                          }}
                          sx={{
                            width: 100,
                            "& .MuiOutlinedInput-root.Mui-focused": {
                              "& > fieldset": {
                                borderColor: "#888888",
                                color: "#f4f5f5",
                              },
                            },
                          }}
                        ></StyledTextField>
                      </div>
                    )}
                  />
                  {errors?.value && (
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </FormControl>
              </div>
              <div className="status-section-ss">
                <FormControl>
                  <Controller
                    name="Parameter"
                    {...register("parameter")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DropDown
                        width={254}
                        data={Parameter}
                        label={t("parameter")}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors?.parameter && (
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                  {/* <DropDown
                    width={254}
                    data={Parameter}
                    label={t("parameter")}
                    onChange={(e) => setStatusParameterID(e.target.value)}
                  /> */}
                </FormControl>
              </div>
            </div>
          </div>
          <div className="color-of-milestone">
            <div className="medium fw-600 fs-14">{t("color")}</div>
            <div className="color-textfield">
              <FormControl m={2}>
                {/* <DropDown
                  width={254}
                  data={Colors}
                  label={t("color")}
                  onChange={(e) => setColor(e.target.value)}
                  value={Color}
                /> */}
                <Controller
                  name="color"
                  {...register("color")}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <DropDown
                      width={254}
                      data={Colors}
                      label={t("color")}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
                {errors?.color && (
                  <p
                    style={{
                      color: "red",
                    }}
                  >
                    {REQUIRED_ERR}
                  </p>
                )}
              </FormControl>
            </div>
          </div>
          <div className="visibility-of-milestone">
            <div className="Target-date-section">
              <div className="medium fw-600 fs-14">{t("visibility")}</div>
              <div>
                <div className="milestone-que-mrk">
                  <Tooltip text={t("visibility")} paragraph="" />
                </div>
              </div>
            </div>

            <div className="use-as-container">
              <div>
                {/* <NavTabs
                  value={visibilityID}
                  onChange={(e) => setVisibilityID(e.target.value)}
                /> */}
                <Controller
                  name="visibility"
                  {...register("visibility")}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <NavTabs
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        setVisibilityID(e.target.value);
                      }}
                      // value={visibilityID}
                      // onChange={(e) => setVisibilityID(e.target.value)}
                    />
                  )}
                />
                {errors?.visibility && (
                  <p
                    style={{
                      color: "red",
                    }}
                  >
                    {REQUIRED_ERR}
                  </p>
                )}
              </div>
              {visibilityID === "Shared" && (
                <>
                  <div
                    className="light fw-300 fs-14"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {t("share-it-with")}
                  </div>
                  <div>
                    <Button
                      id="basic-button"
                      aria-controls={userSelect ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={userSelect ? "true" : undefined}
                      onClick={handleUserSelect}
                      sx={{
                        "&:hover": { backgroundColor: "transparent" },
                        padding: "0px",
                      }}
                    >
                      <div className="all-properties-dropdown-dashboard">
                        <span className="light fs-14 fw-300 mt-3px">
                          {optionChecked.length > 1 ? "Users" : "User"} (
                          {optionChecked.length})
                        </span>

                        <ArrowUpDown component={userSelect} />
                      </div>
                    </Button>
                    {/* <Menu
                      id="basic-menu"
                      anchorEl={userSelect}
                      open={userSelect}
                      onClose={handleUserSelectClose}
                      elevation={0}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      sx={{
                        marginTop: "8px",
                        backgroundColor: "rgba(0, 0, 0, 0.02)",
                      }}
                    >
                      
                      <div className="scrollable-container users-dropdown-milestone">
                        <ActivityDropdown
                          parentId="0"
                          allChecked={allChecked}
                          optionChecked={optionChecked}
                          OnClicked={OnClicked}
                          Activities={Activities}
                          Label={t("all")}
                        />
                      </div>
                      
                    </Menu> */}
                    <Controller
                      name="sharedWith"
                      {...register("sharedWith")}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Menu
                          id="basic-menu"
                          anchorEl={userSelect}
                          open={userSelect}
                          onClose={handleUserSelectClose}
                          elevation={0}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("sharedWith");
                          }}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          sx={{
                            marginTop: "8px",
                            backgroundColor: "rgba(0, 0, 0, 0.02)",
                          }}
                        >
                          {/* <MenuItem> */}
                          <div className="scrollable-container users-dropdown-milestone">
                            <ActivityDropdown
                              parentId="0"
                              allChecked={allChecked}
                              value={value}
                              optionChecked={optionChecked}
                              OnClicked={OnClicked}
                              Activities={Activities}
                              Label={t("all")}
                            />
                          </div>
                          {/* </MenuItem> */}
                        </Menu>
                      )}
                    />
                    {errors?.sharedWith && (
                      <p
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="visibility-of-milestone">
            <div className="Target-date-section">
              <div className="medium fw-600 fs-14">{t("use-as")}</div>
              <div>
                <div className="milestone-que-mrk">
                  <Tooltip text={t("use-as")} paragraph="" />
                </div>
              </div>
            </div>
            <div style={{ marginLeft: "11px" }}>
              {/* <UseAsTabMenu
                displayTypeID={displayTypeID}
                setDisplayTypeID={setDisplayTypeID}
              /> */}
              <Controller
                name="useAs"
                {...register("useAs")}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <UseAsTabMenu
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                )}
              />
              {errors?.useAs && (
                <p
                  style={{
                    color: "red",
                  }}
                >
                  {REQUIRED_ERR}
                </p>
              )}
            </div>
          </div>
          {goalsData.length != 0 && (
            <div className="visibility-of-milestone">
              <div className="Target-date-section">
                <div className="medium fw-600 fs-14">
                  {t("apply-your-goals")}
                </div>
                <div>
                  <div className="milestone-que-mrk">
                    <Tooltip text={t("apply-your-goals")} paragraph="" />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  flexWrap: "wrap",
                  paddingLeft: "28px",
                }}
              >
                {goalsData?.map((item, i) => (
                  <div
                    key={item.index}
                    style={{
                      position: "relative",
                      right: "86px",
                      borderRadius: "8px",
                    }}
                  >
                    {/* <UseAsTabMenu /> */}
                    <StyledTextField
                      // onChange={(e) => setnumberofunits(e.target.value)}
                      onChange={(e) => {
                        handleUnitsChange(e, i, item.year, item.durationNo);
                        // setnumberofunits(e.target.value);
                      }}
                      id="No. of units"
                      label={t("number-of-units")}
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          "& > fieldset": {
                            borderColor: "#888888",
                            color: "#f4f5f5",
                          },
                        },
                        width: "124px",
                        height: "44px",
                        "& .MuiInputBase-root": {
                          borderRadius: "8px 0px 0px 8px",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "rgb(144,144,144)", border: "#f4f5f5" },
                      }}
                    />
                    <StyledTextField
                      onChange={(e) => setnumberofunitsdate(e.target.value)}
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          "& > fieldset": {
                            borderColor: "#888888",
                            color: "#f4f5f5",
                            paddingLeft: "20px",
                          },
                        },

                        width: "124px",
                        height: "44px",
                        backgroundColor: "#f9fafa",
                        borderRadius: "0px 8px 8px 0px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        textAlign: "center",
                        //  paddingLeft:'20px'
                        "& .MuiInputBase-root": {
                          borderRadius: "0px 8px 8px 0px",
                        },
                      }}
                      id="outlined-adornment-weight"
                      // value={`${dayjs(item?.startDate).format("YYYY")}, ${
                      //   item.durationNo
                      // }`}
                      value={item.label}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{}}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div
          className=" create-event-btn auto-milestone-footer"
          style={{ backgroundColor: "#f4f5f5" }}
        >
          <div
            className="semi-bold  fs-14 footer-cancel uppercase"
            onClick={handleClose}
          >
            {t("cancel")}
          </div>
          <div className="footer-reset-save-section">
            <div onClick={()=> reset()} className="semi-bold  fs-14 footer-reset-setting uppercase" >
              {t("reset-settings")}
            </div>
            <button
              type="submit"
              // onClick={handleAddManualMilestone}
              className="semi-bold  fs-14 footer-save uppercase"
            >
              {t("save")}
            </button>
          </div>
        </div>
      </div>

      {/* Alert popup */}

      <Dialog
        maxWidth="lg"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "fit-content",
              overflow: "hidden",
              // position: 'relative'
            },
          },
        }}
        open={open}
        onClose={handleCloseReplace}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="alert-card-container">
          <div className="bold fs-20 alert-card-heading">
            {t("whoa-that's-not-possible!")}
          </div>
          <div className="alert-card-para-section">
            <div className=" light fs-13 regular fw-300 fs-13">
              You cannot have several milestones on the same property on the map
              at the same time
            </div>
            <br />
            <div className="light fs-13">
              Edit the milestone that is already active or replace this
              milestone to activate it and set the old one inactive.
            </div>
            <br />
            <div className="light fs-13">
              It is always possible to reactivate a previous milestone.
            </div>
          </div>
          <div className="alert-card-footer">
            <div className="semi-bold fs-14 alert-card-NO cursor-pointer uppercase" onClick={handleCloseReplace}>{t("no")}</div>
            <div
              onClick={handleReplaceMilestone}
              onClose={handleCloseReplace}
              className="semi-bold fs-14 alert-card-REPLACE cursor-pointer uppercase"
            >
              {t("replace")}
            </div>
          </div>
        </div>
      </Dialog>
    </form>
  );
};

export default ManualMileStone;
