import { Delete } from "../../estateTool/dashboard/utils/Axios";
import { endpoint } from "../EndPoint";
import { GetHeader } from "../Header";

export const DeleteMilestone = (sysid) => {
  return new Promise((resolve, reject) => {
    Delete(endpoint.Delete_Milestone, GetHeader(), sysid)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
