import { Get } from "../../estateTool/dashboard/utils/Axios";
import { endpoint } from "../EndPoint";
import { GetHeader } from "../Header";

export const GetuserbyProperty = (eid, uid) => {
  eid = eid === "" || eid === null || eid === undefined ? 0 : eid;

  return new Promise((resolve, reject) => {
    Get(endpoint.Common_UserbyProperty, GetHeader(), eid + "/" + uid)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
