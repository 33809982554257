import React from "react";
import ArrowUp from "../../Icons/dropdown-chevron-up.svg";
import ArrowDown from "../../Icons/dropdown-chevron-down.svg";

export const ArrowUpDown = ({ component }) => {
  return (
    <>
      <img
        height={8}
        className="arrow-up-down-icon"
        src={component ? ArrowUp : ArrowDown}
        alt="arrowIcon"
      />
    </>
  );
};
