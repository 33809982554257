import React from "react";
import { useEffect, useState } from "react";

import styled from "@emotion/styled";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Typography,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";
import { GetCustomersTransactionAge } from "../../../API/Customers/CustomersTransactionAge";
import { useTranslation } from "react-i18next";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: "Cerebri sans-medium";
    font-size: 13px;
    font-weight: 500;
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family: "Cerebri sans-light";
    font-size: 13px;
    font-weight: 300;
    white-space: nowrap;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
  }
`;

// Data
let id = 0;
function createData(name, calories, fat, carbs) {
  id += 1;

  return { id, name, calories, fat, carbs };
}

const rows = [
  createData("18 - 24", 4, "10%", "10.000.000 DKK"),
  createData("25 - 34", 2, "8%", "10.000.000 DKK"),
  createData("34 - 44", 8, "7%", "10.000.000 DKK"),
  createData("45 - 54", 5, "15%", "10.000.000 DKK"),
  createData("55 - 64", 2, "34%", "10.000.000 DKK"),
  createData("65 - 74", 2, "34%", "10.000.000 DKK"),
  createData("Over 75", "0", "5%", "1.320.000 DKK"),
];
const styles = {
  rectangle: {
    width: "50px",
    height: "50px",
  },
};

function TransactionAgeTable({ sid, datefilter }) {
  //i18n
  const { t, i18n } = useTranslation();

  // API Consumption

  const [myData, setMyData] = useState({ data: [] });
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  console.log('myData',myData);

  useEffect(() => {
    GetCustomersTransactionByAge(sid, datefilter);
  }, [sid, datefilter]);

  const GetCustomersTransactionByAge = (sid, datefilter) => {
    try {
      GetCustomersTransactionAge(sid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  return (
    <>
      <Paper className="container-age">
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell>{t("age")}</CustomTableCell>
              <CustomTableCell align="right">{t("tenants")}</CustomTableCell>
              <CustomTableCell align="right">{t("percentage")}</CustomTableCell>
              <CustomTableCell align="right">
                {t("total-annual-rent")}
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {myData?.data?.Data?.map((row, i) => (
              <CustomTableRow key={(row.id, i)}>
                <CustomTableCell component="th" scope="row">
                  {row.Age}
                </CustomTableCell>
                <CustomTableCell align="right">{row.Tenants}</CustomTableCell>
                <CustomTableCell align="right">
                  {row.Percentage}%
                </CustomTableCell>
                <CustomTableCell align="right">
                  {new Intl.NumberFormat("en-DE").format(row.YearlyRent)} DKK
                </CustomTableCell>
                <CustomTableCell align="right"></CustomTableCell>
              </CustomTableRow>
            ))}
          </TableBody>
          <TableHead>
            <TableRow>
              <CustomTableCell sx={{ fontWeight: 600 }}>
                {myData?.data?.Footer?.Age}
              </CustomTableCell>
              <CustomTableCell align="right" sx={{ fontWeight: 600 }}>
                {myData?.data?.Footer?.Tenants}
              </CustomTableCell>
              <CustomTableCell align="right" sx={{ fontWeight: 600 }}>
                {myData?.data?.Footer?.YearlyRent}
              </CustomTableCell>
              <CustomTableCell align="right" sx={{ fontWeight: 600 }}>
                {myData?.data?.Footer?.Percentage}
              </CustomTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Paper>
    </>
  );
}

export default TransactionAgeTable;
