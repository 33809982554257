import { Get } from "../../estateTool/dashboard/utils/Axios";
import { endpoint } from "../EndPoint";
import { GetHeader } from "../Header";

export const GetCustomersTransactionAge = (eid, datefilter) => {
  eid = eid === "" || eid === null ? 0 : eid;
  datefilter =
    datefilter === "" || datefilter === null || datefilter === undefined
      ? "2020-01-01/2023-01-31"
      : datefilter;
  return new Promise((resolve, reject) => {
    Get(endpoint.Customers_GetAge, GetHeader(), eid + "/" + datefilter)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
