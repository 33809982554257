import { Get } from "../../estateTool/dashboard/utils/Axios";
import { endpoint } from "../EndPoint";
import { GetHeader } from "../Header";

export const GetOverviewStatusActivity = (
  eid,
  selectedActivity,
  datefilter
) => {
  eid = eid === "" || eid === null || eid === undefined ? 0 : eid;
  selectedActivity =
    selectedActivity === "" ||
    selectedActivity === null ||
    selectedActivity === undefined
      ? 0
      : selectedActivity;
  datefilter =
    datefilter === "" || datefilter === null || datefilter === undefined
      ? "2020-01-01/2023-01-31"
      : datefilter;

  return new Promise((resolve, reject) => {
    Get(
      endpoint.Overview_Status_Activity,
      GetHeader(),
      eid + "/" + selectedActivity + "/" + datefilter
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
