import { Put } from "../../estateTool/dashboard/utils/Axios";
import { endpoint } from "../EndPoint";
import { GetHeader } from "../Header";

export const PutTrafficUpdateFunnel = (data) => {
  return new Promise((resolve, reject) => {
    Put(endpoint.Traffic_UpdateFunnel, GetHeader(), data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
