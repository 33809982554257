import React, { useEffect, useState } from "react";
import {
  Dialog,
  IconButton,
  styled,
  Table,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NorthIcon from "@mui/icons-material/North";
import { Menu, MenuItem } from "@mui/material";

import "./events.css";
import Tooltip from "../components/Tooltip/Tooltip";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";
import { AddEventsInformation } from "./AddInformationEvent";
import { GetCompletedEvents } from "../../../API/Events/EventsCompletedEvents";
import moment from "moment";
import { EventsDeleteCommpletedEvents } from "../../../API/Events/DeleteCompletedEvents";
import { GetCompletedEventsCountries } from "../../../API/Events/EventsCompletedEventCountries";
import NoDataScreen from "../components/NoDataScreen";

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border: none;
    font-family: "Cerebri sans-medium";
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap;
  }
  &.${tableCellClasses.body} {
    border: none;
    font-family: "Cerebri sans-light";
    font-size: 13px;
  }
`;

export const CompletedEventsMenu = ({
  eid,
  handleClose,
  anchorEl,
  open,
  PropertyName,
  EventDate,
  EventTime,
  Registered,
  Participated,
  Reserved,
  sys_oh_id,
  GetCompletedByEvents,
}) => {
  //i18n
  const { t, i18n } = useTranslation();

  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCancel, setOpenCancel] = React.useState(false);

  const handleEditClickOpen = () => {
    handleClose();
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const EventsDeleteByCommpletedEvents = () => {
    try {
      EventsDeleteCommpletedEvents(sys_oh_id)
        .then((response) => {})
        .catch((error) => {
          //   setError({ error: error.message, status: error.status });
        });
    } catch (error) {
      //   setError({ error: true, status: "", message: "Failed to retrieve data" });
    }
  };
  const handleDeleteEvent = () => {
    handleClose();
    EventsDeleteByCommpletedEvents(sys_oh_id);
    setTimeout(() => {
      GetCompletedByEvents();
    }, 1000);
  };

  // Get request for Countries dropdown
  const [countryData, setcountryData] = useState("");
  const [errorCountry, setErrorCountry] = useState([
    { error: false, status: "", message: "" },
  ]);

  const GetCountries = () => {
    try {
      GetCompletedEventsCountries()
        .then((data) => {
          if (data != null && data !== undefined) {
            const countryDrodown = data?.data?.map(({ Id, Text }) => {
              return {
                id: Id.toString(),
                name: Text,
              };
            });
            setcountryData(countryDrodown);
          } else {
            setcountryData([null]);
          }
        })
        .catch((error) => {
          setErrorCountry({ error: error.message, status: error.status });
          setcountryData([null]);
        });
    } catch (e) {
      setcountryData([null]);
      setErrorCountry({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };
  useEffect(() => {
    GetCountries();
  }, []);

  return (
    <>
      <Menu
        id="simple-menu"
        align="center"
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
        open={open}
        sx={{ marginTop: "10px", borderRadius: "8px" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <span>
          <MenuItem onClick={() => handleEditClickOpen()}>
            <div className="light fs-14 info-drop">{t("add-information")}</div>
          </MenuItem>
        </span>
        <Dialog
          BackdropProps={{
            style: { backgroundColor: "rgba(0, 0, 0, 0.08)" },
          }}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                overflow: "hidden",
              },
            },
          }}
          open={openEdit}
          onClose={handleEditClose}
          maxWidth="lg"
          aria-labelledby="responsive-dialog-title"
        >
          <AddEventsInformation
            eid={eid}
            PropertyName={PropertyName}
            EventDate={EventDate}
            EventTime={EventTime}
            Registered={Registered}
            Participated={Participated}
            Reserved={Reserved}
            sys_oh_id={sys_oh_id}
            handleClose={handleEditClose}
            countryData={countryData}
          />
        </Dialog>
        <MenuItem>
          <div onClick={handleDeleteEvent} className="light fs-14 info-drop">
            {t("Delete information")}
          </div>
        </MenuItem>
      </Menu>
    </>
  );
};

const CompletedEventsNotes = ({
  PropertyName,
  eid,
  UserName,
  EventDate,
  EventTime,
  Registered,
  Participated,
  ParticpatedPercentage,
  Reserved,
  sys_oh_id,
  GetCompletedByEvents,
}) => {
  //i18n
  const { t, i18n } = useTranslation();
  let formattedDate = moment(EventDate).format("ll");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="events-container">
        <CustomTableCell width="30%" sx={{ border: "none" }}>
          <div className="events-name">
            <span className="events-name-child ">
              <span className="events-name-child2 medium fs-14">
                {PropertyName}
              </span>
              <br />
              <span className="light fw-300 fs-13">{UserName}</span>
            </span>
            <br />
            <span className="events-date-time light fs-10">
              {t("event-date")}: {EventDate}-{EventTime}
            </span>
          </div>
        </CustomTableCell>
        <CustomTableCell
          width="20%"
          style={{ border: "none", paddingLeft: "29px" }}
        >
          <div className="registered-data-value">
            <div className="bold fs-20">{Registered}</div>
            <div className="registered-arrow  medium fw-500 fs-11">
              <NorthIcon fontSize="0.23rem" /> 9
            </div>
          </div>
        </CustomTableCell>
        <CustomTableCell
          width="20%"
          sx={{ border: "none", paddingLeft: "49px" }}
        >
          <div className="participated-value">
            <div className="bold fs-20">{Participated}</div>

            <div className="participated-percentage  medium fw-500 fs-11 ">
              {ParticpatedPercentage} %
            </div>
          </div>
        </CustomTableCell>
        <CustomTableCell
          width="20%"
          sx={{ border: "none", paddingLeft: "75px" }}
        >
          <div className="units-reserved">
            <div className="bold fs-20">{Reserved}</div>

            <div className="units-reserved-value cursor light fs-12">
              <span fontSize="0.23rem" />({t("show")})
            </div>
          </div>
        </CustomTableCell>

        <CustomTableCell width="10%" sx={{ border: "none" }}>
          <div
            className="vertical-ellipsis"
            style={{
              transform: `rotate(${anchorEl ? "90" : "0"}deg)`,
            }}
          >
            <IconButton
              onClick={handleClick}
              aria-haspopup="true"
              aria-controls="long-menu"
              sx={{
                ml: 1,
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
            >
              <span className="moreverticons-span">
                <MoreVertIcon style={{ fill: "#619130" }} />
              </span>
            </IconButton>
          </div>
        </CustomTableCell>

        <div className="regular button-label">
          <CompletedEventsMenu
            eid={eid}
            anchorEl={anchorEl}
            handleClose={handleClose}
            open={open}
            PropertyName={PropertyName}
            EventDate={EventDate}
            EventTime={EventTime}
            Registered={Registered}
            Participated={Participated}
            Reserved={Reserved}
            sys_oh_id={sys_oh_id}
            GetCompletedByEvents={GetCompletedByEvents}
          />
        </div>
      </div>
    </>
  );
};

function CompletedEvents({ sid, datefilter }) {
  //i18n
  const { t, i18n } = useTranslation();

  //Options for CSV data
  const options = {
    keys: [
      { field: "Name", title: "Name" },
      { field: "UserName", title: "UserName" },
      { field: "EventDate", title: "EventDate" },
      { field: "EventTime", title: "EventTime" },
      { field: "Registered", title: "Registered" },
      { field: "Participated", title: "Participated" },
      { field: "Reserved", title: "Reserved" },
      { field: "ParticpatedPercentage", title: "ParticpatedPercentage" },
    ],
  };

  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetCompletedByEvents(sid, datefilter);
  }, [sid, datefilter]);

  const GetCompletedByEvents = (sid, datefilter) => {
    try {
      GetCompletedEvents(sid, datefilter)
        .then((data) => {
          if (data != null && data !== undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  return (
    <>
      <div className="events">
        <div className="events-child ">
          <div className="margin-heading-card events-child2">
            <div className="bold fw-700 fs-14 ">{t("completed-events")}</div>
            <Tooltip text={t("completed-events")} paragraph="" />
          </div>
          <div>
            <DownloadWidget
              options={options}
              data={myData}
              fileName={"events-completed-events"}
              type={false}
            />
          </div>
        </div>

        {myData?.length != 0 ? (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <CustomTableCell width="30%"> </CustomTableCell>
                  <CustomTableCell width="20%">
                    <div className="medium fs-13 fw-500">{t("registered")}</div>
                  </CustomTableCell>
                  <CustomTableCell width="20%">
                    <div className="medium fs-13 fw-500">
                      {t("participated")}
                    </div>
                  </CustomTableCell>
                  <CustomTableCell width="20%">
                    <div className="medium fs-13 fw-500">
                      {t("no-of-units-reserved")}
                    </div>
                  </CustomTableCell>
                  <CustomTableCell width="10%"> </CustomTableCell>
                </TableRow>
              </TableHead>
            </Table>

            <div className="scrollable-container " style={{ height: "242px" }}>
              {myData.map((item) => (
                <CompletedEventsNotes
                  myData={myData}
                  PropertyName={item.Name}
                  eid={item.E_ID}
                  UserName={item.UserName}
                  EventDate={item.EventDate}
                  EventTime={item.EventTime}
                  Registered={item.Registered}
                  Participated={item.Participated}
                  ParticpatedPercentage={item.ParticpatedPercentage}
                  Reserved={item.Reserved}
                  sys_oh_id={item.sys_oh_id}
                  GetCompletedByEvents={GetCompletedByEvents}
                />
              ))}
            </div>
          </>
        ) : (
          <NoDataScreen />
        )}
      </div>
    </>
  );
}

export default CompletedEvents;
