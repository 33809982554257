import React from "react";
import { useEffect, useState } from "react";
import "./economy.css";

import styled from "@emotion/styled";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";
import { GetAvailiabilityRooms } from "../../../API/EconomyAvailabilityRooms/AvailiabilityRooms";
import Tooltip from "../components/Tooltip/Tooltip";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";
import NoDataScreen from "../components/NoDataScreen";

const Card = styled(MuiCard)(spacing);



const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: Cerebri sans-medium;
    font-size: 13px;
    font-weight: 500;
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family: Cerebri sans-light;
    font-size: 13px;
    font-weight: 300;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
  }
`;

// Data
let id = 0;
function createData(name, calories, fat, carbs) {
  id += 1;

  return { id, name, calories, fat, carbs };
}

const rows = [
  createData("1-room", 4, "10%", "10.000.000 DKK"),
  createData("2-room", 2, "8%", "10.000.000 DKK"),
  createData("3-room", 8, "7%", "10.000.000 DKK"),
  createData("4-room", 5, "15%", "10.000.000 DKK"),
  createData("5-room", 2, "34%", "10.000.000 DKK"),
  createData("Over 6-room", "0", "0%", "0 DKK"),
];
const styles = {
  rectangle: {
    width: "50px",
    height: "50px",
  },
};

function AvailabilityRooms({ sid, datefilter }) {
  //i18Next
  const { t, i18n } = useTranslation();

  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetAvailiabilityByRooms();
  }, [sid, datefilter]);

  const GetAvailiabilityByRooms = () => {
    try {
      GetAvailiabilityRooms(sid, datefilter)
        .then((data) => {
          if (data != null && data !== undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  //Options for CSV data
  const options = {
    keys: [
      { field: "NoOfRooms", title: "No. Of Rooms" },
      { field: "Quantity", title: "Quantity" },
      { field: "Percentage", title: "Percentage %" },
      { field: "TotalAmount", title: "Total Amount in DKK" },
    ],
  };

  let Total = 0;
  let total = myData?.reduce(function (sum, value) {
    return (Total += Number(value.Quantity));
  }, 0);

  return (
    <>
      <div
        style={{
          borderRadius: "8px",
          height: "content-fit",
          backgroundColor: "#FFF",
          position: "relative",
        }}
        className="display-flex justify-content-between"
      >
        <div className="margin-heading-card bold fs-14 display-flex availabilityrooms-card-title">
          <div>{t("availability-divided-by-rooms")}</div>

          <Tooltip text={t("availability-divided-by-rooms")} paragraph="" />
        </div>
        <div>
          <DownloadWidget
            options={options}
            data={myData}
            fileName={"economy-availability-by-rooms"}
            type={false}
          />
        </div>
      </div>
      <div mb={6} className="table-card-section">
        {Total != 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell>{t("number-of-rooms")}</CustomTableCell>
                <CustomTableCell align="right">{t("quantity")}</CustomTableCell>
                <CustomTableCell align="right">
                  {t("percentage")}
                </CustomTableCell>
                <CustomTableCell align="right">
                  {t("total-amount")}
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myData?.map((row, i) => (
                <CustomTableRow key={(row.id, i)}>
                  <CustomTableCell component="th" scope="row">
                    {t(row.NoOfRooms)}
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {row.Quantity}
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {row.Percentage}%
                  </CustomTableCell>

                  <CustomTableCell align="right">
                    {new Intl.NumberFormat("en-DE").format(row.TotalAmount)} DKK
                  </CustomTableCell>
                </CustomTableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <NoDataScreen />
        )}
      </div>
    </>
  );
}

export default AvailabilityRooms;
