import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DateField from "../components/DateField";
import "./events.css";
import { useEffect, useState } from "react";
import DropDown from "../components/DropDown";
import dayjs from "dayjs";
import { GetEventsPreferredUnits } from "../../../API/Events/EventParticipantPreferedUnit";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { PutEventsEditParticipant } from "../../../API/Events/EventsEditParticipant";
import { useTranslation } from "react-i18next";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

// Icons for checkbox dropdown
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const EditParticipant = ({
  handleClose,
  GetEventsCompletedByParticipants,
  eid,
  PropertyName,
  sys_oh_id,
  sysid,
  Participant,
  countryData,
}) => {
  //i18Next
  const { t, i18n } = useTranslation();
  // const ResidenceTypes = [
  //   {
  //     name: "Single-family houses",
  //     id: "Single-family houses",
  //   },
  //   {
  //     name: "Ownership apartments",
  //     id: "Ownership apartments",
  //   },
  //   {
  //     name: "Ideal shares",
  //     id: "Ideal shares",
  //   },
  //   {
  //     name: "Cooperative housing",
  //     id: "Cooperative housing",
  //   },
  //   {
  //     name: "Rental housing",
  //     id: "Rental housing",
  //   },
  // ];
  const ResidenceTypes = [
    {
      name: "Owner",
      id: "Owner",
    },
    {
      name: "Renting",
      id: "Renting",
    },
    {
      name: "Other",
      id: "Other",
    },
  ];

  const CivilStatus = [
    {
      name: "Married",
      id: "Married",
    },
    {
      name: "Single",
      id: "Single",
    },
    {
      name: "Divorced",
      id: "Divorced",
    },
    {
      name: "Cohabiting",
      id: "Cohabiting",
    },
  ];

  const Genders = [
    {
      name: "Male",
      id: "Male",
    },
    {
      name: "Female",
      id: "Female",
    },
    {
      name: "Other",
      id: "Other",
    },
  ];
  const HeardAboutproperty = [
    {
      name: "Social Media",
      id: "1",
    },
    {
      name: "Outdoor marketing",
      id: "2",
    },
    {
      name: "Website",
      id: "3",
    },
    {
      name: "Newspaper",
      id: "4",
    },
    {
      name: "Instagram",
      id: "5",
    },
    {
      name: "Facebook",
      id: "6",
    },
    {
      name: "Google Ads",
      id: "7",
    },
    {
      name: "Newsletter",
      id: "8",
    },
    {
      name: "Avis",
      id: "9",
    },
    {
      name: "Youtube",
      id: "10",
    },
  ];

  // Get request for Preferred Unit
  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetEventsByPreferredUnits();
  }, []);

  const GetEventsByPreferredUnits = () => {
    try {
      GetEventsPreferredUnits(eid)
        .then((data) => {
          if (data != null && data !== undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  //Put request for Edit Participants
  const [posterror, setPostError] = useState([
    { error: false, status: "", message: "" },
  ]);

  const PutEventsByEditParticipant = (data) => {
    try {
      PutEventsEditParticipant(data)
        .then((response) => {
          GetEventsCompletedByParticipants();
        })
        .catch((error) => {
          setPostError({ error: error.message, status: error.status });
        });
    } catch (e) {
      setPostError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const [firstName, setfirstName] = useState(Participant.FirstName);
  const [lastName, setlastName] = useState(Participant.LastName);
  const [streetName, setstreetName] = useState(Participant.StreetName);
  const [houseNo, sethouseNo] = useState(Participant.HouseNo);
  const [floor, setfloor] = useState(Participant.FloorNo);
  const [sidedoor, setsidedoor] = useState(Participant.Sidedoor);
  const [zipcode, setzipcode] = useState(Participant.ZipCode);
  const [city, setcity] = useState(Participant.City);
  const [country, setcountry] = useState(Participant.Country);
  const [email, setemail] = useState(Participant.Email);
  const [phone, setphone] = useState(Participant.Mobile);
  const [dateOfBirth, setdateOfBirth] = useState(Participant.DateOfBirth);
  const [residenceType, setresidenceType] = useState(Participant.ResidenceType);
  const [civilStatus, setcivilStatus] = useState(Participant.CivilStatus);
  const [gender, setgender] = useState(Participant.Gender);
  const [bid, setbid] = useState(Participant.PreferredUnit.split(",")); //PreferredUnit
  const [heardAbout, setheardAbout] = useState(
    Participant.HeardAboutTheProperty.split(",")
  );

  //HeardAboutTheProperty
  const handleAddParticipant = () => {
    const myData = {
      sys_res_id: sysid,
      b_id: bid.toString(),
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: dayjs(dateOfBirth).format("YYYY-MM-DD"),
      gender: gender,
      civilStatus: civilStatus,
      residenceType: residenceType,
      email: email,
      street: streetName,
      house: houseNo,
      floor: floor,
      sidedoor: sidedoor,
      zip: zipcode,
      city: city,
      country: country,
      phone: phone,
      source: heardAbout.toString(),
    };
    PutEventsByEditParticipant(myData);
  };

  const handleHeardAbout = (event) => {
    let newArray = [...heardAbout, event.target.value];
    if (heardAbout.includes(event.target.value)) {
      newArray = newArray.filter((item) => item !== event.target.value);
    }
    setheardAbout(newArray);
  };
  return (
    <div>
      <div className="edit-future-container scrollable-container">
        <div className="medium fs-14 edit-heading">{t("information")}</div>
        <div className="event-fst-row">
          <div>
            <TextField
              type="text"
              label={t("first-name")}
              className="Editinfo-text-field"
              value={firstName}
              onChange={(e) => setfirstName(e.target.value)}
            />
          </div>
          <div className="events-last-name">
            <TextField
              type="text"
              label={t("last-name")}
              className="Editinfo-text-field"
              value={lastName}
              onChange={(e) => setlastName(e.target.value)}
            />
          </div>
        </div>
        <div className="event-second-row">
          <div>
            <TextField
              type="text"
              label={("street-name")}
              className="Editinfo-text-field"
              value={streetName}
              onChange={(e) => setstreetName(e.target.value)}
            />
          </div>
          <div className="event-data-sub-section">
            {/* <div className="house-no" > */}
            <TextField
              type="text"
              label={t("house-no.")}
              className="event-second-row-section"
              value={houseNo}
              onChange={(e) => sethouseNo(e.target.value)}
            />
          </div>
          <div>
            <TextField
              type="text"
              label={t("floor")}
              className="regular fw-300 fs-14 event-second-row-section"
              value={floor}
              onChange={(e) => setfloor(e.target.value)}
            />
          </div>
          <div>
            <TextField
              type="text"
              label={t("side/door")}
              className="event-second-row-section"
              value={sidedoor}
              onChange={(e) => setsidedoor(e.target.value)}
            />
          </div>
        </div>
        <div className="event-third-row">
          <div>
            <TextField
              type="text"
              label={t("zipcode")}
              className="Editinfo-text-field"
              value={zipcode}
              onChange={(e) => setzipcode(e.target.value)}
            />
          </div>
          <div className="events-last-name">
            <TextField
              type="text"
              label={t("city")}
              className="Editinfo-text-field"
              value={city}
              onChange={(e) => setcity(e.target.value)}
            />
          </div>
          <div className="events-last-name">
            <DropDown
              width={196}
              data={countryData}
              label={t("country")}
              onChange={(e) => setcountry(e.target.value)}
              value={country}
            />
          </div>
        </div>
        <div className="event-third-row">
          <div>
            <TextField
              type="text"
              label={t("e-mail")}
              className="Editinfo-text-field"
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
          </div>
          <div className="events-last-name">
            <TextField
              type="text"
              label={t("phone")}
              className="Editinfo-text-field"
              value={phone}
              onChange={(e) => setphone(e.target.value)}
            />
          </div>
        </div>
        <div className="event-fourth-row">
          <span className=" regular fw-300 fs-14 ">
            <DateField
              label={t("date-of-birth")}
              value={dateOfBirth}
              onChange={(newValue) => setdateOfBirth(newValue)}
            />
          </span>
          <div>
            <DropDown
              width={196}
              data={ResidenceTypes}
              label={t("current-housing-type")}
              onChange={(e) => setresidenceType(e.target.value)}
              value={residenceType}
            />
          </div>
          <div>
            <DropDown
              width={196}
              data={CivilStatus}
              label={("civil-status")}
              onChange={(e) => setcivilStatus(e.target.value)}
              value={civilStatus}
            />
          </div>
          <div>
            <DropDown
              width={196}
              data={Genders}
              label={t("gender")}
              onChange={(e) => setgender(e.target.value)}
              value={gender}
            />
          </div>
        </div>

        <div>
          <div className="preferred-unit">
            <div className="medium fs-14" style={{ marginBottom: "26px" }}>
              {t("preferred-unit")}
            </div>
            <div className="preferred-units">
              {PropertyName}&nbsp; ({bid.length})
            </div>
            <div>
              <Autocomplete
                onChange={(event, newValue) =>
                  setbid(newValue.map((e) => e.SYS_B_ID))
                }
                multiple
                id="checkboxes-tags-demo"
                options={myData}
                disableCloseOnSelect
                getOptionLabel={(option) => option.Unit}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={bid.includes(myData.map((e) => e.SYS_B_ID))}
                    />
                    {option.Unit}
                  </li>
                )}
                style={{ width: 345 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("search")}
                    // placeholder="Favorites"
                  />
                )}
              />
            </div>
            <div className="heard-prop-checkbox-section">
              <div className="medium fs-14">
                {t("heard-about-the-property")}
              </div>
              <div className="heard-prop-checkboxes">
                {HeardAboutproperty.map((item, i) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "165px",
                        }}
                        key={i}
                      >
                        <FormControlLabel
                          onChange={handleHeardAbout}
                          label={item.name}
                          value={item.id}
                          control={
                            <Checkbox
                              checked={heardAbout.includes(item.id)}
                              {...label}
                              sx={{
                                color: "#619130",
                                borderRadius: "8px",
                                "&.Mui-checked": {
                                  color: "#619130",
                                  verticalAlign: "middle",
                                  position: "relative",
                                  bottom: "1px",
                                },
                              }}
                            />
                          }
                        />

                        {/* <div className="light fw-300 fs-14">{item.name}</div> */}
                      </div>
                    </>
                  );
                })}
              </div>
              {/* <hr className="horizonal-line-event"/> */}
            </div>
          </div>
        </div>
      </div>
      <div className="add-to-event">
        <div className="create-event-btn-btn">
          <div onClick={handleClose} className="medium fs-14 btn-text-no uppercase">
            {typeof("cancel")}
          </div>
        </div>
        <div className="event-btn-green">
          <div
            className="medium fs-14 btn-text-no uppercase"
            onClick={() => {
              handleAddParticipant();
              handleClose();
            }}
          >
            {t("update")} {t("event")}
          </div>
        </div>
      </div>
    </div>
  );
};
