import React from "react";
import { useEffect, useState } from "react";
import "./economy.css";

import styled from "@emotion/styled";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";
import { GetAvailiabilitySqrmtr } from "../../../API/EconomyAvailiabilitySqrmtr/GetAvailiabilitySqrmtr";
import Tooltip from "../components/Tooltip/Tooltip";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";
import NoDataScreen from "../components/NoDataScreen";

const Card = styled(MuiCard)(spacing);

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: Cerebri sans-medium;
    font-size: 13px;
    font-weight: 500;
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family: Cerebri sans-light;
    font-size: 13px;
    font-weight: 300;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
    overflow: hidden;
    border-radius: 8px;
  }
`;

// Data
let id = 0;
function createData(name, calories, fat, carbs) {
  id += 1;
  return { id, name, calories, fat, carbs };
}

const rows = [
  createData("Under 50", 6, "10%", "10.000.000 DKK"),
  createData("50 - 99", 2, "8%", "10.000.000 DKK"),
  createData("100 - 149", 8, "7%", "10.000.000 DKK"),
  createData("150 - 199", 5, "15%", "10.000.000 DKK"),
  createData("200 - 249", 2, "34%", "10.000.000 DKK"),
  createData("Over 250", "0", "0%", "0 DKK"),
];
const styles = {
  rectangle: {
    width: "50px",
    height: "50px",
  },
};

function AvailabilitySquare({ sid, datefilter }) {
  //i18Next
  const { t, i18n } = useTranslation();

  // API Consumption

  const [myData, setMyData] = useState({ data: [] });
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetAvailiabilityBySqrmtr();
  }, [sid, datefilter]);

  const GetAvailiabilityBySqrmtr = () => {
    try {
      GetAvailiabilitySqrmtr(sid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  //Options for CSV data
  const options = {
    keys: [
      { field: "SQMTR", title: "Sq. Mtr" },
      { field: "QUANTITY", title: "Quantity" },
      { field: "PERCENTAGE", title: "Percentage %" },
      { field: "TOTAL_AMOUNT", title: "Total Amount in DKK" },
    ],
  };

  let Total = 0;
  let total = myData?.data?.reduce(function (sum, value) {
    return (Total += Number(value.QUANTITY));
  }, 0);

  return (
    <>
      <div
        style={{
          borderRadius: "8px",
          height: "content-fit",
          backgroundColor: "#FFF",
          position: "relative",
        }}
        className="display-flex justify-content-between"
      >
        <div className="margin-heading-card bold fs-14 display-flex availablitybysquare-card-title">
          {t("availability-divided-by-square-meters")}
          <Tooltip
            text={t("availability-divided-by-square-meters")}
            paragraph=""
          />
        </div>

        <div>
          <DownloadWidget
            options={options}
            data={myData.data}
            fileName={"economy-availability-by-sqmtr"}
            type={false}
          />
        </div>
      </div>
      <Card mb={6} className="table-card-section">
        {Total != 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell>{t("square-meters")}</CustomTableCell>
                <CustomTableCell align="right">{t("quantity")}</CustomTableCell>
                <CustomTableCell align="right">
                  {t("percentage")}
                </CustomTableCell>
                <CustomTableCell align="right">
                  {t("total-amount")}
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myData?.data.map((row, i) => (
                <CustomTableRow key={(row.id, i)}>
                  <CustomTableCell component="th" scope="row">
                    {t(row.SQMTR)}
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {row.QUANTITY}
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {row.PERCENTAGE}%
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {new Intl.NumberFormat("en-DE").format(row.TOTAL_AMOUNT)}{" "}
                    DKK
                  </CustomTableCell>
                </CustomTableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <NoDataScreen />
        )}
      </Card>
    </>
  );
}

export default AvailabilitySquare;
