import React from "react";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GetTrafficPopularUnits } from "../../../API/TrafficPopularUnits/TrafficPopularUnits";
import Tooltip from "../components/Tooltip/Tooltip";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";
import photoComingSoon from "../../dashboard/Icons/photo-coming-soon.jpg";
import { Sorting } from "../components/Sorting/Sorting";
import NoDataScreen from "../components/NoDataScreen";

const ShowPopularUnit = ({ sid, datefilter }) => {
  //i18Next
  const { t, i18n } = useTranslation();

  const [myTopData, setMyTopData] = useState({ data: [] });
  const [apiError, setApiError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetTopPopularUnits(sid, datefilter);
  }, [sid, datefilter]);

  const GetTopPopularUnits = (sid, datefilter) => {
    try {
      GetTrafficPopularUnits(sid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyTopData(data);
          } else {
            setMyTopData([null]);
          }
        })
        .catch((error) => {
          setApiError({ error: error.message, status: error.status });
          setMyTopData([null]);
        });
    } catch (e) {
      setMyTopData([null]);
      setApiError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };
  const views = [
    {
      img: myTopData?.data[0]?.Picture,
      description: myTopData?.data[0]?.Address,
      name: myTopData?.data[0]?.Property,
      views: myTopData?.data[0]?.Views,
      unitNo: myTopData?.data[0]?.UnitNo,
    },
    {
      img: myTopData?.data[1]?.Picture,
      description: myTopData?.data[1]?.Address,
      name: myTopData?.data[1]?.Property,
      views: myTopData?.data[1]?.Views,
      unitNo: myTopData?.data[1]?.UnitNo,
    },
    {
      img: myTopData?.data[2]?.Picture,
      description: myTopData?.data[2]?.Address,
      name: myTopData?.data[2]?.Property,
      views: myTopData?.data[2]?.Views,
      unitNo: myTopData?.data[2]?.UnitNo,
    },
  ];

  return (
    <div className="display-flex">
      {views.map((data, id) => (
        <div key={id} style={{ display: "flex", flexBasis: "100%" }}>
          <div
            style={{
              background: "grey",
              maxWidth: "89px",
              maxHeight: "89px",
              display: "inline-block",
              marginRight: "1em",
              borderRadius: "4px",
            }}
          >
            <img
              src={data?.img ? data?.img : photoComingSoon}
              style={{ height: "89px", width: "89px", borderRadius: "4px" }}
              alt="property images"
            />
          </div>
          <div>
            <span>
              <span className="medium fw-500 fs-13">{data.description}</span>
              <br />

              <span className="light fw-300 fs-13">{data.name}</span>
              <br />

              <span className="light fw-300 fs-13">{data.unitNo}</span>
              <br />
              <div className="pop-unit-views medium fw-500 fs-14">
                {data.views} {t("views")}
              </div>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

function PopularUnits({ sid, datefilter }) {
  //i18Next
  const { t, i18n } = useTranslation();

  const CustomTableCell = styled(TableCell)`
    &.${tableCellClasses.head} {
      background: #ffffff;
      color: #000000;
      border-bottom: none;
      font-family: "Cerebri sans-medium";
      font-size: 13px;
      font-weight: 500;
    }
    &.${tableCellClasses.body} {
      border-bottom: none;
      font-family: "Cerebri sans-light";
      font-size: 13px;
      font-weight: 300;
    }
  `;

  const CustomTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #f9fafa;
    }
  `;

  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  
 console.log("myData",myData);


  useEffect(() => {
    GetTrafficByPopularUnits(sid, datefilter);
  }, [sid, datefilter]);

  const GetTrafficByPopularUnits = (sid, datefilter) => {
    try {
      GetTrafficPopularUnits(sid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  //Options for CSV data
  const options = {
    keys: [
      { field: "Address", title: "Address" },
      { field: "UnitNo", title: "Unit No" },
      { field: "Property", title: "Property" },
      { field: "Views", title: "Views" },
      { field: "Rooms", title: "Rooms" },
      { field: "SquareMeters", title: "Sq. Mtrs" },
      { field: "Listing", title: "Listing" },
    ],
  };

  const HeadCells = [
    {
      id: "Address",
      alignment: "left",
      label: "Address",
    },
    {
      id: "UnitNo",
      alignment: "right",
      label: "Unit no",
    },
    {
      id: "Property",
      alignment: "left",
      label: "Property",
    },
    {
      id: "Views",
      alignment: "right",
      label: "Views",
    },
    {
      id: "Rooms",
      alignment: "right",
      label: "Rooms",
    },
    {
      id: "SquareMeters",
      alignment: "right",
      label: "m2",
      sx: { fontFamily: "Cerebri sans-medium" },
    },

    {
      id: "Listing",
      alignment: "right",
      label: "Listing",
      sx: { padding: "16px 50px 16px 16px" },
    },
  ];

  // let refData = useRef(myData);
  const [flag, setFlag] = useState(false);

  const Ascending = (id) => {
    let myArr = [...myData];
    if (id === "Address") {
      myArr.sort((a, b) => a.Address.localeCompare(b.Address));
      setMyData(myArr);
    } else if (id === "UnitNo") {
      myArr.sort((a, b) => a.UnitNo - b.UnitNo);
      setMyData(myArr);
    } else if (id === "Property") {
      myArr.sort((a, b) => a.Property.localeCompare(b.Property));
      setMyData(myArr);
    } else if (id === "Views") {
      myArr.sort((a, b) => a.Views - b.Views);
      setMyData(myArr);
    } else if (id === "Rooms") {
      myArr.sort((a, b) => a.Rooms - b.Rooms);
      setMyData(myArr);
    } else if (id === "SquareMeters") {
      myArr.sort((a, b) => a.SquareMeters - b.SquareMeters);
      setMyData(myArr);
    } else if (id === "Listing") {
      myArr.sort((a, b) => a.Listing - b.Listing);
      setMyData(myArr);
    }
  };

  const Descending = (id) => {
    let myArr = [...myData];
    if (id === "Address") {
      myArr.sort((a, b) => b.Address.localeCompare(a.Address));
      setMyData(myArr);
    } else if (id === "UnitNo") {
      myArr.sort((a, b) => b.UnitNo - a.UnitNo);
      setMyData(myArr);
    } else if (id === "Property") {
      myArr.sort((a, b) => b.Property.localeCompare(a.Property));
      setMyData(myArr);
    } else if (id === "Views") {
      myArr.sort((a, b) => b.Views - a.Views);
      setMyData(myArr);
    } else if (id === "Rooms") {
      myArr.sort((a, b) => b.Rooms - a.Rooms);
      setMyData(myArr);
    } else if (id === "SquareMeters") {
      myArr.sort((a, b) => b.SquareMeters - a.SquareMeters);
      setMyData(myArr);
    } else if (id === "Listing") {
      myArr.sort((a, b) => b.Listing - a.Listing);
      setMyData(myArr);
    }
  };

  return (
    <>
      <div
        style={{
          borderRadius: "8px",
          height: "content-fit",
          backgroundColor: "#FFF",
          position: "relative",
        }}
        className="paper"
      >
        <div className="card-wrapper">
          <div className="card-header">
            <div className="margin-heading-card header-text">
              <div className="bold fw-700 fs-14">{t("popular-units")}</div>

              <Tooltip text={t("popular-units")} paragraph="" />
            </div>
            <div>
              <DownloadWidget
                options={options}
                data={myData}
                fileName={"traffic-popular-units"}
                type={false}
              />
            </div>
          </div>

          {myData?.length !== 0 ? (
            <>
              <div>
                <Typography
                  sx={{
                    margin: "20px",
                    fontWeight: "500",
                    fontSize: "13px",
                    fontFamily: "Cerebri sans-medium",
                  }}
                  variant="h6"
                  gutterBottom
                >
                  {t("top-3-units")}
                </Typography>
                <div className="popular-views">
                  <ShowPopularUnit sid={sid} datefilter={datefilter} />
                </div>
              </div>
              <div className="popularviews-card-body m-24px">
                <Table>
                  <TableHead className="sticky">
                    <TableRow>
                      {HeadCells.map((item) => (
                        <CustomTableCell
                          className="medium"
                          key={item.id}
                          align={item.alignment}
                          sx={item.sx}
                        >
                          {item.label === "m2" ? <>m&#178;</> : item.label}
                          {/* 
                      <img
                        className="cursor"
                        onClick={() => Ascending(item.id)}
                        height={16}
                        src={sortUp}
                      />
                      <img
                        className="cursor"
                        onClick={() => {
                          Descending(item.id);
                        }}
                        height={16}
                        src={sortDown}
                      /> */}
                          <Sorting
                            Ascending={() => Ascending(item.id)}
                            Descending={() => Descending(item.id)}
                          />
                        </CustomTableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {myData.slice(0, 10).map((row, id) => (
                      <CustomTableRow key={id}>
                        <CustomTableCell component="th" scope="row">
                          {row.Address}
                        </CustomTableCell>
                        <CustomTableCell align="right">
                          {row.UnitNo}
                        </CustomTableCell>
                        <CustomTableCell align="left">
                          {row.Property}
                        </CustomTableCell>
                        <CustomTableCell align="right">
                          {row.Views}
                        </CustomTableCell>
                        <CustomTableCell align="right">
                          {row.Rooms}
                        </CustomTableCell>
                        <CustomTableCell align="right">
                          {row.SquareMeters} m&sup2;
                        </CustomTableCell>
                        <CustomTableCell
                          align="right"
                          sx={{ padding: "16px 50px 16px 16px" }}
                        >
                          {new Intl.NumberFormat("en-DE").format(row.Listing)}{" "}
                          DKK
                        </CustomTableCell>
                      </CustomTableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </>
          ) : (
            <NoDataScreen />
          )}
        </div>
      </div>
    </>
  );
}

export default PopularUnits;
