import React from "react";

import { Grid } from "@mui/material";

import Analysis from "./AnalysisChart";

import Idleness from "./Idleness";
import LineChart from "../economy/ProgressStatusMap";
import AvailabilityRooms from "./AvailabilityRooms";
import AvailabilitySquare from "./AvailabilitySquare";
import UpcomingIdleness from "./UpcomingIdleness";
import StatusChange from "./StatusChange";

function Economy({ sid, datefilter, ActivitiesProperty }) {
  return (
    <>
      <div className="economy-container">
        <Analysis sid={sid} datefilter={datefilter} />
        <UpcomingIdleness sid={sid} datefilter={datefilter} />
        <StatusChange sid={sid} datefilter={datefilter} />
        <Grid container>
          <Grid item xs={12} lg={12}>
            <LineChart
              sid={sid}
              datefilter={datefilter}
              ActivitiesProperty={ActivitiesProperty}
            />
          </Grid>
        </Grid>

        <div>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={6}>
              <div className="availablity-by-rooms-container" style={{minHeight : '450px'}}>
                <AvailabilityRooms sid={sid} datefilter={datefilter} />
              </div>
            </Grid>

            <Grid item xs={12} lg={6}>
              <div className="availablity-by-square-container">
                <AvailabilitySquare sid={sid} datefilter={datefilter} />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Economy;
