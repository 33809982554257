import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";
import Tooltip from "../components/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { GetCivilStatus } from "../../../API/Traffic/TrafficCivilStatus";
import NoDataScreen from "../components/NoDataScreen";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
   background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: "Cerebri sans-medium";
    font-weight: 500;
    font-size  : 13px;
  }
  }
  &.${tableCellClasses.body} {
    font-size: 13px;
    border-bottom: none;
    font-family: "Cerebri sans-light";
    font-weight: 300;

  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: #f9fafa;
  }
`;

// Data
let id = 0;
function createData(name, calories, fat, carbs) {
  id += 1;
  return { id, name, calories, fat, carbs };
}

const rows = [
  createData("Married", 6, "10%"),
  createData("Single", 2, "28%"),
  createData("Divorced ", 8, "37%"),
  createData("Cohabiting ", 7, "17%"),
];
const styles = {
  rectangle: {
    width: "50px",
    height: "50px",
  },
};

function StatisticsCivilStatus({ sid, datefilter }) {
  //i18n
  const { t, i8n } = useTranslation();

  const [myCivilData, setMyCivilData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetEventsByGender(sid, datefilter);
  }, [sid, datefilter]);

  const GetEventsByGender = (sid, datefilter) => {
    try {
      GetCivilStatus(sid, datefilter)
        .then((data) => {
          if (data != null && data !== undefined) {
            setMyCivilData(data?.data);
          } else {
            setMyCivilData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyCivilData([null]);
        });
    } catch (e) {
      setMyCivilData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  // Options for CSV data
  const options = {
    unwindArrays: [
      { field: "Data.Type", title: "Type" },
      { field: "Data.Registrations", title: "Registrations" },
      { field: "Data.Percentage", title: "Percentage" },
    ],
    keys: [
      { field: "Data.Type", title: "Type" },
      { field: "Data.Registrations", title: "Registrations" },
      { field: "Data.Percentage", title: "Percentage" },
    ],
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div className="margin-heading-card sticky" style={{ display: "flex" }}>
          <div className="bold fs-14">
            {t("overall-statistics-on-participants-civil-status")}
          </div>

          <Tooltip
            text={t("overall-statistics-on-participants-civil-status")}
            paragraph=""
          />
        </div>
        <div>
          <DownloadWidget
            options={options}
            // data={myData.data.Data}
            fileName={"events-statistics-participant-civilstatus"}
            type={false}
          />
        </div>
      </div>
      <Card
        mb={6}
        sx={{
          height: "551px",
          padding: "0 24px 24px ",
        }}
      >
        {myCivilData?.Header?.Registrations != 0 ? (
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <CustomTableCell>{t("type")}</CustomTableCell>
                  <CustomTableCell align="right">
                    {t("registrations")}
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {t("percentage")}
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableHead sx={{ fontWeight: "500" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: "#f9f9f9",
                      border: "none",
                      fontWeight: "500",
                      fontFamily: "Cerebri sans-medium",
                      fontSize: "13px",
                    }}
                  >
                    {myCivilData?.Header?.Type}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      backgroundColor: "#f9f9f9",
                      border: "none",
                      fontWeight: "500",
                      fontFamily: "Cerebri sans-medium",
                      fontSize: "13px",
                    }}
                  >
                    {myCivilData?.Header?.Registrations}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      backgroundColor: "#f9f9f9",
                      fontWeight: "600",
                      fontSize: "13px",
                      border: "none",
                    }}
                  >
                    {myCivilData?.Header?.Percentage}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ fontWeight: "300", fontFamily: "Regular" }}>
                {myCivilData?.Data?.map((row) => (
                  <CustomTableRow key={row.id}>
                    <div style={{ marginLeft: "50px" }}>
                      <CustomTableCell align="right" component="th" scope="row">
                        {row.Type}
                      </CustomTableCell>
                    </div>
                    <CustomTableCell align="right">
                      {row.Registrations}
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      {row.Percentage}%
                    </CustomTableCell>
                  </CustomTableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        ) : (
          <NoDataScreen height={"551px"} />
        )}
      </Card>
    </>
  );
}

export default StatisticsCivilStatus;
