import React, { useRef, useState } from "react";
import Visitors from "./Visitors";
import "./traffic.css";

import { Grid } from "@mui/material";
import Tooltip from "../components/Tooltip/Tooltip";
import MixedChart2 from "./MixedChart2";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";

function TrafficPlatform({ sid, datefilter }) {
  //i18Next
  const { t, i18n } = useTranslation();

  const myref = useRef();
  const [refObj] = useState(myref);

  return (
    <>
      <div
        ref={myref}
        style={{
          borderRadius: "8px",
          height: "content-fit",
          backgroundColor: "#FFF",
          position: "relative",
        }}
      >
        <div className="traffic-platform-card-header">
          <div
            className="display-flex justify-content-between"
            // style={{ paddingTop: "15px" }}
          >
            <div className="margin-heading-card traffic-platform-card-title">
              <div>
                <div className="bold fw-700 fs-14">
                  {t("traffic-over-time-divided-by-platform")}
                </div>
              </div>
              <div>
                <Tooltip
                  text={t("traffic-over-time-divided-by-platform")}
                  paragraph=""
                />
              </div>
            </div>
            <div className="display-flex">
              <div>
                <Visitors />
              </div>
              <DownloadWidget
                refObj={refObj}
                fileName={"traffic-overtime"}
                type={true}
              />
            </div>
          </div>
          <div>
            <Grid item xs={12} md={6} sx={{ zIndex: 0 }}>
              <MixedChart2 sid={sid} datefilter={datefilter} />
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrafficPlatform;
