import React, { useEffect, useState } from "react";
import Tooltip from "../components/Tooltip/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddComments from "./AddComments";
import { GetUnitProgressGetComments } from "../../../API/OverView/GetComments";
import moment from "moment";
import {
  DeleteComment,
  DeleteComments,
} from "../../../API/OverView/DeleteComment";
import { useTranslation } from "react-i18next";
import DeleteAlert from "./DeleteCommentAlert";

const CommentsDataList = ({ rpId, bid, eId, pId, mfId, cId }) => {
  console.log("object", rpId);
  // API Consumption for Get Comments

  const [getComment, setGetComment] = useState([]);
  const [getCommentError, setGetCommentError] = useState([
    { error: false, status: "", message: "" },
  ]);

  const GetAllComments = () => {
    try {
      GetUnitProgressGetComments(rpId)
        .then((data) => {
          if (data != null && data != undefined) {
            setGetComment(data.data);
          } else {
            setGetComment([null]);
          }
        })
        .catch((error) => {
          setGetCommentError({ error: error.message, status: error.status });
          setGetComment([null]);
        });
    } catch (e) {
      setGetComment([null]);
      setGetCommentError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  useEffect(() => {
    GetAllComments();
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCancel, setOpenCancel] = React.useState(false);

  const handleCancelClickOpen = () => {
    setOpenCancel(true);
  };
  const handleEditClickOpen = () => {
    setOpenEdit(true);
  };

  const handleCancelClose = () => {
    setOpenCancel(false);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };

  //API Consumption Remover comments
  const [signerError, setSignerError] = useState([
    { error: false, status: "", message: "" },
  ]);

  const CustomTableCell = styled(TableCell)`
    &.${tableCellClasses.head} {
      background: #ffffff;
      color: #000000;
      border-bottom: none;
      font-family: Cerebri sans-medium;
      font-size: 13px;
      font-weight: 500;
    }
    &.${tableCellClasses.body} {
      border-bottom: none;
      font-family: Cerebri sans-light;
      font-size: 13px;
      font-weight: 300;
    }
  `;

  const CustomTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #f9fafa;
    }
  `;

  return (
    <>
      {getComment.map((row, i) => (
        <CustomTableRow>
          <CustomTableCell className="regular fw-300 fs-13">
            <div className="light fs-13">
              {moment(row.Created.time).format("ll")}
            </div>
          </CustomTableCell>
          <CustomTableCell>
            <div className="registered-data-value">
              <div className="email-event">
                <span className="light fs-13">{row.Category}</span>
              </div>
            </div>
          </CustomTableCell>

          <CustomTableCell>
            <div>
              <span className="medium fs-13">{row.Title}</span>
              <br />
              <span className="light fs-13">{row.Comment}</span>
            </div>
          </CustomTableCell>
          <CustomTableCell>
            <div className="participated-value">
              <div className="light fs-14">{row.CreatedBy}</div>
            </div>
          </CustomTableCell>
          <CustomTableCell>
            <MoreOverPopup
              SYSID={row.SYS_N_ID}
              GetAllComments={GetAllComments}
            />
          </CustomTableCell>
        </CustomTableRow>
      ))}
    </>
  );
};

const MoreOverPopup = ({ SYSID, AllComments }) => {
  const [openRemindPopup, setOpenRemindPopup] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleEditClickOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleClickRemind = () => {
    setOpenRemindPopup(true);
    handleClose();
  };
  const handleRemindPopup = () => {
    setOpenRemindPopup(false);
  };

  return (
    <>
      <div className="comments-MoreVertIcon-container">
        <div
          className="comments-vertical-ellipsis"
          style={{
            transform: `rotate(${anchorEl ? "90" : "0"}deg)`,
          }}
        >
          <IconButton
            // aria-label="more"
            onClick={handleClick}
            aria-haspopup="true"
            aria-controls="long-menu"
            sx={{
              ml: 1,
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
              },
            }}
          >
            <span className="comments-moreverticons-span">
              <MoreVertIcon style={{ fill: "#619130" }} />
            </span>
          </IconButton>
        </div>
        <div className="download-dropdown-signings">
          <div className="regular button-label">
            <Menu
              id="simple-menu"
              align="center"
              anchorEl={anchorEl}
              keepMounted
              onClose={handleClose}
              open={open}
              sx={{ marginTop: "10px" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <span>
                <MenuItem
                  onClick={handleEditClickOpen}
                  onClose={handleClose}
                  className="comments-hover-gray"
                >
                  <div
                    onClick={handleClickRemind}
                    onClose={handleClose}
                    className="light fs-14"
                  >
                    Delete
                  </div>
                </MenuItem>
              </span>
            </Menu>
          </div>
        </div>
        <Dialog
          open={openRemindPopup}
          onClose={handleRemindPopup}
          maxWidth="xl"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                height: "260px",
                borderRadius: "8px",
                maxWidth: "384px",
                overflowX: "hidden",
                overflowY: "hidden",
              },
            },
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DeleteAlert
            AllComments={AllComments}
            SYSID={SYSID}
            handleCloseAlert={handleRemindPopup}
          />
        </Dialog>
      </div>
    </>
  );
};

const Comments = ({ handleCommentClose, rpId, bid, eId, pId, mfId, cId }) => {
  const [getComment, setGetComment] = useState([]);
  const [getCommentError, setGetCommentError] = useState([
    { error: false, status: "", message: "" },
  ]);

  const GetAllComments = () => {
    try {
      GetUnitProgressGetComments(rpId)
        .then((data) => {
          if (data != null && data != undefined) {
            setGetComment(data.data);
          } else {
            setGetComment([null]);
          }
        })
        .catch((error) => {
          setGetCommentError({ error: error.message, status: error.status });
          setGetComment([null]);
        });
    } catch (e) {
      setGetComment([null]);
      setGetCommentError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  useEffect(() => {
    GetAllComments(rpId);
  }, [rpId]);

  const [openAddComment, setOpenAddComment] = React.useState(false);
  const handleAddCommentClick = () => {
    setOpenAddComment(true);
  };
  const handleAddCommentClose = () => {
    setOpenAddComment(false);
  };

  const CustomTableCell = styled(TableCell)`
    &.${tableCellClasses.head} {
      background: #ffffff;
      color: #000000;
      border-bottom: none;
      font-family: Cerebri sans-medium;
      font-size: 13px;
      font-weight: 500;
      white-space: nowrap;
    }
    &.${tableCellClasses.body} {
      border-bottom: none;
      font-family: Cerebri sans-light;
      font-size: 13px;
      font-weight: 300;
      white-space: nowrap;
    }
  `;

  const CustomTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #f9fafa;
    }
  `;
  return (
    <div
      style={{
        backgroundColor: "#fff",
        // height: "735px",
        borderRadius: "8px",
      }}
    >
      <div className="sticky">
        <div className="new-comments-subcontainer">
          <div className="new-events-header">
            <div>
              <div className="bold fs-14">Comments</div>
            </div>
            <div>
              <div>
                <Tooltip text="Comments" paragraph="" />
              </div>
            </div>
          </div>
          <div className="new-events-moreovericon" onClick={handleCommentClose}>
            <a>
              <span
                style={{
                  position: "relative",
                  top: "10px",
                  left: "10px",
                }}
              >
                <CloseIcon style={{ fill: "#000000", margin: "1px 1px" }} />
              </span>
            </a>
          </div>
        </div>
        <hr style={{ border: "1px solid #F4F5F5" }} />
      </div>
      <div className="add-comment-button-section">
        <div style={{ display: "flex", padding: "24px", alignItems: "center" }}>
          <div>
            <div style={{ display: "flex" }}>
              <div
                className="cursor regular fw-300 fs-13"
                onClick={handleAddCommentClick}
                style={{
                  width: "172px",
                  height: "44px",
                  backgroundColor: "#619130",
                  color: "#FFFF",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>
                  &nbsp;
                  <AddIcon />
                </span>
                <span className="medium fs-13">&nbsp;ADD COMMENT</span>
              </div>
              <Dialog
                BackdropProps={{
                  style: { backgroundColor: "rgba(0, 0, 0, 0.08)" },
                }}
                open={openAddComment}
                maxWidth="lg"
                fullWidth
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      overflowX: "hidden",
                      overflowY: "hidden",
                    },
                  },
                }}
                onClose={handleAddCommentClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <AddComments
                  handleAddCommentClose={handleAddCommentClose}
                  AllComments={GetAllComments}
                  rpId={rpId}
                  bid={bid}
                  eId={eId}
                  pId={pId}
                  mfId={mfId}
                  cId={cId}
                />
              </Dialog>
            </div>
          </div>
        </div>
      </div>
      <div
        className="scroller"
        style={{
          padding: "0 26px 0 28px",
          height: "300px",
          // paddingBottom: "20px",
          // marginBottom: "40px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell
                className="medium fs-13"
                width="15%"
                sx={{ padding: "25px 0 7px 47px" }}
              >
                Date
              </CustomTableCell>
              <CustomTableCell
                className="medium fs-13"
                width="15%"
                sx={{ padding: "23px 0 7px 75px" }}
              >
                Category
              </CustomTableCell>
              <CustomTableCell
                className="medium fs-13"
                width="30%"
                sx={{ padding: "17px 0 2px 107px" }}
              >
                Comment
              </CustomTableCell>
              <CustomTableCell
                className="medium fs-13"
                width="30%"
                sx={{ padding: "23px 132px 7px 94px" }}
              >
                Created by
              </CustomTableCell>
              <CustomTableCell
                width="10%"
                sx={{ padding: "15px 43px 16px 53px" }}
              ></CustomTableCell>
            </TableRow>
          </TableHead>
          {/* <div
              className="scroller"
              style={{
                height: "300px",
                paddingBottom: "20px",
                marginBottom: "40px",
              }}
            > */}
          <TableBody>
            {getComment.map((row, i) => (
              <CustomTableRow key={i}>
                <CustomTableCell
                  className="regular fw-300 fs-13"
                  width="15%"
                  sx={{ padding: "25px 0 26px 47px" }}
                >
                  <div className="light fs-13">
                    {moment(row.Created.time).format("ll")}
                  </div>
                </CustomTableCell>
                <CustomTableCell
                  width="15%"
                  sx={{ padding: "23px 0 24px 75px" }}
                >
                  <div className="registered-data-value">
                    <div className="email-event">
                      <span className="light fs-13">{row.Category}</span>
                    </div>
                  </div>
                </CustomTableCell>

                <CustomTableCell
                  width="30%"
                  sx={{ padding: "17px 0 18px 107px" }}
                >
                  <div>
                    <span className="medium fs-13">{row.Title}</span>
                    <br />
                    <span className="light fs-13">{row.Comment}</span>
                  </div>
                </CustomTableCell>
                <CustomTableCell
                  width="30%"
                  sx={{ padding: "23px 132px  22px 94px" }}
                >
                  <div className="participated-value">
                    <div className="light fs-14">{row.CreatedBy}</div>
                  </div>
                </CustomTableCell>
                <CustomTableCell
                  width="10%"
                  sx={{ padding: "15px 43px  16px 53px" }}
                >
                  <MoreOverPopup
                    SYSID={row.SYS_N_ID}
                    AllComments={GetAllComments}
                  />
                </CustomTableCell>
              </CustomTableRow>
            ))}
          </TableBody>
          {/* </div> */}
        </Table>
      </div>
    </div>
  );
};

export default Comments;
