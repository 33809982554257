import React from "react";
import { DeleteComment } from "../../../API/OverView/DeleteComment";
import { GetUnitProgressGetComments } from "../../../API/OverView/GetComments";

const DeleteAlert = ({ SYSID, handleCloseAlert, AllComments }) => {
  const OverViewUnitProgressDeleteComments = () => {
    try {
      DeleteComment(SYSID)
        .then((response) => {})
        .catch((error) => {
          //   setError({ error: error.message, status: error.status });
        });
    } catch (error) {
      //   setError({ error: true, status: "", message: "Failed to retrieve data" });
    }
  };

  const handleDeleteAlert = () => {
    handleCloseAlert();
    OverViewUnitProgressDeleteComments(SYSID);

    setTimeout(() => {
      AllComments();
    }, 1000);
  };

  return (
    <>
      <div>
        <div className="medium reminder-msg">
          Are you sure that you
          <br />
          want to delete this comment?
        </div>
        <div style={{ marginTop: "125px" }}>
          <div className="regular fw-500 fs-14 reminder-btn">
            <div className="btn-btn" onClick={handleCloseAlert}>
              <div className="btn-text">No</div>
            </div>
            <div className="btn-btn-green" onClick={() => handleDeleteAlert()}>
              <div className="btn-text">Yes</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteAlert;
