import React from "react";
import noDataImg from "../../dashboard/Icons/no-data.png";

export default function NoDataScreen({ height, imgHeight }) {
  return (
    <div
     style={{ height: height, display: "flex", justifyContent:'center', alignItems:'center'  }}
     >
      <div>

      <img src={noDataImg}  height={imgHeight}></img>
      <p style={{paddingLeft : '40px', color : '#619130'}}>No data available :(</p>
    </div>
      </div>
  );
}

NoDataScreen.defaultProps = {
  height: "373px",
  imgHeight: "150px",
};
