import { useEffect, useState } from "react";
import "./overviews.css";

import { GetOverviewNotesActivity } from "../../../API/OverviewNotesActivity/OverviewNotesActivity";
import ActivityDropdown from "../components/Dropdowns/AcitivityDropdown/ActivityDropdown";
import { GetOverviewNotesCategoryByEID } from "../../../API/OverviewNotesCategoryByEID/OverviewNotesCategoryByEID";
import Tooltip from "../components/Tooltip/Tooltip";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";
import { ArrowUpDown } from "../components/ArrowUpDown/ArrowUpDown";
import moment from "moment/moment";
import NoDataScreen from "../components/NoDataScreen";
import { Button, Menu, MenuItem } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";

const LabelNotes = ({ category, username, created, title, note }) => {
  let formattedTime = moment(created).format("ll | HH:mm");

  //i18Next
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div className="card-body">
        <div style={{ width: "150px" }}>
          <span>
            <span className="medium fw-500 fs-13">
              {category}
            </span>
            <br />

            <span className="light fw-300 fs-13">
              {username}
            </span>
          </span>
          <br />
          <span className="light fs-10 fw-300">{formattedTime}</span>
        </div>
        <div>
          <div className="whitespace medium fw-500 fs-13 line-height-20">
            {title}
          </div>
          <div>
            <div className="light fw-300 fs-13 line-height-20">
              {note.slice(0, 35)} &nbsp; (
              <span
                onClick={handleClick}
                className="light fw-300 fs-12 show-more-link"
              >
                {t("show-more")}
              </span>
              )
            </div>
          </div>
          <div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={anchorEl}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.02)",
                "& .MuiPaper-root": {
                  borderRadius: "8px",
                  width: "fit-content",
                  marginTop: "8px",
                },
              }}
            >
              <MenuItem
                sx={{
                  "&:hover": { backgroundColor: "transparent" },
                  borderRadius: "8px",
                  padding: "0px",
                  overflow: "hidden",
                  border: "1px solid #F4F5F5",
                }}
              >
                <div className="showmore-card">
                  <span className="medium fw-600 fs-12 line-height-20 wrap">
                    {title?.substr(0, 36)}
                  </span>
                  <span className="light fw-300 fs-12 line-height-20 wrap">
                    {note}
                  </span>
                </div>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
};

function NotesActivity({ sid, datefilter }) {
  //i18Next
  const { t, i18n } = useTranslation();

  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  const GetOverviewByNotesActivity = (eid, selectedCategory, datefilter) => {
    try {
      GetOverviewNotesActivity(sid, selectedCategory, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  // API Consumption for Category By EID

  const [myDatas, setMyDatas] = useState([]);
  const [errors, setErrors] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetOverviewByNotesCategoryByEID();
  }, []);

  const GetOverviewByNotesCategoryByEID = () => {
    try {
      GetOverviewNotesCategoryByEID()
        .then((data) => {
          if (data != null && data != undefined) {
            const categoryList = data.data.map(({ id, name }) => {
              return { id: id.toString(), name: name };
            });
            setMyDatas(categoryList);
          } else {
            setMyDatas([null]);
          }
        })
        .catch((error) => {
          setErrors({ error: error.message, status: error.status });
          setMyDatas([null]);
        });
    } catch (e) {
      setMyDatas([null]);
      setErrors({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const Activities = myDatas;

  const [allChecked, setAllChecked] = useState(false);
  const [optionChecked, setOptionChecked] = useState([]);

  const OnClicked = (e, isCheckAll) => {
    const { id, checked } = e.currentTarget;

    if (isCheckAll) {
      if (checked) {
        setOptionChecked(Activities.map((list) => list.id));
        setAllChecked(true);
      } else {
        setOptionChecked([]);
        setAllChecked(false);
      }
      // GetOverviewByNotesActivity(0);
    } else {
      if (checked) {
        const checkValue = [...optionChecked, id];
        setOptionChecked(checkValue);

        if (checkValue.length == Activities.length) {
          setAllChecked(true);
          // GetOverviewByNotesActivity(sid);
        } else {
          // GetOverviewByNotesActivity(sid, checkValue.toString());
        }
      } else {
        const checkValue = optionChecked.filter((item) => item !== id);
        if (checkValue.length != 0) {
          setOptionChecked(checkValue);
          // GetOverviewByNotesActivity(sid, checkValue.toString());
          setAllChecked(false);
        } else {
          setOptionChecked([]);
          setAllChecked(false);
          // GetOverviewByNotesActivity(0);
        }
      }
    }
  };

  let activityFilter =
    optionChecked === null ||
    optionChecked === undefined ||
    optionChecked.length === 0
      ? 0
      : optionChecked;
  useEffect(() => {
    GetOverviewByNotesActivity(sid, activityFilter, datefilter);
  }, [sid, activityFilter, datefilter]);

  //Options for CSV data
  const options = {
    keys: [
      { field: "Username", title: "Name" },
      { field: "Title", title: "Title" },
      { field: "Note" },
      { field: "Category" },
      { field: "Navn", title: "Property Name" },
      { field: "Address" },
      { field: "Created", title: "Created At" },
    ],
  };

  const [anchorEl, setAnchorEl] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [count, setCount] = useState(10);
  const [listState, setListState] = useState([]);

  useEffect(() => {
    setListState(myData.slice(0, count));
  }, [myData]);

  const fetchMoreData = async () => {
    setTimeout(async () => {
      let newArr = listState.concat(myData.slice(count, count + 10));
      setListState(newArr);
      setCount(count + 10);
    }, 100);
  };

  return (
    <>
      <div>
        <div className="scrollable-container">
          <div className="sticky notesactivity-card-header">
            <div className="display-flex justify-content-between">
              <div className="notesactivity-card-title">
                <div className="heading bold fs-14 fw-700">
                  {t("notes-activity")}
                </div>
                <Tooltip text={t("notes-activity")} paragraph="" />
              </div>
              <div className="allactivities">
                <div>
                  <Button
                    id="basic-button"
                    aria-controls={anchorEl ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchorEl ? "true" : undefined}
                    onClick={handleClick}
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                      padding: "0px",
                      color: "black",
                    }}
                  >
                    <div className="cursor notesactivity-allactivities-dropdown">
                      <span className="light fw-300 fs-14 line-height-22">
                        {t("all-categories")}
                      </span>

                      <ArrowUpDown component={anchorEl} />
                    </div>
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    sx={{
                      marginTop: "8px",
                      backgroundColor: "rgba(0, 0, 0, 0.02)",
                      "& .MuiPaper-root": {
                        borderRadius: "8px",
                        width: "fit-content",
                        // left: "320px !important",
                        transformOrigin: "right top !important",
                      },
                    }}
                  >
                    <MenuItem
                      sx={{
                        "&:hover": { backgroundColor: "transparent" },
                        borderRadius: "8px",
                        padding: "0px",
                        overflow: "hidden",
                        border: "1px solid #F4F5F5",
                        left: "320px",
                      }}
                    >
                      <div className="notes-activity-dropdown-container scrollable-container">
                        <ActivityDropdown
                          parentId="0"
                          allChecked={allChecked}
                          optionChecked={optionChecked}
                          OnClicked={OnClicked}
                          Activities={Activities}
                          Label={t("all-categories")}
                        />
                      </div>
                    </MenuItem>
                  </Menu>
                </div>
                <DownloadWidget
                  options={options}
                  data={myData}
                  fileName={"overview-notes-activity"}
                />
              </div>
            </div>
          </div>
          
          {myData.length != 0 ? (
            <div
              id="scrollableNotesDiv"
              className="scrollable-container"
              style={{ height: "373px" }}
            >
              <InfiniteScroll
                dataLength={listState.length}
                next={fetchMoreData}
                hasMore={true}
                scrollableTarget="scrollableNotesDiv"
              >
                {listState?.map((item, i) => {
                  return (
                    <LabelNotes
                      key={i}
                      created={item.Created}
                      title={item.Title}
                      username={item.Username}
                      category={item.Category}
                      note={item.Note}
                    />
                  );
                })}
              </InfiniteScroll>
            </div>
          ) : (
            <NoDataScreen />
          )}
        </div>
      </div>
    </>
  );
}

export default NotesActivity;
