import React, { useRef } from "react";
import { useEffect, useState } from "react";

import "./economy.css";
import styled from "@emotion/styled";
import { Pie } from "react-chartjs-2";
import NorthIcon from "@mui/icons-material/North";

import {
  Box,
  Grid,
  CardContent,
  Card as MuiCard,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";

import { GetEconomyAnalysis } from "../../../API/EconomyAnalysis/EconomyAnalysis";
import Tooltip from "../components/Tooltip/Tooltip";
import AnalysisTable from "./AnalysisTable";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";
import Idleness from "./Idleness";
import Popup from "reactjs-popup";
import { GetEconomyIdlenessRate } from "../../../API/EconomyIdleness/EconomyIdleness";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 217px;
`;

const StyledToggleButton = styled(ToggleButton)(({ selectedColor }) => ({
  textTransform: "capitalize",
  backgroundColor: "#fff",
  "&.Mui-selected": {
    color: "rgb(97, 145, 48)",
    backgroundColor: "rgb(97, 145, 48, 0.05)",
  },
  "&:hover": {
    color: "rgb(97, 145, 48)",
    backgroundColor: "rgb(97, 145, 48, 0.05)",
  },
}));

function AnalysisChart({ sid, datefilter }) {

  console.log(sid, 'sid')
  const [economyIdleRate, setEconomyIdleRate] = useState([])
  const [economyIdleRateType, setIdlenessRateType] = useState(0);

  useEffect(() => {
    GetEconomyByIdlenessRate(sid, economyIdleRateType);
  }, [economyIdleRateType, sid]);

  // Economy idleness rate API call
  const GetEconomyByIdlenessRate = (sid, type) => {
    try {
      GetEconomyIdlenessRate(sid, type)
        .then((data) => {
          if (data != null && data != undefined) {
            setEconomyIdleRate(data.data);
          } else {
            setEconomyIdleRate([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setEconomyIdleRate([null]);
        });
    } catch (e) {
      setEconomyIdleRate([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  // Show History Popup

  const HistoryPopup = () => {
    return (
      <>
        <div className="history-container">
          <div className="light fs-12" style={{ color: "#888" }}>
            2022
          </div>
          <div style={{ lineHeight: "2" }}>
            <div className="display-flex justify-content-between">
              <div className="medium fs-13">August</div>
              <div className="display-flex" style={{ gap: "20px" }}>
                <div className="light fs-13"> 15%</div>
                <div className="light fs-13">(8 units)</div>
              </div>
            </div>
            <div className="display-flex justify-content-between">
              <div className="medium fs-13">July</div>
              <div className="display-flex" style={{ gap: "20px" }}>
                <div className="light fs-13"> 12%</div>
                <div className="light fs-13">(6 units)</div>
              </div>
            </div>
            <div className="display-flex justify-content-between">
              <div className="medium fs-13">June</div>
              <div className="display-flex" style={{ gap: "20px" }}>
                <div className="light fs-13"> 10%</div>
                <div className="light fs-13">(4 units)</div>
              </div>
            </div>
            <div className="display-flex justify-content-between">
              <div className="medium fs-13">May</div>
              <div className="display-flex" style={{ gap: "20px" }}>
                <div className="light fs-13"> 12%</div>
                <div className="light fs-13">(6 units)</div>
              </div>
            </div>
            <div className="display-flex justify-content-between">
              <div className="medium fs-13">April</div>
              <div className="display-flex" style={{ gap: "20px" }}>
                <div className="light fs-13">8%</div>
                <div className="light fs-13">(2 units)</div>
              </div>
            </div>
            <div className="display-flex justify-content-between">
              <div className="medium fs-13">March</div>
              <div className="display-flex" style={{ gap: "20px" }}>
                <div className="light fs-13"> 15%</div>
                <div className="light fs-13">(8 units)</div>
              </div>
            </div>
            <div className="display-flex justify-content-between">
              <div className="medium fs-13">February</div>
              <div className="display-flex" style={{ gap: "20px" }}>
                <div className="light fs-13"> 15%</div>
                <div className="light fs-13">(8 units)</div>
              </div>
            </div>
            <div className="display-flex justify-content-between">
              <div className="medium fs-13">January</div>
              <div className="display-flex" style={{ gap: "20px" }}>
                <div className="light fs-13"> 15%</div>
                <div className="light fs-13">(8 units)</div>
              </div>
            </div>
          </div>
          <div style={{ padding: "10px 0" }}>
            <div className="light fs-12" style={{ color: "#888" }}>
              2021
            </div>
            <div style={{ lineHeight: "2" }}>
              <div className="display-flex justify-content-between">
                <div className="medium fs-13">December</div>
                <div className="display-flex" style={{ gap: "20px" }}>
                  <div className="light fs-13"> 15%</div>
                  <div className="light fs-13">(8 units)</div>
                </div>
              </div>
              <div className="display-flex justify-content-between">
                <div className="medium fs-13">November</div>
                <div className="display-flex" style={{ gap: "20px" }}>
                  <div className="light fs-13"> 15%</div>
                  <div className="light fs-13">(8 units)</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  //i18Next
  const { t, i18n } = useTranslation();

  // daily and monthly toogle button group

  const [idlenessRate, setIdlenessRate] = React.useState("monthly");

  // menu items

  const [user, setUser] = useState(null);

  //  const open = Boolean(anchorEl);

  const handleClickUser = (event) => {
    setUser(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setUser(null);
  };

  // API Consumption

  const [myData, setMyData] = useState({ data: [] });
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetEconomyByAnalysis(sid, datefilter);
  }, [sid, datefilter]);

  const GetEconomyByAnalysis = (sid, datefilter) => {
    try {
      GetEconomyAnalysis(sid, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const data = {
    labels: [
      myData.data[0]?.Status,
      myData.data[1]?.Status,
      myData.data[2]?.Status,
      myData.data[3]?.Status,
    ],
    datasets: [
      {
        data: [
          myData.data[0]?.Quantity,
          myData.data[1]?.Quantity,
          myData.data[2]?.Quantity,
          myData.data[3]?.Quantity,
        ],
        backgroundColor: ["#77C7A1", "#F6E651", "#888888", "#A2C8D0"],
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        yAlign: "top",
        backgroundColor: "#FFFFFF",
        borderWidth: 2,
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
      },
      legend: {
        display: false,
      },
    },
  };

  const plugins = [
    {
      afterDraw: function (chart) {
        if (chart.data.datasets[0].data.every((item) => item === 0)) {
          let ctx = chart.ctx;
          let width = chart.width;
          let height = chart.height;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "25px Cerebri sans";
          ctx.fillText("No data available", width / 2, height / 2);
          ctx.restore();
        }
      },
    },
  ];

  const myref = useRef();
  const [refObj] = useState(myref);

  return (
    <>
      <div
        ref={myref}
        style={{
          borderRadius: "8px",
          height: "content-fit",
          backgroundColor: "#FFF",
          position: "relative",
        }}
        className="analysis-card-header"
      >
        <div className="display-flex justify-content-between">
          <div className="margin-heading-card analysis-card-title">
            <div className="heading bold fs-14 fw-700">{t("analysis")}</div>

            <Tooltip text={t("analysis")} paragraph="" />
          </div>
          <div>
            <DownloadWidget
              refObj={refObj}
              fileName={"economy-analysis-chart"}
              type={true}
            />
          </div>
        </div>
        <Grid
          container
          spacing={6}
          sx={{
            // display: "flex",
            // alignItems: "center",
            // gap: "25px",
            padding: "0 25px",
          }}
        >
          <Grid item xs={12} md={6}>
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                borderRadius: "8px",
                alignItems: "center",
                border: "1px solid #f4f5f5",
                backgroundColor: "#F9FAFA",
              }}
            >
              <Spacer mb={6} />

              <ChartWrapper>
                <Pie data={data} options={options} plugins={plugins} />
              </ChartWrapper>
              <Box>
                <CardContent>
                  <div className="light fw-300 fs-13 margin-bottom-4px">
                    {t("total-units")}
                  </div>
                  <Typography variant="h6">
                    <Box className="medium fw-500 fs-16 ">
                      {myData.data[4]?.Quantity}
                    </Box>
                  </Typography>
                </CardContent>

                <CardContent>
                  <div className="light fw-300 fs-13 margin-bottom-4px">
                    {t("Total yearly rent")}
                  </div>
                  <Typography variant="h6">
                    <Box className="medium fw-500 fs-16 ">
                      {new Intl.NumberFormat("en-DE").format(
                        myData.data[4]?.TotalAmount
                      )}{" "}
                      DKK
                    </Box>
                  </Typography>
                </CardContent>
              </Box>
            </CardContent>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                justifyContent: "center",
                borderRadius: "8px",
                alignItems: "center",
                border: "1px solid #f4f5f5",
                backgroundColor: "#F9FAFA",
                padding: "40px 40px 20px 40px",
                height: "100%",
              }}
            >
              <Box className="fs-14 medium" sx={{ margin: "0 127px" }}>
                
                {t("idleness-rate")}
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="20px"
              >
                <Box className="bold" sx={{ fontSize: "75px" }}>
                  { economyIdleRate[0] ? Math.round(economyIdleRate[0]?.IdlePercentage) : 0} %
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    fontSize: "17px",
                    backgroundColor: "#77C7A133",
                    padding: "3px 14px",
                    borderRadius: "4px",
                  }}
                  className="registered-arrow  medium fw-500"
                >
                  <NorthIcon fontSize="0.23rem" />3
                </Box>
              </Box>
              <Box
                onClick={handleClickUser}
                className="fw-300 light fs-13 cursor"
                style={{ color: "#619130", margin: "0 122px" }}
              >
                {/* Show history */}
                {t("show-history")}
              </Box>
              <Menu
                id="basic-menu"
                anchorEl={user}
                open={user}
                onClose={() => setUser(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.02)",
                  "& .MuiPaper-root": {
                    borderRadius: "8px",
                    width: "fit-content",
                    marginTop: "8px",
                  },
                }}
              >
                <MenuItem
                  sx={{
                    "&:hover": { backgroundColor: "transparent" },
                    borderRadius: "8px",
                    padding: "0px",
                    overflow: "hidden",
                    border: "1px solid #F4F5F5",
                  }}
                >
                  {/* <div className="showmore-card"> */}
                  <div className="untilidleness-container">
                    <HistoryPopup />
                  </div>
                  {/* </div> */}
                </MenuItem>
              </Menu>
              {/* <Box
                display="flex"
                justifyContent="end"
                sx={{ marginTop: "30px" }}
              >
                <ToggleButtonGroup
                  value={idlenessRate}
                  size="small"
                  exclusive
                  onChange={(e) => setIdlenessRate(e.target.value)}
                >
                  <StyledToggleButton
                    className="light fs-14"
                    itemID="1"
                    sx={{
                      borderRadius: "8px 0 0 8px",
                      width: "84px",
                      fontFamily: "Cerebri Sans",
                      fontSize: "12px",
                      color: "#000",
                    }}
                    value="daily"
                    onClick={()=> setIdlenessRateType(0)}
                  >
                    {t("daily")}
                  </StyledToggleButton>
                  <StyledToggleButton
                    className="light fs-14"
                    itemID="2"
                    sx={{
                      borderRadius: "0px 8px 8px 0px",
                      width: "84px",
                      fontFamily: "Cerebri Sans",
                      fontSize: "12px",
                      color: "#000",
                    }}
                    value="monthly"
                    onClick={()=> setIdlenessRateType(1)}
                  >
                    { console.log(economyIdleRateType, 'economyIdleRateType') }
                    {t("monthly")}
                  </StyledToggleButton>
                </ToggleButtonGroup>
              </Box> */}
            </Box>
          </Grid>
            <Idleness sid={sid} datefilter={datefilter} />
        </Grid>
      </div>
    </>
  );
}

export default AnalysisChart;
