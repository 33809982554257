import React, { useRef, useState, useEffect } from "react";
import "./overviews.css";

import Tooltip from "../components/Tooltip/Tooltip";

import { GetOverviewActionActivity } from "../../../API/OverviewActionActivity/OverviewActionActivity";
import usePopup from "../hooks/usePopup";
import ActivityDropdown from "../components/Dropdowns/AcitivityDropdown/ActivityDropdown";
import DownloadWidget from "../components/DownloadWidget/DownloadWidget";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { ArrowUpDown } from "../components/ArrowUpDown/ArrowUpDown";
import NoDataScreen from "../components/NoDataScreen";
import { Button, Menu, MenuItem } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";

const LabelActivity = ({
  name,
  actionName,
  overDeadline,
  actionDeadline,
  afterDeadline,
  color,
}) => {
  return (
    <>
      <div className="regular card-content">
        <div>
          <span>
            <span className="medium fw-500 fs-13">{name}</span>
          </span>
          <div
            className="medium fw-500 fs-10 action-label"
            style={{
              background: color,
            }}
          >
            {actionName.toUpperCase()}
          </div>

          {overDeadline > 0 && (
            <span className="light fs-10 fw-300 overdue-day-color-text">
              {" "}
              Deadline overdue by {overDeadline} days
            </span>
          )}
        </div>
        <div className="deadline">
          <span className="medium fw-500 fs-12">{afterDeadline}</span>
          <div className="light fs-12 fw-300">
            {actionDeadline == "0000-00-00"
              ? ""
              : moment(actionDeadline).format("ll")}
          </div>
        </div>
      </div>
    </>
  );
};

function ActionActivity({ sid, datefilter }) {
  //i18Next
  const { t, i18n } = useTranslation();
  let dateto = moment().format("YYYY-MM-DD");
  let adddate = moment("2022-04-08");
  var a = moment(adddate);
  var b = moment(dateto);
  var diff = a.diff(b, "days");
  //  console.log( a.diff(b, 'days'));

  const Activities = [
    { name: `${t("before-deadline")}`, id: "1" },
    { name: `${t("after-deadline")}`, id: "2" },
    { name: `${t("ok-and-before-or-on-deadline")}`, id: "3" },
    { name: `${t("ok-but-after-deadline")}`, id: "4" },
    { name: `${t("declined")}`, id: "5" },
  ];

  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  const GetOverviewByActionActivity = (eid, activity, datefilter) => {
    try {
      GetOverviewActionActivity(eid, activity, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };
  const [allChecked, setAllChecked] = useState(false);
  const [optionChecked, setOptionChecked] = useState([]);

  const OnClicked = (e, isCheckAll) => {
    const { id, checked } = e.currentTarget;
    if (isCheckAll) {
      if (checked) {
        setOptionChecked(Activities.map((list) => list.id));
        setAllChecked(true);
      } else {
        setOptionChecked([]);
        setAllChecked(false);
      }
      // GetOverviewByActionActivity(0);
    } else {
      if (checked) {
        const checkValue = [...optionChecked, id];
        setOptionChecked(checkValue);

        if (checkValue.length == Activities.length) {
          setAllChecked(true);
          // GetOverviewByActionActivity(0);
        } else {
          // GetOverviewByActionActivity(sid, checkValue.toString());
        }
      } else {
        const checkValue = optionChecked.filter((item) => item !== id);
        if (checkValue.length != 0) {
          setOptionChecked(checkValue);
          // GetOverviewByActionActivity(sid, checkValue.toString());
          setAllChecked(false);
        } else {
          setOptionChecked([]);
          setAllChecked(false);
          // GetOverviewByActionActivity(0);
        }
      }
    }
  };

  let activityFilter =
    optionChecked === null ||
    optionChecked === undefined ||
    optionChecked.length === 0
      ? 0
      : optionChecked;
  useEffect(() => {
    GetOverviewByActionActivity(sid, activityFilter, datefilter);
  }, [sid, activityFilter, datefilter]);

  //Options for CSV data
  const options = {
    keys: [
      { field: "AssetName", title: "Asset Name" },
      { field: "PropertyName", title: "Property Name" },
      { field: "ActionStatus", title: "Action Status" },
      { field: "ActionName", title: "Action Name" },
      { field: "ActionDeadline", title: "Action Deadline" },
      { field: "OverDeadline", title: "Over Deadline" },
    ],
  };

  const [anchorEl, setAnchorEl] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [count, setCount] = useState(10);
  const [listState, setListState] = useState([]);

  useEffect(() => {
    setListState(myData.slice(0, count));
  }, [myData]);

  const fetchMoreData = async () => {
    setTimeout(async () => {
      let newArr = listState.concat(myData.slice(count, count + 10));
      setListState(newArr);
      setCount(count + 10);
    }, 100);
  };
  return (
    <>
      <div className=" actionactivity-card-header">
        <div
          className="display-flex justify-content-between"
          style={{ paddingBottom: "15px" }}
        >
          <div className="actionactivity-card-title">
            <div className="heading bold fs-14 fw-700">
              {t("action-activity")}
            </div>
            <Tooltip text={t("action-activity")} paragraph="" />
          </div>
          <div>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <Button
                id="basic-button"
                aria-controls={anchorEl ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  "&:hover": { backgroundColor: "transparent" },
                  padding: "0px",
                  color: "black",
                }}
              >
                <div className="cursor actionactivity-allactivities-dropdown">
                  <span className="light fw-300 fs-14 line-height-22">
                    {t("all-deadlines")}
                  </span>

                  <ArrowUpDown component={anchorEl} />
                </div>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={anchorEl}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.02)",
                  "& .MuiPaper-root": {
                    borderRadius: "8px",
                    width: "fit-content",
                    marginTop: "8px",
                    // left: "980px !important",
                    transformOrigin: "right top !important",
                  },
                }}
              >
                <MenuItem
                  sx={{
                    "&:hover": { backgroundColor: "transparent" },
                    borderRadius: "8px",
                    padding: "0px",
                    overflow: "hidden",
                    border: "1px solid #F4F5F5",
                  }}
                >
                  <div className="dropdown-container ">
                    <ActivityDropdown
                      parentId="0"
                      allChecked={allChecked}
                      optionChecked={optionChecked}
                      OnClicked={OnClicked}
                      Activities={Activities}
                      Label={t("all-deadlines")}
                    />
                  </div>
                </MenuItem>
              </Menu>

              <DownloadWidget
                options={options}
                data={myData}
                fileName={"overview-action-activity"}
              />
            </div>
          </div>
        </div>
      </div>
      {myData.length != 0 ? (
        <div
          id="scrollableActionDiv"
          className="scrollable-container"
          style={{ height: "373px" }}
        >
          <InfiniteScroll
            dataLength={listState.length}
            next={fetchMoreData}
            hasMore={true}
            scrollableTarget="scrollableActionDiv"
          ></InfiniteScroll>
          {listState?.map((item, i) => {
            return (
              <LabelActivity
                key={i}
                name={item.PropertyName}
                actionName={item.ActionName}
                overDeadline={item.OverDeadline}
                actionDeadline={item.ActionDeadline}
                afterDeadline={item.AssetName}
                color={
                  item.ActionStatus == 0
                    ? "#888888"
                    : item.ActionStatus >= 1 && item.ActionStatus <= 20
                    ? "#77C7A1"
                    : item.ActionStatus >= 21
                    ? item.OverDeadline > 0
                      ? "#F1664B"
                      : item.OverDeadline < 0 && item.OverDeadline >= -3
                      ? "#F6E651"
                      : item.ActionStatus <= 40
                      ? "#A2C8D0"
                      : item.ActionStatus <= 80
                      ? "#F6E651"
                      : item.ActionStatus >= 81
                      ? "#F1664B"
                      : "#888888"
                    : "#888888"

                  // item.OverDeadline> 0 &&  item.ActionStatus>=21 ?"#F1664B":
                  // item.OverDeadline >= -3 &&  item.OverDeadline <0 && item.ActionStatus >= 21  ?"#F6E651":
                  // item.ActionStatus >= 81 ?"#F1664B":
                  // item.ActionStatus >= 41 &&  item.ActionStatus<= 80 ?"#F6E651":
                  // item.ActionStatus >= 21 &&  item.ActionStatus<= 40 ?"#A2C8D0":
                  // item.ActionStatus >= 1 &&  item.ActionStatus<= 20 ?"#77C7A1":
                  // item.ActionStatus==0 ?"#888888":
                  //  null
                }
              />
            );
          })}
        </div>
      ) : (
        <NoDataScreen />
      )}
    </>
  );
}

export default ActionActivity;
