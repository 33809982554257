import React, { useEffect, useState } from "react";
import "./economy.css";
import Tooltip from "../components/Tooltip/Tooltip";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { tableCellClasses } from "@mui/material/TableCell";

import CloseIcon from "@mui/icons-material/Close";
import questionmark from "../Icons/questionmark.svg";
import { GetEconomyIdlenessShow } from "../../../API/Economy/IdlenessShow";
import { Link } from "react-router-dom";
import comingsoon from "../Icons/photo-coming-soon.jpg";
import InfiniteScroll from "react-infinite-scroll-component";

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border: none;
    font-family: "Cerebri sans-medium";
    font-size: 13px;
    font-weight: 500;
    border: none;
    line-height: 20px;
  }
  &.${tableCellClasses.body} {
    border: none;
    font-family: "Cerebri sans-light";
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
  }
`;
const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
    border: none;
  }
`;

const PropertyDetail = ({ Picture, Name, Address, Projekt_nr }) => {
  return (
    <>
      <div className=" display-flex">
        <div className="lbl-reserved">
          {/* {Picture === "" ? (
            "Image not available"
          ) : ( */}
          <img
            src={Picture}
            // height={61}
            style={{ height: "61px", width: "61px", borderRadius: "4px" }}
          />
          {/* )} */}
        </div>
        <div>
          <span>
            <span className="medium fs-13">{Name}</span>
            <br />
            <span className="light fs-13">{Address}</span>
            <br />
            <span className="light fs-13">{Projekt_nr}</span>
          </span>
        </div>
      </div>
    </>
  );
};

const ShowMoreLabel = ({ Headline, Note }) => {
  return (
    <>
      <div className="regular">
        <span className="medium fs-13"> {Headline} </span>
        <br />
        <span className="light fs-13"> {Note} </span>
        <br />(
        <span className="light fw-300 fs-12 show-more-link">show more</span>)
      </div>
    </>
  );
};

// const NameLabel = () => {
//   return (
//     <>
//       <div className="regular fw-300 fs-13">
//         <div>Erik Bakholm</div>
//         <div>Anne Bakholm</div>
//       </div>
//     </>
//   );
// };

const UnitButton = ({ Url }) => {
  return (
    <>
      <a href={Url} target="_blank">
        <div className="btn-unit cursor">
          <div className="medium fw-500 margin-top-3px fs-12">GO TO UNIT</div>
        </div>
      </a>
    </>
  );
};

function createData(name, calories, fat, carbs) {
  return { name, calories, fat, carbs };
}

// const rows = [
//   createData(
//     <ReservedLabel />,
//     <ShowMoreLabel />,
//     <NameLabel />,
//     <UnitButton />
//   ),
//   createData(
//     <ReservedLabel />,
//     <ShowMoreLabel />,
//     <NameLabel />,
//     <UnitButton />
//   ),
//   createData(
//     <ReservedLabel />,
//     <ShowMoreLabel />,
//     <NameLabel />,
//     <UnitButton />
//   ),
//   createData(
//     <ReservedLabel />,
//     <ShowMoreLabel />,
//     <NameLabel />,
//     <UnitButton />
//   ),
//   createData(
//     <ReservedLabel />,
//     <ShowMoreLabel />,
//     <NameLabel />,
//     <UnitButton />
//   ),
// ];

function Reserved({ close, sid, datefilter, AssetID, Status }) {
  // API Consumption

  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetEconomyByIdlenessShow(sid, AssetID, datefilter);
  }, [sid, AssetID, datefilter]);

  const GetEconomyByIdlenessShow = () => {
    try {
      GetEconomyIdlenessShow(sid, AssetID, datefilter)
        .then((data) => {
          if (data != null && data != undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const[count, setCount] = useState(10);
  const [listState, setListState] = useState([]);

  useEffect(() => {
    setListState(myData.slice(0, count));
  }, [myData]);

  const fetchMoreData = async () => {
    setTimeout(async () => {
      let newArr = listState.concat(myData.slice(count, count + 10));
      setListState(newArr);
      setCount(count + 10);
    }, 100);
  };

  return (
    <>
      <div className="container reserved-container ">
        <div className="display-flex justify-content-between">
          <div className="display-flex margin-20">
            <div>
              <div className="bold fw-700 fs-16">
                {Status} ({myData.length})
              </div>
            </div>
            <Tooltip text={Status} paragraph="" />
            {/* <div className="reserved-container-question-mark-icon">
              <img height={12} src={questionmark} alt="questionmark" />
            </div> */}
          </div>
          <div className="div-close-button">
            <a className="close" onClick={close}>
              <div className="mt-7px ml-6px">
                <CloseIcon fontSize="large" />
              </div>
            </a>
          </div>
        </div>
        <div
          style={{ height: "344px" }}
          id="scrollableReservedDiv"
          className="scrollable-container"
        >
          <TableContainer className="padding-15px " component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              {/* <TableHead>
                <TableRow>
                  <TableCell>Dessert (100g serving)</TableCell>
                  <TableCell align="right">Calories</TableCell>
                  <TableCell align="right">Fat&nbsp;(g)</TableCell>
                  <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                </TableRow>
              </TableHead> */}
              <TableBody>
                <InfiniteScroll
                  dataLength={listState.length}
                  next={fetchMoreData}
                  hasMore={true}
                  scrollableTarget="scrollableReservedDiv"
                >
                  {listState?.map((row, i) => (
                    <CustomTableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <CustomTableCell width="30%" component="th" scope="row">
                        <PropertyDetail
                          Picture={
                            row.Picture === "" ? comingsoon : row.Picture
                          }
                          Name={row.Name}
                          Address={row.Address}
                          Projekt_nr={row.Projekt_nr}
                        />
                      </CustomTableCell>
                      <CustomTableCell width="30%" align="left">
                        {row.Headline == "" ? (
                          ""
                        ) : (
                          <ShowMoreLabel
                            Headline={row.Headline}
                            Note={row.Note}
                          />
                        )}
                      </CustomTableCell>
                      <CustomTableCell width="20%" align="left">
                        {row.UserName}
                      </CustomTableCell>
                      <CustomTableCell width="20%" align="right">
                        <UnitButton Url={row.Url} />
                      </CustomTableCell>
                    </CustomTableRow>
                  ))}
                </InfiniteScroll>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}

export default Reserved;
