import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Typography,
  Card,
  Paper,
  Dialog,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import "./customer.css";
import BreakDownSubTable from "./BreakDownSubTable";
import Popup from "reactjs-popup";
import { Button } from "grommet";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GetCustomersBreakdownRegion } from "../../../API/Customers/CustomersBreakdownRegion";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  // table: {
  //   minWidth: 650,
  // },
});

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #ffffff;
    color: #000000;
    border-bottom: none;
    font-family: "Cerebri sans-medium";
    font-size: 13px;
    font-weight: 500;
    border: none;
    line-height: 20px;
  }
  &.${tableCellClasses.body} {
    border-bottom: none;
    font-family: "Cerebri sans-light";
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9fafa;
  }
`;

// const CustomTableRow = styled(TableRow)`
//   font-family: "Cerebri Sans";
//   font-weight: 500px;
//   font-size: 13px;
// `;

function createData(status, quantity, percentage, child) {
  return { status, quantity, percentage, child };
}

const rows = [
  createData("Region Hovedstaden", 33, "22%"),
  createData("Region Sjælland", 33, "22 %"),
  createData("Eclair", 33, "22 %"),
  createData("Cupcake", 33, "22 %"),
  createData("Gingerbread", 33, "22 %"),
];

export default function SimpleTable({ sid, datefilter }) {
  //i18n
  const { t, i18n } = useTranslation();

  //API Consumption
  const [myData, setMyData] = useState([]);
  const [myregionID, setMyRegionID] = useState("");
  const [regionName, setRegionName] = useState("");

  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);
  useEffect(() => {
    GetCustomersBreakdownByRegion(sid, datefilter);
  }, [sid, datefilter]);

  const GetCustomersBreakdownByRegion = (sid, datefilter) => {
    try {
      GetCustomersBreakdownRegion(sid, datefilter)
        .then((data) => {
          if (data != null && data !== undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({ error: true, status: "", message: "Failed to retrieve data" });
    }
  };

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (RegionID, RegionName) => {
    setOpen(true);
    setMyRegionID(RegionID);
    setRegionName(RegionName);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <CustomTableCell>{t("status")}</CustomTableCell>
            <CustomTableCell align="center">{t("quantity")}</CustomTableCell>
            <CustomTableCell align="center">{t("percentage")}</CustomTableCell>
            <CustomTableCell align="center"></CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {myData.map((row, i) => (
            <CustomTableRow key={i}>
              <CustomTableCell align="left" component="td" scope="row">
                {row.RegionName}
              </CustomTableCell>
              <CustomTableCell align="center">{row.Quantity}</CustomTableCell>
              <CustomTableCell align="center">
                {row.Percentage}%
              </CustomTableCell>

              <CustomTableCell width="20%" align="center">
                <button
                  onClick={() => handleClickOpen(row.RegionID, row.RegionName)}
                  className="heatmap-btn"
                >
                  <div className="medium  fs-12 see-details uppercase">
                    {t("see-details")}
                  </div>
                </button>
              </CustomTableCell>
            </CustomTableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog
        maxWidth="lg"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "fit-content",
              overflow: "hidden",
              // position: 'relative'
            },
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <BreakDownSubTable
          handleClose={handleClose}
          sid={sid}
          RegionID={myregionID}
          RegionName={regionName}
          datefilter ={datefilter}
        />
      </Dialog>
    </>
  );
}
