import React, { useEffect, useRef, useState } from "react";
import "./Milestonepopup.css";
import AddIcon from "@mui/icons-material/Add";
import { Dialog, MenuItem, Grid, IconButton, Menu } from "@mui/material";
import questionmark from "../../Icons/questionmark.svg";
import CloseIcon from "@mui/icons-material/Close";
import Popup from "reactjs-popup";
import AutomaticMileStone from "./AutomaticMileStonePopup";
import ManualMileStone from "./ManualMileStonePopup";
import { Modal } from "semantic-ui-react";
import Tooltip from "../../components/Tooltip/Tooltip";
import { GetMilestones } from "../../../../API/OverView/GetMilestone";
import { MilestoneCard } from "./MilestoneCard";
import { useTranslation } from "react-i18next";

// const CreateMileStoneDropDown = ({
//   handleAutomaticOpen,
//   handleClose,
//   openAutomatic,
//   openManual,
//   handleManualOpen,
//   sid,
//   datefilter,
//   ActivitiesProperty,
// }) => {
//   return (
//     <>
//       <div className="milestone-drop-down">
//         <MenuItem
//           onClick={handleAutomaticOpen}
//           className="light fs-13 milestone-automatic"
//         >
//           <div className="light fw-13">Automatic</div>
//         </MenuItem>

//         <hr style={{ border: "1px solid #f4f5f5" }} />

//         <Dialog
//           BackdropProps={{
//             style: { backgroundColor: "rgba(0, 0, 0, 0.08)" },
//           }}
//           fullWidth
//           sx={{
//             "& .MuiDialog-container": {
//               "& .MuiPaper-root": {},
//             },
//           }}
//           maxWidth="lg"
//           open={openAutomatic}
//           onClose={handleClose}
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//         >
//           <AutomaticMileStone
//             handleClose={handleClose}
//             sid={sid}
//             datefilter={datefilter}
//             ActivitiesProperty={ActivitiesProperty}
//           />
//         </Dialog>

//         <MenuItem onClick={handleManualOpen} className="milestone-manual">
//           <div className="light fw-13">Manual</div>
//         </MenuItem>

//         <Dialog
//           BackdropProps={{
//             style: { backgroundColor: "rgba(0, 0, 0, 0.08)" },
//           }}
//           open={openManual}
//           onClose={handleClose}
//           fullWidth
//           maxWidth="lg"
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//         >
//           <ManualMileStone
//             handleClose={handleClose}
//             sid={sid}
//             datefilter={datefilter}
//             ActivitiesProperty={ActivitiesProperty}
//           />
//         </Dialog>
//       </div>
//     </>
//   );
// };

const MileStone = ({ handleClose, sid, datefilter, ActivitiesProperty }) => {
 //i18Next
 const { t, i18n } = useTranslation();

  const [openAutomatic, setOpenAutomatic] = React.useState(false);
  const handleAutomaticOpen = () => setOpenAutomatic(!openAutomatic);

  const [openManual, setOpenManual] = React.useState(false);
  const handleManualOpen = () => setOpenManual(!openManual);

  // API consumption for get Milestones
  const [myData, setMyData] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  useEffect(() => {
    GetByMilestones();
  }, [sid, datefilter]);

  const GetByMilestones = () => {
    try {
      GetMilestones(sid, datefilter)
        .then((data) => {
          if (data != null && data !== undefined) {
            setMyData(data.data);
          } else {
            setMyData([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setMyData([null]);
        });
    } catch (e) {
      setMyData([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };
  return (
    <div className="milestone-container">
      <div className="milestone-header">
        <div className="milestone-header-subcontainer">
          <div className="display-flex">
            <div className="bold fs-16">{t("milestone")}</div>
            <Tooltip text={t("milestone")} paragraph="" />
          </div>
          <div className="milestone-moreovericon" onClick={handleClose}>
            <a>
              <span
                style={{
                  position: "relative",
                  top: "10px",
                  left: "10px",
                }}
              >
                <CloseIcon style={{ fill: "#000000" }} />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div style={{ height: "450px" }} className="scrollable-container">
        <div className=" milestone-para light fs-14">
          Set up one or more milestones. It is possible to set up both automatic
          and manual milestones as well as <br />
          choose whether they are some that only you must see, or everyone with
          access to the property must be able to see.
        </div>

        <Grid container spacing={3} sx={{ padding: "20px" }}>
          {myData.map((item, i) => {
            return (
              <>
                <Grid item key={i}>
                  <MilestoneCard
                    key={i}
                    Name={item.Name}
                    Type={item.Type}
                    Target={item.Target}
                    Status={item.Status}
                    Parameter={item.Parameter}
                    Color={item.Color}
                    Visibility={item.Visibility}
                    UseAs={item.UseAs}
                    CreatedOn={item.CreatedOn}
                    CreatedBy={item.CreatedBy}
                    IsActive={item.IsActive}
                    sysID={item.SYS_ID}
                    Users={item.Users}
                    GetByMilestones={GetByMilestones}
                    item={item}
                  />
                </Grid>
              </>
            );
          })}
          <Grid item xs>
            <div className="create-milestone-section">
              <div style={{ position: "relative", top: "40%", left: "15%" }}>
                <IconButton
                  onClick={handleClick}
                  aria-haspopup="true"
                  aria-controls="long-menu"
                  sx={{
                    ml: 1,
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "transparent",
                    },
                  }}
                >
                  <div className="milestone-btn regular">
                    <span className="add-icon">
                      &nbsp;&nbsp;
                      <AddIcon />
                    </span>
                    <span className="medium fs-13">
                      &nbsp; {t("create-milestone")}
                    </span>
                  </div>
                </IconButton>
              </div>
              <Menu
                id="simple-menu"
                align="center"
                anchorEl={anchorEl}
                keepMounted
                onClose={handleCloseDropdown}
                open={open}
                sx={{
                  "& .MuiPaper-root": {
                    borderRadius: "8px",
                    width: "203px",
                    marginLeft: "8px",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={()=>{handleAutomaticOpen(); setAnchorEl(null)}}
                  className="light fs-13 milestone-automatic"
                >
                  <div className="light fw-13">{t("automatic")}</div>
                </MenuItem>

                <hr style={{ border: "1px solid #f4f5f5" }} />

                <Dialog
                  BackdropProps={{
                    style: { backgroundColor: "rgba(0, 0, 0, 0.08)" },
                  }}
                  fullWidth
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {},
                    },
                  }}
                  maxWidth="lg"
                  open={openAutomatic}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <AutomaticMileStone
                    handleClose={handleAutomaticOpen}
                    sid={sid}
                    datefilter={datefilter}
                    ActivitiesProperty={ActivitiesProperty}
                    GetByMilestones={GetByMilestones}
                  />
                </Dialog>

                <MenuItem
                  onClick={()=>{handleManualOpen(); setAnchorEl(null)}}
                  className="milestone-manual"
                >
                  <div className="light fw-13">{t("manual")}</div>
                </MenuItem>

                <Dialog
                  BackdropProps={{
                    style: { backgroundColor: "rgba(0, 0, 0, 0.08)" },
                  }}
                  open={openManual}
                  onClose={handleManualOpen}
                  fullWidth
                  maxWidth="lg"
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <ManualMileStone
                    handleClose={handleManualOpen}
                    sid={sid}
                    datefilter={datefilter}
                    ActivitiesProperty={ActivitiesProperty}
                    GetByMilestones={GetByMilestones}
                  />
                </Dialog>
              </Menu>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default MileStone;
