import React, { useCallback, useEffect, useRef } from "react";
import questionmark from "../../Icons/questionmark.svg";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  createTheme,
  Dialog,
  FormControl,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { borderColor, styled } from "@mui/system";
import Switch from "@mui/material/Switch";
import EventDatePicker from "../../Events/EventDatePicker";
import EventDatePickerMilestone from "./DatePickerMilestone";
import NavTabs from "./VisibilityTabsMenu";
import UseAsTabMenu from "./UseasTabMenu";
import Tooltip from "../../components/Tooltip/Tooltip";
import "./Milestonepopup.css";
import ActivityDropdown from "../../components/Dropdowns/AcitivityDropdown/ActivityDropdown";
import { GetAllPropertiesByRegionUnitType } from "../../../../API/AllPropertiesByRegionUnitType/AllPropertiesByRegionUnitType";
import UserDropDown from "./UserDropDown";
// import { ReactComponent as DownArrow } from "../../Icons/dropdown-chevron-down.svg";
import DownArrow from "../../Icons/dropdown-chevron-down.svg";
import { PostAddMilestone } from "../../../../API/OverView/AddMilestone";
import { useState } from "react";
import DropDown from "../../components/DropDown";
import dayjs from "dayjs";
import { ArrowUpDown } from "../../components/ArrowUpDown/ArrowUpDown";
import { GetuserbyProperty } from "../../../../API/Common/CommonUserbyProperty";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../utils/Helper";
import { useForm, Controller } from "react-hook-form";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";

const StyledTextField = styled(TextField, {
  name: "StyledTextField",
})({
  width: 254,
  "& .MuiInputBase-root": {
    "& fieldset": {
      borderColor: "#f4f5f5",
    },
    "&:hover fieldset": {
      borderColor: "#f4f5f5",
    },
    height: 44,
    borderRadius: "8px",
    borderColor: "1px solid #f4f5f5",
  },
});

const ColorsObj = ({ color, name }) => {
  return (
    <>
      <div className="color-section">
        <span
          style={{
            backgroundColor: color,
            minWidth: "1.4em",
            height: "18px",
            minHeight: "1em",
            display: "inline-block",
            borderRadius: "4px",
            fontSize: "13px",
          }}
        ></span>
        <div className="regular fw-400 fs-14">{name}</div>
      </div>
    </>
  );
};
const Colors = [
  {
    value: "Pink",
    name: <ColorsObj color="#B23977" name="Pink" />,
    id: "#B23977",
  },
  {
    value: "Black",
    name: <ColorsObj color="#000000" name="Black" />,
    id: "#000000",
  },
  {
    value: "Blue",
    name: <ColorsObj color="#A2C8D0" name="Blue" />,
    id: "#A2C8D0",
  },
  {
    value: "Black",
    name: <ColorsObj color="#AAC992" name="Green" />,
    id: "#AAC992",
  },
];

const AutomaticMileStone = ({ handleClose, ActivitiesProperty,GetByMilestones }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      milestoneName: "",
      selectedProp: "",
      statusValue: "",
      is: "",
      value: "",
      parameter: "",
      color: "",
      visibility: "",
      useAs: "",
      sharedWith: "",
    },
  });

  const onHandleMileStone = (data) => {};

  //i18Next
  const { t, i18n } = useTranslation();

  const Comparision = [
    {
      value: "is",
      name: `${t("is")}`,
      id: "1",
    },
    {
      value: "over",
      name: `${t("over")}`,
      id: "2",
    },
    {
      value: "under",
      name: `${t("under")}`,
      id: "3",
    },
  ];
  const Status = [
    {
      value: "Available",
      name: `${t("available")}`,
      id: "10",
    },
    {
      value: "Reserved",
      name: `${t("reserved")}`,
      id: "50",
    },
    {
      value: "Sold",
      name: `${t("sold")}`,
      id: "80",
    },
    {
      value: "Leased",
      name: `${t("leased")}`,
      id: "90",
    },
    {
      value: "idleness",
      name: `${t("idleness")}`,
      id: "11",
    },
  ];

  const Parameter = [
    {
      value: "Units",
      name: `${t("Units")}`,
      id: "1",
    },
    {
      value: "Percentage",
      name: `${t("percentage")}`,
      id: "2",
    },
  ];

  // UserSelect
  // Exclude user from dropdown
  const ExcludeUser = sessionStorage.getItem("uid");

  // API Consumption for User

  const [myUser, setMyUser] = useState([]);
  const [errorUser, setErrorUser] = useState([
    { error: false, status: "", message: "" },
  ]);
  useEffect(() => {
    GetuserbyByProperty();
  }, []);

  const GetuserbyByProperty = () => {
    try {
      GetuserbyProperty(0, ExcludeUser)
        .then((data) => {
          if (data != null && data != undefined) {
            const categoryList = data.data.map(({ U_ID, Navn }) => {
              return { id: U_ID.toString(), name: Navn };
            });
            setMyUser(categoryList);
          } else {
            setMyUser([null]);
          }
        })
        .catch((error) => {
          setErrorUser({ error: error.message, status: error.status });
          setMyUser([null]);
        });
    } catch (e) {
      setMyUser([null]);
      setErrorUser({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  // Checkbox logic for User Dropdown
  const Activities = myUser;

  const [allChecked, setAllChecked] = useState(false);
  const [optionChecked, setOptionChecked] = useState([]);

  const OnClicked = (e, isCheckAll) => {
    const { id, checked } = e.currentTarget;

    if (isCheckAll) {
      if (checked) {
        setOptionChecked(Activities.map((list) => list.id));
        setAllChecked(true);
      } else {
        setOptionChecked([]);
        setAllChecked(false);
      }
      // GetSigningsByTable(sid);
    } else {
      if (checked) {
        const checkValue = [...optionChecked, id];
        setOptionChecked(checkValue);

        if (checkValue.length == Activities.length) {
          setAllChecked(true);
          // GetSigningsByTable(sid);
        } else {
          // GetSigningsByTable(sid, checkValue.toString());
        }
      } else {
        const checkValue = optionChecked.filter((item) => item !== id);
        if (checkValue.length !== 0) {
          setOptionChecked(checkValue);
          // GetSigningsByTable(sid, checkValue.toString());
          setAllChecked(false);
        } else {
          setOptionChecked([]);
          setAllChecked(false);
          // GetSigningsByTable(sid);
        }
      }
    }
  };

  const [userSelect, setUserSelect] = useState(false);
  const handleUserSelect = (event) => {
    setUserSelect(event.currentTarget);
  };
  const handleUserSelectClose = () => {
    setUserSelect(null);
  };

  const [open, setOpen] = useState(false);

  //Post request for Add Milestone
  const [posterror, setPostError] = useState([
    { error: false, status: "", message: "" },
  ]);
  const [postData, setPostData] = useState([]);
  const OverviewAddMilestone = (data) => {
    try {
      PostAddMilestone(data)
        .then((response) => {
          setPostData(response);
          setOpen(response?.data?.bExist);
          if(!response?.data?.bExist){
            handleClose()
            GetByMilestones()
          }
        })
        .catch((error) => {
          setPostError({ error: error.message, status: error.status });
        });
    } catch (e) {
      setPostError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  // const [milestoneName, setMilestoneName] = useState("");
  const [targetEndDate, setTargetEndDate] = useState(null);
  const [eid, setEid] = useState("");
  const [isOngoingTarget, setIsOngoingTarget] = useState(false);
  const [status, setStatus] = useState("");
  const [statusConditionID, setStatusConditionID] = useState("1");
  const [statusValue, setStatusValue] = useState("");
  const [statusParameterID, setStatusParameterID] = useState("");
  const [Color, setColor] = useState("");
  const [visibilityID, setVisibilityID] = useState("");
  const [displayTypeID, setDisplayTypeID] = useState("");
  const [overWriteData, setOverWriteData] = useState([]);

  const handleAddAutomaticMilestone = (data) => {
    // setOverWrite("1");
    setOverWriteData(data)
    const myData = {
      E_ID: data?.selectedProp,
      MilestoneType: "0",
      Name: data?.milestoneName,
      TargetStartDate: "2000-01-01", // Always default
      TargetEndDate:
        isOngoingTarget === true
          ? "2000-01-01"
          : dayjs(targetEndDate).format("YYYY-MM-DD"),

      IsOngoingTarget: isOngoingTarget === false ? "0" : "1",
      TargetPeriodType: "0", //NA
      StatusID: data?.statusValue,
      StatusConditionID: data?.is,
      StatusValue: data?.value,
      StatusParameterID: data?.parameter,
      Color: data?.color,
      VisibilityID:
        data?.visibility === "Private"
          ? "1"
          : data?.visibility === "Public"
          ? "2"
          : data?.visibility === "Shared"
          ? "3"
          : "",
      DisplayTypeID:
        data?.useAs === "Map Milestone"
          ? "1"
          : data?.useAs === "Chart Milestone"
          ? "2"
          : "",
      Users: data?.visibility === "Shared" ? optionChecked.toString() : "",
      Goal: "", //NA
      OverWrite: "0",
    };
    OverviewAddMilestone(myData);
    // handleClose();
  };

  // Open Modal for Replace Milestone

  const handleReplaceMilestone = () => {
    // handleAddAutomaticMilestone();
    const myData = {
      E_ID: overWriteData?.selectedProp,
      MilestoneType: "0",
      Name: overWriteData?.milestoneName,
      TargetStartDate: "2000-01-01", // Always default
      TargetEndDate:
        isOngoingTarget === true
          ? "2000-01-01"
          : dayjs(targetEndDate).format("YYYY-MM-DD"),

      IsOngoingTarget: isOngoingTarget === false ? "0" : "1",
      TargetPeriodType: "0", //NA
      StatusID: overWriteData?.statusValue,
      StatusConditionID: overWriteData?.is,
      StatusValue: overWriteData?.value,
      StatusParameterID: overWriteData?.parameter,
      Color: overWriteData?.color,
      VisibilityID:
      overWriteData?.visibility === "Private"
          ? "1"
          : overWriteData?.visibility === "Public"
          ? "2"
          : overWriteData?.visibility === "Shared"
          ? "3"
          : "",
      DisplayTypeID:
      overWriteData?.useAs === "Map Milestone"
          ? "1"
          : overWriteData?.useAs === "Chart Milestone"
          ? "2"
          : "",
      Users: overWriteData?.visibility === "Shared" ? optionChecked.toString() : "",
      Goal: "", //NA
      OverWrite: "1",
    };
    OverviewAddMilestone(myData);
    
  };

  const handleCloseReplace = () => {
    setOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleAddAutomaticMilestone)}>
        <div className="automatic-milestone-container">
          <div className="automatic-milestone-header">
            <div className="milestone-header-section">
              <div className="bold fw-700 fs-16">
                {t("create-automatic-milestone")}
              </div>

              <div className="milestone-que-mrk">
                <Tooltip text={t("create-automatic-milestone")} paragraph="" />
              </div>
            </div>
            <div className="new-events-moreovericon" onClick={handleClose}>
              <a>
                <span
                  style={{
                    position: "relative",
                    top: "10px",
                    left: "10px",
                  }}
                >
                  <CloseIcon style={{ fill: "#000000" }} />
                </span>
              </a>
            </div>
          </div>

          <div
            className="scrollable-container"
            style={{ margin: "10px 0", padding: "0 30px", maxHeight: "400px" }}
          >
            <div className="name-of-milestone">
              <div
                className="medium fw-600 fs-14"
                style={{ marginRight: "10px" }}
              >
                {t("name-of-milestone")}
              </div>
              <div>
                <Controller
                  control={control}
                  {...register("milestoneName")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <StyledTextField
                    onKeyDown={(e)=> e.stopPropagation()}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("milestoneName");
                      }}
                      value={value}
                      InputLabelProps={{
                        style: { color: "rgb(144,144,144)", border: "#f4f5f5" },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          "& > fieldset": {
                            borderColor: "#888888",
                            color: "#f4f5f5",
                          },
                        },
                      }}
                      type="text"
                      label={t("name")}
                    />
                  )}
                />
                {errors?.milestoneName && (
                  <p
                    style={{
                      color: "red",
                    }}
                  >
                    {REQUIRED_ERR}
                  </p>
                )}
              </div>
            </div>
            <div style={{ paddingTop: "0" }} className="name-of-milestone">
              <div
                className="medium fw-600 fs-14"
                style={{ marginRight: "10px" }}
              >
                {t("select-property")}
              </div>
              <div style={{ paddingLeft: "19px" }}>
                <div>
                  <Controller
                    name="selectedProp"
                    {...register("selectedProp")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DropDown
                        width={254}
                        data={ActivitiesProperty}
                        label={t("select-property")}
                        // onChange={(e) => setEid(e.target.value)}
                        onChange={(e) => {
                          onChange(e);
                          // setEid(e.target.value);
                        }}
                        value={value}
                      />
                    )}
                  />
                  {errors?.selectedProp && (
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="target-date-field">
              <div className="Target-date-section">
                <div className="medium fw-600 fs-14">{t("target-date")}</div>
                <div className="milestone-que-mrk">
                  <Tooltip text={t("target-date")} paragraph="" />
                </div>
              </div>

              <div className="datefield-toogle-section">
                <div className="target-textfield">
                  <EventDatePickerMilestone
                    label={t("target-date")}
                    targetEndDate={targetEndDate}
                    setTargetEndDate={setTargetEndDate}
                  />
                </div>
                <ThemeProvider theme={theme}>
                  <div className="switch-target">
                    <div className="light fs-14">{t("ongoing-target")}</div>
                    <Switch
                      value={isOngoingTarget}
                      onChange={(e) => setIsOngoingTarget(e.target.checked)}
                    />
                  </div>
                </ThemeProvider>
              </div>
            </div>
            <div className="Status-value-parameter">
              <div
                className="medium fw-600 fs-14"
                style={{ marginLeft: "10px" }}
              >
                {t("status-value-and-parameter")}
              </div>

              <div className="statusparametr-textfield-sub-part">
                <div className="status-section-ss">
                  <FormControl m={2}>
                    <Controller
                      name="statusValue"
                      {...register("statusValue")}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <DropDown
                          width={254}
                          data={Status}
                          label={t("status")}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          value={value}
                        />
                      )}
                    />
                    {errors?.statusValue && (
                      <p
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </FormControl>
                </div>
                <Box>
                  <Controller
                    name="is"
                    {...register("is")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DropDown
                        width={100}
                        data={Comparision}
                        value={value}
                        // onChange={(e) => setStatusConditionID(e.target.value)}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors?.is && (
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </Box>
                <Box>
                  <Controller
                    control={control}
                    {...register("value")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <StyledTextField
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("value");
                          }}
                          type="text"
                          label={t("value")}
                          InputLabelProps={{
                            style: {
                              color: "rgb(144,144,144)",
                              border: "#f4f5f5",
                            },
                          }}
                          sx={{
                            width: 100,
                            "& .MuiOutlinedInput-root.Mui-focused": {
                              "& > fieldset": {
                                borderColor: "#888888",
                                color: "#f4f5f5",
                              },
                            },
                          }}
                        ></StyledTextField>
                      </div>
                    )}
                  />
                  {errors?.value && (
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </Box>

                <FormControl>
                  <Controller
                    name="Parameter"
                    {...register("parameter")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DropDown
                        width={254}
                        data={Parameter}
                        label={t("parameter")}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors?.parameter && (
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </FormControl>
              </div>
            </div>
            <div className="color-of-milestone">
              <div className="medium fw-600 fs-14">{t("color")}</div>
              <div className="color-textfield">
                <FormControl m={2}>
                  <Controller
                    name="color"
                    {...register("color")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DropDown
                        width={254}
                        data={Colors}
                        label={t("color")}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors?.color && (
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </FormControl>
              </div>
            </div>
            <div className="visibility-of-milestone">
              <div className="Target-date-section">
                <div className="medium fw-600 fs-14">{t("visibility")}</div>
                <div className="milestone-que-mrk">
                  <Tooltip text={t("visibility")} paragraph="" />
                </div>
              </div>

              <div className="use-as-container">
                <div>
                  <Controller
                    name="visibility"
                    {...register("visibility")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <NavTabs
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setVisibilityID(e.target.value);
                        }}
                        // value={visibilityID}
                        // onChange={(e) => setVisibilityID(e.target.value)}
                      />
                    )}
                  />
                  {errors?.visibility && (
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </div>

                {visibilityID === "Shared" && (
                  <>
                    <div
                      className="light fw-300 fs-14"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {t("share-it-with")}
                    </div>
                    <div>
                      <Button
                        id="basic-button"
                        aria-controls={userSelect ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={userSelect ? "true" : undefined}
                        onClick={handleUserSelect}
                        sx={{
                          "&:hover": { backgroundColor: "transparent" },
                          padding: "0px",
                        }}
                      >
                        <div className="all-properties-dropdown-dashboard">
                          <span className="light fs-14 fw-300 mt-3px">
                            {optionChecked.length > 1 ? "Users" : "User"} (
                            {optionChecked.length})
                          </span>

                          <ArrowUpDown component={userSelect} />
                        </div>
                      </Button>
                      <Controller
                        name="sharedWith"
                        {...register("sharedWith")}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Menu
                            id="basic-menu"
                            anchorEl={userSelect}
                            open={userSelect}
                            onClose={handleUserSelectClose}
                            elevation={0}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("sharedWith");
                            }}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            sx={{
                              marginTop: "8px",
                              backgroundColor: "rgba(0, 0, 0, 0.02)",
                            }}
                          >
                            {/* <MenuItem> */}
                            <div className="scrollable-container users-dropdown-milestone">
                              <ActivityDropdown
                                parentId="0"
                                allChecked={allChecked}
                                value={value}
                                optionChecked={optionChecked}
                                OnClicked={OnClicked}
                                Activities={Activities}
                                Label={t("all")}
                              />
                            </div>
                            {/* </MenuItem> */}
                          </Menu>
                        )}
                      />
                      {errors?.sharedWith && (
                        <p
                          style={{
                            color: "red",
                          }}
                        >
                          {REQUIRED_ERR}
                        </p>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="visibility-of-milestone">
              <div className="Target-date-section">
                <div className="medium fw-600 fs-14">{t("use-as")}</div>
                <div>
                  <div className="milestone-que-mrk">
                    <Tooltip text={t("use-as")} paragraph="" />
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "11px" }}>
                <Controller
                  name="useAs"
                  {...register("useAs")}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <UseAsTabMenu
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
                {errors?.useAs && (
                  <p
                    style={{
                      color: "red",
                    }}
                  >
                    {REQUIRED_ERR}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div
            className="create-event-btn auto-milestone-footer"
            style={{ backgroundColor: "#f9fafa" }}
          >
            <div
              className="semi-bold  fs-14 footer-cancel uppercase"
              onClick={handleClose}
            >
              {t("cancel")}
            </div>
            <div className="footer-reset-save-section">
              <div
                onClick={() => reset()}
                className="semi-bold fs-14 footer-reset-setting uppercase"
              >
                {t("reset-settings")}
              </div>
              <button
                type="submit"
                className="semi-bold  fs-14 footer-save uppercase"
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </form>

      {/* Alert popup */}

      <Dialog
        maxWidth="lg"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "fit-content",
              overflow: "hidden",
              // position: 'relative'
            },
          },
        }}
        open={open}
        onClose={handleCloseReplace}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="alert-card-container">
          <div className="bold fs-20 alert-card-heading">
            {t("whoa-that's-not-possible!")}
          </div>
          <div className="alert-card-para-section">
            <div className=" light fs-13 regular fw-300 fs-13">
              You cannot have several milestones on the same property on the map
              at the same time
            </div>
            <br />
            <div className="light fs-13">
              Edit the milestone that is already active or replace this
              milestone to activate it and set the old one inactive.
            </div>
            <br />
            <div className="light fs-13">
              It is always possible to reactivate a previous milestone.
            </div>
          </div>
          <div className="alert-card-footer">
            <div onClick={handleCloseReplace} className="semi-bold fs-14 alert-card-NO uppercase cursor-pointer">
              {t("no")}
            </div>
            <div
              onClick={handleReplaceMilestone}
              onClose={handleCloseReplace}
              className="semi-bold fs-14 alert-card-REPLACE uppercase cursor-pointer"
            >
              {t("replace")}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AutomaticMileStone;
