import axios from "axios";

//Get request
const GetHeaders = (header) => {
  const commonHeader = {};
  return Object.assign(commonHeader, header);
};

export const Get = async (Endpoint, header, data) => {
  const config = {
    headers: GetHeaders(header),
  };

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}${Endpoint}${
      data === undefined || null || "" ? "" : "/" + data
    }`,
    config
  );
};

//Put request
export const Put = async (Endpoint, header, data) => {
  const config = {
    headers: GetHeaders(header),
  };

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}${Endpoint}`,
    data,
    config
  );
};

//Post request
export const Post = async (Endpoint, header, data) => {
  const config = {
    headers: GetHeaders(header),
  };
  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${Endpoint}`,
    data,
    config
  );
};

//Delete request
export const Delete = async (Endpoint, header, id) => {
  const config = {
    headers: GetHeaders(header),
  };
  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}${Endpoint}/${id}`,
    config
  );
};
