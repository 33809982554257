import React, { useEffect, useState } from "react";

import { FormControl, Grid, MenuItem, TextField } from "@mui/material";

import TrafficPlatform from "./TrafficPlatform";
import TrafficOverview from "./TrafficOverview";
import PopularUnits from "./PopularUnits";
import PopularComponents from "./PopularComponents";
import PopularFilter from "./PopularFilter";
import "./traffic.css";
import Funnels from "./Funnels";
import HeatMap from "./HeatMap";
import HeatMapWebsite from "./HeatMapWebsite";
function Traffic({ sid, ActivitiesProperty, datefilter }) {
  const [value, setValue] = useState([0]);
  const [propertyName, setPropertyName] = useState([]);

  useEffect(() => {
    console.log("use effect");
    setPropertyName(ActivitiesProperty);
    setValue(ActivitiesProperty[0]?.id);
  }, [sid, ActivitiesProperty]);

  return (
    <>
      <div className="traffic-container">
        <div className="property-all-data">
          <FormControl m={2}>
            <TextField
              focused="disabled"
              sx={{
                width: 240,
                height: 44,
                backgroundColor: "#ffff",
                border: "1px solid #ffff",
                "& .MuiOutlinedInput-root.Mui-focused": {
                  "& > fieldset": {
                    borderColor: "white",
                  },
                },
              }}
              variant="outlined"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              select
            >
              {ActivitiesProperty.map((item, i) => {
                return (
                  <MenuItem sx={{ width: "236px" }} key={i} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormControl>
        </div>
        <Grid mb={4}>
          <TrafficPlatform sid={value} datefilter={datefilter} />
        </Grid>
        <Grid mb={4} sx={{ marginTop: "30px" }}>
          <TrafficOverview sid={value} datefilter={datefilter} />
        </Grid>
        <Grid sx={{ marginTop: "30px" }}>
          <PopularUnits sid={value} datefilter={datefilter} />
        </Grid>
        <Grid sx={{ marginTop: "30px" }}>
          <HeatMap sid={value} datefilter={datefilter} />
        </Grid>
        <Grid sx={{ marginTop: "30px" }}>
          <HeatMapWebsite sid={value} datefilter={datefilter} />
        </Grid>
        <Grid container spacing={6} mb={4}>
          <Grid item xs={12} lg={6} className="popular-comp">
            <div className="popular-components-card">
              <PopularComponents sid={value} />
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className="popular-filter-card">
              <PopularFilter sid={value} datefilter={datefilter} />
            </div>
          </Grid>
        </Grid>
        <Grid mb={4}>
          <Grid item xs={12} lg={6} sx={{ marginTop: "28px" }}>
            <Funnels sid={value} datefilter={datefilter} />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Traffic;
